import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "../styles/styles";

const HomeNavbar = ({ navigation }) => {
	return (
		<View
			style={{
				flexDirection: "row",
				paddingTop: 20,
				paddingBottom: 10,
				paddingHorizontal: "2%",
				alignItems: "center",
				backgroundColor: "white",
			}}
		>
			<img
				src={require("../assets/home_page/logo1.png")}
				style={{ width: "20%" }}
				resizemode="contain"
			/>
			<TouchableOpacity
				style={[
					styles.justifyCenter,
					styles.brPrimary,
					styles.boxShadow2,
					{ height: 46, marginLeft: 32, paddingHorizontal: 16 },
					styles.alignItemsCenter,
				]}
				onPress={() => {
					navigation.navigate("Home");
				}}
			>
				<View
					style={[
						styles.row,
						styles.justifyCenter,
						styles.alignCenter,
						styles.alignItemsCenter,
					]}
				>
					<img
						src={require("../assets/mbbs/home_icon.svg")}
						style={{ height: 25, marginRight: 16 }}
					/>
					<Text style={[styles.text, styles.textPrimary, styles.font20]}>
						HOME
					</Text>
				</View>
			</TouchableOpacity>
		</View>
	);
};

export default HomeNavbar;
