import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import Input from "../../components/Input";
import apisrv from "../../service/ApiService";
import { styles } from "../../styles/styles";

class Masters extends React.Component {
  constructor(props) {
    super();
    this.state = {
      masters: [
        {
          label: "Country",
          value: "country",
          fields: ["label", "value", "icon", "extension"],
          options: [
            {
              label: "Bangladesh",
              value: "Bangladesh",
            },
            {
              label: "China",
              value: "China",
            },
            {
              label: "Egypt",
              value: "Egypt",
            },
            {
              label: "Georgia",
              value: "Georgia",
            },
            {
              label: "Kazakhstan",
              value: "Kazakhstan",
            },
            {
              label: "Malaysia",
              value: "Malaysia",
            },
            {
              label: "Mauritus",
              value: "Mauritus",
            },
            {
              label: "Nepal",
              value: "Nepal",
            },
            {
              label: "Philippines",
              value: "Philippines",
            },
            {
              label: "Russia",
              value: "Russia",
            },
            {
              label: "Ukraine",
              value: "Ukraine",
            },
            {
              label: "Others",
              value: "Others",
            },
          ],
        },
        {
          label: "Years",
          value: "years",
          fields: ["label", "value"],
          options: [
            {
              label: "2022",
              value: "2022",
            },
            {
              label: "2023",
              value: "2023",
            },
            {
              label: "2024",
              value: "2024",
            },
            {
              label: "2025",
              value: "2025",
            },
            {
              label: "Later",
              value: "Later",
            },
          ],
        },
        {
          label: "Months",
          value: "months",
          fields: ["label", "value"],
          options: [
            {
              label: "Apr - Jul",
              value: "Apr - Jul",
            },
            {
              label: "Aug - Nov",
              value: "Aug - Nov",
            },
            {
              label: "Dec - Mar",
              value: "Dec - Mar",
            },
          ],
        },
      ],
      selectedField: 0,
      masterData: null,
    };
  }
  componentDidMount() {
    this.setState({ selectedField: 0, masterData: this.state.masters[0] });
    this.getMasters();
  }
  getMasters = () => {
    let req = {
      userId: this.props.appData.userData.userId,
    };

    apisrv.callApi({
      endPoint: "get-all-masters",
      request: req,
      onSuccess: (res) => {
        console.log(res);
        if (res.masters && res.masters.length) {
          this.setState({
            masters: res.masters,
            selectedField: 0,
            masterData: res.masters[0],
          });
        }
      },
    });
  };
  addRow = () => {
    let stateObj = this.state;
    let masterObj = {};
    stateObj.masterData.fields.forEach((f) => {
      masterObj[f] = "";
    });
    stateObj.masterData.options.push(masterObj);
    this.setState(stateObj);
  };
  setData = () => {
    let req = this.state.masterData;
    req.userId = this.props.appData.userData.userId;

    apisrv.callApi({
      endPoint: "update-master",
      request: req,
      onSuccess: (res) => {
        console.log(res);
        this.getMasters();
      },
    });
  };
  setInputData = (oi, f, v) => {
    let stateObj = this.state;
    stateObj.masterData.options[oi][f] = v.value;
    this.setState(stateObj);
  };
  render() {
    return (
      <View
        style={[
          styles.flexGrow,
          styles.flex,
          {
            width: "calc(100% - 150px)",
            marginLeft: 150,
            paddingTop: 12,
            paddingHorizontal: 8,
          },
        ]}
      >
        <View
          style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}
        >
          <Text style={[styles.textPrimary, styles.title]}>
            Masters Configuration
          </Text>
        </View>
        <View
          style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}
        >
          <View
            style={[
              {
                width: "15%",
                fontSize: 22,
                borderColor: "#434040",
                borderWidth: 0.5,
                borderRadius: 10,
                height: "78vh",
              },
              styles.padding8,
            ]}
          >
            <View
              style={[
                styles.row,
                styles.justifyCenter,
                { paddingVertical: 18 },
              ]}
            >
              <Text style={[styles.textRed, styles.font18, styles.fontBold]}>
                Master List
              </Text>
            </View>
            {(this.state?.masters || []).map((f, fi) => {
              return (
                <View
                  key={"fMenu" + (fi + 1)}
                  style={[
                    styles.row,
                    styles.justifyCenter,
                    (this.state.selectedField == fi && styles.bgDarkBlue) || "",
                    { paddingVertical: 12, borderRadius: 10 },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ selectedField: fi, masterData: f });
                    }}
                  >
                    <Text
                      style={[
                        styles.text,
                        (this.state.selectedField == fi && styles.textWhite) ||
                          styles.textPrimary,
                        styles.font18,
                      ]}
                    >
                      {f.label}
                    </Text>
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>
          {this.state.masterData && (
            <View
              style={[
                styles.flex,
                styles.flexGrow,
                {
                  marginHorizontal: 6,
                  height: "78vh",
                  overflow: "auto",
                  width: "85%",
                },
              ]}
            >
              {(this.state.masterData?.options || []).map((o, oi) => {
                return (
                  <View key={"option" + (oi + 1)} style={[styles.cardBlue]}>
                    <View
                      style={[
                        styles.row,
                        styles.justifyStart,
                        styles.wrap,
                        { paddingVertical: 16 },
                      ]}
                    >
                      {(this.state.masterData?.fields || []).map((f, fi) => {
                        let data = {
                          type: "inputBlack",
                          value: o[f],
                          label: f,
                        };
                        return (
                          <View
                            key={"field" + (fi + 1)}
                            style={[
                              {
                                width: "31%",
                                marginHorizontal: 6,
                                marginVertical: 12,
                              },
                            ]}
                          >
                            <Input
                              data={data}
                              onChange={(v) => {
                                this.setInputData(oi, f, v);
                              }}
                            />
                          </View>
                        );
                      })}
                    </View>
                    <View
                      style={[
                        styles.row,
                        styles.justifyEnd,
                        { paddingVertical: 8 },
                      ]}
                    >
                      <TouchableOpacity
                        style={[styles.btnRed, styles.mh]}
                        onPress={() => {
                          let stateObj = this.state;
                          stateObj.masterData.options.splice(oi, 1);
                          this.setState(stateObj);
                        }}
                      >
                        <Text
                          style={[
                            { color: "#ffffff" },
                            styles.fontBold,
                            styles.font18,
                            styles.text,
                          ]}
                        >
                          Delete
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}
            </View>
          )}
        </View>
        <View style={[styles.row, styles.justifyEnd, { paddingVertical: 8 }]}>
          <TouchableOpacity
            style={[styles.btnGrey, styles.mh]}
            onPress={() => {
              this.props.navigation.navigate("Admin");
            }}
          >
            <Text
              style={[
                { color: "#ffffff" },
                styles.fontBold,
                styles.font20,
                styles.text,
              ]}
            >
              Back
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.btnPrimary, styles.mh]}
            onPress={this.setData}
          >
            <Text
              style={[
                { color: "#ffffff" },
                styles.fontBold,
                styles.font20,
                styles.text,
              ]}
            >
              Save
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.btnPrimary]} onPress={this.addRow}>
            <Text
              style={[
                { color: "#ffffff" },
                styles.fontBold,
                styles.font20,
                styles.text,
              ]}
            >
              Add Row
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appData: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateData: async (parameter, callback) => {
      await dispatch(parameter);
      if (callback) {
        callback();
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Masters);
