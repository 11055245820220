// Example of Tooltip in React Native for Android and IOS
// https://aboutreact.com/example-of-tooltip-in-react-native-for-android-and-ios/

// import React in our code
import React, { useState } from "react";

// import all the components we are going to use
import { Platform, StyleSheet, Text, TouchableHighlight } from "react-native";
import ReactTooltip from "react-tooltip";

//Import Tooltip
import Tooltip from "react-native-walkthrough-tooltip";

const MyTooltip = props => {
	const [toolTipVisible, setToolTipVisible] = useState(false);
	return Platform.OS !== "web" ? (
		<Tooltip
			animated={true}
			//(Optional) When true, tooltip will animate in/out when showing/hiding
			//(Optional) Dimensions of arrow bubble pointing to the highlighted element
			backgroundColor="rgba(0,0,0,0.5)"
			//(Optional) Color of the fullscreen background beneath the tooltip.
			isVisible={toolTipVisible}
			//(Must) When true, tooltip is displayed
			content={<Text>{props.content}</Text>}
			tooltipStyle={{ flex: 0.5 }}
			//(Must) This is the view displayed in the tooltip
			placement="bottom"
			//(Must) top, bottom, left, right, auto.
			onClose={() => setToolTipVisible(false)}
			//(Optional) Callback fired when the user taps the tooltip
		>
			<TouchableHighlight onPress={() => setToolTipVisible(true)}>
				{props.renderComponent()}
			</TouchableHighlight>
		</Tooltip>
	) : (
		<div>
			<ReactTooltip />
			<div data-tip={props.content} type="light">
				{props.renderComponent()}
			</div>
		</div>
	);
};
export default MyTooltip;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		textAlign: "center",
		paddingTop: 30,
		backgroundColor: "#307ecc",
		padding: 16,
	},
	buttonTextStyle: {
		color: "white",
		textAlign: "center",
	},
	titleStyle: {
		color: "white",
		textAlign: "center",
		fontSize: 20,
		marginTop: 10,
	},
});
