import axios from "axios";
import {
	FETCH_APP_CONFIG,
	FETCH_FILTERS_DATA,
	SAVE_USER_FILTERS,
} from "../constants";

const base_url = "https://hammerhead-app-ikpjl.ondigitalocean.app";

export const getAllPageConfig = () => async (dispatch, getState) => {
	try {
		dispatch({ type: FETCH_APP_CONFIG.REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const { data } = await axios.post(
			`${base_url}/get-all-page-config`,
			config
		);

		dispatch({
			type: FETCH_APP_CONFIG.SUCCESS,
			payload: {
				success: "success",
				data: data?.config,
			},
		});
	} catch (error) {
		dispatch({ type: FETCH_APP_CONFIG.FAILURE, payload: error.message });
	}
};

export const fetchFiltersData = (page, label) => async dispatch => {
	try {
		dispatch({ type: FETCH_FILTERS_DATA.REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const { data } = await axios.post(
			`${base_url}/get-field-options`,
			{ page, label },
			config
		);

		dispatch({
			type: FETCH_FILTERS_DATA.SUCCESS,
			payload: {
				success: "success",
				options: data?.options,
				label: data?.label,
			},
		});
	} catch (error) {
		dispatch({ type: FETCH_FILTERS_DATA.FAILURE, payload: error.message });
	}
};

export const saveUserFilters = (label, data) => async dispatch => {
	try {
		dispatch({
			type: SAVE_USER_FILTERS.SUCCESS,
			payload: {
				success: "success",
				data: { label, data },
			},
		});
	} catch (error) {
		dispatch({ type: SAVE_USER_FILTERS.FAILURE, payload: error.message });
	}
};
