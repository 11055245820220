import { Ionicons } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";
import React, { useEffect, useRef, useState } from "react";
import { Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserApplicationData,
  uploadDocuments,
} from "../actions/applictionAction";
import ApplicationContent from "../components/ApplicationContent";
import Toggle from "../components/Toggle";
import VisaContent from "../components/VisaContent";
import { Visa } from "../constants";
import { styles } from "../styles/styles";
const pageConfig = {
  toggle: {
    options: [
      {
        label: "University Application",
        value: "University Application",
      },
      {
        label: "VISA Application",
        value: "VISA Application",
      },
    ],
    backgroundColor: "#FFFFFF",
    borderRadius: 34,
    borderColor: "#DDDDDD",
  },
};

const Application = ({ navigation }) => {
  const collegeData = useSelector((state) => state.applicationData?.colleges);
  const docs = useSelector((state) => state.applicationData?.data);
  const userDetails = useSelector((state) => state.userData?.userDetails);
  const selectDocument = useSelector((state) => state.documentData);
  const [colleges, setColleges] = useState([]);
  const [educationDetail, setEducationDetail] = useState({});
  const [personalDetail, setPersonalDetail] = useState({});
  const [testDetail, setTestDetail] = useState({});
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState(
    "University Application"
  );
  const dispatch = useDispatch();
  const scrollViewRef = useRef(null);

  const toggle = (t) => {
    setSelectedToggle(t.value);
  };

  useEffect(() => {
    dispatch(getUserApplicationData());
  }, []);

  useEffect(() => {
    if (collegeData) {
      setColleges(collegeData.colleges);
      setEducationDetail(collegeData.educationDetails);
      setPersonalDetail(collegeData.personalDetails);
      setTestDetail(collegeData.testDetails);
    }
    if (docs) {
      console.log(docs);
    }
  }, [collegeData]);

  function Month(month) {
    switch (month) {
      case 1:
        return "Janaury";
      case 2:
        return "Febraury";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        break;
    }
  }

  function Redirect(name) {
    switch (name) {
      case "Incomplete Profile":
        return "Complete Your Profile";
      case "Document Upload Pending":
        return "Upload Your Document";
      case "Pending from University":
        return "Request an update";
      case "College Awaiting Document":
        return "Upload Your Document";
      case "Offer Accepted":
        return "View Offer";
      default:
        break;
    }
  }

  function RedirectVisa(name) {
    switch (name) {
      case "Document Upload Pending":
        return "Upload Your Document";
      case "Document Verification Pending":
        return "";
      case "Interview Scheduled":
        return "View Details";
      case "Visa Approved":
        return "All set to Fly";
      default:
        break;
    }
  }

  function onUploadDocument(index, label, section) {
    DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    }).then((response) => {
      if (response.type == "success") {
        let { name, size, uri } = response;
        let nameParts = name.split(".");
        let fileType = nameParts[nameParts.length - 1];
        var fileToUpload = {
          name: name,
          size: size,
          uri: uri,
          type: "application/" + fileType,
        };
        console.log(fileToUpload, "...............file");
        let temp = [];
        temp.push(fileToUpload);
        setFiles(fileToUpload);
        uploadDocToServer(fileToUpload, label, section);
      }
    });
  }

  function uploadDocToServer(file, label, section) {
    setUploading(true);
    dispatch(uploadDocuments(file, userDetails.userId, section, label));
  }

  useEffect(() => {
    setTimeout(() => {
      setUploading(false);
    }, 1200);
  }, [selectDocument]);

  const toNextPage = (scrollOffset) => {
    scrollViewRef.current.scrollLeft += scrollOffset;
  };

  return (
    <View
      style={[
        styles.flexGrow,
        styles.flex,
        {
          width: "calc(100% - 180px)",
          marginLeft: 150,
          paddingTop: 12,
          marginRight: 32,
          zIndex: 0,
        },
      ]}
    >
      <View style={[styles.row, styles.justifyStretch, { paddingBottom: 25 }]}>
        <Text style={[styles.textPrimary, styles.title]}>
          Application Status
        </Text>
      </View>
      <View style={[styles.row, styles.justifyStretch, { marginBottom: 18 }]}>
        <Toggle
          data={pageConfig.toggle}
          onToggle={(d) => {
            toggle(d);
          }}
          selectedOption={selectedToggle}
        />
        {collegeData.careerCouncellor && (
          <View
            style={[
              styles.row,
              {
                borderWidth: 1,
                alignItems: "center",
                padding: 10,
                backgroundColor: "#FFFFFF",
                borderColor: "#DDDDDD",
                borderRadius: 40,
              },
            ]}
          >
            <Image
              source={{
                uri: `${collegeData.careerCouncellor.profileImg}`,
              }}
              style={{ height: 50, width: 50 }}
              resizeMode="contain"
            />

            <View style={{ marginLeft: 10 }}>
              <Text
                style={[
                  styles.textPrimary,
                  styles.fontBold,
                  { fontSize: 22, lineHeight: 27, color: "#0C5397" },
                ]}
              >
                Mr. {collegeData.careerCouncellor.name}
              </Text>
              <Text style={[styles.font14, styles.textOrange]}>
                Career Counsellor
              </Text>
            </View>
            <View style={{ marginLeft: 20 }}>
              <Ionicons name="caret-down-outline" size={30} color="#0C5397" />
            </View>
          </View>
        )}
      </View>

      {selectedToggle === "University Application" ? (
        <ApplicationContent
          colleges={colleges}
          educationDetail={educationDetail}
          personalDetail={personalDetail}
          testDetail={testDetail}
          files={files}
          Month={Month}
          Redirect={Redirect}
          onUploadDocument={onUploadDocument}
          toNextPage={toNextPage}
          scrollViewRef={scrollViewRef}
          onNavigate={() => {
            navigation.navigate("Profile");
          }}
          uploading={uploading}
        />
      ) : (
        <VisaContent
          colleges={Visa.colleges}
          educationDetail={Visa.educationDetails}
          personalDetail={Visa.personalDetails}
          testDetail={Visa.testDetails}
          files={files}
          Month={Month}
          Redirect={RedirectVisa}
          onUploadDocument={onUploadDocument}
          toNextPage={toNextPage}
          scrollViewRef={scrollViewRef}
          uploading={uploading}
        />
      )}
    </View>
  );
};

export default Application;
