import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { saveUserFilters } from "../actions/appActions";
import { getCollegeDetails } from "../actions/collegeActions";
import Footer from "../components/Footer";
import FooterInfo from "../components/FooterInfo";
import Navbar from "../components/Navbar";
import { FeesTable } from "../styles/styledComponents";
import { styles } from "../styles/styles";

class CollegeDetails extends React.Component {
	constructor(props) {
		super();
		this.state = {
			isShortlisted: false,
			viewInformation: "",
			infoBtnText: "",
			selectedgalleryIndex: 0,
			menuOptions: [
				{
					label: "Important Facts",
				},
				{
					label: "Overview",
				},
				{
					label: "Admission Criteria",
				},
				{
					label: "Fee Structure",
				},
				{
					label: "Syllabus",
				},
				{
					label: "Gallery",
				},
			],
			syllabus: [
				{
					title: "First year syllabus (semester 1st and 2nd)",
					subjects: [
						"Human Anatomy",
						"Medical Biology",
						"Bio-organic Chemistry",
						"Medicine History",
						"Histology",
						"Physical Law",
					],
				},
				{
					title: "Second year syllabus (semester 3rd and 4th)",
					subjects: [
						"Human Anatomy",
						"Medical Biology",
						"Bio-organic Chemistry",
						"Medicine History",
						"Histology",
						"Physical Law",
					],
				},
				{
					title: "First year syllabus (semester 1st and 2nd)",
					subjects: [
						"Human Anatomy",
						"Medical Biology",
						"Bio-organic Chemistry",
						"Medicine History",
						"Histology",
						"Physical Law",
					],
				},
				{
					title: "Third year syllabus (semester 5th and 6th)",
					subjects: [
						"Human Anatomy",
						"Medical Biology",
						"Bio-organic Chemistry",
						"Medicine History",
						"Histology",
						"Physical Law",
					],
				},
				{
					title: "Fourth year syllabus (semester 7th and 8th)",
					subjects: [
						"Human Anatomy",
						"Medical Biology",
						"Bio-organic Chemistry",
						"Medicine History",
						"Histology",
						"Physical Law",
					],
				},
			],
			feeStructure: {
				fees: [
					{
						title: "1st Year Fees",
						inr: "Rs 5,21,428",
						dollar: "$ 7,000",
						subText: null,
					},
					{
						title: "2nd-6th Year Fees",
						inr: "Rs 5,21,428/Yr",
						dollar: "$ 7,000/Yr.",
						subText: "Tuition Fees (per year)",
					},
				],
				particulars: [
					{
						title: "Accommodation",
						inr: "Included in Fees",
						dollar: "Included in Fees",
					},
					{
						title: "Food",
						inr: "50/Month",
						dollar: "1200/Month",
					},
					{
						title: "Miscellaneous",
						inr: "1,200(For six-month)",
						dollar: "84,000 (For six-month)",
					},
					{
						title: "Booking Amount",
						inr: "10,000/-",
						dollar: "7,40,000/-)",
					},
					{
						title: "Admission Time",
						inr: "28,250/-",
						dollar: "20,90,500/-",
					},
				],
				total_package: { inr: "Rs 38,48,000/-", dollar: "$ 52,000/-" },
				bottom_text:
					"All figures are calculated on $1 =74/-. Actual exchange rate may vary at the time of admission",
			},
			selectedMenuIndex: 0,
			overview: {
				para1:
					"The Bogomolets National Medical University is definitely a good choice for studying medicine.Bogomolets National Medical University is one of the oldest medical institutes in Ukraine. Students from more than 60 countries are studying here. The presence of so many different cultures offers a holistic view on educational as well as extra curricular activities.\n\nOfficially accredited and/or recognized by the Ministry of Health of Ukraine, O.O. Bogomolets National Medical University (BNMU) is a large coeducational higher education institution.O.O. Bogomolets National Medical University (BNMU) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees , bachelor degrees, master degrees, doctorate degrees in several areas of study.This 178 years old higher-education institution has a selective admission policy based on entrance examinations and students’ past academic record and grades.",
				para2:
					"Bogomolets National Medical University provides hostel facility to all its international students. Total 7 hostels are available. All hostels already equipped with all necessary requirements. Like bed, almirah, chair, mattress, pillow etc.Indian food is available at NMU hostels. Making it more comfortable for Indian students. Students can also enjoy Indian food at nearby Indian restaurants. Hence, increasing their satisfaction of stay and can concentrate more on studies.",
				facilities: [
					{ icon: "https://www.flaticon.com/free-icons/wifi", name: "WIFI" },
					{ icon: "https://www.flaticon.com/free-icons/wifi", name: "Library" },
					{ icon: "https://www.flaticon.com/free-icons/wifi", name: "Gym" },
					{ icon: "https://www.flaticon.com/free-icons/wifi", name: "labs" },
					{ icon: "https://www.flaticon.com/free-icons/wifi", name: "Laundry" },
					{
						icon: "https://www.flaticon.com/free-icons/wifi",
						name: "Indian Mess",
					},
				],
			},
			month: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"June",
				"July",
				"Aug",
				"Sept",
				"Oct",
				"Nov",
				"Dec",
			],
		};
	}
	componentDidMount() {
		this.setState({ courseId: this.props.route.params?.courseId });
		this.getData(this.props.route.params?.courseId);
		this.setViewInfo();
	}

	componentDidUpdate(prevProps) {
		const isShortlistedState = this.state.isShortlisted;
		const { collegeDetails, shortListedColleges } = this.props;
		const shortListedCollegesList = shortListedColleges?.map(c => c?.courseId);
		const isShortlisted = shortListedCollegesList?.includes(
			collegeDetails?.courseId
		);
		if (collegeDetails?.courseId !== prevProps.collegeDetails?.courseId) {
			this.setViewInfo();
		}
		if (isShortlistedState !== isShortlisted)
			this.setState({ ...this.state, isShortlisted });
	}

	getData = (courseId = this.state.courseId) => {
		const { getCollegeDetails, route } = this.props;
		const endPoint =
			route.params?.type === "mbbs"
				? "get-mbbs-course-details"
				: "get-other-course-details";
		getCollegeDetails(endPoint, { courseId });
	};

	setViewInfo = (full = false) => {
		const { collegeDetails } = this.props;
		let stateObj = this.state;
		stateObj.viewInformation = full
			? collegeDetails?.about
			: collegeDetails?.about?.split("\r\n")[0];
		if (collegeDetails?.about?.split("\r\n").length - 1) {
			stateObj.infoBtnText = full ? "Read Less" : "Read More";
		} else {
			stateObj.infoBtnText = "";
		}
		this.setState(stateObj);
	};

	markAsShortlisted = () => {
		const { shortListedColleges, collegeDetails, saveUserFilters } = this.props;
		const isCollegeAlreadyShortlisted = shortListedColleges.filter(
			c => c.courseId === collegeDetails?.courseId
		);
		if (isCollegeAlreadyShortlisted?.length) {
			saveUserFilters(
				"shortListedColleges",
				shortListedColleges?.filter(
					c => c.courseId !== collegeDetails?.courseId
				)
			);
		} else {
			saveUserFilters("shortListedColleges", [
				...shortListedColleges,
				{ courseId: collegeDetails?.courseId, type: "college" },
			]);
		}
	};

	renderSection = () => {
		const { collegeDetails } = this.props;
		const { selectedgalleryIndex } = this.state;

		switch (this.state.selectedMenuIndex) {
			case 0:
				return collegeDetails?.hasOwnProperty("importantFacts") ? (
					<View style={[{}, styles.flex]}>
						<View
							style={[styles.row, styles.justifyStart, { marginBottom: 16 }]}
						>
							<Text
								style={[
									styles.text,
									styles.font30,
									styles.textPrimary,
									styles.fontBold,
								]}
							>
								Important Facts
							</Text>
						</View>

						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<Text style={[styles.text, styles.font25, styles.fontBold]}>
								Course Duration
							</Text>
						</View>
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<Text style={[styles.text, styles.font25]}>Full-time</Text>
							<img
								src={require("../assets/mbbs/right_arrow.svg")}
								style={{ height: 35, marginLeft: 20, marginRight: 20 }}
							/>
							<Text style={[styles.text, styles.font25]}>
								{collegeDetails?.importantFacts?.courseDurationFulltimeMonths}{" "}
								Months
							</Text>
						</View>
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<Text style={[styles.text, styles.font25]}>Internship</Text>
							<img
								src={require("../assets/mbbs/right_arrow.svg")}
								style={{ height: 35, marginLeft: 20, marginRight: 20 }}
							/>
							<Text style={[styles.text, styles.font25]}>
								{collegeDetails?.importantFacts?.courseDurationInternshipMonths}{" "}
								Months
							</Text>
						</View>
						<View
							style={[
								styles.row,
								styles.justifyStart,
								{ marginVertical: 16, paddingBottom: 30 },
								styles.brBotttom,
							]}
						>
							<Text style={[styles.text, styles.font25, styles.fontBold]}>
								Overall Duration:
							</Text>
							<img
								src={require("../assets/mbbs/right_arrow.svg")}
								style={{ height: 35, marginLeft: 20, marginRight: 20 }}
							/>
							<Text style={[styles.text, styles.font25]}>
								{collegeDetails?.importantFacts?.courseDurationOverallMonths}{" "}
								Months
							</Text>
						</View>
						<View
							style={[
								styles.row,
								styles.justifyStart,
								{ marginVertical: 16, paddingTop: 30 },
							]}
						>
							<Text style={[styles.text, styles.font25, styles.fontBold]}>
								Start date & application deadline
							</Text>
						</View>

						<Text style={[styles.text, styles.font25, { lineHeight: 35 }]}>
							Starting {collegeDetails?.importantFacts?.applicationStartDate}
						</Text>

						<Text style={[styles.text, styles.font25]}>
							Deadline {collegeDetails?.importantFacts?.applicationDeadline}
						</Text>

						<View
							style={[
								styles.row,
								styles.justifyStart,
								{ marginVertical: 16, paddingTop: 30 },
							]}
						>
							<Text style={[styles.text, styles.font25, styles.fontBold]}>
								Teaching medium
							</Text>
						</View>
						<View
							style={[
								styles.row,
								styles.justifyStart,
								{ marginVertical: 16, paddingBottom: 30 },
								styles.brBotttom,
							]}
						>
							{collegeDetails?.importantFacts?.teachingMedium.map(a => {
								return (
									<Text
										style={[styles.text, styles.font25, { marginRight: 8 }]}
									>
										{a}
									</Text>
								);
							})}
						</View>
						<View
							style={[
								styles.row,
								styles.justifyStart,
								{ marginVertical: 16, paddingTop: 30 },
							]}
						>
							<Text style={[styles.text, styles.font25, styles.fontBold]}>
								Recognised by
							</Text>
						</View>
						<View
							style={[
								styles.row,
								styles.justifyStart,
								{ marginVertical: 16, paddingBottom: 30 },
								styles.brBotttom,
							]}
						>
							{(collegeDetails?.importantFacts?.recognisedBy || []).map(
								(rb, rbi) => {
									return (
										<Text
											style={[styles.text, styles.font25, { marginRight: 8 }]}
											key={"recBy" + rbi}
										>
											{rb}
										</Text>
									);
								}
							)}
						</View>
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<Text style={[styles.text, styles.font25, styles.fontBold]}>
								Ranking
							</Text>
						</View>
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<Text style={[styles.text, styles.font25]}>Country</Text>
							<img
								src={require("../assets/mbbs/right_arrow.svg")}
								style={{ height: 35, marginLeft: 20, marginRight: 20 }}
							/>
							<Text style={[styles.text, styles.font25]}>
								{collegeDetails?.importantFacts?.ranking?.Country}
							</Text>
						</View>
						<View
							style={[
								styles.row,
								styles.justifyStart,
								{ marginVertical: 16, paddingBottom: 30 },
								styles.brBotttom,
							]}
						>
							<Text style={[styles.text, styles.font25]}>World</Text>
							<img
								src={require("../assets/mbbs/right_arrow.svg")}
								style={{ height: 35, marginLeft: 20, marginRight: 20 }}
							/>
							<Text style={[styles.text, styles.font25]}>
								{collegeDetails?.importantFacts?.ranking?.World}
							</Text>
						</View>
						<View
							style={[styles.row, styles.justifyEnd, { marginVertical: 16 }]}
						>
							<Text
								style={[
									styles.text,
									styles.font25,
									styles.textGreen,
									styles.textAlignEnd,
								]}
							>
								Intrested in this university? Schedule your FREE counselling.
							</Text>
						</View>
					</View>
				) : null;
			case 1:
				return collegeDetails?.hasOwnProperty("overview") ? (
					<View style={[{}, styles.flex]}>
						<View
							style={[styles.row, styles.justifyStart, { marginBottom: 16 }]}
						>
							<Text
								style={[
									styles.text,
									styles.font30,
									styles.textPrimary,
									styles.fontBold,
								]}
							>
								Overview
							</Text>
						</View>
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 8 }]}
						>
							<Text style={[styles.text, styles.font25, styles.fontBold]}>
								Benefits of studying MBBS from this university
							</Text>
						</View>
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<div
								style={{ fontSize: "18px" }}
								dangerouslySetInnerHTML={{
									__html: collegeDetails?.overview?.benefits?.content,
								}}
							/>
						</View>

						{collegeDetails?.overview?.benefits?.videoLinks?.map(link => (
							<iframe
								width="550"
								height="315"
								src={"https://www.youtube.com/watch?v=yXy1PxHUCfI"}
							></iframe>
						))}
						<View
							style={{
								width: "100%",
								borderBottomColor: "#474747",
								borderBottomWidth: 0.25,
								marginVertical: 20,
							}}
						/>
						{collegeDetails?.overview &&
						collegeDetails?.overview?.facilitiesAndAccomodation ? (
							<View>
								<Text
									style={[
										styles.text,
										styles.font25,
										styles.fontBold,
										{ marginBottom: 20 },
									]}
								>
									Facilities & Accommodation
								</Text>
								<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
									{collegeDetails?.overview?.facilitiesAndAccomodation?.facilities.map(
										item => (
											<View
												style={{
													flexDirection: "row",
													backgroundColor: "#E1EAEB",
													width: "30%",
													margin: 10,
													alignItems: "center",
													justifyContent: "space-around",
													paddingVertical: 8,
													borderRadius: 10,
												}}
											>
												<Image
													source={{ uri: item.icon }}
													style={{ width: 40, height: 40 }}
													resizeMode="contain"
												/>
												<Text
													style={[
														styles.text,
														styles.font18,
														{ textAlign: "left" },
													]}
												>
													{item.label}
												</Text>
											</View>
										)
									)}
								</View>
							</View>
						) : null}
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<div
								style={{ fontSize: "18px" }}
								dangerouslySetInnerHTML={{
									__html:
										collegeDetails?.overview?.facilitiesAndAccomodation
											?.content,
								}}
							/>
						</View>
					</View>
				) : null;
			case 2:
				return collegeDetails?.hasOwnProperty("admissionCriteria") ? (
					<View style={[{}, styles.flex]}>
						<View
							style={[styles.row, styles.justifyStart, { marginBottom: 16 }]}
						>
							<Text
								style={[
									styles.text,
									styles.font30,
									styles.textPrimary,
									styles.fontBold,
								]}
							>
								Admission Criteria
							</Text>
						</View>
						{/* {collegeDetails?.admissionCriteria?.academicRequirements ? (
							<View
								style={[styles.row, styles.justifyStart, { marginVertical: 8 }]}
							>
								<Text style={[styles.text, styles.font25, styles.fontBold]}>
									Academic Requirements
								</Text>
							</View>
						) : null} */}
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<div
								style={{ fontSize: "18px" }}
								dangerouslySetInnerHTML={{
									__html: collegeDetails?.admissionCriteria,
								}}
							/>
						</View>
						{/* <View
							style={{
								width: "100%",
								borderBottomColor: "#E8E8E8",
								borderBottomWidth: 1,
								marginVertical: 10,
							}}
						></View>
						{collegeDetails?.admissionCriteria?.otherRequirements ? (
							<View
								style={[styles.row, styles.justifyStart, { marginVertical: 8 }]}
							>
								<Text style={[styles.text, styles.font25, styles.fontBold]}>
									Other Requirements/Documentation
								</Text>
							</View>
						) : null}
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<div
								style={{ fontSize: "18px" }}
								dangerouslySetInnerHTML={{
									__html: collegeDetails?.admissionCriteria?.otherRequirements,
								}}
							/>
						</View>
						<View
							style={{
								width: "100%",
								borderBottomColor: "#E8E8E8",
								borderBottomWidth: 1,
								marginVertical: 10,
							}}
						></View> */}
					</View>
				) : null;
			case 3:
				return collegeDetails?.hasOwnProperty("feeStructure") ? (
					<View style={[{}, styles.flex]}>
						<View
							style={[styles.row, styles.justifyStart, { marginBottom: 16 }]}
						>
							<Text
								style={[
									styles.text,
									styles.font30,
									styles.textPrimary,
									styles.fontBold,
								]}
							>
								Fee Structure
							</Text>
						</View>
						<View
							style={[styles.row, styles.justifyStart, { marginVertical: 16 }]}
						>
							<FeesTable
								dangerouslySetInnerHTML={{
									__html: collegeDetails?.feeStructure?.feesContent,
								}}
							/>
						</View>
						{/* {this.state.feeStructure &&
							this.state.feeStructure.fees.map(item => (
								<View style={{ marginBottom: 20 }}>
									<Text style={[styles.text, styles.font18, styles.fontBold]}>
										{item.title}
									</Text>
									{item.subText ? (
										<Text
											style={[styles.text, styles.font18, { marginTop: 20 }]}
										>
											{item.subText}
										</Text>
									) : null}
									<View
										style={{
											width: "70%",
											flexDirection: "row",
											justifyContent: "space-between",
											marginVertical: 10,
										}}
									>
										<Text
											style={[styles.text, styles.font30, { color: "#0C5397" }]}
										>
											{item.dollar}
										</Text>
										<Text
											style={[styles.text, styles.font30, { color: "#0C5397" }]}
										>
											{item.inr}
										</Text>
									</View>
								</View>
							))}
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								backgroundColor: "#0C5397",
								padding: 8,
								marginTop: 15,
							}}
						>
							<Text
								style={[
									{ width: "33.33%", color: "#FFFFFF" },
									styles.text,
									styles.font18,
									styles.fontBold,
								]}
							>
								Fees Particulars
							</Text>
							<Text
								style={[
									{ width: "33.33%", color: "#FFFFFF" },
									styles.text,
									styles.font18,
								]}
							>
								USD
							</Text>
							<Text
								style={[
									{ width: "33.33%", color: "#FFFFFF" },
									styles.text,
									styles.font18,
								]}
							>
								INR
							</Text>
						</View>
						{this.state.feeStructure &&
							this.state.feeStructure.particulars.map((item, index) => (
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										backgroundColor: index % 2 == 1 ? "#E1EAEB" : null,
										padding: 8,
										borderWidth: 0.25,
										borderColor: "#E5E5E5",
										marginBottom:
											this.state.feeStructure.particulars.length - 1 == index
												? 20
												: 0,
									}}
								>
									<Text
										style={[
											{ width: "33.33%" },
											styles.text,
											styles.font18,
											styles.fontBold,
										]}
									>
										{item.title}
									</Text>
									<Text
										style={[
											{ width: "33.33%", textAlign: "left" },
											styles.text,
											styles.font18,
										]}
									>
										{item.inr}
									</Text>
									<Text
										style={[
											{ width: "33.33%", textAlign: "left" },
											styles.text,
											styles.font18,
										]}
									>
										{item.dollar}
									</Text>
								</View>
							))}
						{this.state.feeStructure &&
						this.state.feeStructure.total_package ? (
							<View style={{ marginTop: 30 }}>
								<Text
									style={[
										styles.text,
										styles.font25,
										styles.textPrimary,
										styles.fontBold,
									]}
								>
									Total Package
								</Text>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										justifyContent: "space-between",
										marginVertical: 10,
									}}
								>
									<Text
										style={[
											styles.text,
											styles.font45,
											styles.fontBold,
											{ color: "#0C5397" },
										]}
									>
										{this.state.feeStructure.total_package.dollar}
									</Text>
									<Text
										style={[
											styles.text,
											styles.font45,
											styles.fontBold,
											{ color: "#0C5397" },
										]}
									>
										{this.state.feeStructure.total_package.inr}
									</Text>
								</View>
							</View>
						) : null}
						{this.state.feeStructure && this.state.feeStructure.bottom_text ? (
							<Text style={{ fontStyle: "italic" }}>
								{this.state.feeStructure && this.state.feeStructure.bottom_text}
							</Text>
						) : null} */}
					</View>
				) : null;
			case 4:
				return collegeDetails?.hasOwnProperty("syllabus") ? (
					<View style={[{}, styles.flex]}>
						<View
							style={[styles.row, styles.justifyStart, { marginBottom: 16 }]}
						>
							<Text
								style={[
									styles.text,
									styles.font30,
									styles.textPrimary,
									styles.fontBold,
								]}
							>
								Syllabus
							</Text>
						</View>
						{collegeDetails?.syllabus?.map(item => (
							<View>
								<Text
									style={[
										{
											backgroundColor: "#E1EAEB",
											paddingVertical: 10,
											paddingHorizontal: 10,
											width: "100%",
											color: "#000",
											marginVertical: 15,
										},
										styles.text,
										styles.font18,
									]}
								>
									{item.title}
								</Text>
								<View
									style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}
								>
									{item.courses &&
										item.courses.map(subject => (
											<Text
												style={[
													{ width: "50%", color: "#000", marginVertical: 10 },
													styles.text,
													styles.font18,
												]}
											>
												{"\u2022"} {subject}
											</Text>
										))}
								</View>
							</View>
						))}
					</View>
				) : null;
			case 5:
				return collegeDetails?.hasOwnProperty("gallery") ? (
					<View style={[{}, styles.flex]}>
						<View
							style={[styles.row, styles.justifyStart, { marginBottom: 16 }]}
						>
							<Text
								style={[
									styles.text,
									styles.font30,
									styles.textPrimary,
									styles.fontBold,
								]}
							>
								Gallery
							</Text>
						</View>
						<View
							style={{
								flexDirection: "row",
								alignSelf: "center",
								marginBottom: 15,
							}}
						>
							{collegeDetails?.gallery &&
							collegeDetails?.gallery?.images.length > 0 ? (
								<TouchableOpacity
									onPress={() => this.setState({ selectedgalleryIndex: 0 })}
								>
									<View
										style={{
											borderWidth: 0.25,
											borderColor:
												selectedgalleryIndex == 0 ? "#0C5397" : "#707070",
											borderRadius: 50,
											paddingHorizontal: 15,
											paddingVertical: 5,
											marginHorizontal: 8,
											backgroundColor:
												selectedgalleryIndex == 0 ? "#0C5397" : "#fff",
										}}
									>
										<Text
											style={{
												fontSize: 20,
												color: selectedgalleryIndex == 0 ? "#fff" : "#000",
											}}
										>
											Images
										</Text>
									</View>
								</TouchableOpacity>
							) : null}
							{collegeDetails?.gallery &&
							collegeDetails?.gallery?.videos.length > 0 ? (
								<TouchableOpacity
									onPress={() => this.setState({ selectedgalleryIndex: 1 })}
								>
									<View
										style={{
											borderWidth: 0.25,
											borderColor:
												selectedgalleryIndex == 1 ? "#0C5397" : "#707070",
											borderRadius: 50,
											paddingHorizontal: 15,
											paddingVertical: 5,
											marginHorizontal: 8,
											backgroundColor:
												selectedgalleryIndex == 1 ? "#0C5397" : "#fff",
										}}
									>
										<Text
											style={{
												fontSize: 20,
												color: selectedgalleryIndex == 1 ? "#fff" : "#000",
											}}
										>
											Videos
										</Text>
									</View>
								</TouchableOpacity>
							) : null}
						</View>
						{selectedgalleryIndex == 0 ? (
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									flexWrap: "wrap",
									justifyContent: "stretch",
								}}
							>
								{collegeDetails?.gallery &&
									collegeDetails?.gallery?.images.map(item => (
										<View
											style={{
												width: "45%",
												marginHorizontal: 10,
												marginVertical: 10,
											}}
										>
											<Image
												source={{ uri: item.imgLink }}
												style={{ width: "100%", height: 200 }}
												resizeMode="contain"
											/>
											<Text
												style={[
													styles.font18,
													styles.text,
													{
														paddingVertical: 8,
														paddingHorizontal: 12,
														borderWidth: 0.5,
														borderColor: "#707070",
														marginTop: 10,
														width: "100%",
														textAlign: "center",
														borderRadius: 10,
														lineHeight: 25,
													},
												]}
											>
												{item.imgCaption}
											</Text>
										</View>
									))}
							</View>
						) : null}
						{selectedgalleryIndex == 1 ? (
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									flexWrap: "wrap",
									justifyContent: "stretch",
								}}
							>
								{collegeDetails?.gallery &&
									collegeDetails?.gallery?.videos.map(item => (
										<View
											style={{
												width: "45%",
												marginHorizontal: 10,
												marginVertical: 10,
											}}
										>
											<iframe
												src={item}
												style={{ width: "100%", height: 200 }}
											/>
										</View>
									))}
							</View>
						) : null}
					</View>
				) : null;
		}
	};

	render() {
		const { collegeDetails } = this.props;

		return (
			<>
				{/* <View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						paddingTop: 20,
						paddingBottom: 10,
						paddingHorizontal: "2%",
						backgroundColor: "white",
					}}
				>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
							flex: 1,
						}}
					>
						<img
							src={require("../assets/home_page/logo1.png")}
							style={{ width: "25%" }}
							resizemode="contain"
						/>
						<TouchableOpacity
							style={[
								styles.justifyCenter,
								styles.brPrimary,
								styles.boxShadow2,
								{ height: 46, marginLeft: 32, paddingHorizontal: 16 },
								styles.alignItemsCenter,
							]}
							onPress={() => {
								this.props.navigation.canGoBack() &&
									this.props.navigation.goBack();
							}}
						>
							<View
								style={[
									styles.row,
									styles.justifyCenter,
									styles.alignCenter,
									styles.alignItemsCenter,
								]}
							>
								<AntDesign name="back" size={30} color="#0C5397" />
								<Text
									style={[
										styles.text,
										styles.textPrimary,
										styles.font20,
										{ marginLeft: 10 },
									]}
								>
									Back
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<TouchableOpacity
							style={[
								styles.justifyCenter,
								styles.brPrimary,
								styles.boxShadow2,
								{ height: 46, marginLeft: 32, paddingHorizontal: 16 },
								styles.alignItemsCenter,
							]}
							onPress={() => {
								this.props.navigation.navigate("SignInBase");
							}}
						>
							<View
								style={[
									styles.row,
									styles.justifyCenter,
									styles.alignCenter,
									styles.alignItemsCenter,
								]}
							>
								<Text style={[styles.text, styles.textPrimary, styles.font20]}>
									Save my preferences
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				</View> */}
				<Navbar navigation={this.props.navigation} />
				<View style={[styles.flex, styles.bgWhite]}>
					<View style={[styles.row, styles.justifyStretch]}>
						<img
							src={collegeDetails?.bgImg}
							style={{ width: "100vw", maxHeight: "55vh" }}
						/>
					</View>
					{collegeDetails && (
						<View
							style={[
								styles.row,
								styles.justifyCenter,
								{ paddingHorizontal: "10%", marginTop: -110 },
							]}
						>
							<View
								style={{
									boxShadow: "0px 5px 35px #00000029",
									borderRadius: 10,
									borderWidth: 1,
									borderColor: "#FFFFFF",
									backgroundColor: "#ffffff",
									paddingBottom: -26,
									maxWidth: "40%",
									textAlign: "center",
								}}
							>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										styles.alignItemsCenter,
										{ paddingHorizontal: 20, paddingVertical: 16 },
									]}
								>
									<View
										style={{
											paddingVertical: 10,
											paddingHorizontal: 20,
											boxShadow: "0px 5px 35px #00000029",
											borderRadius: 10,
											borderWidth: 1,
											borderColor: "#FFFFFF",
											backgroundColor: "#ffffff",
											top: -80,
											left: 10,
											position: "absolute",
											width: 166,
										}}
									>
										<img src={collegeDetails.logoImg} style={{ height: 128 }} />
									</View>
									<Text
										style={[
											styles.text,
											styles.textPrimary,
											styles.font25,
											{ marginLeft: 182 },
										]}
									>
										Estd:{" "}
									</Text>
									<Text
										style={[
											styles.text,
											styles.textPrimary,
											styles.font25,
											styles.fontBold,
											{ marginRight: 116 },
										]}
									>
										{collegeDetails.establishmentYear}
									</Text>
									<TouchableOpacity
										onPress={() => {
											this.markAsShortlisted();
										}}
									>
										{this.state.isShortlisted ? (
											<Ionicons
												name="heart-sharp"
												size={40}
												style={{
													color: "#B51700",
												}}
											/>
										) : (
											<Ionicons name="heart-outline" size={40} color="black" />
										)}
									</TouchableOpacity>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 12, paddingHorizontal: 8 },
									]}
								>
									<Text
										style={[styles.text, styles.textPrimary, styles.font25]}
									>
										{collegeDetails.collegeName}
									</Text>
								</View>
								<View
									style={[styles.row, styles.justifyCenter, { marginTop: 7 }]}
								>
									<Text
										style={[
											styles.text,
											styles.textRed,
											styles.font30,
											styles.fontBold,
										]}
									>
										MBBS in {collegeDetails.country}
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 7, marginBottom: 26 },
									]}
								>
									<Text
										style={[styles.text, styles.textPrimary, styles.font25]}
									>
										{collegeDetails.city}, {collegeDetails.country}
									</Text>
								</View>
							</View>
							<View
								style={{
									boxShadow: "0px 5px 35px #00000029",
									borderRadius: 10,
									borderWidth: 1,
									borderColor: "#FFFFFF",
									backgroundColor: "#ffffff",
									paddingVertical: 32,
									paddingHorizontal: 40,
									marginLeft: 24,
									marginTop: 80,
								}}
							>
								<View style={[styles.row, styles.justifyStretch]}>
									<View>
										<img
											src={require("../assets/mbbs/univ_duration.svg")}
											style={{ height: 50, width: 50 }}
										/>
									</View>
									<View style={[{ marginLeft: 14 }]}>
										<View style={[styles.row, styles.justifyCenter]}>
											<Text
												style={[styles.text, styles.font30, styles.fontBold]}
											>
												{collegeDetails.courseDurationYears} Years
											</Text>
										</View>
										<View style={[styles.row, styles.justifyCenter]}>
											<Text
												style={[styles.text, styles.font25, styles.textPrimary]}
											>
												Course Duration
											</Text>
										</View>
									</View>
									<View style={[{ marginLeft: 78 }]}>
										<img
											src={require("../assets/mbbs/univ_apply_dt.svg")}
											style={{ height: 50, width: 50 }}
										/>
									</View>
									<View style={[{ marginLeft: 14 }]}>
										<View style={[styles.row, styles.justifyCenter]}>
											<Text
												style={[styles.text, styles.font30, styles.fontBold]}
											>
												{collegeDetails?.applyDate}
											</Text>
										</View>
										<View style={[styles.row, styles.justifyCenter]}>
											<Text
												style={[styles.text, styles.font25, styles.textPrimary]}
											>
												Apply Date
											</Text>
										</View>
									</View>
									<View style={[{ marginLeft: 78 }]}>
										<img
											src={require("../assets/mbbs/univ_1st_yr_fees.svg")}
											style={{ height: 50, width: 50 }}
										/>
									</View>
									<View style={[{ marginLeft: 14 }]}>
										<View style={[styles.row, styles.justifyCenter]}>
											<Text
												style={[styles.text, styles.font30, styles.fontBold]}
											>
												{collegeDetails.fees} USD
											</Text>
										</View>
										<View style={[styles.row, styles.justifyCenter]}>
											<Text
												style={[styles.text, styles.font25, styles.textPrimary]}
											>
												1st Year Fees
											</Text>
										</View>
									</View>
								</View>
							</View>
						</View>
					)}
					<View
						style={[
							styles.row,
							styles.justifyStretch,
							{ paddingHorizontal: "10%", marginTop: 68 },
						]}
					>
						<View style={[styles.row, styles.justifyStart]}>
							<Text style={[styles.text, styles.font30, styles.fontBold]}>
								About
							</Text>
						</View>
						<View style={[styles.row, styles.justifyEnd]}>
							<TouchableOpacity
								style={[
									styles.btnRed,
									{ height: 56, marginRight: 16 },
									styles.justifyCenter,
								]}
								onPress={() => {
									this.props.navigation.navigate("BrochureBase");
									//    window.open(collegeDetails.brochureLink,'_blank');
								}}
							>
								<Text
									style={[{ color: "#ffffff" }, styles.fontBold, styles.font20]}
								>
									Download Brochure
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								style={[
									styles.btnPrimary,
									{ height: 56 },
									styles.justifyCenter,
								]}
								onPress={() => {
									this.props.navigation.navigate("SignInBase", {
										from: "ApplyNow",
									});
								}}
							>
								<Text
									style={[{ color: "#ffffff" }, styles.fontBold, styles.font20]}
								>
									Apply Now
								</Text>
							</TouchableOpacity>
						</View>
					</View>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							{ marginTop: 46, paddingHorizontal: "10%" },
						]}
					>
						<Text
							style={[
								styles.text,
								styles.textAlignJustify,
								styles.textPrimary,
								styles.font30,
							]}
						>
							{this.state.viewInformation}
						</Text>
					</View>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							{
								paddingVertical: 22,
								paddingHorizontal: "20%",
								borderWidth: 1,
								borderColor: "#ffffff",
							},
						]}
					>
						<TouchableOpacity
							onPress={() => {
								this.setViewInfo(this.state.infoBtnText == "Read More");
							}}
						>
							<Text style={[styles.text, styles.textLink, styles.font20]}>
								{this.state.infoBtnText}
							</Text>
						</TouchableOpacity>
					</View>
					<View
						style={[
							styles.row,
							styles.justifyStretch,
							{ paddingHorizontal: "10%" },
						]}
					>
						<View style={{ flex: 0.5, marginRight: "5%" }}>
							{this.state.menuOptions.map((m, i) => {
								if (this.state.selectedMenuIndex != i) {
									return (
										<View
											key={m.label}
											style={[
												styles.row,
												styles.noWrap,
												styles.justifyEnd,
												{
													width: "100%",
													paddingRight: 45,
													paddingVertical: 20,
													paddingLeft: 65,
												},
											]}
										>
											<TouchableOpacity
												onPress={() => {
													this.setState({ selectedMenuIndex: i });
												}}
											>
												<Text style={[styles.text, styles.font25]}>
													{m.label}
												</Text>
											</TouchableOpacity>
										</View>
									);
								} else {
									return (
										<View
											key={m.label}
											style={[
												styles.row,
												styles.justifyEnd,
												styles.noWrap,
												styles.bgLightBlue,
												{
													width: "100%",
													paddingRight: 45,
													paddingVertical: 20,
													borderRightColor: "#0C5397",
													borderRightWidth: 12,
												},
											]}
										>
											<TouchableOpacity
												onPress={() => {
													this.setState({ selectedMenuIndex: i });
												}}
											>
												<Text
													style={[
														styles.text,
														styles.font25,
														styles.textPrimary,
														styles.fontBold,
													]}
												>
													{m.label}
												</Text>
											</TouchableOpacity>
										</View>
									);
								}
							})}
						</View>
						{this.renderSection()}
					</View>
					{/* countries */}
					<FooterInfo />
					{/* fOOTER */}
					<Footer />
				</View>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		collegeDetails: state?.collegeData?.collegeDetails,
		colleges: state?.collegeData?.colleges,
		shortListedColleges: state?.appData?.shortListedColleges,
	};
};

export default connect(mapStateToProps, { getCollegeDetails, saveUserFilters })(
	CollegeDetails
);
