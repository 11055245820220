import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "../styles/styles";

const Toggle = ({ data, onToggle, selectedOption }) => {
  return (
    <View
      style={[
        styles.bgWhite,
        styles.brPrimary10,
        {
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ffffff",
          paddingHorizontal: 10,
          borderRadius: 10,
          borderColor: "#0C5397",
        },
        styles.row,
      ]}
    >
      {data?.options?.map((o, oi) => {
        return (
          <TouchableOpacity
            key={"toggle" + (oi + 1)}
            style={[
              (selectedOption == o.value && styles.bgPrimary) || "",
              (selectedOption == o.value && styles.brPrimary10) || "",
              {
                height: 50,
                paddingHorizontal: 28,
                borderRadius: 10,
              },
              styles.justifyCenter,
            ]}
            onPress={() => {
              if (onToggle) {
                onToggle(o);
              }
            }}
          >
            <Text
              style={[
                styles.text,
                (selectedOption == o.value && styles.textWhite) ||
                  styles.textPrimary,
                styles.font18,
              ]}
            >
              {o.label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default Toggle;
