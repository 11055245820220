import React from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { styles } from "../styles/styles";

class EditInfo extends React.Component {
	constructor(props) {
		super();
		this.state = {
			profileComplePercent: "20",
			initialLetter: "K",
			data: {},
		};
	}
	componentDidMount() {
		let initialLetter = "";
		if (this.props.data && this.props.data.firstName) {
			initialLetter = this.props.data.firstName.charAt(0).toUpperCase();
		}
		this.setState({
			data: this.props.data || {},
			initialLetter: initialLetter,
		});
	}

	inputChange = (key, value) => {
		if (this.props.onInputChange) {
			if (key == "mobile") {
				this.props.onInputChange(key, {
					mobile: value,
					code: this.state.data?.code || "91",
				});
			} else {
				this.props.onInputChange(key, value);
			}
		}
	};
	render() {
		return (
			<View style={[{ paddingBottom: 36, width: 420 }]}>
				<View style={[styles.card]}>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							styles.textPrimary,
							{ fontSize: 22 },
						]}
					>
						<Text style={[styles.text, styles.textPrimary, { fontSize: 22 }]}>
							{" "}
							Edit Info
						</Text>
					</View>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							styles.textPrimary,
							{ fontSize: 22 },
							styles,
						]}
					>
						<View
							style={[
								{
									width: 116,
									height: 116,
									borderRadius: "50%",
									background:
										"transparent linear-gradient(180deg, #E5EAFF 0%, #CDC8FF 100%)",
								},
								styles.textAlignCenter,
								styles.justifyCenter,
							]}
						>
							<Text
								style={[
									styles.text,
									styles.textPrimary,
									{ fontSize: 60 },
									styles.fontBold,
								]}
							>
								{this.state.initialLetter}
							</Text>
						</View>
					</View>
					<View style={[styles.row, styles.mv]}>
						<View style={[styles.flex, styles.flexGrow]}>
							<Text
								style={[
									styles.textGrey,
									{
										fontSize: 12,
										marginLeft: 24,
										position: "absolute",
										marginTop: -11,
										background: "#ffffff",
										padding: 4,
									},
								]}
							>
								Name
							</Text>
							<TextInput
								style={[styles.inputText]}
								placeholder=""
								keyboardType={"default"}
								// maxLength={this.props.data.maxLength || 1000}
								// minLength={this.props.data.minLength || 1}
								// multiline={this.props.data.multiline}
								value={`${this.props.data?.firstName} ${this.props.data?.lastName}`}
								// secureTextEntry={this.props.data.type == 'password'}
								onChangeText={text => {
									this.inputChange("name", text);
								}}
								// editable={!this.props.data.disabled}
							/>
						</View>
					</View>
					<View style={[styles.row, styles.mv]}>
						<View style={[styles.flex, styles.flexGrow]}>
							<Text
								style={[
									styles.textGrey,
									{
										fontSize: 12,
										marginLeft: 24,
										position: "absolute",
										marginTop: -11,
										background: "#ffffff",
										padding: 4,
									},
								]}
							>
								Email id
							</Text>
							<TextInput
								style={[styles.inputText]}
								placeholder=""
								keyboardType={"default"}
								// maxLength={this.props.data.maxLength || 1000}
								// minLength={this.props.data.minLength || 1}
								// multiline={this.props.data.multiline}
								value={this.props.data?.email}
								// secureTextEntry={this.props.data.type == 'password'}
								onChangeText={text => {
									this.inputChange("email", text);
								}}
								// editable={!this.props.data.disabled}
							/>
						</View>
					</View>
					<View style={[styles.row, styles.mv]}>
						<View style={{ marginRight: 8, width: "20%" }}>
							<Text
								style={[
									styles.textGrey,
									{
										fontSize: 12,
										marginLeft: 24,
										position: "absolute",
										marginTop: -11,
										background: "#ffffff",
										padding: 4,
									},
								]}
							>
								ISD
							</Text>
							<TextInput
								style={[styles.inputText]}
								placeholder=""
								keyboardType={"default"}
								// maxLength={this.props.data.maxLength || 1000}
								// minLength={this.props.data.minLength || 1}
								// multiline={this.props.data.multiline}
								value={this.props.data?.mobile?.code || ""}
								// secureTextEntry={this.props.data.type == 'password'}
								//onChangeText = {(text)=>{this.inputChange(text)}}
								editable={false}
							/>
						</View>
						<View style={[styles.flexGrow]}>
							<Text
								style={[
									styles.textGrey,
									{
										fontSize: 12,
										marginLeft: 24,
										position: "absolute",
										marginTop: -11,
										background: "#ffffff",
										padding: 4,
									},
								]}
							>
								Mobile Number
							</Text>
							<TextInput
								style={[styles.inputText]}
								placeholder=""
								keyboardType={"default"}
								// maxLength={this.props.data.maxLength || 1000}
								// minLength={this.props.data.minLength || 1}
								// multiline={this.props.data.multiline}
								value={this.props.data?.mobile?.mobile || ""}
								// secureTextEntry={this.props.data.type == 'password'}
								onChangeText={text => {
									this.inputChange("mobile", text);
								}}
								// editable={!this.props.data.disabled}
							/>
						</View>
					</View>
					<View
						style={[
							styles.row,
							styles.mv,
							styles.justifyCenter,
							styles.textRed,
							{ fontSize: 22, marginTop: 33 },
						]}
					>
						<Text
							style={[
								styles.text,
								styles.textRed,
								{ fontSize: 22, marginTop: 33 },
							]}
						>
							{" "}
							{this.state.data?.profileCompletePercent}% Profile Completed
						</Text>
					</View>
				</View>
				<View style={[styles.card]}>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							styles.textPrimary,
							{ fontSize: 22 },
						]}
					>
						<Text style={[styles.text, styles.textPrimary, { fontSize: 22 }]}>
							{" "}
							Edit Your Login Details
						</Text>
					</View>

					<View style={[styles.row, styles.mv]}>
						<View style={[styles.flex, styles.flexGrow]}>
							<Text
								style={[
									styles.textGrey,
									{
										fontSize: 12,
										marginLeft: 24,
										position: "absolute",
										marginTop: -11,
										background: "#ffffff",
										padding: 4,
									},
								]}
							>
								Login
							</Text>
							<TextInput
								style={[styles.inputText]}
								placeholder=""
								keyboardType={"default"}
								// maxLength={this.props.data.maxLength || 1000}
								// minLength={this.props.data.minLength || 1}
								// multiline={this.props.data.multiline}
								value={this.props.data?.userId}
								// secureTextEntry={this.props.data.type == 'password'}
								// onChangeText = {(text)=>{this.inputChange(text)}}
								editable={false}
							/>
						</View>
					</View>

					<View style={[styles.row, styles.mv]}>
						<View style={[styles.flex, styles.flexGrow]}>
							<Text
								style={[
									styles.textGrey,
									{
										fontSize: 12,
										marginLeft: 24,
										position: "absolute",
										marginTop: -11,
										background: "#ffffff",
										padding: 4,
									},
								]}
							>
								Password
							</Text>
							<TextInput
								style={[styles.inputText]}
								placeholder=""
								keyboardType={"default"}
								// maxLength={this.props.data.maxLength || 1000}
								// minLength={this.props.data.minLength || 1}
								// multiline={this.props.data.multiline}
								value={this.props.data?.password}
								secureTextEntry={true}
								onChangeText={text => {
									this.inputChange("password", text);
								}}
								// editable={!this.props.data.disabled}
							/>
						</View>
					</View>
				</View>
				<View style={[styles.card]}>
					<View
						style={[
							styles.flex,
							styles.row,
							styles.justifyCenter,
							styles.textPrimary,
							styles.fontBold,
							{ fontSize: 22 },
						]}
					>
						<TouchableOpacity
							onPress={() => {
								if (this.props.onUnSubscribeClick) {
									this.props.onUnSubscribeClick();
								}
							}}
						>
							<Text
								style={[
									styles.text,
									styles.textPrimary,
									styles.fontBold,
									{ fontSize: 22 },
								]}
							>
								Unsubscribe your account
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	}
}

const mapStateToProps = state => {
	return {
		appData: state,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateData: async (parameter, callback) => {
			await dispatch(parameter);
			if (callback) {
				callback();
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInfo);
