import { Feather } from "@expo/vector-icons";
import Icon from "@expo/vector-icons/AntDesign";
import React, { useState } from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import { styles } from "../styles/styles";

const topMenu = [
	{
		label: "Find Universities",
	},
	{
		label: "Countries",
	},
	{
		label: "Exams",
	},
	{
		label: "About us",
	},
	{
		label: "Our Services",
	},
	{
		label: "Student’s Hub",
	},
];

const exams = [
	{
		title: "IELTS",
		text: "International English Language Testing System",
	},
	{
		title: "GRE",
		text: "Graduate Record Exam",
	},
	{
		title: "GMAT",
		text: "General Management Aptitude Test",
	},
	{
		title: "TOEFL",
		text: "Test of English as a Foreign Language",
	},
	{
		title: "PTE",
		text: "Pearson Test of English",
	},
	{
		title: "Pearson Test of English",
		text: "Scholastic Assessment Test",
	},
	{
		title: "ACT",
		text: "American College Testing",
	},
	{
		title: "IMAT",
		text: "International Medical Admission Test",
	},
	{
		title: "DUOLINGO",
		text: "Computer Based English Test",
	},
];

const Navbar = ({ navigation }) => {
	const [selectedMenu, setSelectedMenu] = useState(-1);

	const win = Dimensions.get("window");

	const menuDiv = () => {
		if (selectedMenu == 0) {
			return (
				<View
					style={{
						position: "absolute",
						backgroundColor: "white",
						paddingHorizontal: 62,
						paddingVertical: 52,
						width: 600,
						marginTop: 50,
					}}
				>
					<View style={[styles.row, styles.flex]}>
						<Text style={[styles.text, styles.fontBold, styles.font20]}>
							MBBS Abroad
						</Text>
					</View>
					<View style={[styles.row, styles.flex, { marginTop: 6 }]}>
						<Text style={[styles.text, styles.font18]}>
							NEET Qualified students can apply to study MBBS from world class
							university from abroad.
						</Text>
					</View>
					<View style={[styles.row, styles.flex, { marginTop: 55 }]}>
						<Text style={[styles.text, styles.fontBold, styles.font20]}>
							Other Courses
						</Text>
					</View>
					<View style={[styles.row, styles.flex, { marginTop: 6 }]}>
						<Text style={[styles.text, styles.font18]}>
							Want to pursue your Undergraduate or Postgraduate from abroad,
							then you can apply with mycareerpathshala to study in your dream
							destination and University.
						</Text>
					</View>
				</View>
			);
		} else if (selectedMenu == 2) {
			return (
				<View
					style={{
						position: "absolute",
						backgroundColor: "white",
						paddingHorizontal: 62,
						paddingVertical: 52,
						width: 1100,
						marginTop: 50,
						marginLeft: -300,
					}}
				>
					<View style={[styles.row, styles.flex, styles.wrap]}>
						{exams?.map((e, ei) => {
							return (
								<View
									key={"exam" + ei}
									style={[
										{ width: 180, marginHorizontal: 20, marginVertical: 12 },
									]}
								>
									<View style={[styles.row, styles.flex, styles.justifyStart]}>
										<Text
											style={[
												styles.text,
												styles.textPrimary,
												styles.fontBold,
												styles.font20,
											]}
										>
											{e.title}
										</Text>
									</View>
									<View
										style={[
											styles.row,
											styles.flex,
											styles.justifyStart,
											{ marginTop: 6 },
										]}
									>
										<Text style={[styles.text, styles.textGrey, styles.font18]}>
											{e.text}
										</Text>
									</View>
								</View>
							);
						})}
					</View>
				</View>
			);
		}
		if (selectedMenu == 5) {
			return (
				<View
					style={{
						position: "absolute",
						backgroundColor: "white",
						paddingHorizontal: 62,
						paddingVertical: 52,
						width: 400,
						marginTop: 50,
					}}
				>
					<View style={[styles.row, styles.flex, styles.justifyStart]}>
						<Text
							style={[
								styles.text,
								styles.textPrimary,
								styles.fontBold,
								styles.font20,
							]}
						>
							EVENTS
						</Text>
					</View>
					<View
						style={[
							styles.row,
							styles.flex,
							styles.justifyStart,
							{ marginTop: 6 },
						]}
					>
						<Text style={[styles.text, styles.textGrey, styles.font18]}>
							Join Webinar/Seminar to know about Courses/Universities.
						</Text>
					</View>
					<View
						style={[
							styles.row,
							styles.flex,
							styles.justifyStart,
							{ marginTop: 32 },
						]}
					>
						<Text
							style={[
								styles.text,
								styles.textPrimary,
								styles.fontBold,
								styles.font20,
							]}
						>
							NEWS & INSIGHTS
						</Text>
					</View>
					<View
						style={[
							styles.row,
							styles.flex,
							styles.justifyStart,
							{ marginTop: 6 },
						]}
					>
						<Text style={[styles.text, styles.textGrey, styles.font18]}>
							Be updated with the details of Courses and Universities.
						</Text>
					</View>
				</View>
			);
		}
		return <View></View>;
	};

	return (
		<View style={{ maxWidth: win.width, zIndex: 1 }}>
			<View
				style={{
					height: 35,
					backgroundColor: "#B51700",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end",
				}}
			>
				<Text
					style={[
						styles.text,
						styles.textWhite,
						styles.font18,
						styles.fontBold,
					]}
				>
					Toll FREE No. : +91-9861-905-906
				</Text>
				<img
					src={require("../assets/home_page/email_icon_top.svg")}
					style={{ width: 28, marginLeft: 18, marginRight: 5 }}
				/>
				<Text
					style={[
						styles.textWhite,
						styles.font18,
						styles.fontBold,
						{ marginRight: 20 },
					]}
				>
					info@mycareerpathshala.com
				</Text>
			</View>
			<View
				style={[
					{
						paddingHorizontal: win.width * 0.02,
					},
					styles.bgWhite,
				]}
			>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						height: 105,
					}}
				>
					<img
						src={require("../assets/home_page/logo1.png")}
						style={{ width: "20%" }}
					/>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							marginLeft: win.width * 0.02,
						}}
					>
						{topMenu?.map((m, i) => {
							return (
								<TouchableOpacity
									key={"m" + (i + 1)}
									onPress={() => {
										if (selectedMenu == i) {
											setSelectedMenu(-1);
										} else {
											setSelectedMenu(i);
										}
									}}
								>
									<Text
										style={[
											styles.text,
											styles.font20,
											styles.textPrimary,
											{ marginLeft: win.width * 0.02, fontWeight: "bold" },
										]}
									>
										{m.label}
										<Icon
											name={"down"}
											size={14}
											style={[styles.textRed, { marginLeft: 5 }]}
										/>
									</Text>
									{selectedMenu == i && menuDiv()}
								</TouchableOpacity>
							);
						})}
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							flex: 1,
						}}
					>
						<TouchableOpacity
							style={[
								styles.btnWhite,
								styles.btnRedBorder,
								{ paddingBottom: 12 },
							]}
							onPress={() => {}}
						>
							<Feather
								name="video"
								style={[styles.textRed, { fontSize: 28 }]}
							/>
						</TouchableOpacity>
						<TouchableOpacity
							style={[styles.btnWhite, styles.mr16, { marginTop: 3 }]}
							onPress={() => {}}
						>
							<img
								src={require("../assets/home_page/shopping_cart.svg")}
								style={{ height: 28 }}
							/>
						</TouchableOpacity>
						<TouchableOpacity
							style={[styles.btnRed]}
							onPress={() => {
								navigation.navigate("SignInBase");
							}}
						>
							<Text
								style={[
									{
										color: "#ffffff",
										textTransform: "uppercase",
										display: "flex",
										flex: 1,
										alignItems: "center",
									},
									styles.fontBold,
								]}
							>
								SIGN IN
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</View>
	);
};

export default Navbar;
