import Icon from "@expo/vector-icons/AntDesign";
import Icon2 from "@expo/vector-icons/Entypo";
import React, { useEffect, useState } from "react";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { TextInput } from "react-native-paper";
import RangeSlider from "react-native-range-slider-expo";
import { useDispatch, useSelector } from "react-redux";
import { fetchFiltersData, saveUserFilters } from "../actions/appActions";
import { saveOtherCoursesFilter } from "../actions/otherCoursesActions";
import Footer from "../components/Footer";
import Input from "../components/Input";
import { styles } from "../styles/styles";

const dollartoInr = 78;

const OtherCourses = ({ navigation }) => {
  const dispatch = useDispatch();
  const [fromValue, setFromValue] = useState(1000);
  const [toValue, setToValue] = useState(50000);
  const otherCoursesFilters = useSelector(
    (state) => state.appData.otherCoursesFilters
  );
  const countries = useSelector((state) => state.appData.countries);

  useEffect(() => {
    dispatch(fetchFiltersData("all", "otherCoursesFilters"));
    dispatch(fetchFiltersData("all", "countries"));
  }, []);

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (otherCoursesFilters?.length) {
      setSteps([
        {
          label: "Study Level",
          subtext: "What study level are you looking for?",
          type: "single_select",
          skip: false,
          isCompleted: "",
          options: otherCoursesFilters.some((obj, i) =>
            obj?.hasOwnProperty("studyLevel")
          )
            ? otherCoursesFilters[0].studyLevel?.map((v) => ({
                ...v,
                isSelected: false,
              }))
            : [],
        },
        {
          label: "Intake Year",
          subtext: "When you are planning to take admission?",
          type: "single_select",
          skip: false,
          isCompleted: "",
          options: otherCoursesFilters.some((obj, i) =>
            obj?.hasOwnProperty("inTakeYear")
          )
            ? otherCoursesFilters[1].inTakeYear?.map((v) => ({
                ...v,
                isSelected: false,
              }))
            : [],
        },
        {
          label: "Course",
          subtext: "What courses are you interested in",
          type: "course",
          isCompleted: "",
          options: otherCoursesFilters.some((obj, i) =>
            obj?.hasOwnProperty("courses")
          )
            ? otherCoursesFilters[5].courses?.map((a) => ({
                ...a,
                subOptions: a.subOptions?.map((v) => ({
                  ...v,
                  isSelected: false,
                })),
              }))
            : [],
        },
        {
          label: "Country",
          subtext: "Select your preferred destination",
          skip: false,
          type: "multiselect",
          isCompleted: "",
          options: countries?.map((v) => ({
            ...v,
            isSelected: false,
          })),
        },
        {
          label: "Tuition Fees",
          subtext: "Your budget for tuition fees?",
          skip: false,
          type: "range",
          isCompleted: "",
          options: {
            minimum: "1000",
            maximum: "50,000",
          },
        },
        {
          label: "Qualification",
          subtext: "Select your highest qualification",
          type: "single_select",
          skip: true,
          isCompleted: "",
          options: otherCoursesFilters.some((obj, i) =>
            obj?.hasOwnProperty("qualification")
          )
            ? otherCoursesFilters[2].qualification?.map((v) => ({
                ...v,
                isSelected: false,
              }))
            : [],
          extraFields: [
            {
              label: "Select your grade type",
              subtext: "Select your grade type",
              type: "select",
              options: [
                { label: "Select your grade Type", value: "" },
                { label: "Percentage Score", value: "Percentage" },
                { label: "GPA(4 - point scale)", value: "GPA" },
                { label: "Indian Grading Scale (0 - 10)", value: "Indian" },
                { label: "Grade", value: "Grade" },
              ],
              value: "",
            },
            {
              subtext: "Fill your score (current/expected)",
              placeholder: "Your Score",
              type: "inputBlack",
              value: "",
            },
          ],
        },
        {
          label: "English Test",
          subtext: "Which English test you have qualified?",
          type: "single_select",
          skip: true,
          isCompleted: "",
          options: otherCoursesFilters.some((obj, i) =>
            obj?.hasOwnProperty("englishTest")
          )
            ? otherCoursesFilters[3].englishTest?.map((v) => ({
                ...v,
                isSelected: false,
              }))
            : [],
          extraFields: [
            {
              subtext: "Fill your score (current/expected)",
              placeholder: "Your Score",
              type: "inputBlack",
              value: "",
            },
          ],
        },
        {
          label: "Other Preference",
          subtext: "Fill your choice to personalise your results.",
          type: "single_select",
          skip: true,
          isCompleted: "",
          options: otherCoursesFilters.some((obj, i) =>
            obj?.hasOwnProperty("otherPreferences")
          )
            ? otherCoursesFilters[4].otherPreferences?.map((v) => ({
                ...v,
                isSelected: false,
              }))
            : [],
        },
      ]);
    }
  }, [otherCoursesFilters]);

  const [cardOpen, setCardOpen] = useState(0);
  const [courseOpen, setCourseOpen] = useState(null);
  const [textcolor, setTextColor] = useState(false);
  const [filterData, setFilterData] = useState({
    userId: null,
    studyLevel: "",
    inTakeYear: "",
    courseList: {},
    countries: [],
    tuitionFeesMin: "",
    tuitionFeesMax: "",
    qualification: {
      highestQualification: "",
      gradeType: "",
      score: "",
    },
    englishTest: "",
    otherPreferences: "",
  });
  useEffect(() => {
    if (steps.length > 0 && cardOpen == steps?.length) setTextColor(true);
  }, [cardOpen]);
  const mbbsFilters = useSelector((state) => state.appData.mbbsFilters);
  const mbbsUserFilters = useSelector((state) => state.appData.mbbsUserFilters);

  const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
  const [buttonActive, setButtonActive] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [selectionComplete, setSelectionComplete] = useState(false);

  const setCountry = (data, i) => {
    const country = countries;
    country[i].isSelected = data?.value;
    setButtonActive(countries.filter((c) => c.isSelected).length > 0);
    setCountries(country);
  };

  const submitStep = (isSkipped = false) => {
    let stepObj = steps;
    if (!buttonActive && !isSkipped) {
      return;
    }
    if (isSkipped) {
      stepObj[selectedSessionIndex].data = null;
    }
    if (!isSkipped) {
      stepObj[selectedSessionIndex].isComplete = true;
    }
    stepObj[selectedSessionIndex].isSelected = false;
    setSelectedSessionIndex(selectedSessionIndex + 1);
    if (selectedSessionIndex + 1 < stepObj.length) {
      stepObj[selectedSessionIndex + 1].isSelected = true;
      stepObj[selectedSessionIndex + 1].isComplete = false;
    } else {
      setSelectionComplete(true);
    }
    setButtonActive(false);
    if (selectedSessionIndex === 4) {
      setComplete(isSkipped);
    }
    setSteps(stepObj);
  };

  const setComplete = (isSkipped = false) => {
    let filtersData = {
      countries: countries.filter((c) => c.isSelected).map((c) => c.value),
      session: steps[1].data,
      yearOfPassing: steps[2].data,
      budget: steps[3].data,
      neet: steps[4].data,
    };
    dispatch(saveUserFilters("mbbsUserFilters", filtersData));

    let stepObj = steps;
    let data = {
      countries: countries
        .filter((c) => c.isSelected)
        .map((c) => c.label)
        .join(" | "),
      session: steps[1].data,
      yearOfPassing: steps[2].data,
      budget:
        (steps[3]?.data &&
          "$" + steps[3]?.data + " | ₹ " + steps[3].data * dollartoInr) ||
        null,
      neet: `${(steps[4]?.data?.year && steps[4]?.data?.year + " | ") || ""}${
        (steps[4]?.data?.category && steps[4]?.data?.category + " | ") || ""
      }${steps[4]?.data?.score || ""}`,
    };
    if (stepObj[selectedSessionIndex]) {
      stepObj[selectedSessionIndex].isSelected = false;
      if (!isSkipped) stepObj[selectedSessionIndex].isComplete = true;
    }
    setFinalData(data);
    setSelectedSessionIndex(-1);
    setSelectionComplete(true);
    setSteps(stepObj);
  };

  const selectSection = (i) => {
    let stepObj = steps;
    setSelectedSessionIndex(i);
    stepObj[i].isSelected = true;
    stepObj[i].isComplete = false;
    setSelectionComplete(false);
    setButtonActive(false);
    setSteps(stepObj);
  };

  const rendercard = (data, index) => {
    return (
      <View
        key={index}
        style={{
          backgroundColor: "#F4FAFF",
          padding: 15,
          width: "60%",
          alignSelf: "center",
          borderRadius: 5,
          borderWidth: 1,
          borderColor: "#BBC9D5",
          marginVertical: 10,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            setCardOpen(index);
            if (data.type == "course") {
              let tempdata = data;

              tempdata.isCompleted = "";
              let tempSteps = steps.slice(0);
              tempSteps[index] = tempdata;
              setSteps(tempSteps);
            }
          }}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{ flexDirection: "row", flexWrap: "wrap", width: "98%" }}
            >
              <Text style={{ fontWeight: 600, fontSize: 18 }}>
                {data.label}
              </Text>
              {data.isCompleted !== "" && cardOpen !== index && (
                <Icon
                  name={"arrowright"}
                  size={18}
                  style={{
                    color: "#000",
                    marginHorizontal: 10,
                    marginTop: 5,
                  }}
                />
              )}
              {data.isCompleted !== "" && cardOpen != index && (
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    color: textcolor ? "#5479CB" : null,
                  }}
                >
                  {data.isCompleted}
                </Text>
              )}
            </View>
            <Icon
              name={index == cardOpen ? "minus" : "plus"}
              size={18}
              style={{
                color: "#000",
                marginRight: 10,
              }}
              onPress={() => {
                cardOpen == index ? setCardOpen(null) : setCardOpen(index);
                if (data.type == "course" && index !== cardOpen) {
                  let tempdata = data;

                  tempdata.isCompleted = "";
                  let tempSteps = steps.slice(0);
                  tempSteps[index] = tempdata;
                  setSteps(tempSteps);
                } else if (data.type == "course" && index == cardOpen) {
                  let tempdata = steps[cardOpen];

                  tempdata.options.map((a, index) => {
                    if (a.isSelected) {
                      tempdata.isCompleted =
                        tempdata.isCompleted == ""
                          ? a.name
                          : tempdata.isCompleted + "\n" + a.name;
                      a.subOptions.map((b) => {
                        if (b.isSelected) {
                          tempdata.isCompleted =
                            tempdata.isCompleted + " | " + b.label;
                        }
                      });
                    }
                  });
                }
              }}
            />
          </View>
        </TouchableOpacity>
        {index == cardOpen ? (
          <View style={{ justifyContent: "center" }}>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {data.subtext}
            </Text>
          </View>
        ) : null}
        {index == cardOpen && data.skip ? (
          <Text
            style={{ color: "#5479CB", fontSize: 18, fontWeight: "600" }}
            onPress={() => {
              const stepsObj = steps;
              stepsObj[index].isCompleted = "";
              stepsObj[index].options?.map((a) => (a.isSelected = false));
              stepsObj[index].extraFields?.map((a) => (a.value = ""));
              setCardOpen(index + 1);
              setSteps(stepsObj);
            }}
          >
            Skip this question
          </Text>
        ) : null}
        {index == cardOpen && data.type == "single_select"
          ? renderSingleSelect(data, index)
          : null}
        {index == cardOpen && data.type == "multiselect"
          ? renderMultiSelect(data, index)
          : null}
        {index == cardOpen && data.type == "course"
          ? renderCourse(data, index)
          : null}
        {index === cardOpen && data.type == "range"
          ? renderSlider(data, index)
          : null}
        <View
          style={{
            width: "60%",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          {index == cardOpen &&
            data?.type !== "range" &&
            data?.isCompleted !== "I haven't taken any test yet" &&
            data?.options
              ?.map((a) => a.isSelected)
              .some((val) => val == true) &&
            data?.extraFields?.map((a, i) => (
              <View key={i} style={{ marginVertical: 10 }}>
                <Text
                  style={{ fontWeight: 600, fontSize: 18, marginBottom: 10 }}
                >
                  {a.subtext}
                </Text>
                <Input
                  data={a}
                  onChange={(data) => {
                    let tempdata = steps[cardOpen];
                    tempdata.extraFields[i].value = data?.value;
                    tempdata.isCompleted =
                      tempdata.extraFields.length > 1
                        ? tempdata.isCompleted.includes("|")
                          ? tempdata.isCompleted?.split("=")[0] +
                            " = " +
                            data?.value
                          : tempdata.isCompleted + " | " + data?.value
                        : tempdata.isCompleted?.split("=")[0] +
                          " = " +
                          data?.value;
                    let tempSteps = steps.slice(0);
                    tempSteps[cardOpen] = tempdata;
                    setSteps(tempSteps);
                    if (index == 5) {
                      if (i == 0)
                        setFilterData({
                          ...filterData,
                          qualification: {
                            ...filterData.qualification,
                            gradeType: data?.value,
                          },
                        });
                      if (i == 1)
                        setFilterData({
                          ...filterData,
                          qualification: {
                            ...filterData.qualification,
                            score: data?.value,
                          },
                        });
                    }
                  }}
                />
              </View>
            ))}
        </View>
        {index == cardOpen && data.type !== "course" && (
          <TouchableOpacity
            onPress={() =>
              steps[cardOpen]?.isCompleted !== "" && setCardOpen(index + 1)
            }
          >
            <View
              style={{
                backgroundColor:
                  steps[cardOpen]?.isCompleted !== "" ? "#0C5397" : "#F1F0ED",
                padding: 10,
                position: "absolute",
                bottom: 10,
                right: 0,
                borderRadius: 30,
                justifyContent: "center",
                borderWidth: steps[cardOpen]?.isCompleted !== "" ? 0 : 1,
                borderColor:
                  steps[cardOpen]?.isCompleted !== "" ? null : "#B7B6B1",
              }}
            >
              <Icon
                name={"arrowright"}
                size={18}
                style={{
                  color:
                    steps[cardOpen]?.isCompleted !== "" ? "#fff" : "#7C7C79",
                }}
              />
            </View>
          </TouchableOpacity>
        )}
        {index == cardOpen && data.type == "course" && (
          <TouchableOpacity
            onPress={() => {
              if (steps[cardOpen]?.options?.some((a) => a.isSelected == true)) {
                let tempdata = steps[cardOpen];

                tempdata.options.map((a, index) => {
                  if (a.isSelected) {
                    tempdata.isCompleted =
                      tempdata.isCompleted == ""
                        ? a.name
                        : tempdata.isCompleted + "\n" + a.name;
                    a.subOptions.map((b) => {
                      if (b.isSelected) {
                        tempdata.isCompleted =
                          tempdata.isCompleted + " | " + b.label;
                      }
                    });
                  }
                });
                setCardOpen(index + 1);
              }
            }}
          >
            <View
              style={{
                backgroundColor: steps[cardOpen]?.options?.some(
                  (a) => a.isSelected == true
                )
                  ? "#0C5397"
                  : "#F1F0ED",
                padding: 10,
                position: "absolute",
                bottom: 10,
                right: 0,
                borderRadius: 30,
                justifyContent: "center",
                borderWidth: steps[cardOpen]?.options?.some(
                  (a) => a.isSelected == true
                )
                  ? 0
                  : 1,
                borderColor: steps[cardOpen]?.options?.some(
                  (a) => a.isSelected == true
                )
                  ? null
                  : "#B7B6B1",
              }}
            >
              <Icon
                name={"arrowright"}
                size={18}
                style={{
                  color: steps[cardOpen]?.options?.some(
                    (a) => a.isSelected == true
                  )
                    ? "#fff"
                    : "#7C7C79",
                }}
              />
            </View>
          </TouchableOpacity>
        )}
      </View>
    );
  };
  const renderSlider = (data, index) => {
    if (steps[cardOpen - 1]?.isCompleted?.length > 0) {
      return (
        <View>
          <Text
            style={{
              textAlign: "center",
              color: "#030000",
              fontSize: 20,
              marginVertical: 10,
            }}
          >
            Select your minimum and maximum fees that fit your budget (per year)
          </Text>
          <RangeSlider
            min={1000}
            max={50000}
            fromKnobColor="#DF68F5"
            toKnobColor={"#DF68F5"}
            inRangeBarColor={"#DF68F5"}
            outOfRangeBarColor={"#F1F0ED"}
            fromValueOnChange={(value) => {
              setFromValue(value);
              let tempdata = data;
              tempdata.isCompleted =
                tempdata.isCompleted = `$ ${fromValue} - $ ${toValue}`;
              let tempSteps = steps.slice(0);
              tempSteps[index] = tempdata;
              setSteps(tempSteps);
              setFilterData({
                ...filterData,
                tuitionFeesMin: fromValue,
                tuitionFeesMax: toValue,
              });
            }}
            toValueOnChange={(value) => {
              setToValue(value);
              let tempdata = data;
              tempdata.isCompleted =
                tempdata.isCompleted = `$ ${fromValue} - $ ${toValue}`;
              let tempSteps = steps.slice(0);
              tempSteps[index] = tempdata;
              setSteps(tempSteps);
              setFilterData({
                ...filterData,
                tuitionFeesMin: fromValue,
                tuitionFeesMax: toValue,
              });
            }}
            initialFromValue={fromValue}
            initialToValue={toValue}
            knobSize={24}
            barHeight={8}
            showRangeLabels={false}
          />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View>
              <TextInput
                style={{
                  backgroundColor: "#F4FAFF",
                  fontSize: 32,
                  color: "#030000",
                  fontWeight: "600",
                  width: "70%",
                }}
                keyboardType={"default"}
                value={`$ ${fromValue}`}
                onChangeText={(text) => {
                  setFromValue(value);
                }}
              />
              <Text
                style={{
                  color: "#030000",
                  fontWeight: "400",
                  fontSize: 18,
                  marginVertical: 10,
                }}
              >
                Minimum
              </Text>
              <Text
                style={{ fontSize: 32, color: "#030000", fontWeight: "600" }}
              >
                ₹ {fromValue * 80}
              </Text>
            </View>
            <View>
              <TextInput
                style={{
                  backgroundColor: "#F4FAFF",
                  fontSize: 32,
                  color: "#030000",
                  fontWeight: "600",
                  width: "70%",
                }}
                keyboardType={"default"}
                value={`$ ${toValue}`}
                onChangeText={(text) => {
                  setToValue(value);
                }}
              />
              <Text
                style={{
                  color: "#030000",
                  fontWeight: "400",
                  fontSize: 18,
                  marginVertical: 10,
                }}
              >
                Maximum
              </Text>
              <Text
                style={{ fontSize: 32, color: "#030000", fontWeight: "600" }}
              >
                ₹ {toValue * 80}
              </Text>
            </View>
          </View>
        </View>
      );
    } else {
      setCardOpen(steps.findIndex((step) => step?.isCompleted?.length === 0));
    }
  };
  const renderMultiSelect = (data, index) => {
    if (steps[cardOpen - 1]?.isCompleted?.length > 0) {
      return (
        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            justifyContent: "center",
            width: "100%",
            flexWrap: "wrap",
            marginVertical: 20,
          }}
        >
          {data?.options?.map((a, i) => (
            <TouchableOpacity
              key={i}
              style={{ width: "20%" }}
              onPress={() => {
                let tempdata = data;
                tempdata.options[i].isSelected =
                  !tempdata.options[i].isSelected;
                if (tempdata.options[i].isSelected) {
                  tempdata.isCompleted =
                    tempdata.isCompleted == ""
                      ? a.label
                      : tempdata.isCompleted?.includes(a.label)
                      ? tempdata.isCompleted
                      : tempdata.isCompleted + " | " + a.label;
                } else {
                  tempdata.isCompleted = tempdata.isCompleted?.includes(
                    " | " + a.label
                  )
                    ? tempdata.isCompleted?.replace(" | " + a.label, "")
                    : tempdata.isCompleted?.includes(a.label)
                    ? tempdata.isCompleted?.replace(a.label, "")
                    : tempdata.isCompleted;
                }
                let tempSteps = steps.slice(0);
                tempSteps[index] = tempdata;
                setSteps(tempSteps);
                setFilterData({
                  ...filterData,
                  countries: [...filterData.countries, a.value],
                });
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: a.isSelected ? "#048E34" : "#030000",
                  borderRadius: 10,
                  paddingHorizontal: 10,
                  paddingVertical: 10,
                  marginHorizontal: 8,
                  marginVertical: 10,
                  backgroundColor: "#fff",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: a.isSelected ? "#048E34" : "#030000",
                    fontWeight: a.isSelected ? "700" : "400",
                  }}
                >
                  {a.label}
                </Text>

                {a.isSelected ? (
                  <Icon
                    name={"checkcircleo"}
                    size={18}
                    style={{
                      color: "#048E34",
                    }}
                  />
                ) : (
                  <Icon2
                    name={"circle"}
                    size={18}
                    style={{
                      color: "#000000",
                    }}
                  />
                )}
              </View>
            </TouchableOpacity>
          ))}
        </View>
      );
    } else {
      setCardOpen(steps.findIndex((step) => step?.isCompleted?.length === 0));
    }
  };
  const renderCourse = (data, index) => {
    if (steps[cardOpen - 1]?.isCompleted?.length > 0) {
      return (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            alignSelf: "center",
            justifyContent: "flex-start",
            width: "90%",
            marginVertical: 20,
          }}
        >
          {data.options?.map((a, i) => (
            <TouchableOpacity
              key={i}
              style={{ width: "30%" }}
              onPress={() => {
                let tempdata = data;
                tempdata.options[i].isSelected = true;
                // tempdata.isCompleted =
                // 	tempdata.isCompleted == ""
                // 		? a.name
                // 		: tempdata.isCompleted?.includes(a.name)
                // 		? tempdata.isCompleted
                // 		: tempdata.isCompleted + "\n" + a.name;
                let tempSteps = steps.slice(0);
                tempSteps[index] = tempdata;
                setSteps(tempSteps);
                i == courseOpen ? setCourseOpen(null) : setCourseOpen(i);
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: a.isSelected ? "#048E34" : "#BBC9D5",
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  paddingVertical: 10,
                  marginHorizontal: 8,
                  backgroundColor: a.isSelected ? "#CAF8DA" : "#fff",
                  marginVertical: 8,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: 50,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: "#030000",
                  }}
                >
                  {a.name}
                </Text>
                <Icon
                  name={"plus"}
                  size={18}
                  style={{
                    color: "#000",
                  }}
                  onPress={() => {
                    let tempdata = data;
                    if (a.isSelected) {
                      tempdata.options[i].isSelected = false;
                      tempdata.options[i].subOptions?.map((a) => {
                        a.isSelected = false;
                      });
                      tempdata.isCompleted = "";
                      let tempSteps = steps.slice(0);
                      tempSteps[index] = tempdata;
                      setSteps(tempSteps);
                    } else {
                      tempdata.options[i].isSelected = true;
                      // tempdata.isCompleted =
                      // 	tempdata.isCompleted == ""
                      // 		? a.name
                      // 		: tempdata.isCompleted?.includes(a.name)
                      // 		? tempdata.isCompleted
                      // 		: tempdata.isCompleted + "\n" + a.name;
                      let tempSteps = steps.slice(0);
                      tempSteps[index] = tempdata;
                      setSteps(tempSteps);
                      i == courseOpen ? setCourseOpen(null) : setCourseOpen(i);
                    }
                  }}
                />
              </View>
            </TouchableOpacity>
          ))}
        </View>
      );
    } else {
      setCardOpen(steps.findIndex((step) => step?.isCompleted?.length === 0));
    }
  };
  const renderCourseModal = () => {
    return (
      <Modal
        animationInTiming={700}
        animationOutTiming={400}
        backdropTransitionInTiming={700}
        backdropTransitionOutTiming={700}
        onModalHide={() => setCourseOpen(null)}
        isVisible={courseOpen == 0 || courseOpen ? true : false}
        transparent={true}
        useNativeDriver={true}
      >
        <View
          style={{
            backgroundColor: "#fff",
            width: "60%",
            padding: 10,
            borderRadius: 5,
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Icon2
            name={"cross"}
            size={18}
            style={{
              color: "#000000",
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onPress={() => {
              let tempdata = steps[cardOpen];
              tempdata.options[courseOpen].isSelected = false;
              tempdata.options[courseOpen]?.subOptions?.map(
                (a) => (a.isSelected = false)
              );
              let tempSteps = steps.slice(0);
              tempSteps[cardOpen] = tempdata;
              setSteps(tempSteps);

              setCourseOpen(null);
            }}
          />
          <Text
            style={{
              textAlign: "center",
              fontSize: 17,
              fontWeight: 600,
              marginBottom: 15,
            }}
          >
            Choose your sub courses
          </Text>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {steps?.[cardOpen]?.options?.[courseOpen]?.subOptions?.map(
              (obj, i) => (
                <TouchableOpacity
                  key={i}
                  style={{ width: "30%" }}
                  onPress={() => {
                    let tempdata = steps[cardOpen];
                    // tempdata.options[courseOpen]?.suboptions?.map((a) => (a.isSelected = false));
                    tempdata.options[courseOpen].subOptions[i].isSelected =
                      !tempdata.options[courseOpen].subOptions[i].isSelected;
                    // if (tempdata.options[courseOpen].subOptions[i].isSelected) {
                    // 	tempdata.isCompleted =
                    // 		tempdata.isCompleted == ""
                    // 			? tempdata.options[courseOpen].subOptions[i].label
                    // 			: tempdata.isCompleted?.includes(
                    // 					tempdata.options[courseOpen].subOptions[i].label
                    // 			  )
                    // 			? tempdata.isCompleted
                    // 			: tempdata.isCompleted +
                    // 			  " | " +
                    // 			  tempdata.options[courseOpen].subOptions[i].label;
                    // } else {
                    // 	tempdata.isCompleted = tempdata.isCompleted?.includes(
                    // 		" | " + tempdata.options[courseOpen].subOptions[i].label
                    // 	)
                    // 		? tempdata.isCompleted?.replace(
                    // 				" | " +
                    // 					tempdata.options[courseOpen].subOptions[i].label,
                    // 				""
                    // 		  )
                    // 		: tempdata.isCompleted?.includes(
                    // 				tempdata.options[courseOpen].subOptions[i].label
                    // 		  )
                    // 		? tempdata.isCompleted?.replace(
                    // 				tempdata.options[courseOpen].subOptions[i].label,
                    // 				""
                    // 		  )
                    // 		: tempdata.isCompleted;
                    // }

                    let tempSteps = steps.slice(0);
                    tempSteps[cardOpen] = tempdata;
                    let name = steps[cardOpen]?.options[courseOpen]?.name;
                    let arr = Array.isArray(filterData.courseList[name])
                      ? filterData.courseList[name]
                      : new Array();
                    setSteps(tempSteps);
                    setFilterData({
                      ...filterData,
                      courseList: {
                        ...filterData.courseList,
                        [name]: [...arr, obj.value],
                      },
                    });
                  }}
                >
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: obj.isSelected ? "#048E34" : "#BBC9D5",
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      paddingVertical: 10,
                      marginHorizontal: 8,
                      backgroundColor: "#030000",
                      backgroundColor: obj.isSelected ? "#CAF8DA" : "#F1F0ED",
                      marginVertical: 8,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#030000",
                      }}
                    >
                      {obj.label}
                    </Text>
                    {obj.isSelected ? (
                      <Icon
                        name={"checkcircleo"}
                        size={18}
                        style={{
                          color: "#048E34",
                        }}
                      />
                    ) : (
                      <Icon2
                        name={"circle"}
                        size={18}
                        style={{
                          color: "#000000",
                        }}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              )
            )}
          </View>
          <TouchableOpacity
            style={{ width: "50%", alignSelf: "center" }}
            onPress={() => setCourseOpen(null)}
          >
            <View
              style={{
                backgroundColor: "#0C5397",
                padding: 10,
                paddingHorizontal: 15,
                borderRadius: 5,
                justifyContent: "center",
                width: "50%",
                marginTop: 15,
                alignSelf: "center",
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Done
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  };
  const renderSingleSelect = (data, index) => {
    if (
      index === 0 ||
      steps[cardOpen - 1]?.isCompleted?.length > 0 ||
      (steps[cardOpen]?.skip === true &&
        steps[steps.findIndex((s) => s?.label === "Tuition Fees")]?.isCompleted
          ?.length > 0)
    ) {
      return (
        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            justifyContent: "center",
            width: "80%",
            flexWrap: "wrap",
            marginVertical: 20,
          }}
        >
          {data.options?.map((a, i) => (
            <TouchableOpacity
              key={i}
              onPress={() => {
                let tempdata = data;
                if (data.label == "Other Preference") {
                  tempdata.options[i].isSelected =
                    !tempdata.options[i].isSelected;
                  if (tempdata.options[i].isSelected) {
                    tempdata.isCompleted =
                      tempdata.isCompleted == ""
                        ? a.label
                        : tempdata.isCompleted?.includes(a.label)
                        ? tempdata.isCompleted
                        : tempdata.isCompleted + " | " + a.label;
                  } else {
                    tempdata.isCompleted = tempdata.isCompleted?.includes(
                      " | " + a.label
                    )
                      ? tempdata.isCompleted?.replace(" | " + a.label, "")
                      : tempdata.isCompleted?.includes(a.label)
                      ? tempdata.isCompleted?.replace(a.label, "")
                      : tempdata.isCompleted;
                  }
                } else {
                  tempdata.options?.map((a) => (a.isSelected = false));
                  // tempdata?.extraFields?.map(p =>p.value='')
                  tempdata.options[i].isSelected = true;
                  tempdata.isCompleted = a.label;
                }
                let tempSteps = steps.slice(0);
                tempSteps[index] = tempdata;

                setSteps(tempSteps);
                if (index == 0)
                  setFilterData({ ...filterData, studyLevel: a.value });
                if (index == 1)
                  setFilterData({ ...filterData, inTakeYear: a.value });
                if (index == 5) {
                  setFilterData({
                    ...filterData,
                    qualification: {
                      ...filterData.qualification,
                      highestQualification: a.value,
                    },
                  });
                }
                if (index == 6)
                  setFilterData({ ...filterData, englishTest: a.value });
                if (index == 7)
                  setFilterData({ ...filterData, otherPreferences: a.value });
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#030000",
                  borderRadius: 50,
                  paddingHorizontal: 15,
                  paddingVertical: 5,
                  marginHorizontal: 8,
                  marginVertical: 10,
                  backgroundColor: a.isSelected ? "#030000" : "#fff",
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: a.isSelected ? "#fff" : "#030000",
                  }}
                >
                  {a.label}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      );
    } else {
      setCardOpen(steps.findIndex((step) => step?.isCompleted?.length === 0));
    }
  };

  return (
    <View
      style={
        ([styles.bgWhite],
        { display: "flex", justifyContent: "space-between", flex: 1 })
      }
    >
      <View
        style={[
          styles.row,
          styles.justifyStart,
          styles.alignItemsCenter,
          styles.pv16,
          styles.pPage,
          styles.bgWhite,
        ]}
      >
        <img
          src={require("../assets/home_page/logo1.png")}
          style={{ width: "20%" }}
        />
        <TouchableOpacity
          style={[
            styles.justifyCenter,
            styles.brPrimary,
            styles.boxShadow2,
            { height: 46, marginLeft: 32, paddingHorizontal: 16 },
            styles.alignItemsCenter,
          ]}
          onPress={() => {
            navigation.navigate("Home");
          }}
        >
          <View
            style={[
              styles.row,
              styles.justifyCenter,
              styles.alignCenter,
              styles.alignItemsCenter,
            ]}
          >
            <img
              src={require("../assets/mbbs/home_icon.svg")}
              style={{ height: 25, marginRight: 16 }}
            />
            <Text style={[styles.text, styles.textPrimary, styles.font18]}>
              HOME
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <ImageBackground
          source={require("../assets/mbbs/page_background.svg")}
          style={[{ width: "100%", paddingTop: "4%" }]}
          resizeMode="cover"
        >
          {renderCourseModal()}
          {steps?.map((a, i) => rendercard(a, i))}
          {steps
            .filter((value) => {
              return value.skip == false;
            })
            ?.map((a) => a?.isCompleted?.length)
            .every((val, i, arr) => val > 1) && (
            <TouchableOpacity
              style={{ width: "50%", alignSelf: "center" }}
              onPress={() => {
                dispatch(saveOtherCoursesFilter(filterData));
                navigation.navigate("OtherCoursesResults");
              }}
            >
              <View
                style={{
                  backgroundColor: "#C80D53",
                  padding: 15,
                  borderRadius: 5,
                  justifyContent: "center",
                  width: "30%",
                  marginVertical: 15,
                  alignSelf: "center",
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: "700",
                  }}
                >
                  Proceed
                </Text>
              </View>
            </TouchableOpacity>
          )}
        </ImageBackground>
      </View>
      <Footer />
    </View>
  );
};

export default OtherCourses;
