import { Alert, Platform } from "react-native";
const apisrv = {
	callApi: async ({
		endPoint,
		request,
		onSuccess,
		onError,
		showAlert,
		hideSuccess,
		method,
		options,
	}) => {
		if (global.isInternetReachable || global.isInternetReachable == undefined) {
			global.isLoading = true;
			// https://hammerhead-app-ax9gc.ondigitalocean.app/welcome
			// http://127.0.0.1:5001/
			await fetch(
				"https://hammerhead-app-ikpjl.ondigitalocean.app/" + endPoint,
				options || {
					method: method || "POST",
					body: JSON.stringify(request),
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			)
				.then(response => response.json())
				.then(responseJson => {
					global.isLoading = false;
					if (responseJson instanceof Object) {
						if (responseJson.error) {
							alert(responseJson.error);
							if (onError) {
								onError(responseJson);
							}
							return;
						}

						if (showAlert && !hideSuccess) {
							if (Platform.OS === "web") {
								if (typeof responseJson.result == "string") {
									alert(responseJson.result);
								} else {
									alert("Successfully executed request");
								}
							} else {
								if (typeof responseJson.result == "string") {
									Alert.alert("Success!!", responseJson.result, [
										{ text: "Close", onPress: () => {} },
									]);
								} else {
									Alert.alert("Success!!", "Successfully executed request", [
										{ text: "Close", onPress: () => {} },
									]);
								}
							}
						}
						if (onSuccess) {
							onSuccess(responseJson);
						}
					} else {
						global.isLoading = false;
						if (onError) {
							onError(responseJson);
						}
						if (showAlert) {
							if (Platform.OS === "web") {
								if (typeof responseJson.result == "string") {
									alert(responseJson.result);
								} else {
									alert("Error while executing request");
								}
							} else {
								if (typeof responseJson.result == "string") {
									Alert.alert("Error", responseJson.result, [
										{ text: "Close", onPress: () => {} },
									]);
								} else {
									Alert.alert("Error", "Error while executing request", [
										{ text: "Close", onPress: () => {} },
									]);
								}
							}
						}
					}
				})
				.catch(error => {
					global.isLoading = false;
					if (onError) {
						onError(error);
					}
					if (showAlert) {
						if (Platform.OS === "web") {
							alert("Error while executing request");
						} else {
							Alert.alert("Error", "Error while executing request", [
								{ text: "Close", onPress: () => {} },
							]);
						}
					}
				});
		}
	},
	showAlert: message => {
		if (Platform.OS === "web") {
			alert(message);
		} else {
			Alert.alert(Alert, message, [{ text: "Close", onPress: () => {} }]);
		}
	},
};

export default apisrv;
