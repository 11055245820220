import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { rootReducers } from "../reducers";

const persistConfig = {
	key: "root",
	storage,
};

const reducers = combineReducers(rootReducers);
const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [thunk];

let initialState = {};

const store = createStore(
	persistedReducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middlewares))
);
let persistor = persistStore(store);

export { store, persistor };
