import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import DragList from "../components/DragList";
import Input from "../components/Input";
import { styles } from "../styles/styles";
import MultiSelect from "../components/Select";

class OtherCoursesProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selectedTab: 0,
      tabs: [
        {
          label: "Your Target",
          key: "yourTarget",
        },
        {
          label: "Study Destinations",
          key: "studyDestinations",
        },
        {
          label: "Shortlist Universities",
          key: "shortlistUniversities",
        },
        {
          label: "Your Study Budget",
          key: "yourStudyBudget",
        },
      ],
      pageConfig: {},
      data: {},
      toggle: {
        type: "toggle",
        offLabel: "Off",
        onLabel: "On",
      },
      selectedCourses: [],
    };
  }
  componentDidMount() {
    let pageConfig = this?.props?.appData?.config?.find(
      (p) => p.name == "Profile Other Courses"
    );
    let data = {};
    let pageConfigData = {};
    this.state.tabs.forEach((t) => {
      pageConfigData[t.key] = pageConfig.sections.find(
        (s) => s.name == t.label
      );
      if (this.props.data && this.props.data[t.key]) {
        data[t.key] = this.props.data[t.key];
      } else {
        data[t.key] = {};
      }
      (pageConfigData[t.key].fields || []).forEach((f) => {
		  if (f.type == "addMultiple") {
			data[t.key][f.key] = [""];
		  } 
        else if (this.props.data) {
          data[t.key][f.key] = this.props.data?.[f.key];
        } else  {
          data[t.key][f.key] = "";
        }
      });
    });
    this.setState({ pageConfig: pageConfigData, data: data });
  }
  toggle = (v) => {
    let stateObj = this.state;
    stateObj.data.shortlistUniversities.shortlistUniversities = v;
    this.setState(stateObj);
    if (this.props.onDataChange) {
      this.props.onDataChange({
        key: "shortlistUniversities",
        value: stateObj.data.shortlistUniversities,
      });
    }
  };
  saveData = () => {
    if (this.state.selectedTab < this.state.tabs.length - 1) {
      this.setState({ selectedTab: this.state.selectedTab + 1 });
    }
  };
  setData = (dataKey, key, value) => {
    let stateObj = this.state;
    stateObj.data[dataKey][key] = value.value;
    this.setState(stateObj);
    if (this.props.onDataChange) {
      this.props.onDataChange({ key: dataKey, value: stateObj.data[dataKey] });
    }
  };
  onSelectedItemsChange = (selectedCourses) => {
    this.setState({ selectedCourses });
  };

  render() {
    return (
      <View>
        <View
          style={[
            styles.row,
            styles.justifyStretch,
            {
              fontSize: 22,
              borderColor: "#434040",
              borderWidth: 0.5,
              borderRadius: 10,
              marginTop: 18,
            },
          ]}
        >
          {this.state.tabs.map((s, i) => {
            if (this.state.selectedTab == i) {
              return (
                <View
                  key={"tab" + (i + 1)}
                  style={[
                    styles.row,
                    {
                      zIndex: 9,
                      borderColor: "#B51700",
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      borderWidth: 0.5,
                      color: "#434040",
                      fontSize: 12,
                      padding: 16,
                      minWidth: "fit-content",
                      backgroundColor: "#F9C07155",
                    },
                    styles.flex,
                    styles.textRed,
                    styles.fontBold,
                  ]}
                >
                  <View
                    style={{
                      backgroundColor: "#B51700",
                      width: 10,
                      marginVertical: i == 0 ? -16 : -17,
                      marginLeft: -20,
                      marginRight: 20,
                      borderTopLeftRadius: i == 0 ? 10 : 0,
                      borderBottomLeftRadius: i == 0 ? 10 : 0,
                    }}
                  ></View>
                  <Text
                    style={[
                      { color: "#434040", fontSize: 14 },
                      styles.fontBold,
                      styles.text,
                      styles.textPrimary,
                    ]}
                  >
                    {s.label}
                  </Text>
                </View>
              );
            } else {
              return (
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ selectedTab: i });
                  }}
                  key={"tab" + (i + 1)}
                  style={[
                    {
                      borderRightColor: "#434040",
                      borderLeftColor: "#434040",
                      borderRightWidth: i == 4 ? 0 : 0.5,
                      borderLeftWidth: i == 0 ? 0 : 0.5,
                      color: "#434040",
                      fontSize: 12,
                      padding: 16,
                      minWidth: "fit-content",
                    },
                    styles.flex,
                  ]}
                >
                  <Text
                    style={[{ color: "#434040", fontSize: 14 }, styles.text]}
                  >
                    {s.label}
                  </Text>
                </TouchableOpacity>
              );
            }
          })}
        </View>
        {this.state.tabs[this.state.selectedTab].key ==
          "shortlistUniversities" && (
          <View>
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.justifyStart,
                { marginTop: 38 },
              ]}
            >
              <Text
                style={[
                  styles.text,
                  styles.textPrimary,
                  styles.font18,
                  styles.fontBold,
                ]}
              >
                Have you shortlisted universties from your end?
              </Text>
            </View>
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.justifyStart,
                { marginTop: 22 },
              ]}
            >
              <Input
                data={this.state.toggle}
                onChange={(d) => {
                  this.toggle(d.value);
                }}
              />
            </View>
          </View>
        )}
        {this.state.pageConfig[this.state.tabs[this.state.selectedTab].key] &&
          this.state.pageConfig[
            this.state.tabs[this.state.selectedTab].key
          ].fields.map((tf, tfi) => {
            let dataKey = this.state.tabs[this.state.selectedTab].key;
            let data = (this.state.data && this.state.data[dataKey]) || {};
            // if (
            //   dataKey == "shortlistUniversities" &&
            //   !data.shortlistUniversities
            // ) {
            //   return <View key={"tabFields" + (tfi + 1)}></View>;
            // }
            return (
              <View key={"tabFields" + (tfi + 1)}>
                <View
                  style={[
                    styles.padding8,
                    styles.row,
                    styles.justifyStart,
                    { marginTop: 38 },
                  ]}
                >
                  <Text
                    style={[
                      styles.text,
                      styles.textPrimary,
                      styles.font18,
                      styles.fontBold,
                    ]}
                  >
                    {tf.type == "addMultiple" ? data.shortlistUniversities && tf.label:tf.label}
                    {tf.type == "addMultiple" && data.shortlistUniversities && tf.max && (
                      <Text
                        style={[
                          styles.text,
                          styles.textRed,
                          styles.font18,
                          styles.fontBold,
                        ]}
                      >
                        {" "}
                        (Max {tf.max})
                      </Text>
                    )}
                  </Text>
                </View>
                {tf.type == "boxSelect" && (
                  <View
                    style={[
                      styles.padding8,
                      styles.row,
                      styles.justifyStart,
                      styles.wrap,
                    ]}
                  >
                    {tf.options.map((mc, mci) => {
                      return (
                        <TouchableOpacity
                          key={"mcs" + (mci + 1) + (tfi + 1)}
                          style={[
                            (data[tf.key] == mc.value && styles.bgPrimary) ||
                              styles.bgWhite,
                            styles.selectCard,
                            styles.justifyCenter,
                            { marginRight: 20, marginTop: 28 },
                          ]}
                          onPress={() => {
                            this.setData(dataKey, tf.key, mc);
                          }}
                        >
                          <Text
                            style={[
                              styles.text,
                              (data[tf.key] == mc.value && styles.textWhite) ||
                                styles.textPrimary,
                              styles.font22,
                            ]}
                          >
                            {mc.label}
                          </Text>
                        </TouchableOpacity>
                      );
                    })}
                  </View>
                )}
                {tf.type == "dragList" && (
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      styles.alignItemsCenter,
                      { marginTop: 24 },
                    ]}
                  >
                    <DragList
                      data={tf}
                      onChange={(d) => {
                        this.setData(dataKey, tf.key, d);
                      }}
                    />
                  </View>
                )}
                {tf.type == "addMultiple" && data.shortlistUniversities && 
                  data[tf.key] &&
                  data[tf.key].map((sf, sfi) => {
                    return (
                      <View
                        style={[
                          styles.row,
                          styles.justifyStart,
                          styles.alignItemsCenter,
                          { marginTop: 24 },
                        ]}
                        key={"sfi" + (sfi + 1)}
                      >
                        <View>
                          <Input
                            data={tf.data}
                            onChange={(v) => {
                              let stateObj = this.state;
                              stateObj.data[dataKey][tf.key][sfi] = v.value;
                              this.setState(stateObj);
                            }}
                          />
                        </View>
                        {tf.max > sfi + 1 && (
                          <View style={{ marginLeft: 22 }}>
                            <TouchableOpacity
                              onPress={() => {
                                let stateObj = this.state;
                                if (!stateObj.data[dataKey][tf.key]) {
                                  stateObj.data[dataKey][tf.key] = [];
                                } else if (sfi != data[tf.key].length - 1) {
                                  stateObj.data[dataKey][tf.key].splice(sfi, 1);
                                } else {
                                  stateObj.data[dataKey][tf.key].push("");
                                }
                                this.setState(stateObj);
                              }}
                            >
                              <Text
                                style={[
                                  styles.text,
                                  styles.textRed,
                                  styles.font18,
                                  styles.fontBold,
                                ]}
                              >
                                {" "}
                                {(sfi != data[tf.key].length - 1 &&
                                  "Remove -") ||
                                  "Add +"}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                    );
                  })}
                {tf.type == "subFields" &&
                  tf.subFields.map((sf, sfi) => {
                    return (
                      <View key={"tabSubFields" + (sfi + 1)}>
                        <View
                          style={[
                            styles.padding8,
                            styles.row,
                            styles.justifyStart,
                            { marginTop: 16 },
                          ]}
                        >
                          <Text
                            style={[
                              styles.text,
                              styles.textPrimary,
                              styles.font18,
                            ]}
                          >
                            {sf.label}
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.padding8,
                            styles.row,
                            styles.justifyStart,
                            styles.wrap,
                          ]}
                        >
                          {sf.options.map((mc, mci) => {
                            return (
                              <TouchableOpacity
                                key={"mcs" + (mci + 1) + (sfi + 1) + (tfi + 1)}
                                style={[
                                  (data[sf.key] == mc.value &&
                                    styles.bgPrimary) ||
                                    styles.bgWhite,
                                  styles.selectCard,
                                  styles.justifyCenter,
                                  { marginRight: 20, marginTop: 28 },
                                ]}
                                onPress={() => {
                                  this.setData(dataKey, sf.key, mc);
                                }}
                              >
                                <Text
                                  style={[
                                    styles.text,
                                    (data[sf.key] == mc.value &&
                                      styles.textWhite) ||
                                      styles.textPrimary,
                                    styles.font22,
                                  ]}
                                >
                                  {mc.label}
                                </Text>
                              </TouchableOpacity>
                            );
                          })}
                        </View>
                      </View>
                    );
                  })}
                {tf.type == "select" && (
                  <Input
                    data={{ ...tf, value: this.state.data[dataKey][tf.key] }}
                    onChange={(v) => {
                      this.setData(dataKey, tf.key, v);
                    }}
                  />
                )}
                {tf.type == "multiselect" && (
                  <View style={{ paddingHorizontal: 12 }}>
                    <MultiSelect
                      hideTags
                      items={tf.options}
                      uniqueKey="value"
                      ref={(component) => {
                        this.multiSelect = component;
                      }}
                      fontSize={18}
                      itemFontSize={18}
                      onSelectedItemsChange={this.onSelectedItemsChange}
                      selectedItems={this.state.selectedCourses}
                      selectText={"Select Specialization"}
                      styleDropdownMenu={{ width: "60%" }}
                      displayKey="label"
                      single={false}
                      hideSubmitButton={true}
                    />
                  </View>
                )}
              </View>
            );
          })}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateData: async (parameter, callback) => {
      await dispatch(parameter);
      if (callback) {
        callback();
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherCoursesProfile);
