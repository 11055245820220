import React, { useEffect, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../actions/userActions";
import Input from "../../components/Input";
import { styles } from "../../styles/styles";

const sideImg = require("../../assets/signin/signup.svg");
const config = {
	mobile: {
		type: "mobileWithCountryCode",
		placeholder: "Mobile Number",
		keyboardType: "phone-pad",
		autoComplete: "tel",
		key: "moblie",
		help: "OTP will be sent to this mobile number.",
		options: [
			{
				label: "+91",
				value: "91",
			},
		],
	},
	email: [
		{
			type: "inputGrey",
			placeholder: "Email ID",
			keyboardType: "email-address",
			autoComplete: "email",
			key: "email",
			pattern:
				/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*[\.]*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
		},
		{
			type: "inputGrey",
			placeholder: "Password",
			isPassword: true,
			key: "password",
		},
	],
};

const SignIn = ({ navigation }) => {
	const [data, setData] = useState({});
	const dispatch = useDispatch();
	const userId = useSelector(state => state.userData?.userDetails?.userId);

	const save = () => {
		dispatch(userLogin(data.email, data.password));
	};

	useEffect(() => {
		if (userId) {
			navigation.navigate("Dashboard");
		}
	}, [userId]);

	// const getUserData = userId => {
	// 	let req = {
	// 		userId: res.userId,
	// 	};
	// 	apisrv.callApi({
	// 		endPoint: "get-user-data",
	// 		request: req,
	// 		onSuccess: res => {
	// 			if (res.error) {
	// 				alert(res.error || "Unable to Fetch user data");
	// 			} else {
	// 				res.userId = req.userId;
	// 				updateData(
	// 					{ key: "userData", value: req, type: "userData" },
	// 					() => {
	// 						if (this.props.appData.redirectRoute) {
	// 							let redirectTo = this.props.appData.redirectRoute;
	// 							this.props.updateData(
	// 								{ key: "redirectRoute", value: "", type: "redirectRoute" },
	// 								() => {
	// 									this.props.navigation.navigate(redirectTo);
	// 								}
	// 							);
	// 						} else {
	// 							this.props.navigation.navigate("Dashboard");
	// 						}
	// 					}
	// 				);
	// 			}
	// 		},
	// 	});
	// };

	return (
		<View
			style={[
				{ padding: 56, overflow: "auto", marginBottom: 160, width: "70vw" },
			]}
		>
			<View style={[styles.row, styles.justifyStretch]}>
				<View style={[{ width: "50%" }]}>
					<View style={[styles.row, styles.justifyStart]}>
						<Text style={[styles.textPrimary, styles.font45, styles.fontBold]}>
							Sign in
						</Text>
					</View>
					<View
						style={[styles.row, styles.justifyStart, { marginVertical: 15 }]}
					>
						<View>
							<TouchableOpacity style={[styles.inputTextGrey]} onPress={save}>
								<Text
									style={[styles.textGrey2, styles.fontBold, styles.font20]}
								>
									GOOGLE
								</Text>
							</TouchableOpacity>
						</View>
						<View>
							<TouchableOpacity
								style={[styles.inputTextGrey, { marginLeft: 26 }]}
								onPress={save}
							>
								<Text
									style={[styles.textGrey2, styles.fontBold, styles.font20]}
								>
									FACEBOOK
								</Text>
							</TouchableOpacity>
						</View>
					</View>
					<View
						style={[styles.row, styles.justifyStart, { marginVertical: 26 }]}
					>
						<Text style={[styles.text, styles.font30]}>Or</Text>
					</View>
					<View style={[styles.row, styles.justifyStart]}>
						<Input
							data={config.mobile}
							onChange={v => {
								setData({ ...data, mobile: v.value });
							}}
						/>
					</View>
					<View style={[styles.row, styles.justifyStart, { marginTop: 45 }]}>
						<TouchableOpacity style={[styles.btnPrimary]} onPress={save}>
							<Text
								style={[
									styles.textWhite,
									styles.text,
									styles.fontBold,
									styles.font25,
								]}
							>
								Request an OTP
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				<View style={[{ width: "50%" }]}>
					<View style={[styles.row, styles.justifyCenter, styles.alignCenter]}>
						<Image
							source={{ uri: sideImg }}
							style={{ width: 360, height: 320, resizeMode: "center" }}
						/>
					</View>
				</View>
			</View>
			<View
				style={[
					styles.row,
					styles.justifyStretch,
					styles.alignItemsCenter,
					{ marginTop: 20 },
				]}
			>
				<View
					style={[
						styles.flex,
						styles.flexGrow,
						{ borderBottomWidth: 0.25, borderBottomColor: "#707070" },
					]}
				></View>
				<View style={[{ marginHorizontal: 24 }]}>
					<Text style={[styles.text, styles.font30]}>Or</Text>
				</View>
				<View
					style={[
						styles.flex,
						styles.flexGrow,
						{ borderBottomWidth: 0.25, borderBottomColor: "#707070" },
					]}
				></View>
			</View>
			<View style={[styles.row, styles.justifyStretch, styles.wrap]}>
				{config.email.map((f, fi) => {
					return (
						<View
							style={[{ width: "48%", marginVertical: 22 }]}
							key={"filed" + (fi + 1)}
						>
							<Input
								data={f}
								onChange={v => {
									setData({ ...data, [f.key]: v.value });
								}}
							/>
						</View>
					);
				})}
			</View>
			<View style={[styles.row, styles.justifyCenter, { marginTop: 32 }]}>
				<TouchableOpacity style={[styles.btnPrimary]} onPress={save}>
					<Text
						style={[
							{ color: "#ffffff", textTransform: "uppercase" },
							styles.text,
							styles.fontBold,
							styles.font25,
						]}
					>
						Sign In
					</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
};

export default SignIn;
