import * as DocumentPicker from "expo-document-picker";
import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import DragList from "../components/DragList";
import EditInfo from "../components/EditInfo";
import Input from "../components/Input";
import Toggle from "../components/Toggle";
import apisrv from "../service/ApiService";
import { styles } from "../styles/styles";
import OtherCoursesProfile from "./OtherCoursesProfile";

class Profile extends React.Component {
  constructor(props) {
    super();
    this.state = {
      tabs: [
        {
          label: "Personal Details",
          key: "personalDetails",
          showSaveBtn: true,
        },
        {
          label: "Education Details & Work Summary",
          key: "educationAndWsDetails",
        },
        {
          label: "Study Abroad Details",
          key: "studyAbroadDetails",
        },
        {
          label: "Tests",
          key: "tests",
          showSaveBtn: true,
        },
        {
          label: "Travel & Documents",
          key: "travelAndDocuments",
          showSaveBtn: false,
        },
      ],
      pageConfig: {
        addressFlds: {
          fields: [],
        },
        basicDetails: [],
        educationDetails: null,
        studyAbroadDetails: {
          toggle: {
            options: [
              {
                label: "MBBS Abroad",
                value: "MBBS Abroad",
              },
              {
                label: "Other Courses",
                value: "Other Courses",
              },
            ],
          },
          mbbs: {
            countries: [
              {
                label: "Bangladesh",
                value: "Bangladesh",
              },
              {
                label: "China",
                value: "China",
              },
              {
                label: "Egypt",
                value: "Egypt",
              },
              {
                label: "Georgia",
                value: "Georgia",
              },
              {
                label: "Kazakhstan",
                value: "Kazakhstan",
              },
              {
                label: "Malaysia",
                value: "Malaysia",
              },
              {
                label: "Mauritus",
                value: "Mauritus",
              },
              {
                label: "Nepal",
                value: "Nepal",
              },
              {
                label: "Philippines",
                value: "Philippines",
              },
              {
                label: "Russia",
                value: "Russia",
              },
              {
                label: "Ukraine",
                value: "Ukraine",
              },
              {
                label: "Others",
                value: "Others",
              },
            ],
            years: [
              {
                label: "2022",
                value: "2022",
              },
              {
                label: "2023",
                value: "2023",
              },
              {
                label: "2024",
                value: "2024",
              },
              {
                label: "2025",
                value: "2025",
              },
              {
                label: "Later",
                value: "Later",
              },
            ],
            intake: [
              {
                label: "Apr - Jul",
                value: "Apr - Jul",
              },
              {
                label: "Aug - Nov",
                value: "Aug - Nov",
              },
              {
                label: "Dec - Mar",
                value: "Dec - Mar",
              },
            ],
            objectives: {
              options: [
                {
                  label: "Get education from top most universities.",
                  value: "Get education from top most universities.",
                  id: 1,
                  isActive: true,
                },
                {
                  label: "Better earning opportunities and job safety.",
                  value: "Better earning opportunities and job safety.",
                  id: 2,
                  isActive: true,
                },
                {
                  label: "International lifestyle.",
                  value: "International lifestyle.",
                  id: 3,
                  isActive: true,
                },
                {
                  label: "Low cost/Budget.",
                  value: "Low cost/Budget.",
                  id: 4,
                  isActive: true,
                },
              ],
            },
            budget: [
              {
                label: "< ₹ 10 Lakh",
                value: "lessthan10Lakh",
              },
              {
                label: "₹ 10 Lakh",
                value: "10lakh",
              },
              {
                label: "₹ 20 Lakh",
                value: "20lakh",
              },
              {
                label: "₹ 40 Lakh",
                value: "40lakh",
              },
              {
                label: "> ₹ 40 Lakh",
                value: "greaterThan40lakh",
              },
            ],
            familyIncome: [
              {
                label: "Below INR 2L",
                value: "lessthan2lakh",
              },
              {
                label: "INR 2L-5L",
                value: "INR2to5lakh",
              },
              {
                label: "INR 5L-10L",
                value: "INR5to10lakh",
              },
              {
                label: "INR 10L-35h",
                value: "INR10to35lakh",
              },
              {
                label: "Above INR 35L",
                value: "aboveINR35lakh",
              },
            ],
            toggle: {
              type: "toggle",
              offLabel: "Off",
              onLabel: "On",
            },
            neetScore: {
              type: "inputBlue",
              placeholder: "NEET Score",
              styles: {
                width: 128,
              },
            },
            passportFields: [
              {
                type: "inputBlue",
                placeholder: "Passport Number",
                label: "Passport Number",
                key: "passportNumber",
              },
              {
                type: "select",
                label: "Country of Issue",
                key: "countryOfIssue",
                options: [
                  {
                    label: "Select Country Name",
                    value: "",
                  },
                  {
                    label: "India",
                    value: "India",
                  },
                ],
              },
              {
                type: "monthYear",
                label: "Issued Month-Year",
                key: "issuedMonthYear",
              },
              {
                type: "monthYear",
                label: "Expiry Month-Year",
                key: "expiryMonthYear",
              },
            ],
          },
        },
        test: {
          englishTests: [
            {
              label: "IELTS",
              value: "IELTS",
            },
            {
              label: "TOEFL",
              value: "TOEFL",
            },
            {
              label: "PTE",
              value: "PTE",
            },
            {
              label: "Didn’t have planned yet",
              value: "Didn’t have planned yet",
            },
          ],
          otherTests: [
            {
              label: "ACT",
              value: "ACT",
            },
            {
              label: "GMAT",
              value: "GMAT",
            },
            {
              label: "GRE",
              value: "GRE",
            },
            {
              label: "SAT",
              value: "SAT",
            },
            {
              label: "Didn’t have planned yet",
              value: "Didn’t have planned yet",
            },
          ],
          testTaken: {
            type: "radio",
            options: [
              {
                label: "Taken",
                value: "Taken",
              },
              {
                label: "Planned",
                value: "Planned",
              },
            ],
          },
          visaRefused: [
            {
              type: "select",
              label: "Issued by Country?",
              key: "issueCountry",
              options: [
                {
                  label: "Bangladesh",
                  value: "Bangladesh",
                },
                {
                  label: "China",
                  value: "China",
                },
                {
                  label: "Egypt",
                  value: "Egypt",
                },
                {
                  label: "Georgia",
                  value: "Georgia",
                },
                {
                  label: "Kazakhstan",
                  value: "Kazakhstan",
                },
                {
                  label: "Malaysia",
                  value: "Malaysia",
                },
                {
                  label: "Mauritus",
                  value: "Mauritus",
                },
                {
                  label: "Nepal",
                  value: "Nepal",
                },
                {
                  label: "Philippines",
                  value: "Philippines",
                },
                {
                  label: "Russia",
                  value: "Russia",
                },
                {
                  label: "Ukraine",
                  value: "Ukraine",
                },
                {
                  label: "Others",
                  value: "Others",
                },
              ],
            },
            {
              type: "monthYear",
              key: "rejectionDate",
              label: "Rejection Month-Year",
            },
            {
              type: "inputBlue",
              key: "rejectionYear",
              label: "Rejection Reason",
            },
          ],
        },
        timeToReach: [
          {
            label: "Morning | 9AM - 12NOON",
            value: "morning",
            icon: require("../assets/dashboard/profile/morning.svg"),
          },
          {
            label: "Noon | 12PM - 4PM",
            value: "noon",
            icon: require("../assets/dashboard/profile/noon.svg"),
          },
          {
            label: "Evening | 4PM - 8PM",
            value: "evening",
            icon: require("../assets/dashboard/profile/evening.svg"),
          },
        ],
        modeOfReach: [
          {
            label: "Phone",
            value: "Phone",
            icon: require("../assets/dashboard/profile/phone.svg"),
          },
          {
            label: "Email",
            value: "Email",
            icon: require("../assets/dashboard/profile/email.svg"),
          },
          {
            label: "Message",
            value: "Message",
            icon: require("../assets/dashboard/profile/message.svg"),
          },
          {
            label: "All",
            value: "All",
            icon: require("../assets/dashboard/profile/all.svg"),
          },
        ],
      },
      pageData: {
        studyAbroadDetails: {
          preference: "MBBS Abroad",
          country: "",
        },
        basicDetails: {
          dob: {},
          address: {},
        },
        parentDetails: {
          motherDetails: {},
          fatherDetails: {},
        },
        test: {
          englishTest: {},
          otherTest: {},
        },
        travelDetails: {},
        documents: {},
      },
      selectedTab: 0,
      selectedDocTab: 0,
      educationDetails: [
        {
          courseName: "B.Tech",
          courseType: "Engineering",
          dbId: 1,
          educationStatus: "Complete",
          endDate: {
            month: "05",
            year: "2018",
          },
          fieldOfStudy: "Electronics",
          highestEducation: "B.Tech",
          languageOfInstruction: "English",
          rank: 1,
          startDate: {
            month: "06",
            year: "2014",
          },
          university: "Mumbai University",
        },
        {
          courseName: "SSC",
          courseType: null,
          dbId: 4,
          educationStatus: "Complete",
          endDate: {
            month: "05",
            year: "2012",
          },
          fieldOfStudy: null,
          highestEducation: "B.Tech",
          languageOfInstruction: "English",
          rank: 2,
          startDate: {
            month: "06",
            year: "2011",
          },
          university: "Maharashtra Board",
        },
      ],
      workSummaryDetails: [
        {
          data: {
            companyName: "Tata iQ",
            currentCompany: 1,
            dbId: 1,
            endDate: {
              month: "01",
              year: "2021",
            },
            jobDescription: "Working as a data scientist",
            jobType: "Analyst",
            startDate: {
              month: "01",
              year: "2021",
            },
          },
        },
      ],
      openParentsInfo: true,
      travelDetails: true,
      documents: true,
      otherTest: false,
      englishTest: true,
    };
  }
  componentDidMount() {
    let pageConfig =
      this?.props?.appData?.config?.find((p) => p.name == "Profile") || {};
    let ewConfig =
      this?.props?.appData?.config?.find(
        (p) => p.name == "Education Details & Work Summary"
      ) || {};
    let parentsInfoConfig =
      this?.props?.appData?.config?.find((p) => p.name == "Parents Info") || {};
    let testConfig =
      this?.props?.appData?.config?.find((p) => p.name == "Tests") || {};
    let documentConfig = this?.props?.appData?.config?.find(
      (p) => p.name == "Documents"
    );
    let pageConfigData = {
      addressFlds: pageConfig?.sections?.find((s) => s.name == "Address"),
      basicDetails: pageConfig?.sections?.filter((s) => s.name != "Address"),
      educationDetails: ewConfig?.sections?.find(
        (s) => s.name == "Education Details"
      ),
      workSummaryDetails: ewConfig?.sections?.find(
        (s) => s.name == "Work Summary"
      ),
      studyAbroadDetails: this.state.pageConfig?.studyAbroadDetails,
      motherInfo: parentsInfoConfig?.sections?.find(
        (s) => s.name == "Mother’s Information"
      ),
      fatherInfo: parentsInfoConfig?.sections?.find(
        (s) => s.name == "Father’s Information"
      ),
      test: this.state.pageConfig?.test,
      englishTest: testConfig?.sections?.find((s) => s.name == "English Test"),
      otherTest: testConfig?.sections?.find((s) => s.name == "Other Test"),
      documents: documentConfig,
      timeToReach: this.state.pageConfig?.timeToReach,
      modeOfReach: this.state.pageConfig?.modeOfReach,
    };
    let educationDetails = [
      {
        fields: pageConfigData?.educationDetails?.fields,
        data: {},
        open: true,
      },
    ];
    this.setState({
      pageConfig: pageConfigData,
      educationDetails: educationDetails,
    });
    this.getBasicDetails();
  }
  getBasicDetails = () => {
    let req = {
      userId: this.props.userData.userId,
    };
    apisrv.callApi({
      endPoint: "get-user-data",
      request: req,
      onSuccess: (res) => {
        let stateObj = this.state;
        res.userId = this.props.userData.userId;
        res.name = res.firstName + " " + res.lastName;
        stateObj.pageData.basicDetails = res;
        this.setState(stateObj);
      },
      onError: () => {
        let stateObj = this.state;
        stateObj.pageData.basicDetails.userId = this.props.userData.userId;
        this.setState(stateObj);
      },
    });
  };
  addEducationDetails = () => {
    let stateObj = this.state;
    stateObj.educationDetails.forEach((e) => {
      e.open = false;
    });
    stateObj.educationDetails.push({
      fields: stateObj.pageConfig.educationDetails.fields,
      data: {},
      open: true,
    });
    this.setState(stateObj);
  };
  addWSDetails = () => {
    let stateObj = this.state;
    stateObj.workSummaryDetails.forEach((e) => {
      e.open = false;
    });
    stateObj.workSummaryDetails.push({
      fields: stateObj.pageConfig.workSummaryDetails.fields,
      data: {},
      open: true,
    });
    this.setState(stateObj);
  };
  studyAbroadToggle = (d) => {
    let stateObj = this.state;
    stateObj.pageData.studyAbroadDetails.preference = d.value;
    this.setState(stateObj);
  };
  setStudyAbroadData = (key, value) => {
    let stateObj = this.state;
    stateObj.pageData.studyAbroadDetails[key] = value;
    this.setState(stateObj);
  };
  setEducationDetailsData = (i, key, value) => {
    let stateObj = this.state;
    stateObj.educationDetails[i][key] = value?.value;
    this.setState(stateObj);
  };
  setWSDetailsData = (i, key, value) => {
    let stateObj = this.state;
    stateObj.workSummaryDetails[i][key] = value?.value;
    this.setState(stateObj);
  };
  setTestData = (parentKey, key, value) => {
    let stateObj = this.state;
    stateObj.pageData.test[parentKey][key] = value.value;
    this.setState(stateObj);
  };
  setTravelData = (key, value) => {
    let stateObj = this.state;
    stateObj.pageData.travelDetails[key] = value;
    this.setState(stateObj);
  };
  setparentInfoData = (parentInfo, key, value) => {
    let stateObj = this.state;
    stateObj.pageData.parentDetails[parentInfo][key] = value.value;
    this.setState(stateObj);
  };
  setEnglistTestConfig = (t) => {
    let stateObj = this.state;
    stateObj.pageData.test.englishTest = {
      englishTest: t,
    };
    stateObj.pageConfig.test.testTaken.value = "";
    stateObj.pageConfig.englishTest =
      this.props.appData.config.find((p) => p.name == t) || {};
    this.setState(stateObj);
  };
  setOtherTestConfig = (t) => {
    let stateObj = this.state;
    stateObj.pageData.test.otherTest = {
      otherTest: t,
    };
    stateObj.pageConfig.test.otherTestTaken = "";
    stateObj.pageConfig.otherTest =
      this.props.appData.config.find((p) => p.name == t) || {};
    this.setState(stateObj);
  };
  saveBasicDetails = () => {
    let req = this.state.pageData.basicDetails;
    req.userId = this.props.userData.userId;
    req.firstName = req.name.split(" ")[0];
    req.lastName = req.name.split(" ")[1];
    apisrv.callApi({
      endPoint: "update-user-data",
      request: req,
      onSuccess: (res) => {},
    });
  };
  getEwDetails = (i) => {
    let req = {
      userId: this.props.userData.userId,
    };
    apisrv.callApi({
      endPoint: "get-user-ew-data",
      request: req,
      onSuccess: (res) => {
        let stateObj = this.state;
        stateObj.selectedTab = i;
        stateObj.educationDetails = [];
        stateObj.workSummaryDetails = [];
        if (!res?.educationDetails?.length) {
          stateObj.educationDetails = [];
        } else {
          (res.educationDetails || []).forEach((e) => {
            let flds = JSON.parse(
              JSON.stringify(stateObj.pageConfig.educationDetails.fields)
            );
            flds.forEach((fl) => {
              fl.value = e[fl.key] || "";
            });
            stateObj.educationDetails.push({
              fields: flds,
              data: e,
              open: false,
              saved: true,
            });
          });
        }
        if (!res?.workSummary?.length) {
          stateObj.workSummaryDetails = [];
        } else {
          (res.workSummary || []).forEach((e) => {
            let flds = JSON.parse(
              JSON.stringify(stateObj.pageConfig.workSummaryDetails.fields)
            );
            flds.forEach((fl) => {
              fl.value = e[fl.key] || "";
            });
            stateObj.workSummaryDetails.push({
              fields: flds,
              data: e,
              open: false,
              saved: true,
            });
          });
        }
        this.setState(stateObj);
      },
      onError: () => {
        this.setState({ selectedTab: i });
      },
    });
  };
  saveEducationDetails = (i) => {
    let req = this.state.educationDetails[i];
    req.userId = this.props.userData.userId;
    if (this.state?.educationDetails?.[i]?.data?.dbId) {
      req = { ...this.state.educationDetails[i]?.data, ...req };
    }
    delete req?.open;
    delete req?.fields;
    delete req?.data;
    apisrv.callApi({
      endPoint: "save-user-e-data",
      request: req,
      onSuccess: (res) => {
        if (res?.success) {
          let stateObj = this.state;
          stateObj.educationDetails[i].saved = true;
          stateObj.educationDetails[i].open = false;
          this.setState(stateObj);
          this.getEwDetails(1);
        }
      },
    });
  };
  deleteEducationDetails = (i) => {
    let req = {};
    req.userId = this.props.userData.userId;
    req.dbId = this.state.educationDetails[i]?.data?.dbId;
    apisrv.callApi({
      endPoint: "delete-user-e-data",
      request: req,
      onSuccess: (res) => {
        if (res?.success) {
          let stateObj = this.state;
          stateObj.educationDetails[i].saved = true;
          stateObj.educationDetails[i].open = false;
          this.setState(stateObj);
          this.getEwDetails(1);
        }
      },
    });
  };
  saveWorkSummaryDetails = (i) => {
    let req = this.state.workSummaryDetails[i];
    req.userId = this.props.userData.userId;
    if (this.state?.workSummaryDetails?.[i]?.data?.dbId) {
      req = { ...this.state.workSummaryDetails[i]?.data, ...req };
    }
    delete req?.open;
    delete req?.fields;
    delete req?.data;
    apisrv.callApi({
      endPoint: "save-user-w-data",
      request: req,
      onSuccess: (res) => {
        if (res?.success) {
          let stateObj = this.state;
          stateObj.workSummaryDetails[i].saved = true;
          stateObj.workSummaryDetails[i].open = false;
          this.setState(stateObj);
          this.getEwDetails(1);
        }
      },
    });
  };
  deleteWorkSummaryDetails = (i) => {
    let req = {};
    req.userId = this.props.userData.userId;
    req.dbId = this.state.workSummaryDetails[i]?.data?.dbId;
    apisrv.callApi({
      endPoint: "delete-user-w-data",
      request: req,
      onSuccess: (res) => {
        if (res?.success) {
          let stateObj = this.state;
          stateObj.workSummaryDetails[i].saved = true;
          stateObj.workSummaryDetails[i].open = false;
          this.setState(stateObj);
          this.getEwDetails(1);
        }
      },
    });
  };
  saveStudyAbroadDetails = () => {
    let req = this.state.pageData.studyAbroadDetails;
    req.userId = this.props.userData.userId;
    req = {
      ...req,
      ...this.state.pageData?.studyAbroadDetails?.yourTarget,
      ...this.state.pageData?.studyAbroadDetails?.studyDestinations,
      ...this.state.pageData?.studyAbroadDetails?.yourStudyBudget,
    };
    apisrv.callApi({
      endPoint: "save-user-sa-data",
      request: req,
      onSuccess: (res) => {
        if (res.success) {
          let stateObj = this.state;
          stateObj.pageData.studyAbroadDetails.closed = true;
          this.setState(stateObj);
        }
      },
    });
  };
  saveTestDetails = () => {
    let req = this.state.pageData.test;
    req.userId = this.props.userData.userId;
    apisrv.callApi({
      endPoint: "save-user-test-data",
      request: req,
      onSuccess: (res) => {
      },
    });
  };
  saveParentDetails = () => {
    let req = this.state.pageData.parentDetails;
    req.userId = this.props.userData.userId;
    apisrv.callApi({
      endPoint: "save-user-parent-data",
      request: req,
      onSuccess: (res) => {
      },
    });
  };
  saveTravelDetails = () => {
    let req = this.state.pageData.travelDetails;
    req.userId = this.props.userData.userId;
    apisrv.callApi({
      endPoint: "save-user-travel-data",
      request: req,
      onSuccess: (res) => {
      },
    });
  };
  setDocuments = () => {
    let req = {
      userId: this.props.userData.userId,
    };
    apisrv.callApi({
      endPoint: "get-user-docs",
      request: req,
      onSuccess: (res) => {
        let stateObj = this.state;
        stateObj.pageData.documents = res;
        stateObj.pageConfig.documents.sections.forEach((s, i) => {
          // stateObj.pageData.documents[s.key] = [
          //     {
          //         name:'Test.doc',
          //         downloadUrl: ''
          //     }
          // ];
          if (s.key == "tests") {
            stateObj.pageConfig.documents.sections[i].fields = [];
            if (stateObj.pageData.test?.englishTest?.englishTest) {
              stateObj.pageConfig.documents.sections[i].fields.push({
                type: "file",
                label: stateObj.pageData.test.englishTest.englishTest,
                description:
                  "Upload your Test certificate in English transcript with clear image.",
              });
            }
            if (stateObj.pageData.test?.otherTest?.otherTest) {
              stateObj.pageConfig.documents.sections[i].fields.push({
                type: "file",
                label: stateObj.pageData.test.otherTest.otherTest,
                description:
                  "Upload your Test certificate in English transcript with clear image.",
              });
            }
          } else if (s.key == "education") {
            stateObj.pageConfig.documents.sections[i].fields = [];
            stateObj.educationDetails.forEach((e) => {
              stateObj.pageConfig.documents.sections[i].fields.push({
                type: "file",
                label: e.courseName,
                description:
                  "Upload your certificate in English transcript with clear image.",
              });
            });
          }
        });
        stateObj.selectedDocTab = 0;
        stateObj.selectedTab = 4;
        this.setState(stateObj);
      },
      onError: () => {},
    });
  };
  setSelectedTab = (i) => {
    if (i == 1) {
      this.getEwDetails(i);
    } else if (i == 2) {
      this.setStudyAbroadDetails(i);
    } else if (i == 3) {
      this.setTestDetails(i);
    } else if (i == 4) {
      this.setState({ selectedTab: i });
      this.setTravelDetails();
      this.setParentDetails();
      this.setDocuments(i);
    } else {
      this.setState({ selectedTab: i });
    }
  };
  setStudyAbroadDetails = (i) => {
    let req = {
      userId: this.props.userData.userId,
    };
    apisrv.callApi({
      endPoint: "get-user-sa-data",
      request: req,
      onSuccess: (res) => {
        let stateObj = this.state;
        stateObj.selectedTab = i;
        if (res instanceof Object && res.success) {
          stateObj.pageData.studyAbroadDetails = res;
          stateObj.pageData.studyAbroadDetails.preference =
            res.preference || "MBBS Abroad";
          stateObj.pageData.studyAbroadDetails.closed = true;
        }
        this.setState(stateObj);
      },
      onError: () => {
        let stateObj = this.state;
        stateObj.selectedTab = i;
        tateObj.pageData.studyAbroadDetails.preference = "MBBS Abroad";
        this.setState(stateObj);
      },
    });
  };
  setTestDetails = (i) => {
    let req = {
      userId: this.props.userData.userId,
    };
    apisrv.callApi({
      endPoint: "get-user-test-data",
      request: req,
      onSuccess: (res) => {
        let stateObj = this.state;
        stateObj.selectedTab = i;
        if (res && res.englishTest && res.otherTest) {
          stateObj.pageData.test = res;
          // let flds = JSON.parse(JSON.stringify(stateObj.pageConfig.educationDetails.fields));
          // flds.forEach(fl=>{
          //     fl.value = e[fl.key] || '';
          // })
          // stateObj.educationDetails.push({
          //     fields: flds,
          //     data: e,
          //     open: false,
          //     saved: true,
          // })
        }

        stateObj.pageConfig.englishTest =
          this.props.appData.config.find(
            (p) => p.name == res.englishTest.englishTest
          ) || {};
        stateObj.pageConfig.otherTest =
          this.props.appData.config.find(
            (p) => p.name == res.otherTest.otherTest
          ) || {};
        this.setState(stateObj);
      },
      onError: () => {
        this.setState({ selectedTab: i });
      },
    });
  };
  setTravelDetails = () => {
    let req = {
      userId: this.props.userData.userId,
    };
    // this.state.pageConfig.test.testTaken
    apisrv.callApi({
      endPoint: "get-user-travel-data",
      request: req,
      onSuccess: (res) => {
        if (res instanceof Object) {
          let stateObj = this.state;
          stateObj.pageData.travelDetails = res;
          // let flds = JSON.parse(JSON.stringify(stateObj.pageConfig.educationDetails.fields));
          //     flds.forEach(fl=>{
          //         fl.value = e[fl.key] || '';
          //     })
          //     stateObj.educationDetails.push({
          //         fields: flds,
          //         data: e,
          //         open: false,
          //         saved: true,
          // })
          this.setState(stateObj);
        }
      },
      onError: () => {},
    });
  };

  setParentDetails = () => {
    let req = {
      userId: this.props.userData.userId,
    };
    apisrv.callApi({
      endPoint: "get-user-parent-data",
      request: req,
      onSuccess: (res) => {
        if (res instanceof Object && res.success === true) {
          let stateObj = this.state;
          stateObj.pageData.parentDetails = res;
          this.setState(stateObj);
        }
      },
      onError: () => {},
    });
  };
  uploadDocument = (d) => {
    DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    }).then((response) => {
      if (response.type == "success") {
        let { name, size, uri } = response;
        let nameParts = name.split(".");
        let fileType = nameParts[nameParts.length - 1];
        var fileToUpload = {
          name: name,
          size: size,
          uri: uri,
          type: "application/" + fileType,
        };
        console.log(fileToUpload, "...............file");
        this.uploadDocToServer(fileToUpload);
      }
    });
  };
  uploadDocToServer(file) {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("userId", this.props.userData.userId);
    const options = {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    apisrv.callApi({
      endPoint: "upload-document",
      options: options,
      onSuccess: (res) => {
        let req = {
          userId: this.props.userData.userId,
          dbId: res.dbId,
          name: file.name,
          section: d.label,
        };
        apisrv.callApi({
          endPoint: "save-user-docs",
          request: req,
          onSuccess: (res) => {
            this.setDocuments();
          },
          onError: () => {},
        });
      },
      onError: () => {},
    });
  }
  unSubscribe() {
    let req = {
      userId: this.props.userData.userId,
    };
    apisrv.callApi({
      endPoint: "unsubscribe-account",
      request: req,
      onSuccess: (res) => {
        this.props.navigation.navigate("Home");
      },
      onError: () => {},
    });
  }
  render() {
    console.log('this.state.pageConfig.studyAbroadDetails.mbbs',this.state.pageConfig.studyAbroadDetails.mbbs)
    return (
      <View style={[styles.flexGrow, styles.flex, { paddingTop: 12 }]}>
        <View
          style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}
        >
          <Text style={[styles.textPrimary, styles.title]}>Your Profile</Text>
        </View>
        {this.state.pageData.basicDetails.userId && (
          <View style={[styles.flexGrow, styles.flex]}>
            <View
              style={[
                styles.flex,
                styles.row,
                styles.justifyStart,
                styles.flexGrow,
              ]}
            >
              {/* Edit your info */}
              <View style={[{ paddingBottom: 36, width: 420 }]}>
                <EditInfo
                  onInputChange={(key, value) => {
                    let stateObj = this.state;
                    stateObj.pageData.basicDetails[key] = value;
                    this.setState(stateObj);
                  }}
                  data={this.state.pageData.basicDetails}
                  onUnSubscribeClick={() => {
                    this.unSubscribe();
                  }}
                />
              </View>
              {/* Main Div */}
              <View
                style={[
                  styles.flexGrow,
                  styles.flex,
                  styles.card,
                  { marginLeft: 8 },
                ]}
              >
                <View style={[styles.row, styles.justifyStart]}>
                  <Text
                    style={[styles.text, styles.textPrimary, { fontSize: 22 }]}
                  >
                    Manage your profiles here.
                  </Text>
                </View>
                {/* style={[styles.row, styles.justifyStretch,{fontSize: 22, borderColor:'#434040', borderWidth:.5, borderRadius: 10,marginTop: 18}]}> */}

                <View
                  style={[
                    styles.row,
                    styles.justifyStretch,
                    styles.bgWhite,
                    styles.brBlue,
                    styles.padding8,
                    { marginTop: 21 },
                  ]}
                >
                  {this.state.tabs.map((s, i) => {
                    if (this.state.selectedTab == i) {
                      // return(
                      // <View key={'tab'+(i+1)} style={[styles.row,{zIndex:9,borderColor: '#B51700',borderTopLeftRadius: 10,borderBottomLeftRadius:10, borderWidth: 0.5, color:'#434040',fontSize: 12,padding: 16, minWidth:'fit-content', backgroundColor:'#F8F9FD'}, styles.flex, styles.textPrimary, styles.fontBold]}>
                      //     <View style={{backgroundColor: '#B51700',width:10,marginVertical:i==0 ? -16: -17,marginLeft:-20, marginRight: 20, borderTopLeftRadius: i==0 ? 10:0, borderBottomLeftRadius: i==0 ?10:0}}></View>
                      //     <Text style={[{ color:'#434040',fontSize: 14}, styles.fontBold, styles.text, styles.textPrimary]}>{s.label}</Text>
                      // </View>)
                      return (
                        <View
                          style={[
                            styles.flex,
                            styles.flexGrow,
                            styles.brPrimary10,
                            styles.bgDarkBlue,
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.alignItemsCenter,
                            { height: 60 },
                            styles.padding8,
                            styles.textAlignCenter,
                          ]}
                          key={"tab" + (i + 1)}
                        >
                          <Text
                            style={[
                              styles.text,
                              styles.textWhite,
                              styles.font18,
                              styles.fontBold,
                            ]}
                          >
                            {s.label}
                          </Text>
                        </View>
                      );
                    } else {
                      // return(
                      // <TouchableOpacity onPress={()=>{
                      //         this.setState({selectedTab: i});
                      //         if(i == 4){
                      //             this.setDocuments()
                      //         }
                      //     }} key={'tab'+(i+1)} style={[{borderRightColor: '#434040',borderLeftColor:'#434040',borderRightWidth: i == 4 ? 0:0.5, borderLeftWidth: i == 0 ? 0:.5, color:'#434040',fontSize: 12,padding: 16, minWidth:'fit-content'}, styles.flex]}>
                      //     <Text style={[{color:'#434040',fontSize: 14}, styles.text]}>{s.label}</Text>
                      // </TouchableOpacity>)

                      return (
                        <View
                          style={[
                            styles.flex,
                            styles.flexGrow,
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.alignItemsCenter,
                            { height: 60 },
                            styles.padding8,
                            styles.textAlignCenter,
                          ]}
                          key={"tab" + (i + 1)}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              this.setSelectedTab(i);
                            }}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.font18,
                                { color: "#434040" },
                              ]}
                            >
                              {s.label}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      );
                    }
                  })}
                </View>
                {/* Basic Details */}
                {this.state.selectedTab == 0 && (
                  <View>
                    <View style={[styles.cardBlue]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                        ]}
                      >
                        <Text
                          style={[
                            styles.textRed,
                            styles.fontBold,
                            { fontSize: 22 },
                          ]}
                        >
                          Basic Details
                        </Text>
                      </View>
                      {(this.state.pageConfig.basicDetails || []).map(
                        (bd, bdi) => {
                          return (
                            <View key={"bd" + (bdi + 1)}>
                              <View
                                style={[
                                  styles.padding8,
                                  styles.row,
                                  styles.justifyStart,
                                  styles.textPrimary,
                                  styles.fontBold,
                                  { fontSize: 22 },
                                ]}
                              >
                                <Text
                                  style={[
                                    styles.textPrimary,
                                    styles.fontBold,
                                    { fontSize: 22 },
                                  ]}
                                >
                                  {bd.name}
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.padding8,
                                  styles.row,
                                  styles.justifyStart,
                                  styles.textPrimary,
                                ]}
                              >
                                {bd.fields.map((bdf, bdfi) => {
                                  let value = "";
                                  if (
                                    bd.name == "Date of Birth" &&
                                    this.state.pageData?.basicDetails?.dob
                                  ) {
                                    value =
                                      this.state.pageData?.basicDetails?.dob[
                                        bdf.key
                                      ];
                                  } else if (
                                    this.state.pageData?.basicDetails
                                  ) {
                                    value =
                                      this.state.pageData?.basicDetails[
                                        bdf.key
                                      ];
                                  }
                                  return (
                                    <Input
                                      data={{ ...bdf, color: "#0C5397" }}
                                      key={bdfi + 1}
                                      value={value}
                                      onChange={(v) => {
                                        let stateObj = this.state;

                                        if (bd.name == "Date of Birth") {
                                          if (
                                            !stateObj.pageData.basicDetails.dob
                                          ) {
                                            stateObj.pageData.basicDetails.dob =
                                              {};
                                          }
                                          stateObj.pageData.basicDetails.dob[
                                            bdf.key
                                          ] = v.value;
                                        } else {
                                          stateObj.pageData.basicDetails[
                                            bdf.key
                                          ] = v.value;
                                        }
                                        this.setState(stateObj);
                                      }}
                                    />
                                  );
                                })}
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>
                    {/* Address */}
                    <View style={[styles.cardBlue]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                          styles.textRed,
                          styles.fontBold,
                          { fontSize: 22 },
                        ]}
                      >
                        <Text
                          style={[
                            styles.textRed,
                            styles.fontBold,
                            { fontSize: 22 },
                          ]}
                        >
                          Address
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                          styles.wrap,
                          ,
                          styles.textPrimary,
                        ]}
                      >
                        {this.state.pageConfig.addressFlds &&
                          this.state.pageConfig.addressFlds.fields.map(
                            (f, i) => {
                              return (
                                <View
                                  key={"af" + (i + 1)}
                                  style={[
                                    styles.m,
                                    styles.flex,
                                    styles.flexGrow,
                                    { minWidth: "31%" },
                                  ]}
                                >
                                  <Input
                                    data={{ ...f, color: "#0C5397" }}
                                    value={
                                      this.state.pageData?.basicDetails
                                        ?.address &&
                                      this.state.pageData?.basicDetails
                                        ?.address[f.key]
                                    }
                                    onChange={(v) => {
                                      let stateObj = this.state;
                                      stateObj.pageData.basicDetails.address[
                                        f.key
                                      ] = v.value;
                                      this.setState(stateObj);
                                    }}
                                  />
                                </View>
                              );
                            }
                          )}
                      </View>
                    </View>
                    {/* Time to Reach */}
                    <View style={[styles.cardBlue]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                          styles.textRed,
                          styles.fontBold,
                          { fontSize: 22 },
                        ]}
                      >
                        <Text
                          style={[
                            styles.textRed,
                            styles.fontBold,
                            { fontSize: 22 },
                          ]}
                        >
                          Prefferred Time To Reach
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                          styles.wrap,
                          ,
                          styles.textPrimary,
                        ]}
                      >
                        {this.state.pageConfig.timeToReach.map((tm, tmi) => {
                          if (
                            (
                              this.state.pageData?.basicDetails
                                ?.preferredTimeToReach || []
                            ).indexOf(tm.value) != -1
                          ) {
                            return (
                              <TouchableOpacity
                                key={"timeToReach" + (tmi + 1)}
                                style={[
                                  {
                                    borderRadius: 50,
                                    borderWidth: 0.5,
                                    borderColor: "#5479CB",
                                    padding: 3,
                                    marginRight: 32,
                                    backgroundColor: "#A9CDF0",
                                  },
                                ]}
                                onPress={() => {
                                  if (
                                    (
                                      this.state.pageData?.basicDetails
                                        ?.preferredTimeToReach || []
                                    ).length
                                  ) {
                                    let stateObj = this.state;
                                    stateObj.pageData.basicDetails.preferredTimeToReach =
                                      stateObj.pageData.basicDetails.preferredTimeToReach.filter(
                                        (obj) => obj != tm.value
                                      );
                                    this.setState(stateObj);
                                  }
                                }}
                              >
                                <View
                                  style={[
                                    styles.row,
                                    styles.justifyCenter,
                                    styles.alignItemsCenter,
                                  ]}
                                >
                                  <View
                                    style={[
                                      {
                                        padding: 2,
                                        borderWidth: 1,
                                        borderColor: "#0C5397",
                                        borderRadius: "50%",
                                      },
                                    ]}
                                  >
                                    <Image
                                      source={{ uri: tm.icon }}
                                      style={{
                                        width: 58,
                                        height: 58,
                                        resizeMode: "center",
                                      }}
                                    />
                                  </View>
                                  <View style={{ margin: 18 }}>
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textWhite,
                                        styles.font22,
                                        styles.fontBold,
                                      ]}
                                    >
                                      {tm.label}
                                    </Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            );
                          } else {
                            return (
                              <TouchableOpacity
                                key={"timeToReach" + (tmi + 1)}
                                style={[
                                  {
                                    borderRadius: 50,
                                    borderWidth: 0.5,
                                    borderColor: "#5479CB",
                                    padding: 3,
                                    marginRight: 32,
                                  },
                                  styles.bgWhite,
                                ]}
                                onPress={() => {
                                  let stateObj = this.state;
                                  if (
                                    !(
                                      stateObj.pageData.basicDetails
                                        .preferredTimeToReach || []
                                    ).length
                                  ) {
                                    stateObj.pageData.basicDetails.preferredTimeToReach =
                                      [tm.value];
                                  } else {
                                    stateObj.pageData.basicDetails.preferredTimeToReach.push(
                                      tm.value
                                    );
                                  }
                                  this.setState(stateObj);
                                }}
                              >
                                <View
                                  style={[
                                    styles.row,
                                    styles.justifyCenter,
                                    styles.alignItemsCenter,
                                  ]}
                                >
                                  <View
                                    style={[
                                      {
                                        padding: 2,
                                        borderWidth: 1,
                                        borderColor: "#0C5397",
                                        borderRadius: "50%",
                                      },
                                    ]}
                                  >
                                    <Image
                                      source={{ uri: tm.icon }}
                                      style={{
                                        width: 58,
                                        height: 58,
                                        resizeMode: "center",
                                      }}
                                    />
                                  </View>
                                  <View style={{ margin: 18 }}>
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {tm.label}
                                    </Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            );
                          }
                        })}
                      </View>
                    </View>
                    {/* Prefferred Mode Of Reach */}
                    <View style={[styles.cardBlue]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                          styles.textRed,
                          styles.fontBold,
                          { fontSize: 22 },
                        ]}
                      >
                        <Text
                          style={[
                            styles.textRed,
                            styles.fontBold,
                            { fontSize: 22 },
                          ]}
                        >
                          Prefferred Mode Of Reach
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                          styles.wrap,
                          ,
                          styles.textPrimary,
                        ]}
                      >
                        {this.state.pageConfig.modeOfReach.map((tm, tmi) => {
                          if (
                            (
                              this.state.pageData?.basicDetails
                                ?.preferredModeOfReach || []
                            ).indexOf(tm.value) != -1
                          ) {
                            return (
                              <TouchableOpacity
                                key={"modeOfReach" + (tmi + 1)}
                                style={[
                                  {
                                    borderRadius: 50,
                                    borderWidth: 0.5,
                                    borderColor: "#5479CB",
                                    padding: 3,
                                    marginRight: 32,
                                    backgroundColor: "#A9CDF0",
                                  },
                                ]}
                                onPress={() => {
                                  if (
                                    (
                                      this.state.pageData?.basicDetails
                                        ?.preferredModeOfReach || []
                                    ).length
                                  ) {
                                    let stateObj = this.state;
                                    stateObj.pageData.basicDetails.preferredModeOfReach =
                                      stateObj.pageData.basicDetails.preferredModeOfReach.filter(
                                        (obj) => obj != tm.value
                                      );
                                    this.setState(stateObj);
                                  }
                                }}
                              >
                                <View
                                  style={[
                                    styles.row,
                                    styles.justifyCenter,
                                    styles.alignItemsCenter,
                                  ]}
                                >
                                  <View
                                    style={[
                                      {
                                        padding: 2,
                                        borderWidth: 1,
                                        borderColor: "#0C5397",
                                        borderRadius: "50%",
                                      },
                                    ]}
                                  >
                                    <Image
                                      source={{ uri: tm.icon }}
                                      style={{
                                        width: 58,
                                        height: 58,
                                        resizeMode: "center",
                                      }}
                                    />
                                  </View>
                                  <View style={{ margin: 18 }}>
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textWhite,
                                        styles.font22,
                                        styles.fontBold,
                                      ]}
                                    >
                                      {tm.label}
                                    </Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            );
                          } else {
                            return (
                              <TouchableOpacity
                                key={"modeOfReach" + (tmi + 1)}
                                style={[
                                  {
                                    borderRadius: 50,
                                    borderWidth: 0.5,
                                    borderColor: "#5479CB",
                                    padding: 3,
                                    marginRight: 32,
                                  },
                                  styles.bgWhite,
                                ]}
                                onPress={() => {
                                  let stateObj = this.state;
                                  if (
                                    !(
                                      stateObj.pageData.basicDetails
                                        .preferredModeOfReach || []
                                    ).length
                                  ) {
                                    stateObj.pageData.basicDetails.preferredModeOfReach =
                                      [tm.value];
                                  } else {
                                    stateObj.pageData.basicDetails.preferredModeOfReach.push(
                                      tm.value
                                    );
                                  }
                                  this.setState(stateObj);
                                }}
                              >
                                <View
                                  style={[
                                    styles.row,
                                    styles.justifyCenter,
                                    styles.alignItemsCenter,
                                  ]}
                                >
                                  <View
                                    style={[
                                      {
                                        padding: 2,
                                        borderWidth: 1,
                                        borderColor: "#0C5397",
                                        borderRadius: "50%",
                                      },
                                    ]}
                                  >
                                    <Image
                                      source={{ uri: tm.icon }}
                                      style={{
                                        width: 58,
                                        height: 58,
                                        resizeMode: "center",
                                      }}
                                    />
                                  </View>
                                  <View style={{ margin: 18 }}>
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {tm.label}
                                    </Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            );
                          }
                        })}
                      </View>
                    </View>
                    {/* Save */}
                    <View
                      style={[
                        styles.row,
                        styles.justifyCenter,
                        styles.textPrimary,
                        { fontSize: 22 },
                      ]}
                    >
                      <TouchableOpacity
                        style={[styles.btnPrimary]}
                        onPress={this.saveBasicDetails}
                      >
                        <Text
                          style={[
                            { color: "#ffffff", textTransform: "uppercase" },
                          ]}
                        >
                          Save Details
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                {/* Education Details & Work Summary */}
                {this.state.selectedTab == 1 && (
                  <View>
                    {/* Education Details*/}
                    {this.state?.educationDetails?.map((ed, edi) => {
                      if (ed.saved && !ed.open) {
                        return (
                          <View
                            key={"edi" + (edi + 1)}
                            style={[styles.cardBlue]}
                          >
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.justifyStretch,
                              ]}
                            >
                              <View>
                                <Text
                                  style={[
                                    styles.textRed,
                                    styles.fontBold,
                                    { fontSize: 22 },
                                  ]}
                                >
                                  {edi > 0
                                    ? "Education Details (Previous)"
                                    : "Education Details (Highest)"}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.row,
                                { borderWidth: 1, borderColor: "#5479CB" },
                              ]}
                            >
                              <View
                                style={[
                                  {
                                    width: 114,
                                    height: 117,
                                    borderWidth: 1,
                                    borderColor: "#5479CB",
                                  },
                                ]}
                              >
                                <Image
                                  source={{
                                    uri: require("../assets/dashboard/profile/education_details.svg"),
                                  }}
                                  style={{
                                    width: 113,
                                    height: 115,
                                    resizeMode: "center",
                                  }}
                                />
                              </View>
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStretch,
                                  {
                                    paddingVertical: 12,
                                    paddingHorizontal: 18,
                                    width: "88%",
                                  },
                                  styles.bgWhite,
                                  styles.alignItemsCenter,
                                ]}
                              >
                                <View>
                                  <View
                                    style={[styles.row, styles.justifyStart]}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                        styles.fontBold,
                                      ]}
                                    >
                                      {ed.data?.educationLevel ||
                                        ed.data?.courseName}
                                    </Text>
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {" "}
                                      | {ed.data?.fieldOfStudy}
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.row,
                                      styles.justifyStart,
                                      { marginTop: 6 },
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                        styles.fontBold,
                                      ]}
                                    >
                                      {ed.data?.university}
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.row,
                                      styles.justifyStart,
                                      { marginTop: 6 },
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {ed.data?.startDate?.month}{" "}
                                      {ed.data?.startDate?.year} -{" "}
                                      {ed.data?.endDate?.month}{" "}
                                      {ed.data?.endDate?.year}
                                    </Text>
                                  </View>
                                </View>
                                <View>
                                  <TouchableOpacity
                                    onPress={() => {
                                      let stateObj = this.state;
                                      stateObj.educationDetails[
                                        edi
                                      ].open = true;
                                      this.setState(stateObj);
                                    }}
                                  >
                                    <View
                                      style={[
                                        styles.row,
                                        styles.justifyEnd,
                                        styles.alignItemsCenter,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.textPrimary,
                                          styles.font18,
                                        ]}
                                      >
                                        Edit
                                      </Text>
                                      <Image
                                        source={{
                                          uri: require("../assets/dashboard/profile/edit.svg"),
                                        }}
                                        style={{
                                          width: 14,
                                          height: 14,
                                          resizeMode: "center",
                                          marginLeft: 14,
                                        }}
                                      />
                                    </View>
                                  </TouchableOpacity>
                                  <TouchableOpacity
                                    onPress={() =>
                                      this.deleteEducationDetails(edi)
                                    }
                                  >
                                    <View
                                      style={[
                                        styles.row,
                                        styles.justifyEnd,
                                        { marginTop: 26 },
                                        styles.alignItemsCenter,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.textRed,
                                          styles.font18,
                                        ]}
                                      >
                                        Delete
                                      </Text>
                                      <Image
                                        source={{
                                          uri: require("../assets/dashboard/profile/delete.svg"),
                                        }}
                                        style={{
                                          width: 14,
                                          height: 14,
                                          resizeMode: "center",
                                          marginLeft: 14,
                                        }}
                                      />
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      }
                      return (
                        <View key={"edi" + (edi + 1)} style={[styles.cardBlue]}>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStretch,
                            ]}
                          >
                            <View>
                              <Text
                                style={[
                                  styles.textRed,
                                  styles.fontBold,
                                  { fontSize: 22 },
                                ]}
                              >
                                {edi > 0
                                  ? "Education Details (Previous)"
                                  : "Education Details (Highest)"}
                              </Text>
                            </View>
                            <View>
                              <TouchableOpacity
                                onPress={() => {
                                  let stateObj = this.state;
                                  stateObj.educationDetails[edi].open =
                                    !stateObj.educationDetails[edi].open;
                                  this.setState(stateObj);
                                }}
                              >
                                <Text
                                  style={[
                                    styles.textRed,
                                    styles.fontBold,
                                    { fontSize: 22 },
                                  ]}
                                >
                                  {ed.open ? "-" : "+"}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                          {ed.open && (
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.justifyStretch,
                                styles.wrap,
                              ]}
                            >
                              {(ed.fields || []).map((edf, edfi) => {
                                return (
                                  <View
                                    key={"edfi" + (edfi + 1)}
                                    style={[{ padding: 16, width: "50%" }]}
                                  >
                                    <View
                                      style={[
                                        styles.padding8,
                                        styles.row,
                                        styles.justifyStart,
                                        styles.textPrimary,
                                        styles.fontBold,
                                        { fontSize: 22 },
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.textPrimary,
                                          styles.fontBold,
                                          { fontSize: 22 },
                                        ]}
                                      >
                                        {edf.label}
                                      </Text>
                                    </View>
                                    <View style={[styles.padding8, styles.row]}>
                                      <Input
                                        data={{ ...edf, color: "#0C5397" }}
                                        onChange={(value) => {
                                          this.setEducationDetailsData(
                                            edi,
                                            edf.key,
                                            value
                                          );
                                        }}
                                      />
                                    </View>
                                  </View>
                                );
                              })}
                            </View>
                          )}
                          {ed.open && (
                            <View
                              style={[
                                styles.row,
                                styles.justifyEnd,
                                styles.alignItemsCenter,
                                styles.textPrimary,
                                { fontSize: 22 },
                              ]}
                            >
                              <TouchableOpacity
                                style={[{ marginHorizontal: 30 }]}
                                onPress={() => {
                                  let stateObj = this.state;
                                  stateObj.educationDetails[edi].open = false;
                                  this.setState(stateObj);
                                }}
                              >
                                <Text
                                  style={[
                                    styles.text,
                                    styles.textRed,
                                    styles.fontBold,
                                    styles.font20,
                                  ]}
                                >
                                  Cancel
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={[styles.btnPrimary]}
                                onPress={() => {
                                  this.saveEducationDetails(edi);
                                }}
                              >
                                <Text
                                  style={[
                                    styles.text,
                                    styles.textWhite,
                                    styles.fontBold,
                                    styles.font20,
                                  ]}
                                >
                                  Save
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                      );
                    })}
                    <View
                      style={[
                        styles.row,
                        styles.justifyStart,
                        { marginTop: 15, marginBottom: 30 },
                      ]}
                    >
                      <TouchableOpacity
                        style={[styles.btnRedBorder]}
                        onPress={this.addEducationDetails}
                      >
                        <Text
                          style={[
                            styles.text,
                            styles.textRed,
                            styles.font18,
                            styles.fontBold,
                          ]}
                        >
                          Add Education Details +
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {/*Work Summary */}
                    {this.state?.workSummaryDetails?.map((ws, wsi) => {
                      if (ws.saved && !ws.open) {
                        return (
                          <View
                            key={"wsi" + (wsi + 1)}
                            style={[styles.cardBlue]}
                          >
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.justifyStretch,
                              ]}
                            >
                              <View>
                                <Text
                                  style={[
                                    styles.textRed,
                                    styles.fontBold,
                                    { fontSize: 22 },
                                  ]}
                                >
                                  Work Summary
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.row,
                                {
                                  borderWidth: 1,
                                  borderColor: "#5479CB",
                                },
                              ]}
                            >
                              <View
                                style={[
                                  {
                                    width: 114,
                                    height: 117,
                                    borderWidth: 1,
                                    borderColor: "#5479CB",
                                  },
                                ]}
                              >
                                <Image
                                  source={{
                                    uri: require("../assets/dashboard/profile/education_details.svg"),
                                  }}
                                  style={{
                                    width: 113,
                                    height: 115,
                                    resizeMode: "center",
                                  }}
                                />
                              </View>
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStretch,
                                  {
                                    paddingVertical: 12,
                                    paddingHorizontal: 18,
                                    width: "88%",
                                  },
                                  styles.bgWhite,
                                  styles.alignItemsCenter,
                                ]}
                              >
                                <View>
                                  <View
                                    style={[styles.row, styles.justifyStart]}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                        styles.fontBold,
                                      ]}
                                    >
                                      {ws.data?.companyName || ws.data?.jobType}
                                    </Text>
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {" "}
                                      | {ws.data?.jobDescription}
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.row,
                                      styles.justifyStart,
                                      { marginTop: 6 },
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                        styles.fontBold,
                                      ]}
                                    >
                                      {ws.data?.companyName}
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.row,
                                      styles.justifyStart,
                                      { marginTop: 6 },
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {ws.data?.startDate?.month}{" "}
                                      {ws.data?.startDate?.year} -{" "}
                                      {ws.data?.endDate?.month}{" "}
                                      {ws.data?.endDate?.year}
                                    </Text>
                                  </View>
                                </View>
                                <View>
                                  <TouchableOpacity
                                    onPress={() => {
                                      let stateObj = this.state;
                                      stateObj.workSummaryDetails[
                                        wsi
                                      ].open = true;
                                      this.setState(stateObj);
                                    }}
                                  >
                                    <View
                                      style={[
                                        styles.row,
                                        styles.justifyEnd,
                                        styles.alignItemsCenter,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.textPrimary,
                                          styles.font18,
                                        ]}
                                      >
                                        Edit
                                      </Text>
                                      <Image
                                        source={{
                                          uri: require("../assets/dashboard/profile/edit.svg"),
                                        }}
                                        style={{
                                          width: 14,
                                          height: 14,
                                          resizeMode: "center",
                                          marginLeft: 14,
                                        }}
                                      />
                                    </View>
                                  </TouchableOpacity>
                                  <TouchableOpacity
                                    onPress={() =>
                                      this.deleteWorkSummaryDetails(wsi)
                                    }
                                  >
                                    <View
                                      style={[
                                        styles.row,
                                        styles.justifyEnd,
                                        { marginTop: 26 },
                                        styles.alignItemsCenter,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.textRed,
                                          styles.font18,
                                        ]}
                                      >
                                        Delete
                                      </Text>
                                      <Image
                                        source={{
                                          uri: require("../assets/dashboard/profile/delete.svg"),
                                        }}
                                        style={{
                                          width: 14,
                                          height: 14,
                                          resizeMode: "center",
                                          marginLeft: 14,
                                        }}
                                      />
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      }
                      return (
                        <View key={"wsi" + (wsi + 1)} style={[styles.cardBlue]}>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStretch,
                            ]}
                          >
                            <View>
                              <Text
                                style={[
                                  styles.textRed,
                                  styles.fontBold,
                                  { fontSize: 22 },
                                ]}
                              >
                                Work Summary
                              </Text>
                            </View>
                            <View>
                              <TouchableOpacity
                                onPress={() => {
                                  let stateObj = this.state;
                                  stateObj.workSummaryDetails[wsi].open =
                                    !stateObj.workSummaryDetails[wsi].open;
                                  this.setState(stateObj);
                                }}
                              >
                                <Text
                                  style={[
                                    styles.textRed,
                                    styles.fontBold,
                                    { fontSize: 22 },
                                  ]}
                                >
                                  {ws.open ? "-" : "+"}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                          {ws.open && (
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.justifyStretch,
                                styles.wrap,
                              ]}
                            >
                              {(ws.fields || []).map((edf, edfi) => {
                                return (
                                  <View
                                    key={"edfi" + (edfi + 1)}
                                    style={[
                                      {
                                        padding: 16,
                                        width:
                                          (edf.type != "textarea" && "50%") ||
                                          "100%",
                                      },
                                    ]}
                                  >
                                    {edf.type != "checkbox" && (
                                      <View
                                        style={[
                                          styles.padding8,
                                          styles.row,
                                          styles.justifyStart,
                                          styles.textPrimary,
                                          styles.fontBold,
                                          { fontSize: 22 },
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.textPrimary,
                                            styles.fontBold,
                                            { fontSize: 22 },
                                          ]}
                                        >
                                          {edf.label}
                                        </Text>
                                      </View>
                                    )}
                                    <View style={[styles.padding8, styles.row]}>
                                      <Input
                                        data={{ ...edf, color: "#0C5397" }}
                                        onChange={(value) => {
                                          this.setWSDetailsData(
                                            wsi,
                                            edf.key,
                                            value
                                          );
                                        }}
                                      />
                                    </View>
                                  </View>
                                );
                              })}
                            </View>
                          )}
                          {ws.open && (
                            <View
                              style={[
                                styles.row,
                                styles.justifyEnd,
                                styles.alignItemsCenter,
                                styles.textPrimary,
                                { fontSize: 22 },
                              ]}
                            >
                              <TouchableOpacity
                                style={[{ marginHorizontal: 30 }]}
                                onPress={() => {
                                  let stateObj = this.state;
                                  stateObj.workSummaryDetails[wsi].open = false;
                                  this.setState(stateObj);
                                }}
                              >
                                <Text
                                  style={[
                                    styles.text,
                                    styles.textRed,
                                    styles.fontBold,
                                    styles.font20,
                                  ]}
                                >
                                  Cancel
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={[styles.btnPrimary]}
                                onPress={() => {
                                  this.saveWorkSummaryDetails(wsi);
                                }}
                              >
                                <Text
                                  style={[
                                    styles.text,
                                    styles.textWhite,
                                    styles.fontBold,
                                    styles.font20,
                                  ]}
                                >
                                  Save
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                      );
                    })}
                    <View
                      style={[
                        styles.row,
                        styles.justifyStart,
                        { marginTop: 15, marginBottom: 30 },
                      ]}
                    >
                      <TouchableOpacity
                        style={[styles.btnRedBorder]}
                        onPress={this.addWSDetails}
                      >
                        <Text
                          style={[
                            styles.text,
                            styles.textRed,
                            styles.font18,
                            styles.fontBold,
                          ]}
                        >
                          Add Work Details +
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                {/* Study Abroad Details */}
                {this.state.selectedTab == 2 && (
                  <View style={[styles.cardBlue]}>
                    <View
                      style={[styles.padding8, styles.row, styles.justifyStart]}
                    >
                      <Text
                        style={[
                          styles.textRed,
                          styles.fontBold,
                          { fontSize: 22 },
                        ]}
                      >
                        Study Abroad Details
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.padding8,
                        styles.row,
                        styles.justifyStart,
                        { marginTop: 18 },
                      ]}
                    >
                      <Text
                        style={[
                          styles.textPrimary,
                          styles.fontBold,
                          styles.font18,
                        ]}
                      >
                        Select your preference
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.padding8,
                        styles.row,
                        styles.justifyStart,
                        { marginTop: 18 },
                      ]}
                    >
                      <Toggle
                        data={this.state.pageConfig.studyAbroadDetails.toggle}
                        onToggle={(d) => {
                          this.studyAbroadToggle(d);
                        }}
                        selectedOption={
                          this.state.pageData?.studyAbroadDetails?.preference
                        }
                      />
                    </View>
                    {/* MBBS Abroad */}
                    {this.state.pageData.studyAbroadDetails.preference ==
                      "MBBS Abroad" &&
                      !this.state.pageData.studyAbroadDetails.closed && (
                        <View style={{ marginTop: 23 }}>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Text>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textRed,
                                  styles.font18,
                                  styles.fontBold,
                                ]}
                              >
                                Important info:{" "}
                              </Text>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.font18,
                                ]}
                              >
                                Important info: Below listed countries are for
                                undergraduate MBBS courses based on{" "}
                              </Text>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.font18,
                                  styles.fontBold,
                                ]}
                              >
                                NEET{" "}
                              </Text>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.font18,
                                ]}
                              >
                                qualified criteria. For MBBS/Other courses in
                                other countries like UK, US, Australia, New
                                Zealand etc., select your preferences in{" "}
                              </Text>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.font18,
                                  styles.fontBold,
                                ]}
                              >
                                Other Courses tab.
                              </Text>
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 38 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              What is your country preferences?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {this.state.pageConfig.studyAbroadDetails.mbbs.countries.map(
                              (mc, mci) => {
                                let isSelected =
                                  (
                                    this.state.pageData.studyAbroadDetails
                                      .country || []
                                  ).indexOf(mc.value) != -1;
                                return (
                                  <TouchableOpacity
                                    key={"mcs" + (mci + 1)}
                                    style={[
                                      (isSelected && styles.bgPrimary) ||
                                        styles.bgWhite,
                                      styles.selectCard,
                                      styles.justifyCenter,
                                      { marginRight: 20, marginTop: 28 },
                                    ]}
                                    onPress={() => {
                                      let stateObj = this.state;
                                      if (
                                        !(
                                          stateObj.pageData?.studyAbroadDetails
                                            ?.country || []
                                        ).length
                                      ) {
                                        stateObj.pageData.studyAbroadDetails.country =
                                          [mc.value];
                                      } else {
                                        let indC =
                                          stateObj.pageData.studyAbroadDetails.country.indexOf(
                                            mc.value
                                          );
                                        if (indC == -1) {
                                          stateObj.pageData.studyAbroadDetails.country.push(
                                            mc.value
                                          );
                                        } else {
                                          stateObj.pageData.studyAbroadDetails.country.splice(
                                            indC,
                                            1
                                          );
                                        }
                                      }
                                      this.setState(stateObj);
                                    }}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        (isSelected && styles.textWhite) ||
                                          styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {mc.label}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }
                            )}
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              When would you like to start college?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 22 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                              ]}
                            >
                              Year
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {this.state.pageConfig.studyAbroadDetails.mbbs.years.map(
                              (mc, mci) => {
                                return (
                                  <TouchableOpacity
                                    key={"mcs" + (mci + 1)}
                                    style={[
                                      (this.state.pageData.studyAbroadDetails
                                        .year == mc.value &&
                                        styles.bgPrimary) ||
                                        styles.bgWhite,
                                      styles.selectCard,
                                      styles.justifyCenter,
                                      { marginRight: 20, marginTop: 11 },
                                    ]}
                                    onPress={() => {
                                      this.setStudyAbroadData("year", mc.value);
                                    }}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        (this.state.pageData.studyAbroadDetails
                                          .year == mc.value &&
                                          styles.textWhite) ||
                                          styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {mc.label}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }
                            )}
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 22 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                              ]}
                            >
                              Intake
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {this.state.pageConfig.studyAbroadDetails.mbbs.intake.map(
                              (mc, mci) => {
                                return (
                                  <TouchableOpacity
                                    key={"mcs" + (mci + 1)}
                                    style={[
                                      (this.state.pageData.studyAbroadDetails
                                        .intake == mc.value &&
                                        styles.bgPrimary) ||
                                        styles.bgWhite,
                                      styles.selectCard,
                                      styles.justifyCenter,
                                      { marginRight: 20, marginTop: 11 },
                                    ]}
                                    onPress={() => {
                                      this.setStudyAbroadData(
                                        "intake",
                                        mc.value
                                      );
                                    }}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        (this.state.pageData.studyAbroadDetails
                                          .intake == mc.value &&
                                          styles.textWhite) ||
                                          styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {mc.label}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }
                            )}
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Have you given NEET exam to study MBBS in abroad?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                                value:
                                  this.state.pageData?.studyAbroadDetails
                                    ?.gaveNeet,
                              }}
                              onChange={(v) => {
                                this.setStudyAbroadData("gaveNeet", v.value);
                              }}
                            />
                          </View>
                          {this.state.pageData.studyAbroadDetails.gaveNeet && (
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.alignItemsCenter,
                                styles.justifyStart,
                                { marginTop: 50 },
                              ]}
                            >
                              <Input
                                data={{
                                  ...this.state.pageConfig.studyAbroadDetails
                                    .mbbs.neetScore,
                                  color: "#0C5397",
                                  value:
                                    this.state.pageData?.studyAbroadDetails
                                      ?.neetScore?.value,
                                }}
                                onChange={(v) => {
                                  this.setStudyAbroadData("neetScore", v);
                                }}
                              />
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.font18,
                                  { marginLeft: 16 },
                                ]}
                              >
                                Out of 720
                              </Text>
                            </View>
                          )}
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Have valid passport?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                                value:
                                  this.state.pageData?.studyAbroadDetails
                                    ?.validPassport,
                              }}
                              onChange={(v) => {
                                this.setStudyAbroadData(
                                  "validPassport",
                                  v.value
                                );
                              }}
                            />
                          </View>
                          {this.state.pageData.studyAbroadDetails
                            .validPassport && (
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.justifyStart,
                                styles.wrap,
                                { marginTop: 32 },
                              ]}
                            >
                              {this.state.pageConfig.studyAbroadDetails.mbbs.passportFields.map(
                                (mp, mpi) => {
                                  return (
                                    <View
                                      key={"mp" + mpi}
                                      style={{ width: "40%", marginRight: 44 }}
                                    >
                                      <View
                                        style={[
                                          styles.padding8,
                                          styles.row,
                                          styles.justifyStart,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.textPrimary,
                                            styles.fontBold,
                                            styles.font18,
                                          ]}
                                        >
                                          {mp.label}
                                        </Text>
                                      </View>
                                      <View
                                        style={[
                                          styles.padding8,
                                          styles.row,
                                          styles.justifyStart,
                                        ]}
                                      >
                                        <Input
                                          data={{
                                            ...mp,
                                            color: "#0C5397",
                                            value:
                                              this.state.pageData
                                                .studyAbroadDetails?.[mp.key],
                                          }}
                                          onChange={(v) => {
                                            this.setStudyAbroadData(
                                              mp.key,
                                              v.value
                                            );
                                          }}
                                        />
                                      </View>
                                    </View>
                                  );
                                }
                              )}
                            </View>
                          )}
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Have you shortlisted universties from your end?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                              }}
                              onChange={(v) => {
                                this.setStudyAbroadData(
                                  "shortListedUniversities",
                                  v.value
                                );
                              }}
                            />
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Prioritise your below objective using drag and
                              drop
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <DragList
                              data={{
                                options:
                                  this.state.pageConfig.studyAbroadDetails.mbbs
                                    .objectives.options,
                                value:
                                  this.state.pageData?.studyAbroadDetails
                                    ?.objectives,
                              }}
                              onChange={(v) => {
                                this.setStudyAbroadData("objectives", v.value);
                              }}
                            />
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Your Budget(Annually)?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {this.state.pageConfig.studyAbroadDetails.mbbs.budget.map(
                              (mc, mci) => {
                                return (
                                  <TouchableOpacity
                                    key={"mcs" + (mci + 1)}
                                    style={[
                                      (this.state.pageData.studyAbroadDetails
                                        .budget == mc.value &&
                                        styles.bgPrimary) ||
                                        styles.bgWhite,
                                      styles.selectCard,
                                      styles.justifyCenter,
                                      { marginRight: 20, marginTop: 11 },
                                    ]}
                                    onPress={() => {
                                      this.setStudyAbroadData(
                                        "budget",
                                        mc.value
                                      );
                                    }}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        (this.state.pageData.studyAbroadDetails
                                          .budget == mc.value &&
                                          styles.textWhite) ||
                                          styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {mc.label}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }
                            )}
                          </View>
                        </View>
                      )}
                    {this.state.pageData.studyAbroadDetails.preference ==
                      "MBBS Abroad" &&
                      this.state.pageData.studyAbroadDetails.closed && (
                        <View
                          style={{
                            marginTop: 23,
                            borderWidth: 1,
                            borderColor: "#5479CB",
                            boxShadow: "0px 15px 36px #00000029",
                            marginVertical: 18,
                          }}
                        >
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.alignItemsCenter,
                              {
                                backgroundColor: "#A9CDF0",
                                borderWidth: 1,
                                borderColor: "#5479CB",
                                padding: 16,
                              },
                            ]}
                          >
                            <Image
                              source={{
                                uri: require("../assets/mbbs/flight.svg"),
                              }}
                              style={{
                                width: 60,
                                height: 60,
                                resizeMode: "center",
                              }}
                            />
                            <View style={[{ marginLeft: 36 }]}>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.font25,
                                  styles.fontBold,
                                ]}
                              >
                                MBBS Abroad
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.alignItemsCenter,
                              { padding: 16, marginTop: 23 },
                            ]}
                          >
                            <View style={[{ width: "50%" }]}>
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStart,
                                  styles.alignItemsCenter,
                                ]}
                              >
                                <Image
                                  source={{
                                    uri: require("../assets/mbbs/globe.svg"),
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "center",
                                  }}
                                />
                                <View style={[{ marginLeft: 9 }]}>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textPrimary,
                                      styles.font22,
                                    ]}
                                  >
                                    Country
                                  </Text>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textGrey1,
                                      styles.font20,
                                    ]}
                                  >
                                    {
                                      this.state.pageData?.studyAbroadDetails
                                        ?.country
                                    }
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View style={[{ width: "50%" }]}>
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStart,
                                  styles.alignItemsCenter,
                                ]}
                              >
                                <Image
                                  source={{
                                    uri: require("../assets/mbbs/intake.svg"),
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "center",
                                  }}
                                />
                                <View style={[{ marginLeft: 9 }]}>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textPrimary,
                                      styles.font22,
                                    ]}
                                  >
                                    Year | Intake
                                  </Text>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textGrey1,
                                      styles.font20,
                                    ]}
                                  >
                                    {
                                      this.state.pageData?.studyAbroadDetails
                                        ?.intake
                                    }
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.alignItemsCenter,
                              { padding: 16, marginTop: 23 },
                            ]}
                          >
                            <View style={[{ width: "50%" }]}>
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStart,
                                  styles.alignItemsCenter,
                                ]}
                              >
                                <Image
                                  source={{
                                    uri: require("../assets/mbbs/book.svg"),
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "center",
                                  }}
                                />
                                <View style={[{ marginLeft: 9 }]}>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textPrimary,
                                      styles.font22,
                                    ]}
                                  >
                                    NEET
                                  </Text>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textGrey1,
                                      styles.font20,
                                    ]}
                                  >
                                    {this.state.pageData?.studyAbroadDetails
                                      ?.gaveNeet
                                      ? "Yes"
                                      : "No"}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View style={[{ width: "50%" }]}>
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStart,
                                  styles.alignItemsCenter,
                                ]}
                              >
                                <Image
                                  source={{
                                    uri: require("../assets/mbbs/passport.svg"),
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "center",
                                  }}
                                />
                                <View style={[{ marginLeft: 9 }]}>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textPrimary,
                                      styles.font22,
                                    ]}
                                  >
                                    Passport
                                  </Text>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textGrey1,
                                      styles.font20,
                                    ]}
                                  >
                                    {this.state.pageData?.studyAbroadDetails
                                      ?.validPassport
                                      ? "Yes"
                                      : "No"}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.alignItemsCenter,
                              { padding: 16, marginTop: 23 },
                            ]}
                          >
                            <View style={[{ width: "50%" }]}>
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStart,
                                  styles.alignItemsCenter,
                                ]}
                              >
                                <Image
                                  source={{
                                    uri: require("../assets/mbbs/currency.svg"),
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "center",
                                  }}
                                />
                                <View style={[{ marginLeft: 9 }]}>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textPrimary,
                                      styles.font22,
                                    ]}
                                  >
                                    Budget(annually)
                                  </Text>
                                  <Text
                                    style={[
                                      styles.text,
                                      styles.textGrey1,
                                      styles.font20,
                                    ]}
                                  >
                                    {
                                      this.state.pageData?.studyAbroadDetails
                                        ?.budget
                                    }
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <TouchableOpacity
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyEnd,
                              { padding: 16, marginVertical: 20 },
                            ]}
                            onPress={() => {
                              let stateObj = this.state;
                              stateObj.pageData.studyAbroadDetails.closed = false;
                              this.setState(stateObj);
                            }}
                          >
                            <Image
                              source={{
                                uri: require("../assets/mbbs/edit.svg"),
                              }}
                              style={{
                                width: 52,
                                height: 22,
                                resizeMode: "center",
                              }}
                            />
                          </TouchableOpacity>
                        </View>
                      )}
                    {/* Other Courses */}
                    {this.state.pageData.studyAbroadDetails.preference ==
                      "Other Courses" &&
                      this.state.pageData?.studyAbroadDetails && (
                        <View style={{ marginTop: 23 }}>
                          <OtherCoursesProfile
                            onDataChange={(cd) => {
                              this.setStudyAbroadData(cd.key, cd.value);
                            }}
                            data={this.state.pageData?.studyAbroadDetails}
                          />
                        </View>
                      )}
                    {(!this.state.pageData.studyAbroadDetails.closed ||
                      this.state.pageData.studyAbroadDetails.preference !=
                        "MBBS Abroad") && (
                      <View
                        style={[
                          styles.row,
                          styles.justifyEnd,
                          styles.textPrimary,
                          { fontSize: 22 },
                        ]}
                      >
                        <TouchableOpacity
                          style={[styles.btnPrimary]}
                          onPress={this.saveStudyAbroadDetails}
                        >
                          <Text
                            style={[
                              { color: "#ffffff", textTransform: "uppercase" },
                            ]}
                          >
                            Save Details
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                )}
                {/* Tests */}
                {this.state.selectedTab == 3 && (
                  <View>
                    <View style={[styles.cardBlue, { marginTop: 11 }]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStretch,
                          { marginTop: 11 },
                        ]}
                      >
                        <View>
                          <Text
                            style={[
                              styles.textRed,
                              styles.fontBold,
                              { fontSize: 22 },
                            ]}
                          >
                            English Test
                          </Text>
                        </View>
                        <View>
                          <TouchableOpacity
                            onPress={() => {
                              this.setState({
                                englishTest: !this.state.englishTest,
                              });
                            }}
                          >
                            <Text
                              style={[
                                styles.textRed,
                                styles.fontBold,
                                { fontSize: 22 },
                              ]}
                            >
                              {this.state.englishTest ? "-" : "+"}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      {this.state.englishTest && (
                        <View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {(
                              this.state.pageConfig?.test?.englishTests || []
                            ).map((mc, mci) => {
                              return (
                                <TouchableOpacity
                                  key={"eTests" + (mci + 1)}
                                  style={[
                                    (this.state.pageData.test.englishTest
                                      .englishTest == mc.value &&
                                      styles.bgPrimary) ||
                                      styles.bgWhite,
                                    styles.selectCard,
                                    styles.justifyCenter,
                                    { marginRight: 20, marginTop: 28 },
                                  ]}
                                  onPress={() => {
                                    this.setEnglistTestConfig(mc.value);
                                  }}
                                >
                                  <Text
                                    style={[
                                      styles.text,
                                      (this.state.pageData.test.englishTest
                                        .englishTest == mc.value &&
                                        styles.textWhite) ||
                                        styles.textPrimary,
                                      styles.font22,
                                    ]}
                                  >
                                    {mc.label}
                                  </Text>
                                </TouchableOpacity>
                              );
                            })}
                          </View>
                          {this.state.pageData.test.englishTest.englishTest &&
                            this.state.pageData.test.englishTest.englishTest !=
                              "Didn’t have planned yet" && (
                              <View
                                style={[
                                  styles.padding8,
                                  styles.row,
                                  styles.justifyStart,
                                  styles.wrap,
                                ]}
                              >
                                <Input
                                  data={{
                                    ...this.state.pageConfig.test.testTaken,
                                    color: "#0C5397",
                                  }}
                                  value={
                                    this.state.pageData?.test?.englishTest
                                      ?.testTaken
                                  }
                                  onChange={(v) => {
                                    this.setTestData(
                                      "englishTest",
                                      "testTaken",
                                      v
                                    );
                                  }}
                                />
                              </View>
                            )}
                          {this.state.pageData.test.englishTest.testTaken ==
                            "Taken" &&
                            this.state.pageData.test.englishTest.englishTest !=
                              "Didn’t have planned yet" && (
                              <View>
                                {(
                                  this.state.pageConfig?.englishTest
                                    ?.sections || []
                                ).map((et, eti) => {
                                  return (
                                    <View
                                      key={"ets" + (eti + 1)}
                                      style={[styles.row]}
                                    >
                                      <View
                                        style={[
                                          styles.padding8,
                                          { width: "100%" },
                                        ]}
                                      >
                                        {et.label && (
                                          <View
                                            style={[
                                              styles.row,
                                              styles.justifyStart,
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                styles.textPrimary,
                                                styles.font18,
                                                styles.fontBold,
                                              ]}
                                            >
                                              {et.label}
                                            </Text>
                                          </View>
                                        )}
                                        <View
                                          style={[
                                            styles.row,
                                            styles.justifyStart,
                                            styles.wrap,
                                          ]}
                                        >
                                          {(et?.fields || []).map(
                                            (etf, etfi) => {
                                              return (
                                                <View
                                                  key={"etsf" + (etfi + 1)}
                                                  style={[
                                                    {
                                                      margin: 16,
                                                      width: et.width || "30%",
                                                    },
                                                  ]}
                                                >
                                                  <View
                                                    style={[
                                                      styles.row,
                                                      styles.justifyStart,
                                                    ]}
                                                  >
                                                    <Text
                                                      style={[
                                                        styles.textPrimary,
                                                        styles.font18,
                                                      ]}
                                                    >
                                                      {etf.label}
                                                    </Text>
                                                  </View>
                                                  <View
                                                    style={[
                                                      styles.row,
                                                      styles.justifyStart,
                                                    ]}
                                                  >
                                                    <Input
                                                      data={{
                                                        ...etf,
                                                        color: "#0C5397",
                                                      }}
                                                      value={
                                                        this.state.pageData
                                                          ?.test?.englishTest[
                                                          etf.key
                                                        ]
                                                      }
                                                      onChange={(v) => {
                                                        this.setTestData(
                                                          "englishTest",
                                                          etf.key,
                                                          v
                                                        );
                                                      }}
                                                    />
                                                  </View>
                                                </View>
                                              );
                                            }
                                          )}
                                        </View>
                                      </View>
                                    </View>
                                  );
                                })}
                              </View>
                            )}
                        </View>
                      )}
                    </View>
                    <View style={[styles.cardBlue, { marginTop: 11 }]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStretch,
                          { marginTop: 11 },
                        ]}
                      >
                        <View>
                          <Text
                            style={[
                              styles.textRed,
                              styles.fontBold,
                              { fontSize: 22 },
                            ]}
                          >
                            Other Tests
                          </Text>
                        </View>
                        <View>
                          <TouchableOpacity
                            onPress={() => {
                              this.setState({
                                otherTest: !this.state.otherTest,
                              });
                            }}
                          >
                            <Text
                              style={[
                                styles.textRed,
                                styles.fontBold,
                                { fontSize: 22 },
                              ]}
                            >
                              {this.state.otherTest ? "-" : "+"}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      {this.state.otherTest && (
                        <View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {(
                              this.state.pageConfig?.test?.otherTests || []
                            ).map((mc, mci) => {
                              return (
                                <TouchableOpacity
                                  key={"oTests" + (mci + 1)}
                                  style={[
                                    (this.state.pageData.test.otherTest
                                      .otherTest == mc.value &&
                                      styles.bgPrimary) ||
                                      styles.bgWhite,
                                    styles.selectCard,
                                    styles.justifyCenter,
                                    { marginRight: 20, marginTop: 28 },
                                  ]}
                                  onPress={() => {
                                    this.setOtherTestConfig(mc.value);
                                  }}
                                >
                                  <Text
                                    style={[
                                      styles.text,
                                      (this.state.pageData.test.otherTest
                                        .otherTest == mc.value &&
                                        styles.textWhite) ||
                                        styles.textPrimary,
                                      styles.font22,
                                    ]}
                                  >
                                    {mc.label}
                                  </Text>
                                </TouchableOpacity>
                              );
                            })}
                          </View>
                          {this.state.pageData.test.otherTest.otherTest &&
                            this.state.pageData.test.otherTest.otherTest !=
                              "Didn’t have planned yet" && (
                              <View
                                style={[
                                  styles.padding8,
                                  styles.row,
                                  styles.justifyStart,
                                  styles.wrap,
                                ]}
                              >
                                <Input
                                  data={{
                                    ...this.state.pageConfig.test.testTaken,
                                    color: "#0C5397",
                                  }}
                                  value={
                                    this.state.pageData?.test?.otherTest
                                      ?.testTaken
                                  }
                                  onChange={(v) => {
                                    this.setTestData(
                                      "otherTest",
                                      "testTaken",
                                      v
                                    );
                                  }}
                                />
                              </View>
                            )}
                          {this.state.pageData.test.otherTest.testTaken ==
                            "Taken" &&
                            this.state.pageData.test.otherTest.otherTest !=
                              "Didn’t have planned yet" && (
                              <View>
                                {(
                                  this.state.pageConfig?.otherTest?.sections ||
                                  []
                                ).map((et, eti) => {
                                  return (
                                    <View
                                      key={"ots" + (eti + 1)}
                                      style={[styles.row]}
                                    >
                                      <View
                                        style={[
                                          styles.padding8,
                                          { width: "100%" },
                                        ]}
                                      >
                                        {et.label && (
                                          <View
                                            style={[
                                              styles.row,
                                              styles.justifyStart,
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                styles.textPrimary,
                                                styles.font18,
                                                styles.fontBold,
                                              ]}
                                            >
                                              {et.label}
                                            </Text>
                                          </View>
                                        )}
                                        <View
                                          style={[
                                            styles.row,
                                            styles.justifyStart,
                                            styles.wrap,
                                          ]}
                                        >
                                          {(et?.fields || []).map(
                                            (etf, etfi) => {
                                              return (
                                                <View
                                                  key={"otsf" + (etfi + 1)}
                                                  style={[
                                                    {
                                                      margin: 16,
                                                      width: et.width || "30%",
                                                    },
                                                  ]}
                                                >
                                                  <View
                                                    style={[
                                                      styles.row,
                                                      styles.justifyStart,
                                                    ]}
                                                  >
                                                    <Text
                                                      style={[
                                                        styles.textPrimary,
                                                        styles.font18,
                                                      ]}
                                                    >
                                                      {etf.label}
                                                    </Text>
                                                  </View>
                                                  <View
                                                    style={[
                                                      styles.row,
                                                      styles.justifyStart,
                                                    ]}
                                                  >
                                                    <Input
                                                      data={{
                                                        ...etf,
                                                        color: "#0C5397",
                                                      }}
                                                      value={
                                                        this.state.pageData
                                                          ?.test?.otherTest[
                                                          etf.key
                                                        ]
                                                      }
                                                      onChange={(v) => {
                                                        this.setTestData(
                                                          "otherTest",
                                                          etf.key,
                                                          v
                                                        );
                                                      }}
                                                    />
                                                  </View>
                                                </View>
                                              );
                                            }
                                          )}
                                        </View>
                                      </View>
                                    </View>
                                  );
                                })}
                              </View>
                            )}
                        </View>
                      )}
                    </View>
                    {/* Save */}
                    <View
                      style={[
                        styles.row,
                        styles.justifyCenter,
                        styles.textPrimary,
                        { fontSize: 22 },
                      ]}
                    >
                      <TouchableOpacity
                        style={[styles.btnPrimary]}
                        onPress={this.saveTestDetails}
                      >
                        <Text
                          style={[
                            { color: "#ffffff", textTransform: "uppercase" },
                          ]}
                        >
                          Save Details
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                {/* Travel and Documents */}
                {this.state.selectedTab == 4 && (
                  <View>
                    {/* Travel Details */}
                    <View style={[styles.cardBlue, { marginTop: 11 }]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStretch,
                          { marginTop: 11 },
                        ]}
                      >
                        <View>
                          <Text
                            style={[
                              styles.textRed,
                              styles.fontBold,
                              { fontSize: 22 },
                            ]}
                          >
                            Travel Details
                          </Text>
                        </View>
                        <View>
                          <TouchableOpacity
                            onPress={() => {
                              this.setState({
                                travelDetails: !this.state.travelDetails,
                              });
                            }}
                          >
                            <Text
                              style={[
                                styles.textRed,
                                styles.fontBold,
                                { fontSize: 22 },
                              ]}
                            >
                              {this.state.travelDetails ? "-" : "+"}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      {this.state.travelDetails && (
                        <View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Do you have valid passport?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                              }}
                              value={
                                this.state.pageData?.travelDetails
                                  ?.validPassport
                              }
                              onChange={(v) => {
                                this.setTravelData("validPassport", v.value);
                              }}
                            />
                          </View>
                          {this.state.pageData.travelDetails.validPassport && (
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.justifyStart,
                                styles.wrap,
                                { marginTop: 32 },
                              ]}
                            >
                              {this.state.pageConfig.studyAbroadDetails.mbbs.passportFields.map(
                                (mp, mpi) => {
                                  let inpData = JSON.parse(JSON.stringify(mp));
                                  inpData.value =
                                    this.state.pageData?.travelDetails[mp.key];
                                  return (
                                    <View
                                      key={"mp" + mpi}
                                      style={{ width: "40%", marginRight: 44 }}
                                    >
                                      <View
                                        style={[
                                          styles.padding8,
                                          styles.row,
                                          styles.justifyStart,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.textPrimary,
                                            styles.fontBold,
                                            styles.font18,
                                          ]}
                                        >
                                          {mp.label}
                                        </Text>
                                      </View>
                                      <View
                                        style={[
                                          styles.padding8,
                                          styles.row,
                                          styles.justifyStart,
                                        ]}
                                      >
                                        <Input
                                          data={{
                                            ...inpData,
                                            color: "#0C5397",
                                          }}
                                          value={
                                            this.state.pageData?.travelDetails[
                                              mp.key
                                            ]
                                          }
                                          onChange={(v) => {
                                            this.setTravelData(mp.key, v.value);
                                          }}
                                        />
                                      </View>
                                    </View>
                                  );
                                }
                              )}
                            </View>
                          )}
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Any parent/local guardian staying in the country
                              where you are planning to study?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                              }}
                              value={
                                this.state.pageData?.travelDetails
                                  ?.localGuardian
                              }
                              onChange={(v) => {
                                this.setTravelData("localGuardian", v.value);
                              }}
                            />
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textRed,
                                styles.font22,
                              ]}
                            >
                              Some Background Check:
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 21 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Any criminal charges/court case on you which will
                              reduce your VISA issuance rate?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                              }}
                              value={
                                this.state.pageData?.travelDetails
                                  ?.criminalCharges
                              }
                              onChange={(v) => {
                                this.setTravelData("criminalCharges", v.value);
                              }}
                            />
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 21 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Have you ever been refused student VISA?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                              }}
                              value={
                                this.state.pageData?.travelDetails?.visaRefused
                              }
                              onChange={(v) => {
                                this.setTravelData("visaRefused", v.value);
                              }}
                            />
                          </View>
                          {this.state.pageData.travelDetails.visaRefused && (
                            <View
                              style={[
                                styles.padding8,
                                styles.row,
                                styles.justifyStart,
                                styles.wrap,
                                { marginTop: 32 },
                              ]}
                            >
                              {this.state.pageConfig.test.visaRefused.map(
                                (mp, mpi) => {
                                  let inpData = JSON.parse(JSON.stringify(mp));
                                  inpData.value =
                                    this.state.pageData?.travelDetails[mp.key];
                                  return (
                                    <View
                                      key={"mp" + mpi}
                                      style={{ width: "40%", marginRight: 44 }}
                                    >
                                      <View
                                        style={[
                                          styles.padding8,
                                          styles.row,
                                          styles.justifyStart,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.textPrimary,
                                            styles.fontBold,
                                            styles.font18,
                                          ]}
                                        >
                                          {mp.label}
                                        </Text>
                                      </View>
                                      <View
                                        style={[
                                          styles.padding8,
                                          styles.row,
                                          styles.justifyStart,
                                        ]}
                                      >
                                        <Input
                                          data={{ ...mp, color: "#0C5397" }}
                                          value={
                                            this.state.pageData?.travelDetails[
                                              mp.key
                                            ]
                                          }
                                          onChange={(v) => {
                                            this.setTravelData(mp.key, v.value);
                                          }}
                                        />
                                      </View>
                                    </View>
                                  );
                                }
                              )}
                            </View>
                          )}
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 21 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Any health issue/critical illness that you are
                              suffering from?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                            ]}
                          >
                            <Input
                              data={{
                                ...this.state.pageConfig.studyAbroadDetails.mbbs
                                  .toggle,
                                color: "#0C5397",
                              }}
                              value={
                                this.state.pageData?.travelDetails
                                  ?.criticalIllness
                              }
                              onChange={(v) => {
                                this.setTravelData("criticalIllness", v.value);
                              }}
                            />
                          </View>
                          {this.state.pageData?.travelDetails
                            ?.criticalIllness && (
                            <View style={{ marginTop: 15 }}>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.font18,
                                  styles.fontBold,
                                  styles.padding8,
                                ]}
                              >
                                Which Health issues?
                              </Text>
                              <Input
                                data={{
                                  type: "inputBlack",
                                  color: "#0C5397",
                                }}
                                onChange={(v) => {
                                  this.setTravelData("heathIssues", v.value);
                                }}
                              />
                            </View>
                          )}
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 55 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textRed,
                                styles.font22,
                              ]}
                            >
                              Family Income:
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 21 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.text,
                                styles.textPrimary,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              Approximate Family Income(annual)?
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {this.state.pageConfig.studyAbroadDetails.mbbs.familyIncome.map(
                              (mc, mci) => {
                                return (
                                  <TouchableOpacity
                                    key={"mcs" + (mci + 1)}
                                    style={[
                                      (this.state.pageData.studyAbroadDetails
                                        .familyIncome == mc.value &&
                                        styles.bgPrimary) ||
                                        styles.bgWhite,
                                      styles.selectCard,
                                      styles.justifyCenter,
                                      { marginRight: 20, marginTop: 11 },
                                    ]}
                                    onPress={() => {
                                      this.setStudyAbroadData(
                                        "familyIncome",
                                        mc.value
                                      );
                                    }}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        (this.state.pageData.studyAbroadDetails
                                          .familyIncome == mc.value &&
                                          styles.textWhite) ||
                                          styles.textPrimary,
                                        styles.font22,
                                      ]}
                                    >
                                      {mc.label}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }
                            )}
                          </View>
                          <View
                            style={[
                              styles.row,
                              styles.justifyCenter,
                              { marginTop: 55 },
                            ]}
                          >
                            <TouchableOpacity
                              style={[styles.btnPrimary]}
                              onPress={() => {
                                this.saveTravelDetails();
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  },
                                ]}
                              >
                                Save
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    </View>
                    {/* Documents */}
                    <View style={[styles.cardBlue, { marginTop: 11 }]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStretch,
                          { marginTop: 11, marginBottom: 38 },
                        ]}
                      >
                        <View>
                          <Text
                            style={[
                              styles.textRed,
                              styles.fontBold,
                              { fontSize: 22 },
                            ]}
                          >
                            Documents
                          </Text>
                        </View>
                        <View>
                          <TouchableOpacity
                            onPress={() => {
                              this.setState({
                                documents: !this.state.documents,
                              });
                            }}
                          >
                            <Text
                              style={[
                                styles.textRed,
                                styles.fontBold,
                                { fontSize: 22 },
                              ]}
                            >
                              {this.state.documents ? "-" : "+"}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      {this.state.documents && (
                        <View>
                          <View
                            style={[
                              styles.row,
                              styles.justifyStretch,
                              styles.bgWhite,
                              styles.brBlue,
                              styles.padding8,
                            ]}
                          >
                            {(
                              this.state.pageConfig?.documents?.sections || []
                            ).map((d, di) => {
                              if (di == this.state.selectedDocTab) {
                                return (
                                  <View
                                    style={[
                                      styles.flex,
                                      styles.flexGrow,
                                      styles.brPrimary10,
                                      styles.bgDarkBlue,
                                      styles.justifyCenter,
                                      styles.alignCenter,
                                      styles.alignItemsCenter,
                                      { height: 60 },
                                    ]}
                                    key={"doc" + (di + 1)}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.textWhite,
                                        styles.font18,
                                        styles.fontBold,
                                      ]}
                                    >
                                      {d.name}
                                    </Text>
                                  </View>
                                );
                              } else {
                                return (
                                  <View
                                    style={[
                                      styles.flex,
                                      styles.flexGrow,
                                      styles.justifyCenter,
                                      styles.alignCenter,
                                      styles.alignItemsCenter,
                                      { height: 60 },
                                    ]}
                                    key={"doc" + (di + 1)}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        this.setState({ selectedDocTab: di });
                                      }}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.font18,
                                          { color: "#434040" },
                                        ]}
                                      >
                                        {d.name}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                );
                              }
                            })}
                          </View>
                          {(
                            this.state.pageConfig?.documents?.sections[
                              this.state.selectedDocTab
                            ].fields || []
                          ).map((df, dfi) => {
                            let key =
                              this.state.pageConfig?.documents?.sections[
                                this.state.selectedDocTab
                              ].key;
                            return (
                              <View
                                style={[
                                  styles.row,
                                  styles.justifyStretch,
                                  styles.bgWhite,
                                  styles.brBlue,
                                  styles.boxShadow1,
                                  { marginTop: 21 },
                                ]}
                                key={"docf" + (dfi + 1)}
                              >
                                <View style={{ width: "100%", padding: 0 }}>
                                  <View
                                    style={[
                                      styles.row,
                                      styles.justifyStretch,
                                      (this.state.pageData.documents[key] || [])
                                        .length && styles.brBotttomBlue,
                                      { padding: 0 },
                                    ]}
                                  >
                                    <View
                                      style={[
                                        {
                                          width: "20%",
                                          paddingVertical: 26,
                                          justifyContent: "center",
                                        },
                                        styles.alignItemsCenter,
                                        styles.textAlignCenter,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.font18,
                                          styles.textPrimary,
                                          styles.fontBold,
                                        ]}
                                      >
                                        {df.label}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        {
                                          width: "40%",
                                          paddingVertical: 26,
                                          justifyContent: "center",
                                          borderRightWidth: 0.5,
                                          borderLeftWidth: 0.5,
                                          borderColor: "#5479CB",
                                        },
                                        styles.flexGrow,
                                        styles.flex,
                                        styles.alignItemsCenter,
                                        styles.textAlignCenter,
                                        styles.brBlue0,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.font18,
                                          styles.textPrimary,
                                        ]}
                                      >
                                        {df.description}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        { width: "20%", paddingVertical: 26 },
                                        styles.alignItemsCenter,
                                        styles.justifyCenter,
                                      ]}
                                    >
                                      <TouchableOpacity
                                        onPress={() => {
                                          this.uploadDocument(df);
                                        }}
                                      >
                                        <Text
                                          style={[
                                            styles.text,
                                            styles.font18,
                                            styles.textAlignCenter,
                                            styles.textPrimary,
                                          ]}
                                        >
                                          Add file Max size 2MB
                                        </Text>
                                        <Image
                                          source={{
                                            uri: require("../assets/dashboard/profile/file_upload.svg"),
                                          }}
                                          style={{
                                            width: 85,
                                            height: 58,
                                            resizeMode: "center",
                                            alignSelf: "center",
                                          }}
                                        />
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                  {(
                                    this.state.pageData.documents[key] || []
                                  ).map((uf, ufi) => {
                                    return (
                                      <View
                                        key={"uploadedDoc" + (ufi + 1)}
                                        style={[
                                          styles.row,
                                          styles.justifyStretch,
                                          { backgroundColor: "#F8F9FD" },
                                        ]}
                                      >
                                        <View
                                          style={[
                                            styles.alignItemsCenter,
                                            styles.textAlignCenter,
                                            {
                                              width: "20%",
                                              paddingVertical: 26,
                                            },
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              styles.text,
                                              styles.font22,
                                              styles.textPrimary,
                                            ]}
                                          >
                                            {ufi + 1} File Uploaded
                                          </Text>
                                        </View>
                                        <View
                                          style={[
                                            {
                                              width: "40%",
                                              paddingVertical: 26,
                                            },
                                            styles.flexGrow,
                                            styles.flex,
                                            styles.alignItemsCenter,
                                            styles.textAlignCenter,
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              styles.text,
                                              styles.font18,
                                              styles.textRed,
                                            ]}
                                          >
                                            {uf?.name}
                                          </Text>
                                        </View>
                                        <View
                                          style={[
                                            {
                                              width: "20%",
                                              paddingVertical: 26,
                                            },
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              styles.text,
                                              styles.font18,
                                              styles.textPrimary,
                                            ]}
                                          ></Text>
                                        </View>
                                      </View>
                                    );
                                  })}
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      )}
                    </View>
                    {/* Parents Info */}
                    <View style={[styles.cardBlue, { marginTop: 11 }]}>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStretch,
                        ]}
                      >
                        <View>
                          <Text
                            style={[
                              styles.textRed,
                              styles.fontBold,
                              { fontSize: 22 },
                            ]}
                          >
                            Parents Info
                          </Text>
                        </View>
                        <View>
                          <TouchableOpacity
                            onPress={() => {
                              this.setState({
                                openParentsInfo: !this.state.openParentsInfo,
                              });
                            }}
                          >
                            <Text
                              style={[
                                styles.textRed,
                                styles.fontBold,
                                { fontSize: 22 },
                              ]}
                            >
                              {this.state.openParentsInfo ? "-" : "+"}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      {this.state.openParentsInfo && (
                        <View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 18 },
                            ]}
                          >
                            <View>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.fontBold,
                                  styles.font22,
                                ]}
                              >
                                Mother’s Information
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {(
                              this.state.pageConfig?.motherInfo?.fields || []
                            ).map((edf, edfi) => {
                              return (
                                <View
                                  key={"medfi" + (edfi + 1)}
                                  style={[{ margin: 16, width: "30%" }]}
                                >
                                  {edf.type != "checkbox" && (
                                    <View
                                      style={[
                                        styles.padding8,
                                        styles.row,
                                        styles.justifyStart,
                                        styles.textPrimary,
                                        styles.font18,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.textPrimary,
                                          styles.font18,
                                        ]}
                                      >
                                        {edf?.label}
                                      </Text>
                                    </View>
                                  )}
                                  <View style={[styles.padding8, styles.row]}>
                                    <Input
                                      data={{ ...edf, color: "#0C5397" }}
                                      value={
                                        this.state.pageData?.parentDetails
                                          ?.motherDetails[edf?.key]
                                      }
                                      onChange={(value) => {
                                        this.setparentInfoData(
                                          "motherDetails",
                                          edf?.key,
                                          value
                                        );
                                      }}
                                    />
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              { marginTop: 18 },
                            ]}
                          >
                            <View>
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.fontBold,
                                  styles.font22,
                                ]}
                              >
                                Father’s Information
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.padding8,
                              styles.row,
                              styles.justifyStart,
                              styles.wrap,
                            ]}
                          >
                            {(
                              this.state.pageConfig?.fatherInfo?.fields || []
                            ).map((edf, edfi) => {
                              return (
                                <View
                                  key={"fedfi" + (edfi + 1)}
                                  style={[{ margin: 16, width: "30%" }]}
                                >
                                  {edf.type != "checkbox" && (
                                    <View
                                      style={[
                                        styles.padding8,
                                        styles.row,
                                        styles.justifyStart,
                                        styles.textPrimary,
                                        styles.font18,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.textPrimary,
                                          styles.font18,
                                        ]}
                                      >
                                        {edf.label}
                                      </Text>
                                    </View>
                                  )}
                                  <View style={[styles.padding8, styles.row]}>
                                    <Input
                                      data={{ ...edf, color: "#0C5397" }}
                                      value={
                                        this.state.pageData?.parentDetails
                                          ?.fatherDetails[edf.key]
                                      }
                                      onChange={(value) => {
                                        this.setparentInfoData(
                                          "fatherDetails",
                                          edf.key,
                                          value
                                        );
                                      }}
                                    />
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                          <View
                            style={[
                              styles.row,
                              styles.justifyCenter,
                              { marginTop: 55 },
                            ]}
                          >
                            <TouchableOpacity
                              style={[styles.btnPrimary]}
                              onPress={() => {
                                this.saveParentDetails();
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  },
                                ]}
                              >
                                Save
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return { appData: state?.appData, userData: state?.userData?.userDetails };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateData: async (parameter, callback) => {
      await dispatch(parameter);
      if (callback) {
        callback();
      }
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
