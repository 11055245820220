import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import React from "react";
import {
	CheckBox,
	Image,
	Modal,
	Switch,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { RadioButton } from "react-native-paper";
import { Slider } from "react-native-range-slider-expo";
import { styles } from "../styles/styles";

class Input extends React.Component {
	constructor(props) {
		super();
		this.state = {
			isChecked: false,
			isError: false,
			months: [
				{
					label: "Month",
					value: "",
				},
				{
					label: "01",
					value: "01",
				},
				{
					label: "02",
					value: "02",
				},
				{
					label: "03",
					value: "03",
				},
				{
					label: "04",
					value: "04",
				},
				{
					label: "05",
					value: "05",
				},
				{
					label: "06",
					value: "06",
				},
				{
					label: "07",
					value: "07",
				},
				{
					label: "08",
					value: "08",
				},
				{
					label: "09",
					value: "09",
				},
				{
					label: "10",
					value: "10",
				},
				{
					label: "11",
					value: "11",
				},
				{
					label: "12",
					value: "12",
				},
			],
			years: [
				{
					label: "Year",
					value: "",
				},
				{
					label: "2022",
					value: "2022",
				},
				{
					label: "2023",
					value: "2023",
				},
			],
			monthYear: {},
			mobilePicker: {
				code: "91",
				mobile: "",
			},
			value: "",
			open: false,
		};
	}
	componentDidMount() {
		if (
			this.props.data.type == "checkbox" ||
			this.props.data.type == "toggle"
		) {
			this.setState({
				isChecked: this.props.value || this.props.data.value || false,
			});
		} else if (this.props.data.type == "monthYear" && this.props.data.value) {
			let stateObj = this.state;
			stateObj.monthYear.month = this.props.data.value.month;
			stateObj.monthYear.year = this.props.data.value.year;
			this.setState(stateObj);
		} else {
			this.setState({ value: this.props.value || this.props.data.value || "" });
		}
	}
	componentDidUpdate(prevProps) {
		if (
			this.props.data.value == prevProps.data.value &&
			this.props.value == prevProps.value
		) {
			return;
		}
		if (
			this.props.data.type == "checkbox" ||
			this.props.data.type == "toggle"
		) {
			this.setState({
				isChecked: this.props.value || this.props.data.value || false,
			});
		} else if (this.props.data.type == "monthYear" && this.props.data.value) {
			let stateObj = this.state;
			stateObj.monthYear.month = this.props.data.value.month;
			stateObj.monthYear.year = this.props.data.value.year;
			this.setState(stateObj);
		} else {
			this.setState({ value: this.props.value || this.props.data.value || "" });
		}
	}
	inputChange = text => {
		this.setState({ value: text, isError: false });
		if (this.props.data.max && text > this.props.data.max) {
			this.setState({ isError: true });
			return;
		}
		if (this.props.data.min && text < this.props.data.min) {
			this.setState({ isError: true });
			return;
		}
		if (this.props.data.pattern && !this.props.data.pattern.test(text)) {
			this.setState({ isError: true });
			return;
		}
		if (this.props.onChange) {
			this.props.onChange({ value: text });
		}
		if (this.props.data.type == "toggle") {
			this.setState({ isChecked: text });
		}
	};
	mobilePickerChange = (key, value) => {
		if (key == "mobile" && !/^[6-9]\d{9}$/.test(value)) {
			this.setState({ isError: true });
			return;
		}
		let stateObj = this.state;
		stateObj.mobilePicker[key] = value;
		stateObj.isError = false;
		this.setState(stateObj);
		if (this.props.onChange) {
			this.props.onChange({ value: stateObj.mobilePicker });
		}
	};
	monthYearChange = (key, value) => {
		let stateObj = this.state;
		stateObj.monthYear[key] = value;
		this.setState(stateObj);
		if (this.props.onChange) {
			this.props.onChange({ value: stateObj.monthYear });
		}
	};
	render() {
		if (this.props.data.type == "inputBlack") {
			return (
				<View
					style={[styles.flex, styles.flexGrow, this.props.data.styles || {}]}
				>
					<Text
						style={[
							styles.textGrey,
							{
								fontSize: 10,
								marginLeft: 24,
								position: "absolute",
								marginTop: -11,
								background: "#ffffff",
								padding: 4,
							},
						]}
					>
						{this.props.data.label}
					</Text>
					<TextInput
						style={[styles.inputText, { height: this.props.data.height || 55 }]}
						placeholder={this.props.data.placeholder || ""}
						keyboardType={this.props.data.keyboardType || "default"}
						autoComplete={this.props.data.autoComplete || ""}
						maxLength={this.props.data.maxLength || 1000}
						minLength={this.props.data.minLength || 1}
						multiline={this.props.data.multiline}
						value={this.state.value}
						secureTextEntry={this.props.data.isPassword}
						onChangeText={text => {
							this.inputChange(text);
						}}
						placeholderTextColor={"#505050"}
						editable={!this.props.data.disabled}
					/>
					{this.state.isError && (
						<Text style={[styles.text, styles.textRed, styles.font20]}>
							{this.props.data.errorMsg || "Please enter proper value"}
						</Text>
					)}
				</View>
			);
		} else if (this.props.data.type == "inputBlue") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<TextInput
						style={[styles.inputTextBlue, styles.mh]}
						placeholder={this.props.data.placeholder || ""}
						keyboardType={this.props.data.keyboardType || "default"}
						autoComplete={this.props.data.autoComplete || ""}
						maxLength={this.props.data.maxLength || 1000}
						minLength={this.props.data.minLength || 1}
						multiline={this.props.data.multiline}
						value={this.state.value}
						secureTextEntry={this.props.data.isPassword}
						onChangeText={text => {
							this.inputChange(text);
						}}
						editable={!this.props.data.disabled}
					/>
					{this.state.isError && (
						<Text style={[styles.text, styles.textRed, styles.font20]}>
							{"Please enter proper value"}
						</Text>
					)}
				</View>
			);
		} else if (this.props.data.type == "inputGrey") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<TextInput
						style={[
							styles.inputTextGrey,
							styles.mh,
							this.props.from == "brochure" || this.props.from == "home"
								? {
										backgroundColor: "#fff",
										borderWidth: 1,
										borderColor: "#0d0d0d",
										padding: 20,
								  }
								: null,
						]}
						placeholder={this.props.data.placeholder || ""}
						keyboardType={this.props.data.keyboardType || "default"}
						autoComplete={this.props.data.autoComplete || ""}
						maxLength={this.props.data.maxLength || 1000}
						minLength={this.props.data.minLength || 1}
						multiline={this.props.data.multiline}
						value={this.state.value}
						secureTextEntry={this.props.data.isPassword}
						onChangeText={text => {
							this.inputChange(text);
						}}
						editable={!this.props.data.disabled}
					/>
					{this.props.data.help && (
						<Text
							style={[
								styles.text,
								styles.font18,
								styles.textGrey2,
								styles.mh,
								{ marginTop: 11 },
							]}
						>
							{this.props.data.help}
						</Text>
					)}
					{this.state.isError && (
						<Text style={[styles.text, styles.textRed, styles.font20]}>
							{"Please enter proper value"}
						</Text>
					)}
				</View>
			);
		} else if (this.props.data.type == "textarea") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<TextInput
						style={[styles.inputTextBlue, styles.mh, { height: 200 }]}
						placeholder={this.props.data.placeholder || ""}
						keyboardType={this.props.data.keyboardType || "default"}
						maxLength={this.props.data.maxLength || 1000}
						minLength={this.props.data.minLength || 1}
						multiline={this.props.data.multiline}
						value={this.state.value}
						secureTextEntry={this.props.data.isPassword}
						onChangeText={text => {
							this.inputChange(text);
						}}
						editable={!this.props.data.disabled}
					/>
					{this.state.isError && (
						<Text style={[styles.text, styles.textRed, styles.font20]}>
							{"Please enter proper value"}
						</Text>
					)}
				</View>
			);
		} else if (this.props.data.type == "multiselect") {
			return (
				<View
					ref={view => {
						if (view && view.measure) {
							view.measure(
								(
									fx,
									fy,
									containerWidth,
									containerHeight,
									px,
									yOffsetToPage
								) => {
									let stateObj = this.state;
									if (
										stateObj.position?.top !=
										yOffsetToPage + (this.props.data.height || 50) + 6
									) {
										stateObj.position = {
											top: yOffsetToPage + (this.props.data.height || 50) + 6,
											left: px,
											width: containerWidth,
										};
										if (stateObj.position.top + 256 >= window.innerHeight) {
											stateObj.position.top = stateObj.position.top - 256;
										}
										this.setState(stateObj);
									}
								}
							);
						}
					}}
					style={[
						this.props.data.styles || {
							width: "100%",
							height: this.props.data.height || 50,
						},
					]}
				>
					<TouchableOpacity
						onPress={() => {
							this.setState({ open: !this.state.open });
						}}
						style={[
							this.props.data.styles || {
								width: "100%",
								paddingHorizontal: 14,
							},
							styles.inputTextBlue,
							styles.row,
							styles.alignItemsCenter,
						]}
					>
						{!this.props.data?.hideLabel &&
							(this.state.value || []).length &&
							(this.props.data?.label || this.props.data?.placeholder) && (
								<View
									style={[
										{
											position: "absolute",
											marginTop: -(this.props.data.height || 50),
											padding: 4,
										},
										styles.bgWhite,
									]}
								>
									<Text
										style={[styles.text, styles.textPrimary, styles.font20]}
									>
										{this.props.data?.label || this.props.data?.placeholder}
									</Text>
								</View>
							)}
						<View style={[{ width: "95%", paddingHorizontal: 12 }]}>
							<Text
								style={[
									styles.text,
									styles.textPrimary,
									styles.font18,
									styles.fontBold,
								]}
							>
								{(this.state.value && this.state.value.join(", ")) ||
									this.props.data?.placeholder}
							</Text>
						</View>
						<View style={[{ width: "5%" }, styles.row, styles.justifyEnd]}>
							<Image
								source={{ uri: require("../assets/dropdown_arrow.svg") }}
								style={{ width: 21, height: 11, resizeMode: "center" }}
							/>
						</View>
					</TouchableOpacity>
					{this.state.open && (
						<Modal animationType="none" transparent={true}>
							<View
								style={[
									{
										boxShadow: "0px 3px 6px #00000029",
										marginLeft: this.state.position?.left,
										marginTop: this.state.position?.top,
										width: this.state.position?.width,
										height: 200,
										overflow: "auto",
									},
									styles.bgWhite,
								]}
							>
								<TouchableOpacity
									onPress={() => {
										this.setState({ open: false });
									}}
									style={[
										{ paddingHorizontal: 30, paddingVertical: 6 },
										styles.row,
										styles.justifyEnd,
										styles.alignItemsCenter,
									]}
								>
									<FontAwesome name="remove" size={18} color={"red"} />
								</TouchableOpacity>
								{(this.props.data.options || []).map((item, ik) => {
									return (
										<TouchableOpacity
											onPress={() => {
												let stateObj = this.state;
												if (!(stateObj.value || []).length) {
													stateObj.value = [item.value];
												} else {
													let indO = stateObj.value.indexOf(item.value);
													if (indO == -1) {
														stateObj.value.push(item.value);
													} else {
														stateObj.value.splice(indO, 1);
													}
												}
												this.setState(stateObj);
												if (this.props.onChange) {
													this.props.onChange({ value: stateObj.value });
												}
											}}
											key={"ik" + ik}
											style={[
												{ paddingHorizontal: 30, paddingVertical: 6 },
												styles.row,
												styles.justifyCenter,
												styles.alignItemsCenter,
											]}
										>
											<Text
												style={[
													styles.text,
													styles.textGrey,
													styles.font22,
													{ zIndex: 9 },
												]}
											>
												{item.label}
											</Text>
										</TouchableOpacity>
									);
								})}
							</View>
						</Modal>
					)}
				</View>
			);
		} else if (this.props.data.type == "select") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<FontAwesome5
						name="sort-down"
						size={30}
						color={this.props?.data?.color || "#707070"}
						style={{
							position: "absolute",
							right: 10,
							top: "10%",
							zIndex: 100,
						}}
					/>
					<View
						style={{
							backgroundColor: "#ffffff",
							height: 25,
							width: 25,
							zIndex: 99,
							position: "absolute",
							right: -5,
							top: "25%",
						}}
					></View>
					<Picker
						style={[
							styles.mh,
							{
								width: "100%",
								// height: this.props.data.height || 50,
								borderColor: this.props?.data?.color || "#707070",
								borderWidth: 1,
								borderRadius: 5,
								color: this.props?.data?.color || "#505050",
								backgroundColor: "#ffffff",
								fontSize: 18,
								padding: 16,
							},
						]}
						selectedValue={this.state.value}
						onValueChange={(itemValue, itemIndex) =>
							this.inputChange(itemValue)
						}
					>
						{(this.props.data.options || []).map(o => {
							return (
								<Picker.Item key={o.value} label={o.label} value={o.value} />
							);
						})}
					</Picker>
				</View>
			);
		} else if (this.props.data.type == "selectGrey") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<Picker
						style={[styles.mh, styles.inputTextGrey, { width: "100%" }]}
						selectedValue={this.state.value}
						onValueChange={(itemValue, itemIndex) =>
							this.inputChange(itemValue)
						}
					>
						{(this.props.data.options || []).map(o => {
							return (
								<Picker.Item key={o.value} label={o.label} value={o.value} />
							);
						})}
					</Picker>
				</View>
			);
		} else if (this.props.data.type == "mobileWithCountryCode") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<View
						style={[
							styles.row,
							styles.justifyStart,
							styles.alignItemsCenter,
							styles.noWrap,
						]}
					>
						<View style={[{ width: "26%", marginRight: 20 }]}>
							<Text
								style={[
									styles.textGrey2,
									{
										fontSize: 14,
										position: "absolute",
										left: "30%",
										top: -10,
									},
								]}
							>
								ISD CODE
							</Text>
							<FontAwesome5
								name="sort-down"
								size={30}
								color={
									this.props.from == "brochure" || this.props.from == "home"
										? "#707070"
										: "#5479CB"
								}
								style={{
									position: "absolute",
									right: -5,
									top: "10%",
									zIndex: 100,
								}}
							/>
							<View
								style={{
									backgroundColor: "#ffffff",
									height: 25,
									width: 25,
									zIndex: 99,
									position: "absolute",
									right: -5,
									top: "25%",
								}}
							></View>

							<Picker
								style={[
									styles.mh,
									styles.inputTextGrey,
									{ width: "100%" },
									this.props.from == "brochure" || this.props.from == "home"
										? {
												backgroundColor: "#fff",
												borderWidth: 1,
												borderColor: "#0d0d0d",
												padding: 20,
										  }
										: null,
								]}
								selectedValue={this.state.value?.code}
								onValueChange={(itemValue, itemIndex) =>
									this.mobilePickerChange("code", itemValue)
								}
							>
								{(this.props.data.options || []).map(o => {
									return (
										<Picker.Item
											key={o.value}
											label={o.label}
											value={o.value}
										/>
									);
								})}
							</Picker>
						</View>
						<View style={[styles.flex, styles.flexGrow]}>
							<TextInput
								style={[
									styles.inputTextGrey,
									styles.mh,
									this.props.from == "brochure" || this.props.from == "home"
										? {
												backgroundColor: "#fff",
												borderWidth: 1,
												borderColor: "#0d0d0d",
												padding: 20,
										  }
										: null,
								]}
								placeholder={this.props.data.placeholder || ""}
								keyboardType={this.props.data.keyboardType || "default"}
								autoComplete={this.props.data.autoComplete || ""}
								value={this.state.value?.mobile}
								onChangeText={text => {
									this.mobilePickerChange("mobile", text);
								}}
								editable={!this.props.data.disabled}
							/>
						</View>
					</View>
					{this.state.isError && (
						<Text style={[styles.text, styles.textRed, styles.font20]}>
							{"Please enter 10 digit mobile number"}
						</Text>
					)}
				</View>
			);
		} else if (this.props.data.type == "monthYear") {
			return (
				<View
					style={[
						this.props.data.styles || { width: "100%" },
						styles.row,
						styles.justifyStart,
					]}
				>
					<View>
						<FontAwesome5
							name="sort-down"
							size={30}
							color={
								this.props.data && this.props.data.screen !== "brochure"
									? "#5479CB"
									: "#707070"
							}
							style={{
								position: "absolute",
								right: 20,
								top: "10%",
								zIndex: 100,
							}}
						/>
						<View
							style={{
								backgroundColor: "#ffffff",
								height: 25,
								width: 25,
								zIndex: 99,
								position: "absolute",
								right: 10,
								top: "25%",
							}}
						></View>

						<Picker
							style={[
								styles.mh,
								{ width: 140, height: this.props.data.height || 55 },
								this.props.data && this.props.data.screen == "brochure"
									? styles.brochurepicker
									: styles.picker,
							]}
							selectedValue={
								this.state.monthYear?.month || this.props?.value?.month
							}
							onValueChange={(itemValue, itemIndex) =>
								this.monthYearChange("month", itemValue)
							}
						>
							{(this.state.months || []).map(o => {
								return (
									<Picker.Item
										key={"month" + o.value}
										label={o.label}
										value={o.value}
									/>
								);
							})}
						</Picker>
					</View>
					<View>
						<FontAwesome5
							name="sort-down"
							size={30}
							color={
								this.props.data && this.props.data.screen !== "brochure"
									? "#5479CB"
									: "#707070"
							}
							style={{
								position: "absolute",
								right: 20,
								top: "10%",
								zIndex: 100,
							}}
						/>
						<View
							style={{
								backgroundColor: "#ffffff",
								height: 25,
								width: 25,
								zIndex: 99,
								position: "absolute",
								right: 10,
								top: "25%",
							}}
						></View>
						<Picker
							style={[
								styles.mh,
								{ width: 140, height: this.props.data.height || 55 },
								this.props.data && this.props.data.screen == "brochure"
									? styles.brochurepicker
									: styles.picker,
							]}
							selectedValue={
								this.state.monthYear?.year || this.props?.value?.year
							}
							onValueChange={(itemValue, itemIndex) =>
								this.monthYearChange("year", itemValue)
							}
						>
							{(this.state.years || []).map(o => {
								return (
									<Picker.Item
										key={"year" + o.value}
										label={o.label}
										value={o.value}
									/>
								);
							})}
						</Picker>
					</View>
				</View>
			);
		} else if (this.props.data.type == "radio") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<RadioButton.Group
						onValueChange={newValue => {
							this.inputChange(newValue);
						}}
						value={this.state.value}
					>
						<View style={[styles.row, styles.justifyStart, styles.textPrimary]}>
							{(this.props.data.options || []).map(o => {
								return (
									<View
										key={o.value}
										style={[
											styles.row,
											styles.justifyStart,
											styles.textPrimary,
										]}
									>
										<RadioButton value={o.value} />
										<Text
											style={[
												{ color: "#000" },
												{ fontSize: 17, margin: 5, marginRight: 18 },
											]}
										>
											{o.label}
										</Text>
									</View>
								);
							})}
						</View>
					</RadioButton.Group>
				</View>
			);
		} else if (this.props.data.type == "checkbox") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<View
						style={[
							styles.row,
							styles.justifyStart,
							styles.alignItemsCenter,
							(this.props.data.wrapLabel && styles.wrap) || styles.noWrap,
						]}
					>
						<CheckBox
							key={this.props.data.label}
							value={this.state.isChecked}
							onValueChange={itemValue => {
								this.setState({ isChecked: !this.state.isChecked });
								this.inputChange(itemValue);
							}}
							style={[
								{
									borderRadius: 5,
									width: this.props.data.width || 30,
									height: this.props.data.height || 30,
									color: this.props.data.color,
								},
								styles.mh,
							]}
						/>
						<Text
							style={[
								styles.textPrimary,
								{
									fontSize: this.props.data.fontSize || 16,
									marginLeft: 5,
									wordBreak: this.props.data.breakWord || "break-all",
									color: this.props.data.color,
									whiteSpace: "inherit",
								},
							]}
						>
							{this.props.data.label}
						</Text>
					</View>
				</View>
			);
		} else if (this.props.data.type == "slider") {
			return (
				<View style={[this.props.data.styles || { width: "100%" }]}>
					<Slider
						min={1000}
						max={50000}
						step={1000}
						valueOnChange={value => this.inputChange(value)}
						initialValue={this.props.value || 1000}
						knobColor={"#DDDDDD"}
						valueLabelsBackgroundColor={"#0C5397"}
						inRangeBarColor={"#969696"}
						outOfRangeBarColor={"#0C5397"}
						styleSize={"small"}
						knobBubbleTextStyle={{
							fontSize: 11,
							position: "absolute",
							width: 54,
							textAlign: "center",
							bottom: 28,
						}}
					/>
				</View>
			);
		} else if (this.props.data.type == "toggle") {
			return (
				<View
					style={[
						this.props.data.styles || { width: "100%" },
						styles.row,
						styles.alignItemsCenter,
						styles.justifyStart,
					]}
				>
					<Text
						style={[
							styles.text,
							styles.textPrimary,
							styles.font18,
							{ marginRight: 20 },
						]}
					>
						{this.props.data.offLabel}
					</Text>
					<Switch
						trackColor={{ false: "#C5CAC969", true: "#B51700" }}
						thumbColor={"#ffffff"}
						onValueChange={value => {
							this.inputChange(value);
						}}
						value={this.state.isChecked}
					/>
					<Text
						style={[
							styles.text,
							styles.textPrimary,
							styles.font18,
							{ marginLeft: 20 },
						]}
					>
						{this.props.data.onLabel}
					</Text>
				</View>
			);
		} else {
			return <View></View>;
		}
	}
}

export default Input;
