import {
	GET_COLLEGE_DETAILS,
	GET_COLLEGE_FEES,
	GET_COLLEGE_LIST,
	GET_SHORTLISTED_COLLEGES,
	SAVE_OTHER_COURSES_FILTER,
} from "../constants";

const initialState = {
	isFetchingAppConfig: false,
	isFetchingMbbsColleges: false,
	isFetchingShortlistedColleges: false,
	colleges: [],
	pagination: {
		pageSize: 12,
		currentPage: 1,
		totalItems: 0,
	},
	otherCoursesFilters: {},
	shortListedColleges: [],
	shortListedCountries: [],
	shortListedCourses: [],
};

export const collegeReducers = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_COLLEGE_DETAILS.REQUEST:
			return {
				...state,
				isFetchingCollegeDetails: true,
			};
		case GET_COLLEGE_DETAILS.SUCCESS:
			return {
				...state,
				isFetchingCollegeDetails: false,
				collegeDetails: payload?.data,
			};
		case GET_COLLEGE_DETAILS.FAILURE:
			return {
				...state,
				isFetchingCollegeDetails: false,
			};
		case GET_COLLEGE_LIST.REQUEST:
			return {
				...state,
				isFetchingMbbsColleges: true,
			};
		case GET_COLLEGE_LIST.SUCCESS:
			return {
				...state,
				isFetchingMbbsColleges: false,
				colleges: [...payload?.data?.colleges],
				pagination: {
					...state.pagination,
					totalItems: payload?.data?.totalItems,
					currentPage: payload?.data?.pageNumber,
				},
			};
		case GET_COLLEGE_LIST.FAILURE:
			return {
				...state,
				isFetchingMbbsColleges: false,
			};

		case GET_SHORTLISTED_COLLEGES.REQUEST:
			return {
				...state,
				isFetchingShortlistedColleges: true,
			};
		case GET_SHORTLISTED_COLLEGES.SUCCESS:
			return {
				...state,
				isFetchingShortlistedColleges: false,
				shortListedColleges: payload?.data?.colleges,
				shortListedCountries: payload?.data?.country,
				shortListedCourses: payload?.data?.courses,
			};
		case GET_SHORTLISTED_COLLEGES.FAILURE:
			return {
				...state,
				isFetchingShortlistedColleges: false,
			};
		case GET_COLLEGE_FEES.SUCCESS:
			return {
				...state,
				collegeFees: payload?.data,
			};

		case SAVE_OTHER_COURSES_FILTER:
			return {
				...state,
				otherCoursesFilters: payload,
			};

		default:
			return state;
	}
};
