import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { styles } from "../styles/styles";

class DragList extends React.Component {
	constructor(props) {
		super();
		this.state = {
			options: [],
		};
	}
	componentDidMount() {
		if (this.props.data.options && this.props.data.options[0]) {
			this.setState({
				options: this.props?.data?.options?.sort(
					(a, b) =>
						this.props?.data?.value?.indexOf(a.value) -
						this.props?.data?.value?.indexOf(b.value)
				),
			});
		}
	}
	dragEnd = data => {
		data.forEach(c => {
			c.isActive = false;
		});
		this.setState({ options: data });
		if (this.props.onChange) {
			this.props.onChange({ value: data.map(d => d.value) });
		}
	};
	renderItem = ({ item, index, drag, isActive }) => {
		if (this.props.data.renderItem) {
			this.props.data.renderItem({ item, index, drag, isActive });
		} else {
			return (
				<TouchableOpacity
					key={index}
					onLongPress={drag}
					onPress={() => {
						let stateObj = this.state;
						stateObj.options.forEach(c => {
							c.isActive = false;
						});
						stateObj.options[index].isActive = true;
						stateObj.selectedCard = index;
						this.setState(stateObj);
					}}
				>
					<View
						style={[
							styles.bgWhite,
							styles.brPrimary10,
							{
								paddingHorizontal: 9,
								paddingVertical: 6,
								marginVertical: 8,
								height: 50,
							},
							styles.row,
							styles.justifyStart,
							styles.alignItemsCenter,
						]}
					>
						<Text style={[styles.text, styles.textPrimary, styles.font18]}>
							{index + 1}. {item.label}
						</Text>
					</View>
				</TouchableOpacity>
			);
		}
	};
	render() {
		return (
			<View>
				<DraggableFlatList
					data={this.state.options}
					renderItem={this.renderItem}
					horizontal={this.props.data.horizontal}
					keyExtractor={(item, index) => `draggable-item-${index.toString()}`}
					onDragEnd={({ data }) => {
						this.dragEnd(data);
					}}
				/>
			</View>
		);
	}
}

export default DragList;
