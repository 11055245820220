import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	flex: {
		flex: 1,
		fontFamily: "Montserrat",
	},
	flexGrow: {
		flexGrow: 1,
	},
	containerSpaceBetween: {
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "space-between",
		fontFamily: "Montserrat",
	},
	containerCenter: {
		flexDirection: "column",
		justifyContent: "center",
		fontFamily: "Montserrat",
	},
	column: {
		flexDirection: "column",
		fontFamily: "Montserrat",
	},
	row: {
		flexDirection: "row",
		fontFamily: "Montserrat",
	},
	alignContentEnd: {
		alignContent: "flex-end",
	},
	alignCenter: {
		alignContent: "center",
	},
	alignItemsCenter: {
		alignItems: "center",
	},
	alignItemsEnd: {
		alignItems: "flex-end",
	},
	justifyCenter: {
		justifyContent: "center",
	},
	justifyStart: {
		justifyContent: "flex-start",
	},
	justifyStretch: {
		justifyContent: "space-between",
	},
	alignStart: {
		alignItems: "flex-start",
		alignContent: "flex-start",
	},
	alignEnd: {
		alignItems: "flex-end",
		alignContent: "flex-end",
	},
	justifyEnd: {
		justifyContent: "flex-end",
	},
	padding16: {
		padding: 16,
	},
	padding8: {
		padding: 8,
	},
	textLink: {
		color: "#2CDDDD",
		fontFamily: "Montserrat",
	},
	textPrimary: {
		color: "#0C5397",
		fontFamily: "Montserrat",
	},
	textThickBlue: {
		color: "#5479CB",
		fontFamily: "Montserrat",
	},
	textGrey: {
		color: "#43404090",
		fontFamily: "Montserrat",
	},
	textGrey1: {
		color: "#969696",
		fontFamily: "Montserrat",
	},
	textGrey2: {
		color: "#707070",
		fontFamily: "Montserrat",
	},
	textGrey3: {
		color: "#505050",
		fontFamily: "Montserrat",
	},
	textRed: {
		color: "#B51700",
		fontFamily: "Montserrat",
	},
	textWhite: {
		color: "#FFFFFF",
		fontFamily: "Montserrat",
	},
	textOrange: {
		color: "#E8820A",
		fontFamily: "Montserrat",
	},
	textGreen: {
		color: "#599021",
		fontFamily: "Montserrat",
	},
	textAlignCenter: {
		textAlign: "center",
	},
	textAlignJustify: {
		textAlign: "justify",
	},
	textAlignEnd: {
		textAlign: "end",
	},
	fontBold: {
		fontWeight: "bold",
		fontFamily: "Montserrat-Bold",
	},
	font14: {
		fontSize: 12,
	},
	font18: {
		fontSize: 16,
	},
	font20: {
		fontSize: 18,
	},
	font22: {
		fontSize: 20,
	},
	font25: {
		fontSize: 22,
	},
	font30: {
		fontSize: 26,
	},
	font38: {
		fontSize: 32,
	},
	font45: {
		fontSize: 38,
	},
	font55: {
		fontSize: 48,
	},
	text: {
		fontFamily: "Montserrat",
	},
	textMedium: {
		fontFamily: "Montserrat-SemiBold",
	},
	textUpper: {
		textTransform: "uppercase",
	},
	title: {
		fontFamily: "Montserrat-SemiBold",
		fontWeight: "bold",
		fontSize: 28,
	},
	card: {
		backgroundColor: "#FFFFFF",
		borderRadius: 10,
		padding: 12,
		marginVertical: 8,
	},
	cardBlue: {
		backgroundColor: "#F8F9FD",
		padding: 12,
		marginVertical: 8,
	},
	inputText: {
		borderColor: "#707070",
		borderWidth: 0.5,
		borderRadius: 5,
		color: "#505050",
		width: "100%",
		height: 55,
		padding: 16,
		marginHorizontal: 8,
		fontSize: 18,
		fontFamily: "Arial",
	},
	inputTextBlue: {
		borderColor: "#5479CB",
		borderWidth: 0.5,
		borderRadius: 10,
		backgroundColor: "#ffffff",
		color: "#5479CB",
		width: "100%",
		height: 55,
		padding: 8,
	},
	inputTextGrey: {
		borderColor: "#F0F4F5",
		borderWidth: 0.25,
		borderRadius: 5,
		backgroundColor: "#E1EAEB",
		color: "#707070",
		width: "100%",
		fontSize: 18,
		padding: 30,
	},
	m: {
		margin: 8,
	},
	mv: {
		marginVertical: 8,
	},
	mh: {
		marginHorizontal: 8,
	},
	mr16: {
		marginRight: 16,
	},
	btnPrimary: {
		backgroundColor: "#0C5397",
		paddingHorizontal: 28,
		paddingVertical: 16,
		color: "#FFFFFF",
		borderRadius: 10,
	},
	btnRed: {
		backgroundColor: "#B51700",
		paddingHorizontal: 28,
		paddingVertical: 12,
		color: "#FFFFFF",
		borderRadius: 10,
	},
	btnRedBorder: {
		backgroundColor: "#FFFFFF",
		paddingHorizontal: 28,
		paddingVertical: 12,
		borderRadius: 10,
		borderColor: "#B51700",
		borderWidth: 1,
	},
	btnWhite: {
		backgroundColor: "#FFFFFF",
		paddingHorizontal: 28,
		paddingVertical: 12,
		borderRadius: 10,
	},
	btnGrey: {
		backgroundColor: "#969696",
		paddingHorizontal: 28,
		paddingVertical: 12,
		borderRadius: 5,
	},
	picker: {
		padding: 12,
		borderWidth: 0.5,
		borderColor: "#5479CB",
		borderRadius: 15,
		backgroundColor: "#FFFFFF",
		color: "#5479CB",
		height: 55,
	},
	brochurepicker: {
		padding: 12,
		borderWidth: 0.5,
		borderColor: "#0d0d0d",
		borderRadius: 8,
		backgroundColor: "#FFFFFF",
		color: "#0d0d0d",
		height: 55,
	},
	pPage: {
		paddingHorizontal: 60,
	},
	pv16: {
		paddingVertical: 16,
	},
	pv8: {
		paddingVertical: 8,
	},
	bgDarkBlue: {
		backgroundColor: "#0C5397",
	},
	bgWhite: {
		backgroundColor: "#FFFFFF",
	},
	bgPrimary: {
		backgroundColor: "#0C5397",
	},
	bgLightBlue: {
		backgroundColor: "#A9ECF8",
	},
	bgRed: {
		backgroundColor: "#B51700",
	},
	bgGrey: {
		backgroundColor: "#DDDDDD35",
	},
	bgDarkGrey: {
		backgroundColor: "#EEEEEE",
	},
	noWrap: {
		whiteSpace: "nowrap",
	},
	wrap: {
		flexWrap: "wrap",
	},
	breakAll: {
		wordBreak: "break-all",
	},
	breakword: {
		wordBreak: "break-word",
	},
	breakNone: {
		wordBreak: "none",
	},
	boxShadow: {
		boxShadow: "0px 18px 48px #B5170033",
	},
	boxShadow1: {
		boxShadow: "0px 5px 35px #00000029",
	},
	boxShadow2: {
		boxShadow: "0px 3px 6px #00000029",
	},
	brTop: {
		borderTopWidth: 1,
		borderTopColor: "#CCCCCC",
	},
	brBotttom: {
		borderBottomWidth: 1,
		borderBottomColor: "#434040",
	},
	brBotttomGrey: {
		borderBottomWidth: 1,
		borderBottomColor: "#707070",
	},
	brBotttomBlue: {
		borderBottomWidth: 1,
		borderBottomColor: "#0C5397",
	},
	brBlue: {
		borderColor: "#5479CB",
		borderRadius: 10,
		borderWidth: 0.5,
	},
	brBlue0: {
		borderColor: "#5479CB",
		borderWidth: 0.5,
	},
	brPrimary0: {
		borderColor: "#0C5397",
		borderRadius: 0,
		borderWidth: 1,
	},
	brPrimary: {
		borderColor: "#0C5397",
		borderRadius: 5,
		borderWidth: 1,
	},
	brPrimary10: {
		borderColor: "#0C5397",
		borderRadius: 10,
		borderWidth: 0.5,
	},
	brRRed: {
		borderColor: "#B51700",
		borderRadius: 10,
		borderWidth: 0.5,
	},
	brRRed0: {
		borderColor: "#B51700",
		borderRadius: 0,
		borderWidth: 0.5,
	},
	cardCompleted: {
		backgroundColor: "#0C5397",
	},
	cardSelected: {
		backgroundColor: "#FFFFFF",
		borderWidth: 1,
		borderColor: "#0C5397",
	},
	cardGrey: {
		backgroundColor: "#DDDDDD",
		borderWidth: 1,
		borderColor: "#DDDDDD",
	},
	cursorDisabled: {
		cursor: "not-allowed",
	},
	cursorPointer: {
		cursor: "pointer",
	},
	selectCard: {
		borderColor: "#5479CB",
		borderRadius: 15,
		borderWidth: 0.5,
		paddingHorizontal: 24,
		height: 54,
	},
	collegeFinderCard: {
		backgroundColor: "#F1F2F8",
		borderRadius: 5,
		paddingVertical: 10,
		borderColor: "#F1F2F8",
	},
});
