import React from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import { styles } from "../styles/styles";
import Input from "./Input";

const KeepMePostedEmail = () => {
	return (
		<View
			style={[
				styles.row,
				{
					backgroundColor: "#f0f4f5",
					paddingHorizontal: 187,
					paddingVertical: 119,
				},
			]}
		>
			<View style={{ flex: 1 }}>
				<View style={[styles.row]}>
					<Text style={[{ fontSize: 50 }, styles.text, styles.textPrimary]}>
						Get best advice and guidance
					</Text>
				</View>
				<View style={[styles.row, styles.justifyStretch]}>
					<View style={{ width: "50%" }}>
						<View style={[styles.row, styles.justifyStart, { marginTop: 45 }]}>
							<Text
								style={[
									{ fontSize: 60 },
									styles.text,
									styles.textPrimary,
									styles.fontBold,
								]}
							>
								Be in touch,
							</Text>
						</View>
						<View style={[styles.row, styles.justifyStart]}>
							<Text
								style={[
									{ fontSize: 60 },
									styles.text,
									styles.textRed,
									styles.fontBold,
								]}
							>
								be informed
							</Text>
						</View>
						<View style={[styles.row, styles.justifyStart, { marginTop: 14 }]}>
							<Input
								data={{
									label:
										"I confirm I am over 16 and I agree to the mycareerpathshala Terms and Conditions & Privacy Policy.",
									type: "checkbox",
								}}
							/>
						</View>
					</View>
					<View style={{ width: "50%", padding: 16 }}>
						<View style={[styles.row, styles.justifyStart]}>
							<img
								src={require("../assets/home_page/subscribe.svg")}
								style={{
									width: 50,
									position: "absolute",
									top: 82,
									left: 10,
								}}
							/>
							<TextInput
								style={{
									borderColor: "#707070",
									backgroundColor: "#FFFFFF",
									color: "#B5B2B2",
									height: 100,
									borderWidth: 1,
									width: "100%",
									fontSize: 18,
									fontWeight: "bold",
									paddingLeft: 66,
									boxShadow: "0px 3px 45px #00000029",
									marginTop: 50,
								}}
								placeholder={"Your mail"}
								placeholderTextColor={"#B5B2B2"}
								textAlign="center"
								keyboardType={"email-address"}
							/>
						</View>
						<View style={[styles.row, styles.justifyEnd, { marginTop: 35 }]}>
							<TouchableOpacity
								style={[
									styles.btnRed,
									{ height: 68, zIndex: 0 },
									styles.justifyCenter,
								]}
								onPress={() => {
									// navigation.navigate("SignInBase");
								}}
							>
								<Text
									style={[
										{ color: "#ffffff", zIndex: 0 },
										styles.fontBold,
										styles.font20,
									]}
								>
									Keep me Posted
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</View>
		</View>
	);
};

export default KeepMePostedEmail;
