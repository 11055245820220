import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import Input from "../../components/Input";
import MultiSelect from "../../components/Select";
import { styles } from "../../styles/styles";

class BrochureSignup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fields: [
        {
          type: "inputGrey",
          placeholder: "Email ID",
          keyboardType: "email-address",
          autoComplete: "email",
          key: "email",
          pattern:
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*[\.]*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
        },
        {
          type: "inputGrey",
          placeholder: "Password",
          isPassword: true,
          key: "password",
        },
        {
          type: "inputGrey",
          placeholder: "Name",
          key: "name",
        },
        {
          type: "mobileWithCountryCode",
          placeholder: "Mobile Number",
          keyboardType: "phone-pad",
          autoComplete: "tel",
          key: "moblie",
          help: "OTP will be sent to this mobile number.",
          options: [
            {
              label: "+91",
              value: "91",
            },
          ],
        },
        {
          type: "selectGrey",
          placeholder: "City",
          key: "city",
          options: [
            {
              id: "C1",
              name: "c1",
            },
            {
              id: "C2",
              name: "c2",
            },
            {
              id: "C3",
              name: "c3",
            },
          ],
        },
      ],
      checkbox: {
        type: "checkbox",
        label: "I agree to Terms & Conditions.",
      },
      data: {},
      countries: [
        {
          id: "Bangladesh",
          name: "Bangladesh",
        },
        {
          id: "China",
          name: "China",
        },
        {
          id: "Egypt",
          name: "Egypt",
        },
        {
          id: "Georgia",
          name: "Georgia",
        },
        {
          id: "Kazakhstan",
          name: "Kazakhstan",
        },
        {
          id: "Malaysia",
          name: "Malaysia",
        },
        {
          id: "Mauritus",
          name: "Mauritus",
        },
        {
          id: "Nepal",
          name: "Nepal",
        },
        {
          id: "Philippines",
          name: "Philippines",
        },
        {
          id: "Russia",
          name: "Russia",
        },
        {
          id: "Ukraine",
          name: "Ukraine",
        },
      ],
      selectedCountries: [],
      city: [],
      sideImg: require("../../assets/signin/signup.svg"),
    };
  }
  componentDidMount() {
    this.setState({ sections: this.props.appData.pageConfig });
  }
  onSelectedItemsChange = (selectedCountries) => {
    if (selectedCountries && selectedCountries.length < 4)
      this.setState({ selectedCountries });
  };
  setData = (key, v) => {
    let stateObj = this.state;
    stateObj.data[key] = v.value;
    this.setState(stateObj);
  };
  save = () => {
    // let req = this.state.data;
    // req.firstName = (req.name && req.name.split(" ")[0]) || "";
    // req.lastName = (req.name && req.name.split(" ")[1]) || "";
    // req.address = {
    //   city: req.city,
    // };
    // apisrv.callApi({
    //   endPoint: "sign-up",
    //   request: req,
    //   onSuccess: (res) => {
    //     if (res.success) {
    //       req.userId = res.userId;
    //       apisrv.callApi({
    //         endPoint: "save-user-data",
    //         request: req,
    //         onSuccess: (res) => {
    //           this.props.updateData(
    //             { key: "userData", value: req, type: "userData" },
    //             () => {
    //               if (this.props.appData.redirectRoute) {
    //                 let redirectTo = this.props.appData.redirectRoute;
    //                 this.props.updateData(
    //                   {
    //                     key: "redirectRoute",
    //                     value: "",
    //                     type: "redirectRoute",
    //                   },
    //                   () => {
    //                     this.props.navigation.navigate(redirectTo);
    //                   }
    //                 );
    //               } else {
    //                 this.props.navigation.navigate("Dashboard");
    //               }
    //             }
    //           );
    //         },
    //       });
    //     } else {
    //       alert(res.error || "Unable to Sign Up");
    //     }
    //   },
    // });
  };
  onCitySelected = (city) => {
    this.setState({ city: city });
  };
  render() {
    return (
      <View
        style={[
          { padding: 56, overflow: "auto", marginBottom: 160, width: "70vw" },
        ]}
      >
        <View style={[{ width: "100%", marginBottom: "2%" }]}>
          <View style={[styles.row, styles.justifyCenter]}>
            <Text style={[styles.textPrimary, styles.font45, styles.fontBold]}>
              Download Brochure
            </Text>
          </View>
          <View style={[styles.row, styles.justifyCenter]}>
            <Text
              style={[
                styles.font20,
                styles.text,
                { textAlign: "center", width: "50%" },
              ]}
            >
              Get university details, fees, eligibility criteria, accommodation
              and other expense details.
            </Text>
          </View>
        </View>

        <View>
          <Text
            style={{
              fontSize: 25,
              fontFamily: "Montserrat-SemiBold",
              color: "#000",
            }}
          >
            1. Personal details:
          </Text>
          <Text
            style={{
              fontSize: 20,
              fontFamily: "Montserrat",
              color: "#000",
              marginTop: 10,
            }}
          >
            This will create your account and enable you to receive updates and
            recommendation.
          </Text>
        </View>
        <View style={[styles.row, styles.justifyStart, { marginVertical: 31 }]}>
          <View>
            <TouchableOpacity
              style={[styles.inputTextGrey]}
              onPress={this.save}
            >
              <Text style={[styles.textGrey2, styles.fontBold, styles.font20]}>
                GOOGLE
              </Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              style={[styles.inputTextGrey, { marginLeft: 26 }]}
              onPress={this.save}
            >
              <Text style={[styles.textGrey2, styles.fontBold, styles.font20]}>
                FACEBOOK
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.row, styles.justifyStart, { marginVertical: 35 }]}>
          <Text style={[styles.font20, styles.text]}>
            <Text>By continuing, you agree to our </Text>
            <Text style={[styles.textPrimary, styles.fontBold]}>
              Privacy Policy{" "}
            </Text>
            <Text>& </Text>
            <Text style={[styles.textPrimary, styles.fontBold]}>
              Terms & Conditions.
            </Text>
          </Text>
        </View>
        <View
          style={[
            styles.row,
            styles.justifyStretch,
            styles.alignItemsCenter,
            { marginTop: 20 },
          ]}
        >
          <View
            style={[
              styles.flex,
              styles.flexGrow,
              { borderBottomWidth: 0.25, borderBottomColor: "#707070" },
            ]}
          ></View>
          <View style={[{ marginHorizontal: 24 }]}>
            <Text style={[styles.text, styles.font30]}>Or</Text>
          </View>
          <View
            style={[
              styles.flex,
              styles.flexGrow,
              { borderBottomWidth: 0.25, borderBottomColor: "#707070" },
            ]}
          ></View>
        </View>
        <View style={[styles.row, styles.justifyStretch, styles.wrap]}>
          {this.state.fields.map((f, fi) => {
            if (f.key == "city") {
              return (
                <View
                  style={[
                    { width: "80%", marginVertical: 22, marginHorizontal: 10 },
                  ]}
                  key={"filed" + (fi + 1)}
                >
                  <MultiSelect
                    hideTags
                    items={f.options}
                    uniqueKey="id"
                    ref={(component) => {
                      this.multiSelect = component;
                    }}
                    onSelectedItemsChange={this.onCitySelected}
                    selectedItems={this.state.city}
                    selectText={"Select City"}
                    styleDropdownMenu={{ width: "60%" }}
                    displayKey="name"
                    single={true}
                    hideSubmitButton={true}
                  />
                </View>
              );
            } else {
              return (
                <View
                  style={[{ width: "48%", marginVertical: 22 }]}
                  key={"filed" + (fi + 1)}
                >
                  <Input
                    from="brochure"
                    data={f}
                    onChange={(v) => {
                      this.setData(f.key, v);
                    }}
                  />
                </View>
              );
            }
          })}
        </View>
        <View>
          <Text
            style={{
              fontSize: 25,
              fontFamily: "Montserrat-SemiBold",
              color: "#000",
            }}
          >
            2. When you plan to start your studies:
          </Text>
          <Text
            style={{
              fontSize: 20,
              fontFamily: "Montserrat",
              color: "#000",
              marginTop: 10,
            }}
          >
            This will help us to recommend you your country & course of
            interest.
          </Text>
          <View style={{ marginTop: "2%", marginBottom: "5%" }}>
            <Input
              data={{
                type: "monthYear",
                styles: { width: "50%" },
                screen: "brochure",
              }}
              onChange={(v) => {
                this.setData("monthYear", v);
              }}
            />
          </View>
        </View>
        <View>
          <Text
            style={{
              fontSize: 25,
              fontFamily: "Montserrat-SemiBold",
              color: "#000",
              marginBottom: "2%",
            }}
          >
            3. Your preferred country:
          </Text>
          <MultiSelect
            hideTags
            items={this.state.countries}
            uniqueKey="id"
            ref={(component) => {
              this.multiSelect = component;
            }}
            onSelectedItemsChange={this.onSelectedItemsChange}
            selectedItems={this.state.selectedCountries}
            selectText={"Select country"}
            styleDropdownMenu={{ width: "60%" }}
            displayKey="name"
            single={false}
            hideSubmitButton={true}
          />
          <Text
            style={{
              fontSize: 20,
              fontFamily: "Montserrat",
              color: "#000",
              marginTop: "2%",
              marginBottom: "5%",
            }}
          >
            Add upto 3 countries from list.
          </Text>
        </View>
        <View>
          <Text
            style={{
              fontSize: 25,
              fontFamily: "Montserrat-SemiBold",
              color: "#000",
            }}
          >
            4. Have you given any exam to study abroad?
          </Text>
          <Text
            style={{
              fontSize: 20,
              fontFamily: "Montserrat",
              color: "#000",
              marginTop: 10,
            }}
          >
            This will help us to recommend you best suited universities.
          </Text>
        </View>
        <View style={{ marginTop: "2%", marginBottom: "5%" }}>
          <Input
            data={{
              type: "radio",
              options: [
                {
                  label: "Yes",
                  value: "Yes",
                },
                {
                  label: "No",
                  value: "No",
                },
                {
                  label: "Booked",
                  value: "Booked",
                },
              ],
            }}
            onChange={(v) => {
              this.setData("examStatus", v);
            }}
          />
        </View>

        <View>
          <Text
            style={{
              fontSize: 25,
              fontFamily: "Montserrat-SemiBold",
              color: "#000",
            }}
          >
            Do you have a valid passport?
          </Text>
        </View>
        <View style={{ marginTop: "2%", marginBottom: "5%" }}>
          <Input
            data={{
              type: "radio",
              options: [
                {
                  label: "Yes",
                  value: "Yes",
                },
                {
                  label: "No",
                  value: "No",
                },
              ],
            }}
            onChange={(v) => {
              this.setData("validPassport", v);
            }}
          />
        </View>

        <View style={[styles.row, styles.justifyStart, { marginTop: 32 }]}>
          <Input
            data={this.state.checkbox}
            onChange={(v) => {
              this.setData("tnc", v);
            }}
          />
        </View>
        <View style={[styles.row, styles.justifyCenter, { marginTop: 32 }]}>
          <TouchableOpacity style={[styles.btnPrimary]} onPress={this.save}>
            <Text
              style={[
                { color: "#ffffff", textTransform: "uppercase" },
                styles.text,
                styles.fontBold,
                styles.font25,
              ]}
            >
              Download Brochure
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appData: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateData: async (parameter, callback) => {
      await dispatch(parameter);
      if (callback) {
        callback();
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrochureSignup);
