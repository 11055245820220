import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import apisrv from "../../service/ApiService";
import { styles } from "../../styles/styles";

class Pages extends React.Component {
	constructor(props) {
		super();
		this.state = {
			pages: [],
		};
	}
	componentDidMount() {
		this.setState({ pages: this.props.appData.pageConfig });
		this.getAllConfig();
	}
	sectionSelected(s, pageName) {
		s.pageName = pageName;
		this.props.updateData(
			{ key: "selectedSection", value: s, type: "selectedSection" },
			() => {
				this.props.navigation.navigate("EditConfig");
			}
		);
	}
	getAllConfig = () => {
		let req = {
			userId: this.props.appData.userData.userId,
		};

		apisrv.callApi({
			endPoint: "get-all-page-config",
			request: req,
			onSuccess: res => {
				if ((res.config || []).length) {
					this.setState({ pages: res.config });
					this.props.updateData({
						key: "pageConfig",
						value: res.config,
						type: "pageConfig",
					});
				}
			},
		});
	};
	render() {
		return (
			<View
				style={[
					styles.flexGrow,
					styles.flex,
					{
						width: "calc(100% - 150px)",
						marginLeft: 150,
						paddingTop: 12,
						paddingHorizontal: 50,
						marginVertical: 50,
					},
				]}
			>
				<View
					style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}
				>
					<View>
						<Text style={[styles.textPrimary, styles.title]}>
							Page Configuration
						</Text>
					</View>
					<View>
						<TouchableOpacity
							style={[
								{
									boxShadow: "0px 3px 6px #00000029",
									borderRadius: 5,
									borderWidth: 1,
									borderColor: "#0C5397",
									paddingVertical: 10,
									paddingHorizontal: 14,
								},
								styles.bgWhite,
							]}
							onPress={() => {
								this.props.navigation.navigate("Dashboard");
							}}
						>
							<Text style={[styles.text, styles.textPrimary, styles.font18]}>
								Home
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				{this.state.pages?.map((p, pi) => {
					return (
						<View key={"page" + (pi + 1)} style={[styles.cardBlue]}>
							<View style={[styles.row, styles.justifyStretch]}>
								<View style={[styles.alignItemsCenter]}>
									<View
										style={[styles.row, styles.justifyStart, { marginTop: 6 }]}
									>
										<Text
											style={[
												styles.textRed,
												styles.fontBold,
												{ fontSize: 22 },
											]}
										>
											{p.name}
										</Text>
									</View>
								</View>
								<View>
									<TouchableOpacity
										onPress={() => {
											let stateObj = this.state;
											stateObj.pages[pi].open = !stateObj.pages[pi].open;
											this.setState(stateObj);
										}}
									>
										<View
											style={[
												styles.row,
												styles.justifyEnd,
												styles.alignItemsCenter,
											]}
										>
											<Text
												style={[
													styles.textRed,
													styles.fontBold,
													{ fontSize: 22 },
												]}
											>
												{p.open ? "-" : "+"}
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</View>
							{p.open && (
								<View
									style={[
										styles.row,
										styles.justifyStart,
										styles.wrap,
										{ marginTop: 22 },
									]}
								>
									{(p.sections || [])?.map((ps, psi) => {
										return (
											<View
												style={[
													styles.row,
													styles.justifyStretch,
													styles.bgWhite,
													{
														borderWidth: 1,
														borderColor: "#5479CB",
														width: "31%",
														marginHorizontal: 10,
														marginVertical: 14,
														padding: 12,
													},
												]}
												key={"pageSection" + (psi + 1)}
											>
												<View
													style={[
														styles.alignItemsCenter,
														styles.justifyCenter,
													]}
												>
													<Text
														style={[
															styles.text,
															styles.textPrimary,
															styles.alignItemsCenter,
															styles.font18,
															styles.fontBold,
														]}
													>
														{ps.name}
													</Text>
												</View>
												<View
													style={[
														styles.alignItemsCenter,
														styles.justifyCenter,
													]}
												>
													<TouchableOpacity
														onPress={() => {
															this.sectionSelected(ps, p.name);
														}}
													>
														<View
															style={[
																styles.row,
																styles.justifyEnd,
																styles.alignItemsCenter,
															]}
														>
															<Text
																style={[
																	styles.text,
																	styles.textPrimary,
																	styles.font18,
																]}
															>
																Edit
															</Text>
															<Image
																source={{
																	uri: require("../../assets/dashboard/profile/edit.svg"),
																}}
																style={{
																	width: 14,
																	height: 14,
																	resizeMode: "center",
																	marginLeft: 14,
																}}
															/>
														</View>
													</TouchableOpacity>
												</View>
											</View>
										);
									})}
								</View>
							)}
						</View>
					);
				})}
			</View>
		);
	}
}

const mapStateToProps = state => {
	return {
		appData: state,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateData: async (parameter, callback) => {
			await dispatch(parameter);
			if (callback) {
				callback();
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
