import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "../styles/styles";

const Pagination = props => {
	const [pagination, setPagination] = useState({
		totalItems: 0,
		currentPage: 0,
		pageSize: 0,
		pageNoArray: [],
		showDots: false,
		showingPagesText: "Showing results of 1 out of 10",
	});

	useEffect(() => {
		if (props?.totalItems) {
			let paginationObj = pagination;
			let totalPages = Math.ceil(props?.totalItems / props?.pageSize);
			paginationObj.totalItems = props?.totalItems;
			paginationObj.pageSize = props?.pageSize;
			paginationObj.pageNoArray = [];
			for (let i = 0; i < totalPages; i++) {
				paginationObj.pageNoArray.push({
					page: i + 1,
					isSelected: i == 0,
				});
			}
			paginationObj.showingPagesText = `Showing results 1-${props?.pageSize} of ${props?.totalItems}`;
			if (paginationObj.pageNoArray.length > 5) {
				paginationObj.showDots = true;
			}
			setPagination({ ...paginationObj });
		}
	}, [props.totalItems]);

	const changePage = step => {
		let paginationObj = pagination;
		paginationObj.pageNoArray[paginationObj.currentPage].isSelected = false;
		if (step === "back") {
			paginationObj.currentPage -= 1;
		} else if (step === "next") {
			paginationObj.currentPage += 1;
		} else {
			paginationObj.currentPage = step - 1;
		}
		if (paginationObj.pageNoArray.length > paginationObj.currentPage + 5) {
			paginationObj.showDots = true;
		} else {
			paginationObj.showDots = false;
		}
		paginationObj.pageNoArray[paginationObj.currentPage].isSelected = true;
		paginationObj.showingPagesText = `Showing results ${
			paginationObj.currentPage > 0
				? paginationObj.currentPage * paginationObj.pageSize + 1
				: 1
		}-${(paginationObj.currentPage + 1) * paginationObj.pageSize} of ${
			paginationObj.totalItems
		}`;
		setPagination({ ...paginationObj });
		if (props.onPageChange) {
			props.onPageChange({ currentPage: paginationObj.currentPage + 1 });
		}
	};

	if (pagination.totalItems && pagination.pageNoArray.length > 1) {
		return (
			<View
				style={[
					{ width: "100%" },
					styles.alignItemsCenter,
					styles.justifyCenter,
				]}
			>
				<View style={[styles.row, styles.justifyCenter]}>
					<TouchableOpacity
						style={[
							(pagination.pageNoArray[0].isSelected && styles.cursorDisabled) ||
								"",
							(pagination.pageNoArray[0].isSelected && styles.bgGrey) ||
								styles.bgPrimary,
							styles.justifyCenter,
							styles.textAlignCenter,
							{ borderRadius: 3, paddingVertical: 6, paddingHorizontal: 12 },
						]}
						onPress={() => {
							if (!pagination.pageNoArray[0].isSelected) {
								changePage("back");
							}
						}}
					>
						<FontAwesome
							name="chevron-left"
							size={24}
							color={
								(pagination.pageNoArray[0].isSelected && "#C5C5C5") || "#FFFFFF"
							}
						/>
					</TouchableOpacity>
					<View style={{ marginHorizontal: 40 }}>
						<View
							style={[
								styles.row,
								styles.justifyCenter,
								styles.alignItemsCenter,
							]}
						>
							{pagination.pageNoArray.map((p, i) => {
								if (
									(i >= pagination.currentPage ||
										i >= pagination.pageNoArray.length - 5) &&
									i < pagination.currentPage + 5
								) {
									return (
										<TouchableOpacity
											onPress={() => {
												if (!pagination.pageNoArray[p.page - 1].isSelected) {
													changePage(p.page);
												}
											}}
											key={p.page}
										>
											<View
												style={[
													(p.isSelected && styles.bgRed) || "",
													{
														padding: 16,
														borderRadius: (p.isSelected && 3) || 0,
													},
												]}
											>
												<Text
													style={[
														(p.isSelected && styles.textWhite) ||
															styles.textRed,
														styles.font18,
														styles.fontBold,
													]}
												>
													{p.page}
												</Text>
											</View>
										</TouchableOpacity>
									);
								} else {
									<View></View>;
								}
							})}
							{pagination.showDots && (
								<Text
									style={[
										styles.text,
										styles.font18,
										styles.fontBold,
										styles.textRed,
									]}
								>
									......
								</Text>
							)}
						</View>
					</View>
					<TouchableOpacity
						style={[
							(pagination.pageNoArray[pagination.pageNoArray.length - 1]
								.isSelected &&
								styles.cursorDisabled) ||
								"",
							(pagination.pageNoArray[pagination.pageNoArray.length - 1]
								.isSelected &&
								styles.bgGrey) ||
								styles.bgPrimary,
							styles.justifyCenter,
							styles.textAlignCenter,
							{ borderRadius: 3, paddingVertical: 6, paddingHorizontal: 12 },
						]}
						onPress={() => {
							if (
								!pagination.pageNoArray[pagination.pageNoArray.length - 1]
									.isSelected
							) {
								changePage("next");
							}
						}}
					>
						<FontAwesome
							name="chevron-right"
							size={24}
							color={
								(pagination.pageNoArray[pagination.pageNoArray.length - 1]
									.isSelected &&
									"#C5C5C5") ||
								"#FFFFFF"
							}
						/>
					</TouchableOpacity>
				</View>
				<View style={[styles.row, styles.justifyCenter, { marginTop: 42 }]}>
					<Text style={[styles.text, styles.textPrimary, styles.font20]}>
						{pagination.showingPagesText}
					</Text>
				</View>
			</View>
		);
	} else {
		return <View></View>;
	}
};

export default Pagination;
