import { FontAwesome } from "@expo/vector-icons";
import { useEffect, useState } from "react";
import { Modal, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import EditInfo from "../components/EditInfo";
import apisrv from "../service/ApiService";
import { styles } from "../styles/styles";

const config = {
  enum: {
    welcomeSubtitle:
      "Unlock the world of opportunities with mycareerpathshala and find your dream destination to study abroad.",
    verticalText: "Building tomorrow using technology",
    pageTitle: "Home",
  },
  tabs: [
    {
      label: "Your personal space",
      text: "Welcome!! Explore universities, courses of your choice. Save, shortlist and compare your choice of interest.",
      steps: [
        {
          title: "Tell us more about yourself",
          description:
            "We need some information to help you in finding best universities for you.",
        },
        {
          title: "Wait is over",
          description:
            "Boost your career with immense opportunities in abroad, with world class infrastructure and best education system.",
        },
      ],
      imgUrl: "../assets/dashboard/home/img_2.svg",
    },
    {
      label: "Eligibility for your best fit",
      text: "Explore universities and get to know your best fit one. Our career counsellor will help you find the best fit and will provide you with the best possible advice for your career.",
      steps: [
        {
          title: "Request Career Counsellor",
          description:
            "Send a request for allotment of career counsellor for complete guidance in studying abroad to top universities.",
        },
        {
          title: "Academic/Education Details",
          description:
            "Fill your academic/educations details in profile section",
        },
        {
          title:
            "Shortlist courses and universities. Apply to Universities.Academic/Education Details",
          description:
            "Explore and shortlist your courses/universities. Save your shortlist and apply.",
        },
      ],
      imgUrl: "../assets/dashboard/home/img_2.svg",
    },
    {
      label: "Apply to Universities",
      text: "Great!!\nOur career counsellor will be always in touch with you to help you to choose best match for you. Track your application/admission status",
      steps: [
        {
          title: "Upload documents",
          description:
            "Upload your documents to process your admission to applied universities.",
        },
        {
          title: "Track admission/application status.",
          description:
            "Apply to your dream destination and universities and track the progress of getting there.",
        },
      ],
      imgUrl: "../assets/dashboard/home/img_2.svg",
    },
    {
      label: "Accept your admission",
      text: "Congratulations!! You have been selected for admission",
      steps: [],
      imgUrl: "../assets/dashboard/home/img_2.svg",
    },
    {
      label: "VISA Status",
      text: "Apply your study visa with mycareerpathshala. Getting visa for studying abroad increases by 90% when applying with us.",
      steps: [
        {
          title: "Upload documents",
          description: "Upload your documents to process VISA",
        },
        {
          title: "Track VISA status",
          description: "",
        },
      ],
      imgUrl: "../assets/dashboard/home/img_2.svg",
    },
  ],
};

const requestCounsellorOptions = [
  {
    label: "Other Courses",
    value: "others",
  },
  {
    label: "MBBS Abroad",
    value: "mbbs",
  },
];

const UserHome = ({ navigation }) => {
  const userDetails = useSelector((state) => state.userData?.userDetails);
  const [stages, setStages] = useState({
    stage1: {
      stageCompleted: false,
      step1: false,
      step2: false,
    },
    stage2: {
      stageCompleted: false,
      step1: false,
      step2: false,
      step3: false,
    },
    stage3: {
      stageCompleted: false,
      step1: false,
      step2: false,
    },
    stage4: {
      stageCompleted: false,
    },
    stage5: {
      stageCompleted: false,
      step1: false,
      step2: false,
    },
  });
  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCounsellorOption, setSelectedCounsellorOption] =
    useState(null);

  const getCareerCounsellor = (type) => {
    apisrv.callApi({
      endPoint: `user/request-counsellor/${userDetails?.userId}/${type}`,
      method: "GET",
      onSuccess: (res) => {
        if (res.sucess) setIsModalOpen(false);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const renderRequestCareerCounsellorModal = () => {
    return (
      <Modal animationType="slide" transparent={true} visible={isModalOpen}>
        <View
          style={[
            {
              boxShadow: "0px 5px 15px #00000029",
              backgroundColor: "#ffffff",
              position: "absolute",
              top: "30%",
              left: "30%",
              width: "40vw",
              borderRadius: 10,
              padding: 35,
            },
          ]}
        >
          <View
            style={[styles.row, styles.alignItemsCenter, styles.justifyStretch]}
          >
            <View></View>
            <View>
              <TouchableOpacity
                onPress={() => {
                  setIsModalOpen(false);
                  setSelectedCounsellorOption(null);
                }}
              >
                <FontAwesome name={"remove"} size={30} color="#B51700" />
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ width: "100%", marginTop: 10 }}>
            <Text
              style={[
                styles.textPrimary,
                styles.font38,
                styles.textAlignCenter,
                styles.fontBold,
              ]}
            >
              Request for dedicated career counsellor
            </Text>
          </View>
          <View
            style={[
              styles.padding8,
              styles.row,
              styles.justifyStart,
              styles.wrap,
            ]}
          >
            <View style={{ width: "100%", textAlign: "center", marginTop: 20 }}>
              <Text style={[styles.font22, styles.fontBold]}>
                Select your preference
              </Text>
            </View>
            <View
              style={[
                {
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  flex: "auto",
                },
              ]}
            >
              {requestCounsellorOptions.map((mc, mci) => {
                return (
                  <TouchableOpacity
                    key={"mcs" + (mci + 1)}
                    style={[
                      (selectedCounsellorOption == mc.value &&
                        styles.bgPrimary) ||
                        styles.bgWhite,
                      styles.selectCard,
                      styles.justifyCenter,
                      { marginRight: 20, marginTop: 11 },
                    ]}
                    onPress={() => {
                      setSelectedCounsellorOption(mc.value);
                    }}
                  >
                    <Text
                      style={[
                        styles.text,
                        (selectedCounsellorOption == mc.value &&
                          styles.textWhite) ||
                          styles.textPrimary,
                        styles.font22,
                      ]}
                    >
                      {mc.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
          <TouchableOpacity
            style={[
              !selectedCounsellorOption ? styles.btnGrey : styles.btnPrimary,
              { marginTop: 30, marginHorizontal: "auto" },
            ]}
            disabled={!selectedCounsellorOption}
            onPress={() => getCareerCounsellor(selectedCounsellorOption)}
          >
            <Text
              style={[
                {
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontSize: 20,
                },
              ]}
            >
              Send My Request
            </Text>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  };

  const unSubscribe = () => {
    let req = {
      userId: userDetails?.userId,
    };
    apisrv.callApi({
      endPoint: "unsubscribe-account",
      request: req,
      onSuccess: (res) => {
        navigation.navigate("Home");
      },
      onError: () => {},
    });
  };
  useEffect(() => {
    apisrv.callApi({
      endPoint: `user/stages/${userDetails?.userId}`,
      method: "GET",
      onSuccess: (res) => {
        setStages({ ...res });
      },
      onError: () => {},
    });
  }, []);

  useEffect(() => {
    let tempdata = Object.values(stages);
    let index = tempdata.findIndex((a) => !a.stageCompleted);
    setData({
      ...config.tabs[index],
      selectedTab: index,
      stepsSelector: tempdata[index],
    });
  }, [stages]);

  return (
    <>
      {renderRequestCareerCounsellorModal()}
      <View
        style={[
          styles.flex,
          {
            padding: 12,
            gap: 20,
          },
        ]}
      >
        <Text style={[styles.textPrimary, styles.title]}>
          {config?.enum.pageTitle}
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <View
            style={[
              styles.row,
              styles.bgDarkGrey,
              {
                borderWidth: 0.25,
                borderRadius: 20,
                borderColor: "#F7F7F7",
                padding: 28,
                flexDirection: "row",
                flex: 1,
                gap: 10,
                justifyContent: "space-between",
                flexWrap: "wrap",
              },
            ]}
          >
            <View style={{ justifyContent: "space-between" }}>
              <View
                style={[
                  styles.row,
                  styles.justifyStart,
                  styles.alignItemsCenter,
                ]}
              >
                <Text style={[styles.text, styles.textPrimary, styles.font30]}>
                  Welcome!!
                </Text>
                <Text
                  style={[
                    styles.text,
                    styles.textPrimary,
                    styles.font45,
                    styles.fontBold,
                  ]}
                >
                  {data?.firstName}
                </Text>
              </View>
              <Text
                style={[
                  styles.text,
                  styles.textGrey,
                  styles.font20,
                  { width: 500, flex: 1, flexWrap: "wrap" },
                ]}
              >
                {config?.enum.welcomeSubtitle}
              </Text>
              <View style={[styles.row]}>
                <TouchableOpacity
                  style={[
                    styles.boxShadow2,
                    styles.justifyCenter,
                    styles.textAlignCenter,
                    {
                      borderRadius: 15,
                      borderColor: "#F5F5F5",
                      paddingHorizontal: 37,
                      paddingVertical: 20,
                      background:
                        "transparent radial-gradient(closest-side at 85% 50%, #B1DAE1 0%, #34A7B5 260%)",
                    },
                  ]}
                  onPress={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <View
                    style={[
                      styles.row,
                      styles.justifyStretch,
                      styles.alignItemsCenter,
                    ]}
                  >
                    <img
                      src={require("../assets/dashboard/home/req_career_counsellor.svg")}
                    />
                    <Text
                      style={[
                        styles.text,
                        styles.textWhite,
                        styles.font20,
                        styles.textMedium,
                        { paddingHorizontal: 8 },
                      ]}
                    >
                      Request your Career Counsellor
                    </Text>
                    <img
                      src={require("../assets/dashboard/home/tab_arrow.svg")}
                      style={{ width: 34 }}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <img
              src={require("../assets/dashboard/home/img_1.svg")}
              style={{ marginTop: -50 }}
            />
          </View>
          <View
            style={[
              {
                borderWidth: 0.25,
                borderRadius: 20,
                borderColor: "#F7F7F7",
                padding: 28,
              },
              styles.bgDarkGrey,
            ]}
          >
            <View style={[styles.row, styles.justifyStart]}>
              <TouchableOpacity
                style={[
                  styles.boxShadow2,
                  styles.justifyCenter,
                  styles.textAlignCenter,
                  {
                    borderRadius: 15,
                    borderColor: "#F5F5F5",
                    paddingHorizontal: 37,
                    paddingVertical: 20,
                    width: 370,
                    background:
                      "transparent radial-gradient(closest-side at 85% 50%, #B6DFFF 0%, #4079AB 240%)",
                  },
                ]}
                onPress={() => {}}
              >
                <View
                  style={[
                    styles.row,
                    styles.justifyStretch,
                    styles.alignItemsCenter,
                  ]}
                >
                  <View
                    style={[
                      {
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: "#A9CDF0",
                        borderColor: "#EEEEEE",
                        borderWidth: 1,
                      },
                      styles.justifyCenter,
                    ]}
                  >
                    <Text
                      style={[
                        styles.text,
                        { color: "#434040" },
                        styles.font25,
                        styles.textMedium,
                      ]}
                    >
                      N
                    </Text>
                  </View>
                  <Text
                    style={[
                      styles.text,
                      styles.textWhite,
                      styles.font20,
                      styles.textMedium,
                      { paddingHorizontal: 8 },
                    ]}
                  >
                    NEET Counselling
                  </Text>
                  <img
                    src={require("../assets/dashboard/home/tab_arrow.svg")}
                    style={{ width: 34 }}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.row, styles.justifyStart, { marginTop: 22 }]}>
              <TouchableOpacity
                style={[
                  styles.boxShadow2,
                  styles.justifyCenter,
                  styles.textAlignCenter,
                  {
                    borderRadius: 15,
                    borderColor: "#F5F5F5",
                    paddingHorizontal: 37,
                    paddingVertical: 20,
                    width: 370,
                    background:
                      "transparent radial-gradient(closest-side at 85% 50%, #F9C071 0%, #FCA209 100%)",
                  },
                ]}
                onPress={() => {}}
              >
                <View
                  style={[
                    styles.row,
                    styles.justifyStretch,
                    styles.alignItemsCenter,
                  ]}
                >
                  <View
                    style={[
                      {
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: "#F89EB6",
                        borderColor: "#EEEEEE",
                        borderWidth: 1,
                      },
                      styles.justifyCenter,
                    ]}
                  >
                    <Text
                      style={[
                        styles.text,
                        { color: "#434040" },
                        styles.font25,
                        styles.textMedium,
                      ]}
                    >
                      I
                    </Text>
                  </View>
                  <Text
                    style={[
                      styles.text,
                      styles.textWhite,
                      styles.font20,
                      styles.textMedium,
                      { paddingHorizontal: 8 },
                    ]}
                  >
                    IMAT Path
                  </Text>
                  <img
                    src={require("../assets/dashboard/home/tab_arrow.svg")}
                    style={{ width: 34 }}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.row, styles.justifyStart, { marginTop: 22 }]}>
              <TouchableOpacity
                style={[
                  styles.boxShadow2,
                  styles.justifyCenter,
                  styles.textAlignCenter,
                  {
                    borderRadius: 15,
                    borderColor: "#F5F5F5",
                    paddingHorizontal: 37,
                    paddingVertical: 20,
                    width: 370,
                    background:
                      "transparent radial-gradient(closest-side at 85% 50%, #F499AE 0%, #EC548F 260%)",
                  },
                ]}
                onPress={() => {}}
              >
                <View
                  style={[
                    styles.row,
                    styles.justifyStretch,
                    styles.alignItemsCenter,
                  ]}
                >
                  <View
                    style={[
                      {
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: "#11669E",
                      },
                      styles.justifyCenter,
                      styles.alignItemsCenter,
                    ]}
                  >
                    <img
                      src={require("../assets/dashboard/home/event_tab_icon.svg")}
                      style={{ width: 24 }}
                    />
                  </View>
                  <Text
                    style={[
                      styles.text,
                      styles.textWhite,
                      styles.font20,
                      styles.textMedium,
                      { paddingHorizontal: 8 },
                    ]}
                  >
                    Events
                  </Text>
                  <img
                    src={require("../assets/dashboard/home/tab_arrow.svg")}
                    style={{ width: 34 }}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        {/* --------------------------------------------------------------------------- */}
        {/* <View style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}>
        {userDetails?.isAdmin ? (
          <View>
            <TouchableOpacity
              style={[
                {
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: "#0C5397",
                  paddingVertical: 10,
                  paddingHorizontal: 14,
                },
                styles.bgWhite,
              ]}
              onPress={() => {
                navigation.navigate("Admin");
              }}
            >
              <Text style={[styles.text, styles.textPrimary, styles.font18]}>
                Admin
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View> */}

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          {userDetails?.userId && (
            <EditInfo
              onInputChange={(key, value) => {
                // let stateObj = this.state;
                // stateObj.basicDetails[key] = value;
                // this.setState(stateObj);
              }}
              data={userDetails}
              onUnSubscribeClick={() => {
                unSubscribe();
              }}
            />
          )}
          <View
            style={[
              styles.flex,
              {
                borderWidth: 0.25,
                borderRadius: 20,
                borderColor: "#F7F7F7",
                padding: 28,
              },
              styles.bgDarkGrey,
            ]}
          >
            <View
              style={[
                styles.row,
                styles.justifyStretch,
                {
                  borderColor: "#C7C1C1",
                  borderWidth: 3,
                  marginHorizontal: 100,
                  borderRadius: 5,
                },
              ]}
            ></View>
            <View
              style={[
                styles.row,
                styles.justifyStretch,
                {
                  borderColor: "#0075FF",
                  borderWidth: 3,
                  marginHorizontal: 100,
                  borderRadius: 5,
                  marginTop: -5,
                  width: data?.selectedTab * 20 + "%",
                },
              ]}
            ></View>
            <View
              style={[
                styles.row,
                styles.justifyStretch,
                { marginTop: -16, marginHorizontal: 100 },
              ]}
            >
              {config?.tabs.map((t, ti) => {
                return (
                  <View
                    key={"tab" + (ti + 1)}
                    style={[
                      styles.alignItemsCenter,
                      styles.justifyCenter,
                      {
                        backgroundColor:
                          (ti <= data?.selectedTab && "#0075FF") || "#FFFFFF",
                        borderRadius: "50%",
                        height: 23,
                        width: 23,
                        borderWidth: 1,
                        borderColor: "#C7C1C1",
                      },
                    ]}
                  >
                    <View
                      style={[
                        {
                          backgroundColor:
                            ti === data?.selectedTab
                              ? "#FFFFFF"
                              : ti < data?.selectedTab
                              ? "#0075FF"
                              : "#C7C1C1",
                          borderRadius: "50%",
                          height: 4,
                          width: 4,
                        },
                      ]}
                    ></View>
                  </View>
                );
              })}
            </View>
            <View
              style={[
                styles.row,
                styles.justifyStretch,
                { marginTop: 11, marginHorizontal: 40 },
              ]}
            >
              {config?.tabs.map((t, ti) => {
                return (
                  <View
                    key={"tabText" + (ti + 1)}
                    style={[
                      {
                        width: 100 / (config?.tabs.length + 1.4) + "%",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.text,
                        styles.font18,
                        (ti == data?.selectedTab && styles.textPrimary) ||
                          styles.textGrey1,
                        styles.textAlignCenter,
                      ]}
                    >
                      {t.label}
                    </Text>
                  </View>
                );
              })}
            </View>
            <View
              style={[
                styles.row,
                styles.justifyCenter,
                { marginTop: 21, marginHorizontal: 16 },
              ]}
            >
              <View
                style={[
                  {
                    width: "100%",
                    borderColor: "#EBF1F6",
                    borderWidth: 0.25,
                    borderRadius: 20,
                  },
                  styles.bgWhite,
                ]}
              >
                <View style={[styles.row, styles.justifyStretch]}>
                  <View style={[styles.flex, styles.flexGrow]}>
                    <View
                      style={[
                        styles.row,
                        styles.justifyStart,
                        styles.bgDarkGrey,
                        {
                          borderRightWidth: 0,
                          borderTopRightRadius: 50,
                          borderBottomRightRadius: 50,
                          marginTop: 30,
                          paddingVertical: 8,
                          paddingHorizontal: 40,
                          width: "fit-content",
                        },
                      ]}
                    >
                      <View>
                        <View style={[styles.row, styles.justifyStart]}>
                          <Text
                            style={[styles.text, styles.textRed, styles.font14]}
                          >
                            Stage {data?.selectedTab + 1}
                          </Text>
                        </View>
                        <View style={[styles.row, styles.justifyStart]}>
                          <Text
                            style={[
                              styles.text,
                              styles.textPrimary,
                              styles.font20,
                              styles.textMedium,
                            ]}
                          >
                            {data?.label}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.row,
                        styles.justifyStart,
                        { marginTop: 30, marginHorizontal: 48 },
                      ]}
                    >
                      <Text
                        style={[
                          styles.text,
                          styles.font20,
                          { color: "#434040" },
                        ]}
                      >
                        {data?.text}
                      </Text>
                    </View>
                    {data?.steps?.map((s, si) => {
                      return (
                        <View
                          key={"step" + (si + 1)}
                          style={[
                            styles.row,
                            styles.justifyStart,
                            {
                              marginVertical: 30,
                              marginHorizontal: 48,
                              marginLeft: 93,
                              width: "100%",
                            },
                          ]}
                        >
                          <View style={{ width: "100%" }}>
                            <View style={[styles.row, styles.justifyStart]}>
                              <View
                                style={[
                                  styles.alignItemsCenter,
                                  styles.justifyCenter,
                                  {
                                    backgroundColor: data.stepsSelector?.[
                                      `step${si + 1}`
                                    ]
                                      ? "#72E241"
                                      : "#FFFFFF",
                                    borderRadius: "50%",
                                    height: 17,
                                    width: 17,
                                    borderWidth: 1,
                                    borderColor: "#C7C1C1",
                                  },
                                ]}
                              >
                                <View
                                  style={[
                                    {
                                      backgroundColor: data.stepsSelector?.[
                                        `step${si + 1}`
                                      ]
                                        ? "#FFFf"
                                        : "#C7C1C1",
                                      borderRadius: "50%",
                                      height: 4,
                                      width: 4,
                                    },
                                  ]}
                                ></View>
                              </View>
                              <View style={[{ marginLeft: 16 }]}>
                                <Text
                                  style={[
                                    styles.text,
                                    styles.font20,
                                    styles.fontBold,
                                  ]}
                                >
                                  {s.title}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.row,
                                styles.justifyStart,
                                { marginTop: 5, marginLeft: 16 },
                                styles.breakAll,
                              ]}
                            >
                              <Text
                                style={[
                                  styles.text,
                                  styles.font18,
                                  { marginLeft: 16, width: "84%" },
                                ]}
                              >
                                {s.description}
                              </Text>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                    {data?.selectedTab === 0 && (
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate("SignInBase", {
                            from: "userHome",
                          });
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            width: "40%",
                            backgroundColor: "#0C5397",
                            marginHorizontal: 48,
                            borderRadius: 10,
                            padding: 10,
                            marginBottom: 15,
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: "#F99746",
                              width: "50%",
                              height: "100%",
                              left: 0,
                              position: "absolute",
                              borderRadius: 10,
                              top: 0,
                            }}
                          ></View>
                          <Text
                            style={[
                              styles.text,
                              styles.font18,
                              {
                                color: "#fff",
                                zIndex: 999,
                                fontWeight: "900",
                                marginRight: 15,
                              },
                            ]}
                          >
                            More about Yourself
                          </Text>
                          <FontAwesome
                            name="arrow-circle-right"
                            size={24}
                            color="#fff"
                          />
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>

                  <View
                    style={[
                      { width: "33%" },
                      styles.alignItemsCenter,
                      styles.justifyCenter,
                      { padding: 30 },
                    ]}
                  >
                    <img
                      src={require("../assets/dashboard/home/img_2.svg")}
                      style={{ width: "100%" }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default UserHome;
