import { FontAwesome, Ionicons } from "@expo/vector-icons";
import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "../styles/styles";

class Sidebar extends React.Component {
  constructor(props) {
    super();
    this.state = {
      menu: [
        {
          label: "",
          iconType: "ion",
          icon: "home-outline",
        },
        {
          label: "",
          iconType: "fa",
          icon: "user-o",
        },
        {
          label: "",
          iconType: "fa",
          icon: "university",
        },
        {
          label: "",
          iconType: "fa",
          icon: "heart-o",
        },
        {
          label: "",
          iconType: "fa",
          icon: "university",
        },
      ],
      selectedIndex: 0,
    };
  }
  componentDidMount() {
    if (this.props.menu) {
      this.setState({ menu: this.props.menu });
    }
  }
  clicked = (i) => {
    this.setState({ selectedIndex: i });
    if (this.props.onSelect) {
      this.props.onSelect({ index: i });
    }
  };
  render() {
    return (
      <View
        style={[
          styles.flex,
          {
            position: "fixed",
            background: "#0C5397",
            width: 80,
            height: "98%",
            margin: 8,
            borderRadius: 20,
            paddingVertical: 14,
          },
        ]}
      >
        <View style={[styles.justifyCenter, styles.row, { paddingBottom: 32 }]}>
          <TouchableOpacity onPress={this.clicked}>
            <Ionicons name="menu-outline" size={38} color="white" />
          </TouchableOpacity>
        </View>
        {this.state.menu.map((m, i) => {
          return (
            <TouchableOpacity
              key={"sidebaroption" + (i + 1)}
              style={[
                this.state.selectedIndex == i
                  ? styles.justifyStart
                  : styles.justifyCenter,
                styles.row,
                {
                  paddingVertical: 12,
                  marginLeft: this.state.selectedIndex == i ? "30%" : 0,
                },
                styles.cursorPointer,
              ]}
              onPress={() => {
                this.clicked(i);
              }}
            >
              {this.state.selectedIndex == i ? (
                <View
                  style={[
                    {
                      borderTopLeftRadius: "50%",
                      borderBottomLeftRadius: "50%",
                      backgroundColor: "#F5F5F5",
                      padding: 12,
                    },
                    styles.cursorPointer,
                  ]}
                >
                  {(m.iconType == "ion" && (
                    <Ionicons name={m.icon} size={24} color="#5479CB" />
                  )) ||
                    (m.iconType == "fa" && (
                      <FontAwesome name={m.icon} size={24} color="#5479CB" />
                    )) || (
                      <Text
                        style={[
                          styles.text,
                          styles.font14,
                          styles.fontBold,
                          { color: "#5479CB" },
                        ]}
                      >
                        {m.label}
                      </Text>
                    )}
                </View>
              ) : (
                (m.iconType == "ion" && (
                  <Ionicons name={m.icon} size={24} color="#FFFFFF" />
                )) ||
                (m.iconType == "fa" && (
                  <FontAwesome name={m.icon} size={24} color="#FFFFFF" />
                )) || (
                  <Text
                    style={[
                      styles.text,
                      styles.font14,
                      styles.fontBold,
                      { color: "#FFFFFF" },
                    ]}
                  >
                    {m.label}
                  </Text>
                )
              )}
              {this.state.selectedIndex == i ? (
                <View
                  style={[
                    { backgroundColor: "#F5F5F5" },
                    styles.flexGrow,
                    styles.cursorPointer,
                  ]}
                ></View>
              ) : (
                <View></View>
              )}
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }
}

export default Sidebar;
