import React from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import Input from "../../components/Input";
import apisrv from "../../service/ApiService";
import { styles } from "../../styles/styles";

class EditConfig extends React.Component {
  constructor(props) {
    super();
    this.state = {
      data: null,
      fieldData: null,
      parent: null,
      selectedField: 0,
    };
  }
  componentDidMount() {
    let fieldData = this.setFieldData(
      this.props.appData.selectedSection.fields[0]
    );
    this.setState({
      data: this.props.appData.selectedSection,
      fieldData: fieldData,
    });
    this.getConfig();
  }
  getConfig = () => {
    let req = {
      userId: this.props.appData.userData.userId,
      pageName: this.props.appData?.selectedSection?.pageName,
    };

    apisrv.callApi({
      endPoint: "get-page-config",
      request: req,
      onSuccess: (res) => {
        console.log(res);
      },
    });
  };
  setFieldData = (inputData) => {
    return {
      data: [
        {
          type: "select",
          key: "type",
          options: [
            {
              label: "Field Type",
              value: "",
            },
            {
              label: "Input Grey",
              value: "inputBlack",
            },
            {
              label: "Input Blue",
              value: "inputBlue",
            },
            {
              label: "Select",
              value: "select",
            },
            {
              label: "Radio",
              value: "radio",
            },
            {
              label: "Checkbox",
              value: "checkbox",
            },
          ],
          value: inputData?.type,
        },
        {
          type: "inputBlack",
          label: "Placeholder",
          key: "placeholder",
          value: inputData?.placeholder,
        },
        {
          type: "inputBlack",
          label: "Label",
          key: "label",
          value: inputData?.label,
        },
        {
          type: "inputBlack",
          label: "Key",
          key: "key",
          value: inputData?.key,
        },
      ],
      options: inputData?.options || [],
    };
  };
  saveData = () => {
    let req = {
      pageName: this.state.data.pageName,
      sectionName: this.state.data.name,
      data: this.state.data.fields,
    };
    apisrv.callApi({
      endPoint: "save-page-config",
      request: req,
      onSuccess: (res) => {
        console.log(res);
      },
    });
  };
  addOption = () => {
    let stateObj = this.state;
    stateObj.fieldData.options.push({
      label: "",
      value: "",
    });
    this.setState(stateObj);
  };
  render() {
    return (
      <View style={[styles.padding16, styles.bgWhite, { height: "100%" }]}>
        <View
          style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}
        >
          <Text style={[styles.textPrimary, styles.title]}>
            {this.state.data?.name} Section configuration
          </Text>
        </View>
        <View
          style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}
        >
          <View
            style={[
              {
                width: "15%",
                fontSize: 22,
                borderColor: "#434040",
                borderWidth: 0.5,
                borderRadius: 10,
                height: "85vh",
              },
              styles.padding8,
            ]}
          >
            <View
              style={[
                styles.row,
                styles.justifyCenter,
                { paddingVertical: 18 },
              ]}
            >
              <Text style={[styles.textRed, styles.font18, styles.fontBold]}>
                {this.state.data?.name} Section fields
              </Text>
            </View>
            {(this.state?.data?.fields || []).map((f, fi) => {
              return (
                <View
                  key={"fMenu" + (fi + 1)}
                  style={[
                    styles.row,
                    styles.justifyCenter,
                    (this.state.selectedField == fi && styles.bgDarkBlue) ||
                      styles.bgWhite,
                    { paddingVertical: 12, borderRadius: 10 },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() => {
                      let fieldData = this.setFieldData(f);
                      this.setState({
                        selectedField: fi,
                        fieldData: fieldData,
                      });
                    }}
                  >
                    <Text
                      style={[
                        styles.text,
                        (this.state.selectedField == fi && styles.textWhite) ||
                          styles.textPrimary,
                        styles.font18,
                      ]}
                    >
                      {f.label || f.placeholder || f.key}
                    </Text>
                  </TouchableOpacity>
                </View>
              );
            })}
            <View
              style={[
                styles.row,
                styles.justifyCenter,
                { paddingVertical: 18 },
                styles.brTop,
              ]}
            >
              <TouchableOpacity
                style={[styles.btnWhite, styles.boxShadow1]}
                onPress={() => {
                  let stateObj = this.state;
                  stateObj.fieldData.options.push({
                    label: "",
                    value: "",
                  });
                  this.setState(stateObj);
                }}
              >
                <Text
                  style={[{ fontSize: 13 }, styles.textPrimary, styles.text]}
                >
                  Add New
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={[styles.flex, styles.flexGrow, { marginHorizontal: 6 }]}>
            {this.state.fieldData && (
              <View
                style={[
                  styles.row,
                  styles.justifyStart,
                  styles.pPage,
                  { paddingVertical: 32 },
                ]}
              >
                <View
                  style={[
                    styles.boxShadow1,
                    { padding: 16, width: "100%", borderRadius: 5 },
                  ]}
                >
                  <View
                    style={[styles.row, styles.justifyStart, styles.brBotttom]}
                  >
                    <Text style={[styles.text, { fontSize: 20 }]}>
                      Field Config
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStretch,
                      { paddingVertical: 16 },
                    ]}
                  >
                    {(this.state.fieldData?.data || []).map((f, i) => {
                      return (
                        <View
                          key={"field" + (i + 1)}
                          style={[styles.mh, styles.flex, styles.flexGrow]}
                        >
                          <Input
                            data={f}
                            onChange={(data) => {
                              let stateObj = this.state;
                              stateObj.fieldData.data[i].value = data.value;
                              this.setState(stateObj);
                            }}
                          />
                        </View>
                      );
                    })}
                  </View>
                  {(this.state.fieldData?.options || []).length ? (
                    <View
                      style={[
                        styles.row,
                        styles.justifyStretch,
                        styles.brBotttom,
                        { paddingVertical: 6 },
                      ]}
                    >
                      <View>
                        <Text style={[styles.text, { fontSize: 20 }]}>
                          Options
                        </Text>
                      </View>
                      <TouchableOpacity
                        style={[
                          {
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "#0C5397",
                            paddingVertical: 10,
                            paddingHorizontal: 14,
                          },
                          styles.bgWhite,
                        ]}
                        onPress={this.addOption}
                      >
                        <Text
                          style={[
                            styles.textPrimary,
                            styles.fontBold,
                            styles.font14,
                            styles.text,
                          ]}
                        >
                          Add Option
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <View></View>
                  )}
                  {(this.state.fieldData?.options || []).length ? (
                    (this.state.fieldData?.options || []).map((o, i) => {
                      return (
                        <View
                          key={"option" + (i + 1)}
                          style={[
                            styles.row,
                            styles.justifyStretch,
                            { paddingVertical: 16 },
                          ]}
                        >
                          <View
                            style={[styles.mh, styles.flex, styles.flexGrow]}
                          >
                            <Text
                              style={[
                                styles.textGrey,
                                {
                                  fontSize: 12,
                                  marginLeft: 24,
                                  position: "absolute",
                                  marginTop: -11,
                                  background: "#ffffff",
                                  padding: 4,
                                },
                              ]}
                            >
                              Label
                            </Text>
                            <TextInput
                              style={[styles.inputText]}
                              value={o.label}
                              keyboardType={"default"}
                              onChangeText={(text) => {
                                let stateObj = this.state;
                                stateObj.fieldData.options[i].label = text;
                                this.setState(stateObj);
                              }}
                            />
                          </View>
                          <View
                            style={[styles.mh, styles.flex, styles.flexGrow]}
                          >
                            <Text
                              style={[
                                styles.textGrey,
                                {
                                  fontSize: 12,
                                  marginLeft: 24,
                                  position: "absolute",
                                  marginTop: -11,
                                  background: "#ffffff",
                                  padding: 4,
                                },
                              ]}
                            >
                              Value
                            </Text>
                            <TextInput
                              style={[styles.inputText]}
                              value={o.value}
                              keyboardType={"default"}
                              onChangeText={(text) => {
                                let stateObj = this.state;
                                stateObj.fieldData.options[i].value = text;
                                this.setState(stateObj);
                              }}
                            />
                          </View>
                          <View>
                            <TouchableOpacity
                              style={[styles.btnWhite]}
                              onPress={() => {
                                let stateObj = this.state;
                                stateObj.fieldData.options =
                                  stateObj.fieldData.options
                                    .slice(0, i)
                                    .concat(
                                      stateObj.fieldData.options.slice(
                                        i + 1,
                                        this.state.fieldData.options.length
                                      )
                                    );
                                stateObj.data.fields[
                                  stateObj.selectedField
                                ].options = stateObj.fieldData.options;
                                this.setState(stateObj);
                              }}
                            >
                              <Text
                                style={[
                                  { fontSize: 13 },
                                  styles.textRed,
                                  styles.text,
                                ]}
                              >
                                Remove
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      );
                    })
                  ) : (
                    <View></View>
                  )}
                  <View
                    style={[
                      styles.row,
                      styles.justifyEnd,
                      { paddingVertical: 8 },
                    ]}
                  >
                    <TouchableOpacity
                      style={[styles.btnGrey, styles.mh]}
                      onPress={() => {
                        this.props.navigation.navigate("Admin");
                      }}
                    >
                      <Text
                        style={[
                          { color: "#ffffff" },
                          styles.fontBold,
                          styles.font20,
                          styles.text,
                        ]}
                      >
                        Back
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[styles.btnPrimary]}
                      onPress={this.saveData}
                    >
                      <Text
                        style={[
                          { color: "#ffffff" },
                          styles.fontBold,
                          styles.font20,
                          styles.text,
                        ]}
                      >
                        Save
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appData: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateData: async (parameter, callback) => {
      await dispatch(parameter);
      if (callback) {
        callback();
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditConfig);
