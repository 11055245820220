import React from "react";
import { Text, View } from "react-native";
import { styles } from "../styles/styles";

class Footer extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
		// this.setState({sections: this.props.appData.pageConfig});
	}
	render() {
		return (
			<View
				style={[
					styles.row,
					styles.bgDarkBlue,
					{ paddingHorizontal: "8%", paddingVertical: "1.75%" },
					styles.alignItemsCenter,
					styles.justifyStretch,
				]}
			>
				<View>
					<Text style={[styles.text, styles.font20, styles.textWhite]}>
						©2021 mycareerpathshala. All rights reserved.
					</Text>
				</View>
				<View>
					<View style={[styles.row, styles.justifyEnd]}>
						<Text
							style={[
								styles.text,
								styles.font20,
								styles.textWhite,
								{ marginRight: 30 },
							]}
						>
							Terms & Condition
						</Text>
						<Text style={[styles.text, styles.font20, styles.textWhite]}>
							Privacy Policy
						</Text>
					</View>
				</View>
			</View>
		);
	}
}

export default Footer;
