import React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import Sidebar from "../Sidebar";
import Masters from "./Masters";
import Pages from "./Pages";

class Admin extends React.Component {
	constructor(props) {
		super();
		this.state = {
			selectedIndex: 0,
			menu: [
				{
					label: "Pages",
				},
				{
					label: "Masters",
				},
			],
		};
	}
	componentDidMount() {
		this.setState({ sections: this.props.appData.pageConfig });
	}
	setOption(data) {
		this.setState({ selectedIndex: data.index });
	}
	render() {
		return (
			<View>
				<Sidebar
					onSelect={i => {
						this.setOption(i);
					}}
					menu={this.state.menu}
				/>
				<View style={{ marginLeft: 10 }}>
					{(this.state.selectedIndex == 0 && (
						<Pages navigation={this.props.navigation} />
					)) ||
						(this.state.selectedIndex == 1 && (
							<Masters navigation={this.props.navigation} />
						))}
				</View>
			</View>
		);
	}
}

const mapStateToProps = state => {
	return {
		appData: state,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateData: async (parameter, callback) => {
			await dispatch(parameter);
			if (callback) {
				callback();
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
