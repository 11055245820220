import { Ionicons } from "@expo/vector-icons";
import { Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

const Wishlist = ({ onPress, count = 0 }) => {
	return (
		<TouchableOpacity onPress={onPress}>
			<Ionicons
				name="heart-outline"
				size={45}
				color="black"
				style={{ marginLeft: 20 }}
			/>
			<View
				style={{
					height: 25,
					width: 25,
					position: "absolute",
					backgroundColor: "#B51700",
					top: -2,
					right: -10,
					borderRadius: "50%",
				}}
			>
				<Text
					style={{
						textAlign: "center",
						marginTop: 3,
						color: "white",
						fontWeight: 500,
					}}
				>
					{count}
				</Text>
			</View>
		</TouchableOpacity>
	);
};

export default Wishlist;
