import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import WishList from "../components/Wishlist";
import { styles } from "../styles/styles";

const CollegeResultNavbar = ({ colleges, navigation }) => {
	return (
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				paddingTop: 20,
				paddingBottom: 10,
				paddingHorizontal: "2%",
				backgroundColor: "white",
			}}
		>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					flex: 1,
				}}
			>
				<img
					src={require("../assets/home_page/logo1.png")}
					style={{ width: "25%" }}
					resizemode="contain"
				/>
				<TouchableOpacity
					style={[
						styles.justifyCenter,
						styles.brPrimary,
						styles.boxShadow2,
						{ height: 46, marginLeft: 32, paddingHorizontal: 16 },
						styles.alignItemsCenter,
					]}
					onPress={() => {
						navigation.navigate("Home");
					}}
				>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							styles.alignCenter,
							styles.alignItemsCenter,
						]}
					>
						<img
							src={require("../assets/mbbs/home_icon.svg")}
							style={{ height: 25, marginRight: 16 }}
						/>
						<Text style={[styles.text, styles.textPrimary, styles.font20]}>
							HOME
						</Text>
					</View>
				</TouchableOpacity>
			</View>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<TouchableOpacity
					style={[
						styles.justifyCenter,
						styles.brPrimary,
						styles.boxShadow2,
						{ height: 46, marginLeft: 32, paddingHorizontal: 16 },
						styles.alignItemsCenter,
					]}
					onPress={() => {
						navigation.navigate("SignInBase");
					}}
				>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							styles.alignCenter,
							styles.alignItemsCenter,
						]}
					>
						<Text style={[styles.text, styles.textPrimary, styles.font20]}>
							Save my preferences
						</Text>
					</View>
				</TouchableOpacity>
				<WishList
					count={colleges.filter(c => c.isShortlisted === true).length}
					onPress={() => navigation.navigate("SignInBase")}
				/>
			</View>
		</View>
	);
};

export default CollegeResultNavbar;
