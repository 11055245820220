import { UPLOAD_DOCUMENT } from "../constants";

const initialState = {
  data: null,
};

export const documentReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPLOAD_DOCUMENT.REQUEST:
      return {
        ...state,
        isUploadingDocuemnt: true,
      };
    case UPLOAD_DOCUMENT.SUCCESS:
      return {
        ...state,
        isUploadingDocuemnt: false,
        colleges: { ...state.success, ...payload.success },
      };
    case UPLOAD_DOCUMENT.FAILURE:
      return {
        ...state,
        isUploadingDocuemnt: false,
      };
    default:
      return state;
  }
};
