import axios from "axios";
import { SAVE_MBBS_FILTER } from "../constants";

const base_url = "https://hammerhead-app-ikpjl.ondigitalocean.app";

export const saveMbbsFilter = body => async dispatch => {
	try {
		dispatch({ type: SAVE_MBBS_FILTER.REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const { data } = await axios.post(
			`${base_url}/save-mbbs-filter`,
			body,
			config
		);

		dispatch({
			type: SAVE_MBBS_FILTER.SUCCESS,
			payload: {
				success: "success",
				data: data,
			},
		});
	} catch (error) {
		dispatch({ type: SAVE_MBBS_FILTER.FAILURE, payload: error.message });
	}
};
