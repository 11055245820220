import { FETCH_USER_DATA, USER_LOGIN, USER_SIGN_UP } from "../constants";

const initialState = {
	isFetchingUserDetails: false,
	isUserLoggingIn: false,
	userDetails: {},
};

export const userReducers = (state = initialState, { type, payload }) => {
	switch (type) {
		case USER_SIGN_UP.REQUEST:
			return {
				...state,
				isUserLoggingIn: true,
			};
		case USER_SIGN_UP.SUCCESS:
			return {
				...state,
				isUserLoggingIn: false,
				userDetails: { userId: payload?.data?.userId },
			};
		case USER_SIGN_UP.FAILURE:
			return {
				...state,
				isUserLoggingIn: false,
			};

		case USER_LOGIN.REQUEST:
			return {
				...state,
				isUserLoggingIn: true,
			};
		case USER_LOGIN.SUCCESS:
			return {
				...state,
				isUserLoggingIn: false,
				userDetails: payload?.data,
			};
		case USER_LOGIN.FAILURE:
			return {
				...state,
				isUserLoggingIn: false,
			};

		case FETCH_USER_DATA.REQUEST:
			return {
				...state,
				isUserLoggingIn: true,
			};
		case FETCH_USER_DATA.SUCCESS:
			return {
				...state,
				isUserLoggingIn: false,
				userDetails: { ...state.userDetails, ...payload.data },
			};
		case FETCH_USER_DATA.FAILURE:
			return {
				...state,
				isUserLoggingIn: false,
			};

		default:
			return state;
	}
};
