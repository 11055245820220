import { FontAwesome5 } from "@expo/vector-icons";
import {
	TextPropTypes,
	ViewPropTypes,
} from "deprecated-react-native-prop-types";
import find from "lodash/find";
import get from "lodash/get";
import reject from "lodash/reject";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	FlatList,
	Text,
	TouchableOpacity,
	TouchableWithoutFeedback,
	UIManager,
	View,
} from "react-native";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

import styles, { colorPack } from "./styles";

// set UIManager LayoutAnimationEnabledExperimental
if (UIManager.setLayoutAnimationEnabledExperimental) {
	UIManager.setLayoutAnimationEnabledExperimental(true);
}

export default class MultiSelect extends Component {
	static propTypes = {
		single: PropTypes.bool,
		selectedItems: PropTypes.array,
		items: PropTypes.array.isRequired,
		uniqueKey: PropTypes.string,
		tagBorderColor: PropTypes.string,
		tagTextColor: PropTypes.string,
		tagContainerStyle: ViewPropTypes.style,
		fontFamily: PropTypes.string,
		tagRemoveIconColor: PropTypes.string,
		onSelectedItemsChange: PropTypes.func.isRequired,
		selectedItemFontFamily: PropTypes.string,
		selectedItemTextColor: PropTypes.string,
		itemFontFamily: PropTypes.string,
		itemTextColor: PropTypes.string,
		itemFontSize: PropTypes.number,
		selectedItemIconColor: PropTypes.string,
		searchInputPlaceholderText: PropTypes.string,
		searchInputStyle: PropTypes.object,
		selectText: PropTypes.string,
		styleDropdownMenu: ViewPropTypes.style,
		styleDropdownMenuSubsection: ViewPropTypes.style,
		styleInputGroup: ViewPropTypes.style,
		styleItemsContainer: ViewPropTypes.style,
		styleListContainer: ViewPropTypes.style,
		styleMainWrapper: ViewPropTypes.style,
		styleRowList: ViewPropTypes.style,
		styleSelectorContainer: ViewPropTypes.style,
		styleTextDropdown: TextPropTypes.style,
		styleTextDropdownSelected: TextPropTypes.style,
		styleTextTag: TextPropTypes.style,
		styleIndicator: ViewPropTypes.style,
		altFontFamily: PropTypes.string,
		hideSubmitButton: PropTypes.bool,
		hideDropdown: PropTypes.bool,
		submitButtonColor: PropTypes.string,
		submitButtonText: PropTypes.string,
		textColor: PropTypes.string,
		fontSize: PropTypes.number,
		fixedHeight: PropTypes.bool,
		hideTags: PropTypes.bool,
		canAddItems: PropTypes.bool,
		onAddItem: PropTypes.func,
		onChangeInput: PropTypes.func,
		displayKey: PropTypes.string,
		textInputProps: PropTypes.object,
		flatListProps: PropTypes.object,
		filterMethod: PropTypes.string,
		onClearSelector: PropTypes.func,
		onToggleList: PropTypes.func,
		removeSelected: PropTypes.bool,
		noItemsText: PropTypes.string,
	};

	static defaultProps = {
		single: false,
		selectedItems: [],
		uniqueKey: "_id",
		tagBorderColor: colorPack.primary,
		tagTextColor: colorPack.primary,
		fontFamily: "",
		tagRemoveIconColor: colorPack.danger,
		selectedItemFontFamily: "",
		selectedItemTextColor: colorPack.primary,
		itemFontFamily: "",
		itemTextColor: colorPack.textPrimary,
		itemFontSize: 16,
		selectedItemIconColor: colorPack.primary,
		searchInputPlaceholderText: "Search",
		searchInputStyle: { color: colorPack.textPrimary },
		textColor: colorPack.textPrimary,
		selectText: "Select",
		altFontFamily: "",
		hideSubmitButton: false,
		submitButtonColor: "#CCC",
		submitButtonText: "Submit",
		fontSize: 14,
		fixedHeight: false,
		hideTags: false,
		hideDropdown: false,
		onChangeInput: () => {},
		displayKey: "name",
		canAddItems: false,
		onAddItem: () => {},
		onClearSelector: () => {},
		onToggleList: () => {},
		removeSelected: false,
		noItemsText: "No items to display.",
	};

	constructor(props) {
		super(props);
		this.state = {
			selector: false,
			searchTerm: "",
		};
	}

	shouldComponentUpdate() {
		return true;
	}

	getSelectedItemsExt = optionalSelctedItems => (
		<View
			style={{
				flexDirection: "row",
				flexWrap: "wrap",
			}}
		>
			{this._displaySelectedItems(optionalSelctedItems)}
		</View>
	);

	_onChangeInput = value => {
		const { onChangeInput } = this.props;
		if (onChangeInput) {
			onChangeInput(value);
		}
		this.setState({ searchTerm: value });
	};

	_getSelectLabel = () => {
		const { selectText, single, selectedItems, displayKey } = this.props;
		if (!selectedItems || selectedItems.length === 0) {
			return selectText;
		}
		if (single) {
			const item = selectedItems[0];
			const foundItem = this._findItem(item);
			return get(foundItem, displayKey) || selectText;
		}
		return this._displaySelectedItems();
	};

	_findItem = itemKey => {
		const { items, uniqueKey } = this.props;
		return find(items, singleItem => singleItem[uniqueKey] === itemKey) || {};
	};

	_displaySelectedItems = optionalSelectedItems => {
		const {
			fontFamily,
			tagContainerStyle,
			tagRemoveIconColor,
			tagBorderColor,
			uniqueKey,
			tagTextColor,
			selectedItems,
			displayKey,
			styleTextTag,
		} = this.props;
		const actualSelectedItems = optionalSelectedItems || selectedItems;
		return actualSelectedItems.map(singleSelectedItem => {
			const item = this._findItem(singleSelectedItem);
			if (!item[displayKey]) return null;
			return (
				<View
					style={[
						styles.selectedItem,
						{
							width: item[displayKey].length * 8 + 60,
							justifyContent: "center",
							// height: 40,
							borderColor: tagBorderColor,
						},
						tagContainerStyle || {},
					]}
					key={item[uniqueKey]}
				>
					<Text
						style={[
							{
								flex: 1,
								color: tagTextColor,
								fontSize: 15,
							},
							styleTextTag && styleTextTag,
							fontFamily ? { fontFamily } : {},
						]}
						numberOfLines={1}
					>
						{item[displayKey]}
					</Text>
					<TouchableOpacity
						onPress={() => {
							this._removeItem(item);
						}}
					>
						<Icon
							name="close-circle"
							style={{
								color: tagRemoveIconColor,
								fontSize: 22,
								marginLeft: 10,
							}}
						/>
					</TouchableOpacity>
				</View>
			);
		});
	};

	_removeItem = item => {
		const { uniqueKey, selectedItems, onSelectedItemsChange } = this.props;
		const newItems = reject(
			selectedItems,
			singleItem => item[uniqueKey] === singleItem
		);
		// broadcast new selected items state to parent component
		onSelectedItemsChange(newItems);
	};

	_removeAllItems = () => {
		const { onSelectedItemsChange } = this.props;
		// broadcast new selected items state to parent component
		onSelectedItemsChange([]);
	};

	_clearSelector = () => {
		this.setState({
			selector: false,
		});
	};

	_clearSelectorCallback = () => {
		const { onClearSelector } = this.props;
		this._clearSelector();
		if (onClearSelector) {
			onClearSelector();
		}
	};

	_toggleSelector = () => {
		const { onToggleList } = this.props;
		this.setState({
			selector: !this.state.selector,
		});
		if (onToggleList) {
			onToggleList();
		}
	};

	_clearSearchTerm = () => {
		this.setState({
			searchTerm: "",
		});
	};

	_submitSelection = () => {
		this._toggleSelector();
		// reset searchTerm
		this._clearSearchTerm();
	};

	_itemSelected = item => {
		const { uniqueKey, selectedItems } = this.props;
		return selectedItems.indexOf(item[uniqueKey]) !== -1;
	};

	_addItem = () => {
		const {
			uniqueKey,
			items,
			selectedItems,
			onSelectedItemsChange,
			onAddItem,
		} = this.props;
		let newItems = [];
		let newSelectedItems = [];
		const newItemName = this.state.searchTerm;
		if (newItemName) {
			const newItemId = newItemName
				.split(" ")
				.filter(word => word.length)
				.join("-");
			newItems = [...items, { [uniqueKey]: newItemId, name: newItemName }];
			newSelectedItems = [...selectedItems, newItemId];
			onAddItem(newItems);
			onSelectedItemsChange(newSelectedItems);
			this._clearSearchTerm();
		}
	};

	_toggleItem = item => {
		const { single, uniqueKey, selectedItems, onSelectedItemsChange } =
			this.props;
		if (single) {
			this._submitSelection();
			onSelectedItemsChange([item[uniqueKey]]);
		} else {
			const status = this._itemSelected(item);
			let newItems = [];
			if (status) {
				newItems = reject(
					selectedItems,
					singleItem => item[uniqueKey] === singleItem
				);
			} else {
				newItems = [...selectedItems, item[uniqueKey]];
			}
			// broadcast new selected items state to parent component
			onSelectedItemsChange(newItems);
		}
	};

	_itemStyle = item => {
		const {
			selectedItemFontFamily,
			selectedItemTextColor,
			itemFontFamily,
			itemTextColor,
			itemFontSize,
		} = this.props;
		const isSelected = this._itemSelected(item);
		const fontFamily = {};
		if (isSelected && selectedItemFontFamily) {
			fontFamily.fontFamily = selectedItemFontFamily;
		} else if (!isSelected && itemFontFamily) {
			fontFamily.fontFamily = itemFontFamily;
		}
		const color = isSelected
			? { color: selectedItemTextColor }
			: { color: itemTextColor };
		return {
			...fontFamily,
			...color,
			fontSize: itemFontSize,
		};
	};

	_getRow = item => {
		const { selectedItemIconColor, displayKey, styleRowList } = this.props;
		return (
			<TouchableOpacity
				disabled={item.disabled}
				onPress={() => this._toggleItem(item)}
				style={[
					styleRowList && styleRowList,
					{
						paddingLeft: 20,
						paddingRight: 20,
						backgroundColor: "#fff",
					},
				]}
			>
				<View style={{}}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<Text
							style={[
								{
									flex: 1,
									fontSize: 16,
									paddingTop: 5,
									paddingBottom: 5,
								},
								this._itemStyle(item),
								item.disabled ? { color: "grey" } : {},
							]}
						>
							{item[displayKey]}
						</Text>
						{this._itemSelected(item) ? (
							<Icon
								name="check"
								style={{
									fontSize: 20,
									zIndex: 9999,
									color: selectedItemIconColor,
								}}
							/>
						) : null}
					</View>
				</View>
			</TouchableOpacity>
		);
	};

	_getRowNew = item => (
		<TouchableOpacity
			disabled={item.disabled}
			onPress={() => this._addItem(item)}
			style={{ paddingLeft: 20, paddingRight: 20 }}
		>
			<View>
				<View style={{ flexDirection: "row", alignItems: "center" }}>
					<Text
						style={[
							{
								flex: 1,
								fontSize: 16,
								paddingTop: 5,
								paddingBottom: 5,
							},
							this._itemStyle(item),
							item.disabled ? { color: "grey" } : {},
						]}
					>
						Add {item.name} (tap or press return)
					</Text>
				</View>
			</View>
		</TouchableOpacity>
	);

	_filterItems = searchTerm => {
		switch (this.props.filterMethod) {
			case "full":
				return this._filterItemsFull(searchTerm);
			default:
				return this._filterItemsPartial(searchTerm);
		}
	};

	_filterItemsPartial = searchTerm => {
		const { items, displayKey } = this.props;
		const filteredItems = [];
		items.forEach(item => {
			const parts = searchTerm.trim().split(/[ \-:]+/);
			const regex = new RegExp(`(${parts.join("|")})`, "ig");
			if (regex.test(get(item, displayKey))) {
				filteredItems.push(item);
			}
		});
		return filteredItems;
	};

	_filterItemsFull = searchTerm => {
		const { items, displayKey } = this.props;
		const filteredItems = [];
		items.forEach(item => {
			if (
				item[displayKey]
					.toLowerCase()
					.indexOf(searchTerm.trim().toLowerCase()) >= 0
			) {
				filteredItems.push(item);
			}
		});
		return filteredItems;
	};

	_renderItems = () => {
		const {
			canAddItems,
			items,
			fontFamily,
			uniqueKey,
			selectedItems,
			flatListProps,
			styleListContainer,
			removeSelected,
			noItemsText,
		} = this.props;
		const { searchTerm } = this.state;
		let component = null;
		// If searchTerm matches an item in the list, we should not add a new
		// element to the list.
		let searchTermMatch;
		let itemList;
		let addItemRow;
		let renderItems = searchTerm ? this._filterItems(searchTerm) : items;
		// Filtering already selected items
		if (removeSelected) {
			renderItems = renderItems.filter(
				item => !selectedItems.includes(item[uniqueKey])
			);
		}
		if (renderItems.length) {
			itemList = (
				<FlatList
					data={renderItems}
					extraData={selectedItems}
					keyExtractor={(item, index) => index.toString()}
					listKey={item => item[uniqueKey]}
					renderItem={rowData => this._getRow(rowData.item)}
					{...flatListProps}
					nestedScrollEnabled
				/>
			);
			searchTermMatch = renderItems.filter(
				item => item.name === searchTerm
			).length;
		} else if (!canAddItems) {
			itemList = (
				<View style={{ flexDirection: "row", alignItems: "center" }}>
					<Text
						style={[
							{
								flex: 1,
								marginTop: 20,
								textAlign: "center",
								color: colorPack.danger,
							},
							fontFamily ? { fontFamily } : {},
						]}
					>
						{noItemsText}
					</Text>
				</View>
			);
		}

		if (canAddItems && !searchTermMatch && searchTerm.length) {
			addItemRow = this._getRowNew({ name: searchTerm });
		}
		component = (
			<View
				style={[
					{
						borderWidth: 1,
						position: "relative",
						width: "100%",
						top: -15,
						zIndex: 99999,
					},
					styleListContainer && styleListContainer,
				]}
			>
				{itemList}
			</View>
		);
		return component;
	};

	render() {
		const {
			selectedItems,
			single,
			fontFamily,
			altFontFamily,
			searchInputPlaceholderText,
			searchInputStyle,
			styleDropdownMenu,
			styleDropdownMenuSubsection,
			hideSubmitButton,
			hideDropdown,
			submitButtonColor,
			submitButtonText,
			fontSize,
			textColor,
			fixedHeight,
			hideTags,
			textInputProps,
			styleMainWrapper,
			styleInputGroup,
			styleItemsContainer,
			styleSelectorContainer,
			styleTextDropdown,
			styleTextDropdownSelected,
			styleIndicator,
		} = this.props;
		const { searchTerm, selector } = this.state;
		return (
			<View
				style={[
					{
						flexDirection: "column",
					} &&
						styleMainWrapper &&
						styleMainWrapper,
				]}
			>
				<View>
					<View
						style={[
							styles.dropdownView,
							styleDropdownMenu && styleDropdownMenu,
						]}
					>
						<View
							style={[
								styles.subSection,
								{
									borderColor: "#707070",
									borderWidth: 1,
									borderRadius: 5,
									color: "#505050",
									backgroundColor: "#ffffff",
									fontSize: 18,
								},
								styleDropdownMenuSubsection && styleDropdownMenuSubsection,
							]}
						>
							<TouchableWithoutFeedback onPress={this._toggleSelector}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										alignItems: "center",
										padding: 12,
									}}
								>
									<Text
										style={
											!selectedItems || selectedItems.length === 0
												? [
														{
															flex: 1,
															fontSize: fontSize || 18,
															color: textColor || "#505050",
														},
														styleTextDropdown && styleTextDropdown,
														altFontFamily
															? { fontFamily: altFontFamily }
															: fontFamily
															? { fontFamily }
															: {},
												  ]
												: [
														{
															flex: 1,
															fontSize: fontSize || 18,
															color: textColor || "#505050",
														},
														styleTextDropdownSelected &&
															styleTextDropdownSelected,
												  ]
										}
										numberOfLines={1}
									>
										{this._getSelectLabel()}
									</Text>
									<FontAwesome5
										name={hideSubmitButton ? "sort-down" : "sort-down"}
										style={[styles.indicator, styleIndicator && styleIndicator]}
									/>
								</View>
							</TouchableWithoutFeedback>
						</View>
					</View>
					{selector ? (
						<View
							style={{
								flexDirection: "column",
								backgroundColor: "#fafafa",
								zIndex: 9999,
							}}
						>
							<View
								style={[
									{ width: "60%", zIndex: 9999 },
									styleItemsContainer && styleItemsContainer,
								]}
							>
								{this._renderItems()}
							</View>
						</View>
					) : null}
				</View>
			</View>
		);
	}
}
