import React, { useEffect, useState } from "react";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { fetchFiltersData, saveUserFilters } from "../actions/appActions";
import { saveMbbsFilter } from "../actions/mbbsActions";
import Footer from "../components/Footer";
import HomeNavbar from "../components/HomeNavbar";
import Input from "../components/Input";
import { styles } from "../styles/styles";

const finalDataFlds = [
	{
		label: "Select Country",
		key: "countries",
	},
	{
		label: "Session",
		key: "session",
	},
	{
		label: "12th Passing Year",
		key: "yearOfPassing",
	},
	{
		label: "Your Budget",
		key: "budget",
	},
	{
		label: "NEET Score",
		key: "neet",
	},
];

const dollartoInr = 78;

const MbbsFilter = ({ navigation }) => {
	const dispatch = useDispatch();

	const [steps, setSteps] = useState([
		{
			label: "Select Country",
			isSelected: true,
			isComplete: false,
			isOptional: false,
		},
		{
			label: "Session",
			isSelected: false,
			isOptional: false,
			data: null,
		},
		{
			label: "12th Passing Year",
			isSelected: false,
			isOptional: false,
			data: null,
		},
		{
			label: "Your Budget",
			isSelected: false,
			isOptional: true,
		},
		{
			label: "NEET Score",
			isSelected: false,
			isOptional: true,
			data: null,
		},
	]);

	const allCountries = useSelector(state => state.appData.countries);
	const mbbsFilters = useSelector(state => state.appData.mbbsFilters);
	const intakeMonthYear = useSelector(state => state.appData.intakeMonthYear);
	const mbbsUserFilters = useSelector(state => state.appData.mbbsUserFilters);

	const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
	const [buttonActive, setButtonActive] = useState(false);
	const [finalData, setFinalData] = useState({});
	const [selectionComplete, setSelectionComplete] = useState(false);

	const [countries, setCountries] = useState([]);

	useEffect(() => {
		dispatch(fetchFiltersData("all", "mbbsFilters"));
		dispatch(fetchFiltersData("all", "countries"));
		dispatch(fetchFiltersData("collegeFilters", "intakeMonthYear"));
	}, []);

	useEffect(() => {
		setCountries(
			allCountries?.map(c => {
				if (mbbsUserFilters?.countries?.includes(c?.value)) {
					return { ...c, isSelected: true };
				}
				return { ...c, isSelected: false };
			})
		);
	}, [allCountries, mbbsUserFilters]);

	useEffect(() => {
		const stepObj = steps;
		if (mbbsUserFilters?.session) {
			stepObj[1].data = mbbsUserFilters?.session;
			stepObj[1].isComplete = true;
		}
		if (mbbsUserFilters?.yearOfPassing) {
			stepObj[2].data = mbbsUserFilters?.yearOfPassing;
			stepObj[2].isComplete = true;
		}
		if (mbbsUserFilters?.budget) {
			stepObj[3].data = mbbsUserFilters?.budget;
			stepObj[3].isComplete = true;
		}
		if (mbbsUserFilters?.neet) {
			stepObj[4].data = mbbsUserFilters?.neet;
			stepObj[4].isComplete = true;
		}
		setSteps(stepObj);
	}, [mbbsUserFilters, allCountries]);

	useEffect(() => {
		if (selectedSessionIndex === 0) {
			setButtonActive(countries?.filter(c => c.isSelected).length > 0);
		}
		if (0 < selectedSessionIndex < 5 && steps[selectedSessionIndex]?.data) {
			setButtonActive(true);
		}
	});

	const setCountry = (data, i) => {
		const country = countries;
		country[i].isSelected = data?.value;
		setButtonActive(countries.filter(c => c.isSelected).length > 0);
		setCountries(country);
	};

	const setSession = data => {
		let stepsObj = steps;
		stepsObj[selectedSessionIndex].data = data.value;
		setButtonActive(data.value ? true : false);
		setSteps([...stepsObj]);
	};

	const setNeetData = (data, key) => {
		let stepsObj = steps;
		if (!stepsObj[selectedSessionIndex].data) {
			stepsObj[selectedSessionIndex].data = {};
		}
		stepsObj[selectedSessionIndex].data[key] = data.value;
		if (
			stepsObj[selectedSessionIndex].data?.year &&
			stepsObj[selectedSessionIndex].data?.category &&
			stepsObj[selectedSessionIndex].data?.score
		) {
			setButtonActive(true);
		} else {
			setButtonActive(false);
		}
		setSteps([...stepsObj]);
	};

	const submitStep = (isSkipped = false) => {
		let stepObj = steps;
		if (!buttonActive && !isSkipped) {
			return;
		}
		if (isSkipped) {
			stepObj[selectedSessionIndex].data = null;
		}
		if (!isSkipped) {
			stepObj[selectedSessionIndex].isComplete = true;
		}
		stepObj[selectedSessionIndex].isSelected = false;
		setSelectedSessionIndex(selectedSessionIndex + 1);
		if (selectedSessionIndex + 1 < stepObj.length) {
			stepObj[selectedSessionIndex + 1].isSelected = true;
			stepObj[selectedSessionIndex + 1].isComplete = false;
		} else {
			setSelectionComplete(true);
		}
		setButtonActive(false);
		if (selectedSessionIndex === 4) {
			setComplete(isSkipped);
		}
		setSteps(stepObj);
	};

	const setComplete = (isSkipped = false) => {
		let filtersData = {
			countries: countries.filter(c => c.isSelected).map(c => c.value),
			session: steps[1].data,
			yearOfPassing: steps[2].data,
			budget: steps[3].data,
			neet: steps[4].data,
		};
		dispatch(saveUserFilters("mbbsUserFilters", filtersData));

		let stepObj = steps;
		let data = {
			countries: countries
				.filter(c => c.isSelected)
				.map(c => c.label)
				.join(" | "),
			session: steps[1].data,
			yearOfPassing: steps[2].data,
			budget:
				(steps[3]?.data &&
					"$" + steps[3]?.data + " | ₹ " + steps[3].data * dollartoInr) ||
				null,
			neet: `${(steps[4]?.data?.year && steps[4]?.data?.year + " | ") || ""}${
				(steps[4]?.data?.category && steps[4]?.data?.category + " | ") || ""
			}${steps[4]?.data?.score || ""}`,
		};
		if (stepObj[selectedSessionIndex]) {
			stepObj[selectedSessionIndex].isSelected = false;
			if (!isSkipped) stepObj[selectedSessionIndex].isComplete = true;
		}
		setFinalData(data);
		setSelectedSessionIndex(-1);
		setSelectionComplete(true);
		setSteps(stepObj);
	};

	const selectSection = i => {
		let stepObj = steps;
		setSelectedSessionIndex(i);
		stepObj[i].isSelected = true;
		stepObj[i].isComplete = false;
		setSelectionComplete(false);
		setButtonActive(false);
		setSteps(stepObj);
	};

	const getResults = () => {
		let data = {
			countries: countries.filter(c => c.isSelected).map(c => c.value),
			session: steps[1]?.data,
			yearOfPassing: steps[2]?.data,
			budget: steps[3]?.data,
			neetYr: steps[4]?.data?.year,
			neetCategory: steps[4]?.data?.category,
			neetScore: steps[4]?.data?.score,
		};
		dispatch(saveMbbsFilter(data));
		navigation.navigate("MbbsResults", data);
	};

	const neetPassingMarks =
		mbbsFilters &&
		(mbbsFilters[4]?.neetPassingCriteria[steps[4]?.data?.year]?.[
			steps[4]?.data?.category
		] ||
			0);

	return (
		<View
			style={
				([styles.bgWhite],
				{ display: "flex", justifyContent: "space-between", flex: 1 })
			}
		>
			<HomeNavbar navigation={navigation} />
			<View style={{ display: "flex", flex: 1, flexDirection: "row" }}>
				<ImageBackground
					source={require("../assets/mbbs/page_background.svg")}
					style={[{ width: "100%" }]}
					resizeMode="cover"
				>
					{/* Steps  Cards */}
					{!selectionComplete && (
						<View style={[styles.row, styles.justifyCenter, { marginTop: 70 }]}>
							{steps.map((s, i) => {
								return (
									<View
										key={s.label}
										style={[
											(s.isComplete && styles.cardCompleted) ||
												(s.isSelected && styles.cardSelected) ||
												styles.cardGrey,
											{
												paddingHorizontal: 24,
												borderRadius: 5,
												paddingVertical: 14,
												marginRight: 27,
												width: 200,
											},
											styles.justifyStretch,
										]}
									>
										<View style={[styles.row, styles.justifyCenter]}>
											<Text
												style={[
													styles.text,
													styles.font18,
													styles.fontBold,
													styles.textAlignCenter,
													{
														color:
															(s.isComplete && "#FFFFFF") ||
															(s.isSelected && "#0C5397") ||
															"#707070",
													},
												]}
											>
												{s.label}
											</Text>
										</View>
										<View
											style={[
												styles.row,
												styles.justifyCenter,
												{ marginTop: 15 },
											]}
										>
											<Text
												style={[
													styles.text,
													styles.font18,
													styles.textAlignCenter,
													{
														color:
															(s.isComplete && "#FFFFFF") ||
															(s.isSelected && "#0C5397") ||
															"#707070",
													},
												]}
											>
												Step {i + 1}
											</Text>
										</View>
									</View>
								);
							})}
						</View>
					)}
					{/* Steps */}
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							{ marginTop: 126, marginBottom: 126 },
						]}
					>
						{/* Step 1 */}
						{selectedSessionIndex == 0 && (
							<View
								style={[
									styles.cardSelected,
									{
										paddingVertical: 18,
										paddingHorizontal: 22,
										maxWidth: "66%",
										borderRadius: 5,
									},
								]}
							>
								<View style={[styles.row, styles.justifyCenter]}>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											styles.textPrimary,
										]}
									>
										Select Country
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											{ fontSize: 25 },
										]}
									>
										Choose your preferred destination to study MBBS in abroad
									</Text>
								</View>
								<View style={[styles.row, styles.justifyStart, styles.wrap]}>
									{countries?.map((c, i) => {
										return (
											<View
												key={c.label}
												style={[
													{
														borderColor: "#505050",
														borderRadius: 5,
														borderWidth: 1,
														paddingHorizontal: 24,
														paddingVertical: 14,
														margin: 10,
														width: "23%",
													},
												]}
											>
												<Input
													data={{ type: "checkbox", label: c.label }}
													value={c?.isSelected}
													onChange={value => {
														setCountry(value, i);
													}}
												/>
											</View>
										);
									})}
								</View>
								<View
									style={[styles.row, styles.justifyStretch, { marginTop: 28 }]}
								>
									<View>
										<Text
											style={[
												styles.textPrimary,
												styles.fontBold,
												styles.font18,
											]}
										>
											Select at least 1 or at most all.
										</Text>
									</View>
									<TouchableOpacity
										style={[
											(buttonActive && styles.btnPrimary) || styles.btnGrey,
											{ height: 68 },
											styles.justifyCenter,
										]}
										onPress={() => submitStep()}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font18,
											]}
										>
											NEXT
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						)}
						{/* Step 2 */}
						{selectedSessionIndex == 1 && (
							<View
								style={[
									styles.cardSelected,
									{
										paddingVertical: 18,
										paddingHorizontal: 22,
										width: "66%",
										borderRadius: 5,
									},
								]}
							>
								<View style={[styles.row, styles.justifyCenter]}>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											styles.textPrimary,
										]}
									>
										Session
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											{ fontSize: 25 },
										]}
									>
										Choose your session for enrollment
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<View style={{ width: "60%" }}>
										<Input
											data={{
												type: "select",
												options: intakeMonthYear,
												value: steps?.[1]?.data,
											}}
											onChange={data => {
												setSession(data);
											}}
										/>
									</View>
								</View>
								<View
									style={[styles.row, styles.justifyEnd, { marginTop: 28 }]}
								>
									<TouchableOpacity
										style={[
											(buttonActive && styles.btnPrimary) || styles.btnGrey,
											{ height: 68 },
											styles.justifyCenter,
										]}
										onPress={() => submitStep()}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font18,
											]}
										>
											NEXT
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						)}
						{/* Step 3 */}
						{selectedSessionIndex == 2 && (
							<View
								style={[
									styles.cardSelected,
									{
										paddingVertical: 18,
										paddingHorizontal: 22,
										width: "66%",
										borderRadius: 5,
									},
								]}
							>
								<View style={[styles.row, styles.justifyCenter]}>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											styles.textPrimary,
										]}
									>
										12th Passing Year
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											{ fontSize: 25 },
										]}
									>
										Choose your 12th passing year
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<View style={{ width: "60%" }}>
										<Input
											data={{
												type: "select",
												options: mbbsFilters[1]?.yearOfPassing,
												value: steps[2]?.data,
											}}
											onChange={data => {
												setSession(data);
											}}
										/>
									</View>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyEnd,
										styles.alignItemsCenter,
										{ marginTop: 28 },
									]}
								>
									{buttonActive && (
										<TouchableOpacity
											style={[styles.justifyCenter, { marginRight: 40 }]}
											onPress={() => setComplete(false)}
										>
											<Text
												style={[
													styles.textPrimary,
													styles.fontBold,
													styles.font18,
												]}
											>
												Match College
											</Text>
										</TouchableOpacity>
									)}
									<TouchableOpacity
										style={[
											(buttonActive && styles.btnPrimary) || styles.btnGrey,
											{ height: 68 },
											styles.justifyCenter,
										]}
										onPress={() => submitStep()}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font18,
											]}
										>
											NEXT
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						)}
						{/* Step 4 */}
						{selectedSessionIndex == 3 && (
							<View
								style={[
									styles.cardSelected,
									{
										paddingVertical: 18,
										paddingHorizontal: 22,
										width: "66%",
										borderRadius: 5,
									},
								]}
							>
								<View style={[styles.row, styles.justifyEnd]}>
									<TouchableOpacity
										onPress={() => {
											submitStep(true);
										}}
									>
										<Text
											style={[
												styles.text,
												styles.font18,
												styles.fontBold,
												styles.textPrimary,
											]}
										>
											Skip this question
										</Text>
									</TouchableOpacity>
								</View>
								<View style={[styles.row, styles.justifyCenter]}>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											styles.textPrimary,
										]}
									>
										Your Budget
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											{ fontSize: 25 },
										]}
									>
										What is your budget for tuition fees?
									</Text>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.textAlignCenter,
											{ fontSize: 25 },
										]}
									>
										(per year)
									</Text>
								</View>
								<View
									style={[styles.row, styles.justifyCenter, { marginTop: 5 }]}
								>
									<View style={{ width: "80%" }}>
										<Input
											data={{ type: "slider" }}
											onChange={data => {
												setSession(data);
											}}
											value={steps[3]?.data}
										/>
									</View>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginBottom: 10 },
									]}
								>
									<Text style={[styles.text, styles.font22]}>
										Upto $ {steps && steps[3] && steps[3].data}
									</Text>
								</View>
								<View style={[styles.row, styles.justifyCenter]}>
									<Text style={[styles.text, styles.font22]}>
										₹ {steps[3].data ? steps[3].data * dollartoInr : ""}
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyEnd,
										styles.alignItemsCenter,
										{ marginTop: 28 },
									]}
								>
									{buttonActive && (
										<TouchableOpacity
											style={[styles.justifyCenter, { marginRight: 40 }]}
											onPress={() => setComplete(false)}
										>
											<Text
												style={[
													styles.textPrimary,
													styles.fontBold,
													styles.font18,
												]}
											>
												Match College
											</Text>
										</TouchableOpacity>
									)}
									<TouchableOpacity
										style={[
											(buttonActive && styles.btnPrimary) || styles.btnGrey,
											{ height: 68 },
											styles.justifyCenter,
										]}
										onPress={() => submitStep()}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font18,
											]}
										>
											NEXT
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						)}
						{/* Step 5*/}
						{selectedSessionIndex == 4 && (
							<View
								style={[
									styles.cardSelected,
									{
										paddingVertical: 18,
										paddingHorizontal: 22,
										width: "66%",
										borderRadius: 5,
									},
								]}
							>
								<View style={[styles.row, styles.justifyEnd]}>
									<TouchableOpacity
										onPress={() => {
											submitStep(true);
										}}
									>
										<Text
											style={[
												styles.text,
												styles.font18,
												styles.fontBold,
												styles.textPrimary,
											]}
										>
											Skip this question
										</Text>
									</TouchableOpacity>
								</View>
								<View style={[styles.row, styles.justifyCenter]}>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											styles.textPrimary,
										]}
									>
										NEET Score
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											{ fontSize: 25 },
										]}
									>
										Fill your NEET Score
									</Text>
								</View>
								<View style={[styles.row, styles.justifyStart]}>
									<Text styles={[styles.text, styles.font18]}>
										1. Select year in which you have given NEET
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyStart,
										{ marginBottom: 30, marginTop: 15 },
									]}
								>
									<Input
										data={{
											type: "select",
											options: mbbsFilters[2]?.mbbsYear,
											value: steps[4]?.data?.year,
										}}
										onChange={data => {
											setNeetData(data, "year");
										}}
									/>
								</View>
								<View style={[styles.row, styles.justifyStart]}>
									<Text styles={[styles.text, styles.font18]}>
										2. Select your category
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyStart,
										{ marginBottom: 30, marginTop: 15 },
									]}
								>
									<Input
										data={{
											type: "select",
											options: mbbsFilters[3]?.mbbsCategory,
											value: steps[4]?.data?.category,
										}}
										onChange={data => {
											setNeetData(data, "category");
										}}
									/>
								</View>
								<View style={[styles.row, styles.justifyStart]}>
									<Text styles={[styles.text, styles.font18]}>
										3. Your NEET Score
									</Text>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyStart,
										{ marginBottom: 30, marginTop: 15 },
									]}
								>
									<Input
										data={{
											type: "inputBlack",
											placeholder: "Fill your Score",
											min: neetPassingMarks,
											max: 720,
											errorMsg: `Qualifying marks is ${neetPassingMarks}. Highest is 720.`,
											value: steps[4]?.data?.score,
										}}
										onChange={data => {
											setNeetData(data, "score");
										}}
									/>
								</View>
								<View
									style={[
										styles.row,
										styles.justifyEnd,
										styles.alignItemsCenter,
										{ marginTop: 28 },
									]}
								>
									<TouchableOpacity
										style={[
											(buttonActive && styles.btnPrimary) || styles.btnGrey,
											{ height: 68 },
											styles.justifyCenter,
										]}
										onPress={() => submitStep()}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font18,
											]}
										>
											NEXT
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						)}
						{/* Selection Complete */}
						{selectionComplete && (
							<View
								style={[
									styles.cardSelected,
									{
										paddingVertical: 18,
										paddingHorizontal: 28,
										width: "66%",
										borderRadius: 5,
									},
								]}
							>
								<View
									style={[
										styles.row,
										styles.justifyCenter,
										{ marginTop: 5, marginBottom: 47 },
									]}
								>
									<Text
										style={[
											styles.text,
											styles.font18,
											styles.fontBold,
											styles.textAlignCenter,
											{ fontSize: 25 },
										]}
									>
										My selected preferences
									</Text>
								</View>
								{finalDataFlds.map((f, i) => {
									return (
										<View
											key={f.key}
											style={[
												styles.row,
												styles.justifyStretch,
												styles.alignItemsCenter,
												{
													backgroundColor: "#DDDDDD36",
													paddingHorizontal: 26,
													paddingVertical: 18,
													borderBottomColor: "#bbbbbb",
													borderBottomWidth: 1,
												},
											]}
										>
											<View>
												<View
													style={[
														styles.row,
														styles.justifyStart,
														{ marginBottom: 22 },
													]}
												>
													<Text
														style={[
															styles.text,
															styles.textPrimary,
															styles.font18,
															styles.fontBold,
														]}
													>
														{f.label}
													</Text>
												</View>
												<View
													style={[
														styles.row,
														styles.justifyStart,
														{ marginBottom: 22 },
													]}
												>
													<Text style={[styles.text, styles.font18]}>
														{finalData && finalData[f.key]}
													</Text>
												</View>
											</View>
											<View>
												{finalData && finalData[f.key] ? (
													<TouchableOpacity
														style={[styles.justifyCenter]}
														onPress={() => {
															selectSection(i);
														}}
													>
														<Text
															style={[
																styles.text,
																styles.textRed,
																styles.font18,
															]}
														>
															Change
														</Text>
													</TouchableOpacity>
												) : (
													<TouchableOpacity
														style={[styles.justifyCenter]}
														onPress={() => {
															selectSection(i);
														}}
													>
														<Text
															style={[
																styles.text,
																styles.textRed,
																styles.font18,
															]}
														>
															Add
														</Text>
													</TouchableOpacity>
												)}
											</View>
										</View>
									);
								})}
								<View
									style={[
										styles.row,
										styles.justifyEnd,
										styles.alignItemsCenter,
										{ marginTop: 28 },
									]}
								>
									<TouchableOpacity
										style={[
											styles.btnPrimary,
											{ height: 68 },
											styles.justifyCenter,
										]}
										onPress={getResults}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font18,
											]}
										>
											View my match
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						)}
					</View>
				</ImageBackground>
			</View>
			<Footer />
		</View>
	);
};

export default MbbsFilter;
