import React from "react";
import { Dimensions, Image, Text, TouchableOpacity, View } from "react-native";
import Footer from "../components/Footer";
import FooterInfo from "../components/FooterInfo";
import HomeNavbar from "../components/HomeNavbar";
import KeepMePostedEmail from "../components/KeepMePostedEmail";
import { styles } from "../styles/styles";

const win = Dimensions.get("window");

const OtherCourses = ({ navigation }) => {
	return (
		<View style={{ flex: 1, backgroundColor: "#fff" }}>
			<HomeNavbar navigation={navigation} />
			<View>
				<View style={{ backgroundColor: "#f4fcfc" }}>
					<Text
						style={[
							styles.textAlignCenter,
							styles.textRed,
							{ fontSize: 52, marginTop: 50 },
						]}
					>
						Match Universities
					</Text>
					<Text
						style={[
							styles.textAlignCenter,
							styles.textPrimary,
							{ fontSize: 52, fontWeight: 800 },
						]}
					>
						that are right for you
					</Text>
					<Text style={[styles.textAlignCenter, { fontSize: 32 }]}>
						Answer few question and our AI will do the rest
					</Text>
					<View style={{ marginTop: 30, alignItems: "center" }}>
						<TouchableOpacity
							style={[
								styles.btnRed,
								{
									height: 68,
									width: 200,
									justifyContent: "center",
									alignItems: "center",
								},
							]}
							onPress={() => {
								navigation.navigate("OtherCoursesFilter");
							}}
						>
							<Text
								style={[{ color: "#ffffff" }, styles.fontBold, styles.font20]}
							>
								Get Started
							</Text>
						</TouchableOpacity>
					</View>
					<Image
						source={require("../assets/othercourses/otherCoursesUni.svg")}
						style={{
							marginVertical: 50,
							minHeight: win.height * 0.5,
							minWidth: win.width * 0.8,
							marginHorizontal: "auto",
						}}
						resizeMode="contain"
					></Image>
				</View>
				<View style={{ backgroundColor: "#f0eceb" }}>
					<Image
						source={require("../assets/othercourses/otherCoursesEdu.svg")}
						style={{
							minWidth: win.width * 0.8,
							minHeight: win.height * 0.65,
							marginVertical: 70,
							marginHorizontal: "auto",
						}}
						resizeMode="contain"
					></Image>
				</View>
			</View>

			{/* email */}
			<KeepMePostedEmail />
			{/* countries */}
			<FooterInfo />
			{/* fOOTER */}
			<Footer />
			{/* </View> */}
		</View>
	);
};

export default OtherCourses;
