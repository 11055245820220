import { appReducers } from "./appReducers";
import { collegeReducers } from "./collegeReducers";
import { userReducers } from "./userReducers";
import { applicationReducers } from "./applicatonReducers";
import { documentReducers } from "./documentReducers";

export const rootReducers = {
  appData: appReducers,
  userData: userReducers,
  collegeData: collegeReducers,
  applicationData: applicationReducers,
  documentData: documentReducers,
};
