import React from "react";
import {
	Dimensions,
	ImageBackground,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { connect } from "react-redux";
import Footer from "../components/Footer";
import HomeNavbar from "../components/HomeNavbar";
import InputSuggestion from "../components/InputSuggestions";
import { styles } from "../styles/styles";

const win = Dimensions.get("window");

class MBBS extends React.Component {
	constructor(props) {
		super();
		this.state = {
			sections: [],
		};
	}
	componentDidMount() {
		// this.setState({sections: this.props.appData.pageConfig});
	}
	render() {
		return (
			<View style={{ flex: 1, backgroundColor: "#fff" }}>
				<HomeNavbar navigation={this.props.navigation} />
				<ImageBackground
					source={require("../assets/mbbs/university_background.svg")}
					style={[
						{
							width: win.width,
							flex: 1,
							backgroundColor: "rgba(0,0,0,0.5)",
						},
					]}
				>
					<View
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: "rgba(0,0,0,0.1)",
						}}
					/>
					<View
						style={{
							flexDirection: "row",
							alignSelf: "center",
							marginTop: "4%",
							marginBottom: "4%",
						}}
					>
						<Text style={[styles.textWhite, styles.font55, styles.text]}>
							Choose your preference{" "}
						</Text>
						<Text
							style={[
								styles.textOrange,
								styles.font55,
								styles.text,
								styles.fontBold,
							]}
						>
							for MBBS Abroad
						</Text>
					</View>
					<View
						style={[
							styles.row,
							styles.justifyCenter,
							styles.bgWhite,
							styles.pPage,
							{ paddingVertical: "2%", zIndex: 9999 },
						]}
					>
						<View style={{ width: "50%" }}>
							<View style={[styles.row, styles.justifyCenter, {}]}>
								<Text style={[styles.textRed, styles.font45, styles.text]}>
									I want to{" "}
								</Text>
								<Text
									style={[styles.textPrimary, styles.fontBold, styles.font45]}
								>
									explore the college
								</Text>
							</View>
							<View
								style={[styles.row, styles.justifyCenter, { marginTop: 21 }]}
							>
								<TouchableOpacity
									style={[styles.btnRed, { height: 68 }, styles.justifyCenter]}
									onPress={() => {
										this.props.navigation.navigate("MBBSFilter");
									}}
								>
									<Text
										style={[
											{ color: "#ffffff" },
											styles.fontBold,
											styles.font20,
										]}
									>
										Find my match
									</Text>
								</TouchableOpacity>
							</View>
						</View>
						<View style={{ width: "50%" }}>
							<View style={[styles.row, styles.justifyCenter, {}]}>
								<Text style={[styles.textRed, styles.font45, styles.text]}>
									I know the{" "}
								</Text>
								<Text
									style={[styles.textPrimary, styles.fontBold, styles.font45]}
								>
									University name
								</Text>
							</View>
							<View
								style={[
									styles.row,
									styles.justifyCenter,
									{ marginTop: 21, zIndex: 9999, backgroundColor: "#fff" },
								]}
							>
								<InputSuggestion
									onSuggestionTapped={value =>
										this.props.navigation.navigate("CollegeDetails", {
											courseId: value.courseId,
											type: "mbbs",
										})
									}
								/>
							</View>
						</View>
					</View>
					<View style={[styles.justifyCenter, { marginTop: "2%" }]}>
						<Text
							style={[
								{
									fontSize: 35,
									textAlign: "center",
									fontWeight: "bold",
									marginTop: "2%",
									paddingHorizontal: "15%",
								},
								styles.textMedium,
								styles.textWhite,
							]}
						>
							Simple can be harder than complex: You have to work hard to get
							your thinking clean to make it simple. But it’s worth it in the
							end because once you get there, you can move mountains.
						</Text>
						<Text
							style={[
								{
									fontSize: 35,
									textAlign: "justify",
									alignSelf: "flex-end",
									fontWeight: "bold",
									marginTop: 10,
									paddingHorizontal: "15%",
								},
								styles.textMedium,
								styles.textWhite,
							]}
						>
							— Steve Jobs
						</Text>
					</View>
				</ImageBackground>

				<Footer />
			</View>
		);
	}
}

const mapStateToProps = state => {
	return {
		appData: state,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateData: async (parameter, callback) => {
			await dispatch(parameter);
			if (callback) {
				callback();
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MBBS);
