import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getCollegeList } from "../actions/collegeActions";
import CollegeResultNavbar from "../components/CollegeResultNavbar";
import CollegeResultsList from "../components/CollegeResultsList";
import Footer from "../components/Footer";
import { styles } from "../styles/styles";

const MbbsResults = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const shortListedColleges = useSelector(
    (state) => state.appData.shortListedColleges
  );

  const collegesStore = useSelector((state) => state.collegeData.colleges);
  const paginationStore = useSelector((state) => state.collegeData.pagination);

  const [colleges, setColleges] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 12,
    currentPage: 1,
    totalItems: 0,
  });

  useEffect(() => {
    if (collegesStore) setColleges(collegesStore);
  }, [collegesStore]);

  useEffect(() => {
    if (paginationStore) setPagination(paginationStore);
  }, [paginationStore]);

  const filters = route?.params;

  if (typeof filters?.countries === "string") {
    filters.countries = filters?.countries.split(",");
  }
  if (filters?.neetYr && filters?.neetCategory && filters?.neetScore) {
    filters.neet = JSON.stringify({
      year: filters?.neetYr,
      score: filters?.neetScore,
      category: filters?.neetCategory,
    });
    delete filters.neetYr;
    delete filters?.neetCategory;
    delete filters?.neetScore;
  }

  useEffect(() => {
    const shortListedCollegesList = shortListedColleges?.map((c) => c.courseId);
    if (shortListedCollegesList) {
      const collegesObj = collegesStore?.map((college) => {
        if (shortListedCollegesList?.includes(college.courseId)) {
          return { ...college, isShortlisted: true };
        }
        return college;
      });
      setColleges(collegesObj);
    }
  }, [shortListedColleges, collegesStore]);

  useEffect(() => {
    getCollege();
  }, []);

  const getCollege = (currentPage = 1) => {
    let req = {
      ...filters,
      ...{
        userId: "US01",
        campusType: ["Boys", "Women", "Co-Ed"],
        campusLocation: ["Semi-Urban", "Urban", "Rural"],
        universityType: ["Public", "Private"],
        courseDuration: ["3-4", "5-7", "6-7", "4-5"],
        intake: [
          {
            month: "Jan",
            year: 2023,
          },
          {
            month: "Feb",
            year: 2023,
          },
          {
            month: "Mar",
            year: 2023,
          },
        ],
        neet: {
          year: filters?.neetYr,
          score: filters?.neetScore,
          category: filters?.neetCategory,
        },
        yearOfPassing: "2018",
        budget: {
          min: filters.budget,
          max: 50000,
        },
        pageNumber: currentPage || pagination?.currentPage,
        pageSize: pagination?.pageSize,
      },
    };
    dispatch(getCollegeList("get-mbbs-college", req));
  };

  const onPageChange = (data) => {
    setPagination({ ...pagination, currentPage: data.currentPage });
    getCollege(data.currentPage);
  };

  return (
    <View
      style={
        ([styles.bgWhite],
        { display: "flex", justifyContent: "space-between", flex: 1 })
      }
    >
      <CollegeResultNavbar colleges={colleges} navigation={navigation} />
      <CollegeResultsList
        type="mbbs"
        colleges={colleges}
        countries={filters?.countries}
        pagination={pagination}
        onPageChange={onPageChange}
        setColleges={setColleges}
        navigation={navigation}
      />
      <Footer />
    </View>
  );
};

export default MbbsResults;
