export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

export const createRequestTypes = (base) =>
  [REQUEST, SUCCESS, FAILURE].reduce(
    (types, type) => ({
      ...types,
      [type]: `${base}_${type}`,
    }),
    {}
  );

//User constants
export const USER_LOGIN = createRequestTypes("USER_LOGIN");
export const USER_SIGN_UP = createRequestTypes("USER_SIGN_UP");
export const FETCH_USER_DATA = createRequestTypes("FETCH_USER_DATA");

//App constants
export const FETCH_APP_CONFIG = createRequestTypes("FETCH_APP_CONFIG");
export const FETCH_FILTERS_DATA = createRequestTypes("FETCH_FILTERS_DATA");
export const SAVE_USER_FILTERS = createRequestTypes("SAVE_USER_FILTERS");

//College constants
export const GET_COLLEGE_LIST = createRequestTypes("GET_COLLEGE_LIST");
export const GET_COLLEGE_DETAILS = createRequestTypes("GET_COLLEGE_DETAILS");
export const GET_SHORTLISTED_COLLEGES = createRequestTypes(
  "GET_SHORTLISTED_COLLEGES"
);
export const GET_COLLEGE_FEES = createRequestTypes("GET_COLLEGE_FEES");

//Mbbs constants
export const SAVE_MBBS_FILTER = createRequestTypes("SAVE_MBBS_FILTER");

//Other courses constants
export const SAVE_OTHER_COURSES_FILTER = createRequestTypes(
  "SAVE_OTHER_COURSES_FILTER"
);

//Application Constants
export const FETCH_APPLICATION_DATA = createRequestTypes(
  "FETCH_APPLICATION_DATA"
);
export const UPLOAD_DOCUMENT = createRequestTypes("UPLOAD_DOCUMENT");

export const Visa = {
  colleges: [
    {
      action: "uploadDocument",
      applicationStatus: "INCOMPLETE",
      country: "China",
      collegeInfo: {
        courseId: "BANG001MBBS",
        courseName: "MBBS in Russia",
        intake: {
          month: 12,
          year: 2023,
        },
        logoImg: "https://www.dnmc.edu.bd/public/logo/logo.png",
        name: "Altai State Medical University",
        shortlistType: "mbbs",
      },
      dbId: 1,
      inStage: "Document Upload Pending",
      stageName: "pendingDocumentUpload",
      uploadDocument: [
        {
          description: "Upload your Aadhar card to verify your identity.",
          label: "Aadhar Card",
          sectionName: "Application",
          type: "file",
        },
        {
          description: "Upload your passport size photograph.",
          label: "Photo",
          sectionName: "Application",
          type: "file",
        },
      ],
    },
    {
      action: "redirect",
      applicationStatus: "INPROGRESS",
      country: "China",
      collegeInfo: {
        courseId: "BANG001MBBS",
        courseName: "MBBS in Ukraine",
        intake: {
          month: 9,
          year: 2023,
        },
        logoImg: "https://www.dnmc.edu.bd/public/logo/logo.png",
        name: "Bukivinian State National University",
        shortlistType: "mbbs",
      },
      dbId: 2,
      inStage: "Document Verification Pending",
      stageNamee: "verificationPending",
    },
    {
      action: null,
      applicationStatus: "INPROGRESS",
      country: "China",
      collegeInfo: {
        courseId: "BANG001MBBS",
        courseName: "MBBS in Russia",
        intake: {
          month: 12,
          year: 2023,
        },
        logoImg: "https://www.dnmc.edu.bd/public/logo/logo.png",
        name: "Altai State Medical University",
        shortlistType: "mbbs",
      },
      dbId: 3,
      conditionalOffer: {
        content:
          "<h3>Conditional Offer: Below criteria to be fulfilled.</h3><ul><li>ID Proof(Adhaar/ PAN Card/ Voter ID).</li><li>Passport.</li><li>Bank Statement.</li><li>Financial Statement</li></ul>",
      },
      inStage: "Interview Scheduled",
      stageName: "interviewScheduled",
    },
    {
      action: "conditionalOffer",
      applicationStatus: "COMPLETE",
      country: "China",
      collegeInfo: {
        courseName: "Bachelor of Science in Biomedical Engineering",
        intake: {
          month: 1,
          year: 2023,
        },
        logoImg: "https://www.dnmc.edu.bd/public/logo/logo.png",
        name: "Wright State University",
        shortlistType: "others",
      },

      dbId: 4,
      inStage: "Visa Approved",
      stageName: "visaApproved",
    },
  ],
  educationDetails: {
    country: "India",
    highestQualification: "H.S.C.",
    specialization: ["Physics", "Chemistry", "Biology"],
  },
  personalDetails: {
    city: "Mumbai",
    country: "India",
    emailId: "",
    gender: "Male",
    maritalStatus: "Unmarried",
    mobile: {
      code: "91",
      mobile: "8888899999",
    },
    name: "Kumar Avinash",
    nationality: "Indian",
    state: "Maharashtra",
    zipcode: "400092",
  },
  testDetails: [
    {
      score: 500,
      test: "NEET",
    },
  ],
};
