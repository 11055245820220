import _ from "lodash";
import React, { Component } from "react";
import {
	Platform,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import Icon from "react-native-vector-icons/AntDesign";
import { connect } from "react-redux";
import apisrv from "../service/ApiService";

class MyAutosuggest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			showAll: false,
			showSuggestion: false,
			data: this.props.data,
			suggestions: [],
			selected: false,
			inputFocusedSchool: false,
		};
		this.onChangeDebounce = _.debounce(this.onChangeDebounce, 500);
	}
	onChange = newValue => {
		const { id, onSuggestionSelected } = this.props;
		this.setState({
			value: newValue,
			showAll: false,
			selected: false,
			showSuggestion: newValue?.length ? true : false,
		});
		this.onChangeDebounce(newValue);
	};

	onChangeDebounce = value => {
		let req = {
			userId: this.props.appData.userData.userId,
			searchTerm: value,
		};

		if (value?.length) {
			apisrv.callApi({
				endPoint: "search-college-name",
				request: req,
				onSuccess: res => {
					if (res.colleges?.length > 0) {
						this.setState({ suggestions: res.colleges });
					}
				},
			});
		}

		// onSuggestionSelected(id, newValue);
	};
	onBlur = newValue => {
		const { id, onBlur } = this.props;
		// this.setState({
		//   showSuggestion: false,
		//   showAll: false,
		// });
	};

	onSuggestionSelected = newValue => {
		const { id, onSuggestionSelected } = this.props;
		this.setState(
			{
				value: newValue.name,
				showSuggestion: false,
				showAll: false,
				selected: true,
			},
			() => {
				this.props.onSuggestionTapped
					? this.props.onSuggestionTapped(newValue)
					: null;
			}
		);
		// ;
	};

	render() {
		const { suggestions } = this.state;
		const Touchable =
			Platform.OS === "android" ? TouchableOpacity : TouchableOpacity;
		return (
			<View
				style={{
					width: "80%",
					zIndex: 10000,
				}}
			>
				<View
					className="auto-suggestion"
					style={{
						flexDirection: "row",
						paddingRight: 35,
						borderColor: "#DEE2E6",
						borderWidth: 1.5,
						backgroundColor: "#DDDDDD",
						borderRadius: 50,
						alignItems: "center",
						maxWidth: 500,
					}}
				>
					<Icon
						style={{
							marginHorizontal: 15,
						}}
						name="search1"
						size={16}
						color="#333333"
					/>
					<TextInput
						style={[
							{
								paddingLeft: 10,
								zIndex: 9,
								width: "100%",
								fontSize: 14,
								height: 58,
								fontFamily: "Montserrat",
								color: "#343A40",
							},
							Platform.OS === "web" ? { outlineWidth: 0 } : null,
						]}
						placeholderStyle={{
							fontFamily: "Montserrat-Medium",
							fontSize: 12,
							color: "#6C757D",
							// borderColor: 'red',
						}}
						keyboardType={
							Platform.OS == "ios" ? "ascii-capable" : "visible-password"
						}
						label={""}
						dropDown={this.props.dropDown}
						placeholder={"Enter University Name"}
						onBlur={text => {
							this.onBlur(text);
						}}
						value={this.state.value}
						onChangeText={text => this.onChange(text)}
					/>
				</View>

				{this.state.showSuggestion && (
					<View
						style={{
							maxHeight: suggestions && !suggestions.length ? 0 : 250,
							borderWidth: suggestions && !suggestions.length ? 0 : 2,
							borderColor: "#E9ECEF",
							borderRadius: 12,
							position: "absolute",
							backgroundColor: "#fff",
							top: 60,
							zIndex: 999999,
							overflow: "hidden",
							width: "100%",
						}}
					>
						{suggestions.map((item, index) => (
							<View
								style={{
									width: "100%",
									zIndex: 99999,
									borderRadius: 12,
									backgroundColor: "#fff",
								}}
							>
								<Touchable
									onPress={() => {
										this.onSuggestionSelected(item);
									}}
									key={index}
									style={{
										borderRadius: 12,
										zIndex: 99999,
										backgroundColor: "#fff",
									}}
								>
									<View
										key={"citysug_" + index}
										style={{
											minHeight: 30,
											padding: 12,
											backgroundColor: "#fff",
											width: "100%",
											borderRadius: 12,
											zIndex: 999999,
										}}
									>
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												marginBottom: 5,
												zIndex: 999999,
												backgroundColor: "#fff",
											}}
										>
											<Text
												style={{
													color: "#343A40",
													fontFamily: "Montserrat-Bold",
													fontSize: 12,
													zIndex: 999999,
													backgroundColor: "#fff",
												}}
											>
												{item && item.name}
											</Text>
										</View>
									</View>
								</Touchable>
								<View
									style={{
										borderBottomColor: "#E9ECEF",
										borderBottomWidth: 1,
										marginHorizontal: 15,
										borderRadius: 12,
									}}
								/>
							</View>
						))}
					</View>
				)}
			</View>
		);
	}
}

const mapStateToProps = state => {
	return {
		appData: state,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateData: async (parameter, callback) => {
			await dispatch(parameter);
			if (callback) {
				callback();
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAutosuggest);
