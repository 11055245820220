import React from "react";
import { Text, View } from "react-native";
import { styles } from "../../styles/styles";

const ShortlistedCollegeMsg = ({ message }) => {
	return (
		<View
			style={[styles.row, styles.justifyCenter, { paddingHorizontal: "8%" }]}
		>
			<View
				style={[
					{
						paddingVertical: 30,
						paddingHorizontal: 60,
						backgroundColor: "#DCDDFF",
						borderColor: "#A6E1FC",
						borderWidth: 1,
						borderRadius: 10,
					},
				]}
			>
				<Text style={[styles.text, styles.font25, styles.textPrimary]}>
					{message}
				</Text>
			</View>
		</View>
	);
};

export default ShortlistedCollegeMsg;
