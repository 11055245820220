import axios from "axios";
import { FETCH_APPLICATION_DATA, UPLOAD_DOCUMENT } from "../constants";

const base_url = "https://hammerhead-app-ikpjl.ondigitalocean.app";

export const getUserApplicationData = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_APPLICATION_DATA.REQUEST });

    const { data } = await axios.post(`${base_url}//user/application`);

    dispatch({
      type: FETCH_APPLICATION_DATA.SUCCESS,
      payload: {
        success: "success",
        data: data,
      },
    });
  } catch (error) {
    dispatch({ type: FETCH_APPLICATION_DATA.FAILURE, payload: error.message });
  }
};

export const uploadDocuments =
  (file, userId, docSection, docLabel) => async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_DOCUMENT.REQUEST });
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const body = new FormData();
      body.append("file", file);
      body.append("userId", userId);
      body.append("docSection", docSection);
      body.append("docLabel", docLabel);

      const { data } = await axios.get(
        `${base_url}/user/docs/upload`,
        body,
        config
      );

      dispatch({
        type: UPLOAD_DOCUMENT.SUCCESS,
        payload: {
          success: "success",
          data: data,
        },
      });
    } catch (error) {
      dispatch({ type: UPLOAD_DOCUMENT.FAILURE, payload: error.message });
    }
  };
