import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import Icon from "react-native-vector-icons/AntDesign";
import { useDispatch } from "react-redux";
import { saveUserFilters } from "../actions/appActions";
import { styles } from "../styles/styles";
import Pagination from "./Pagination";
import MyTooltip from "./ToolTip";

const dollartoInr = 78;

const CollegeResultsList = ({
	type,
	colleges,
	countries,
	pagination,
	onPageChange,
	setColleges,
	navigation,
}) => {
	const dispatch = useDispatch();
	const markAsShortlisted = c => {
		const collegesObj = colleges.map(college => {
			if (college.courseId === c.courseId) {
				return { ...college, isShortlisted: !college.isShortlisted };
			}
			return college;
		});

		dispatch(
			saveUserFilters(
				"shortListedColleges",
				collegesObj
					.filter(c => c.isShortlisted === true)
					.map(c => {
						return { courseId: c.courseId, shortlistType: type };
					})
			)
		);
		setColleges(collegesObj);
	};

	return (
		<View style={{ display: "flex", flex: 1, flexDirection: "row" }}>
			<ImageBackground
				source={require("../assets/mbbs/page_background.svg")}
				style={[{ width: "100vw", paddingHorizontal: "14%" }]}
				imageStyle={{ opacity: 0.3 }}
				resizeMode="cover"
			>
				<View style={[styles.row, styles.justifyStart, { marginTop: 30 }]}>
					<Text style={[styles.text, styles.font55]}>
						{type === "mbbs" ? "MBBS Abroad" : "Courses"} in{" "}
						{(countries || []).join(", ")}
					</Text>
				</View>
				<View
					style={[
						styles.row,
						styles.justifyStart,
						{ marginTop: 16, marginBottom: 72 },
					]}
				>
					<TouchableOpacity
						onPress={() => {
							type === "mbbs"
								? navigation.navigate("MBBSFilter")
								: navigation.navigate("OtherCoursesFilter");
						}}
					>
						<Text
							style={[
								styles.text,
								styles.textPrimary,
								styles.fontBold,
								styles.font20,
							]}
						>
							Review my preferences
						</Text>
					</TouchableOpacity>
				</View>
				<View style={[styles.row, styles.brBotttom]}></View>
				<View
					style={[
						styles.row,
						styles.justifyStart,
						{ marginTop: 16, marginBottom: 36 },
					]}
				>
					<Text style={[styles.text, styles.font30]}>
						{pagination?.totalItems} colleges matching your preferences
					</Text>
				</View>
				<View
					style={[
						styles.row,
						styles.wrap,
						{ display: "flex", justifyContent: "space-evenly" },
					]}
				>
					{colleges?.map((c, i) => {
						return (
							<View
								key={c.courseId}
								style={[
									{
										boxShadow: "0px 1px 1px #00000029",
										margin: 12,
										padding: 15,
										width: 400,
										borderRadius: 5,
									},
									styles.bgWhite,
								]}
							>
								<ImageBackground
									source={{ uri: c.bgImg }}
									style={[
										styles.row,
										{
											height: 250,
											display: "flex",
											padding: 10,
											justifyContent: "space-between",
										},
									]}
								>
									<img
										src={c.logoImg}
										style={{
											height: 60,
											padding: 15,
											borderRadius: 50,
											backgroundColor: "white",
											alignSelf: "flex-end",
										}}
									/>
									<TouchableOpacity
										onPress={() => {
											markAsShortlisted(c);
										}}
									>
										{c?.isShortlisted ? (
											<Ionicons
												name="heart-sharp"
												size={40}
												style={{
													color: "#B51700",
												}}
											/>
										) : (
											<Ionicons
												name="heart-sharp"
												size={40}
												color="black"
												style={{ color: "white" }}
											/>
										)}
									</TouchableOpacity>
									{type === "others" && (
										<View
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<View
												style={{
													border: "2px solid #e8b9f1",
													borderRadius: 35,
													width: 45,
													height: 45,
												}}
											>
												<Text
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														flex: 1,
														fontSize: 18,
														textAlign: "center",
													}}
												>
													{c?.QSRanking}
												</Text>
											</View>
											<View>
												<Text
													style={{
														fontSize: 12,
														width: 70,
														textAlign: "center",
														color: "#e8b9f1",
													}}
												>
													QS World Ranking
												</Text>
											</View>
										</View>
									)}
								</ImageBackground>
								<View style={{ height: 125 }}>
									<View
										style={[styles.row, styles.justifyStart, { marginTop: 20 }]}
									>
										<TouchableOpacity
											style={{ flex: 1 }}
											onPress={() => {
												navigation.navigate("CollegeDetails", {
													courseId: c.courseId,
													type,
												});
											}}
										>
											<Text
												style={[
													styles.text,
													styles.textPrimary,
													styles.fontBold,
													{ fontSize: 22 },
												]}
											>
												{type === "mbbs" ? c?.name : c?.courseName}
											</Text>
										</TouchableOpacity>
									</View>
									<View
										style={[styles.row, styles.justifyStart, { marginTop: 6 }]}
									>
										<Text
											style={[styles.text, styles.textGrey1, { fontSize: 22 }]}
										>
											{c.address.city}, {c.address.country}
										</Text>
									</View>
								</View>
								<View style={{ height: 50 }}>
									<View
										style={[styles.row, styles.justifyStart, { marginTop: 16 }]}
									>
										<View
											style={[
												styles.bgGrey,
												{
													borderRadius: 25,
													paddingHorizontal: 8,
													paddingVertical: 16,
												},
											]}
										>
											<Text style={[styles.text, { fontSize: 18 }]}>
												{c.duration} years
											</Text>
										</View>
										<View
											style={[
												styles.bgGrey,
												{
													borderRadius: 25,
													paddingHorizontal: 8,
													paddingVertical: 16,
													marginLeft: 5,
												},
											]}
										>
											<Text style={[styles.text, { fontSize: 18 }]}>
												$ {c.fees} | ₹ {c.fees ? c.fees * dollartoInr : ""}
											</Text>
										</View>
										<View style={{ marginLeft: 10, alignSelf: "center" }}>
											<MyTooltip
												content={"Amount shown is annual figure"}
												renderComponent={() => {
													return (
														<View
															style={{
																width: 20,
																height: 20,
																borderRadius: 20,
																borderWidth: 1,
																alignItems: "center",
																justifyContent: "center",
															}}
														>
															<Icon name="info" color={"#000"} size={14} />
														</View>
													);
												}}
											/>
										</View>
									</View>
								</View>
								<View style={{ height: 100 }}>
									<View
										style={[
											styles.row,
											styles.justifyStretch,
											{ position: "absolute", bottom: 20, width: "95%" },
										]}
									>
										<TouchableOpacity
											style={[
												styles.bgRed,
												styles.justifyCenter,
												styles.textAlignCenter,
												styles.flex,
												styles.flexGrow,
												styles.boxShadow2,
												{
													marginRight: 16,
													borderRadius: 3,
													padding: 6,
													minHeight: 40,
												},
											]}
											onPress={() => {
												navigation.navigate("CollegeDetails", {
													courseId: c.courseId,
													type,
												});
											}}
										>
											<Text
												style={[
													styles.text,
													styles.textWhite,
													styles.fontBold,
													{ fontSize: 14 },
												]}
											>
												View College
											</Text>
										</TouchableOpacity>
										<TouchableOpacity
											style={[
												styles.bgPrimary,
												styles.justifyCenter,
												styles.textAlignCenter,
												styles.flex,
												styles.flexGrow,
												styles.boxShadow2,
												{ borderRadius: 3, padding: 6, minHeight: 40 },
											]}
											onPress={() => {
												navigation.navigate("BrochureBase");
											}}
										>
											<Text
												style={[
													styles.text,
													styles.textWhite,
													styles.fontBold,
													{ fontSize: 14 },
												]}
											>
												Download Brochure
											</Text>
										</TouchableOpacity>
									</View>
								</View>
							</View>
						);
					})}
				</View>
				<View style={[styles.row, styles.justifyCenter, { marginTop: 74 }]}>
					<TouchableOpacity
						style={[
							styles.bgPrimary,
							styles.justifyCenter,
							styles.textAlignCenter,
							{ borderRadius: 3, padding: 10, width: 300 },
						]}
						onPress={() => {
							type === "mbbs"
								? navigation.navigate("MBBSFilter")
								: navigation.navigate("OtherCoursesFilter");
						}}
					>
						<Text style={[styles.text, styles.textWhite, styles.font20]}>
							Review my preferences
						</Text>
					</TouchableOpacity>
				</View>
				<View
					style={[styles.row, styles.justifyCenter, { marginVertical: 76 }]}
				>
					<Pagination
						onPageChange={() => onPageChange(pagination)}
						totalItems={pagination.totalItems}
						pageSize={10}
						currentPage={pagination.currentPage}
					/>
				</View>
			</ImageBackground>
		</View>
	);
};

export default CollegeResultsList;
