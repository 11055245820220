import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import MainNavigationStack from "./Routing/Routes";
import { persistor, store } from "./store/configStore";

export default function App() {
	let [fontsLoaded] = useFonts({
		"Montserrat-Bold": require("./fonts/Montserrat-Bold.ttf"),
		Montserrat: require("./fonts/Montserrat-Regular.ttf"),
		"Montserrat-Light": require("./fonts/Montserrat-Light.ttf"),
		"Montserrat-SemiBold": require("./fonts/Montserrat-SemiBold.ttf"),
	});
	if (!fontsLoaded) {
		return <AppLoading />;
	} else {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<MainNavigationStack />
				</PersistGate>
			</Provider>
		);
	}
}
