import React from 'react';
import { styles } from '../../styles/styles';
import { View, TouchableOpacity, Text, ImageBackground, Image } from 'react-native';
import {connect} from 'react-redux';
import SignIn from './BrochureSignIn';
import SignUp from './BrochureSignUp';

class BrochureBase extends React.Component  {
    constructor(props){
        super();
        this.state = {
            selectedOption:'signup',
            image:{ uri: require('../../assets/signin/background.svg')}
        }
    }
    componentDidMount(){
        this.setState({sections: this.props.appData.pageConfig});
    }
    render(){
        return(
            <View style={[styles.bgWhite]}>
                <View style={[styles.row, styles.justifyEnd, styles.bgRed, styles.alignItemsCenter, {paddingHorizontal: 48, paddingVertical: 14}]}>
                    <Text style={[styles.text,styles.textWhite,styles.font20, styles.textMedium, {marginRight: 23}]}>Toll FREE No. : +91-9861-905-906</Text>
                    <Image style={[{marginRight: 6, width:28, height: 28}]} source={{uri: require("../../assets/home_page/email_icon_top.svg")}}/>
                    <Text style={[styles.text,styles.textWhite,styles.font20, styles.textMedium]}>info@mycareerpathshala.com</Text>
                </View>
                <View style={[styles.row, styles.justifyCenter, styles.brBotttomGrey,]}>
                    <Image style={[{width:530, paddingVertical: 36, height:128, resizeMode:'center'}]} source={{uri: require("../../assets/home_page/logo1.png")}}/>
                </View>
                <View style={[styles.row, styles.justifyCenter, {marginTop: 12,paddingHorizontal: '10%'}]}>
                    <ImageBackground source={this.state.image} resizeMode="cover" >
                        <View style={[styles.justifyCenter,{marginTop: 18}, styles.alignItemsCenter]}>
                            <View style={[styles.row, styles.justifyCenter, {borderColor: '#707070', borderWidth: .5, borderRadius: 40, padding: 6, flexGrow: 0, width:'fit-content'},styles.bgWhite]}>
                                <TouchableOpacity onPress = {()=>{this.setState({selectedOption: 'signup'})}}>
                                    <View style={[{marginRight: 32, borderColor: '#707070', borderWidth: .5, borderRadius: 40, paddingVertical: 18, paddingHorizontal: 44 }, this.state.selectedOption == 'signup' && styles.bgPrimary || styles.bgWhite, styles.justifyCenter]}>
                                        <Text style={[styles.text, this.state.selectedOption == 'signup' && styles.textWhite || styles.textGrey, styles.fontBold, styles.font20]}>SIGN UP</Text>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity onPress = {()=>{this.setState({selectedOption: 'signin'})}}>
                                    <View style={[{ borderColor: '#707070', borderWidth: .5, borderRadius: 40, paddingVertical: 18, paddingHorizontal: 44 }, this.state.selectedOption == 'signin' && styles.bgPrimary || styles.bgWhite, styles.justifyCenter]}>
                                        <Text style={[styles.text, this.state.selectedOption == 'signin' && styles.textWhite || styles.textGrey, styles.fontBold, styles.font20]}>SIGN IN</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={[styles.row, styles.justifyCenter, {marginTop: 12}]}>
                        {
                            this.state.selectedOption == 'signin' && <SignIn navigation={this.props.navigation}/> || 
                            this.state.selectedOption == 'signup' && <SignUp navigation={this.props.navigation}/>
                        }
                        </View>
                    </ImageBackground>
                </View>
                <View style={[styles.row, styles.justifyStretch, styles.bgPrimary, styles.alignItemsCenter, {paddingHorizontal: 190, paddingVertical: 36, position:'fixed',bottom: 0, width: '100vw'}]}>
                    <View>
                        <Text style={[styles.text,styles.textWhite,styles.font20]}>i©2021 mycareerpathshala. All rights reserved.</Text>
                    </View>
                    <View style={[styles.row]}>
                        <Text style={[styles.text,styles.textWhite,styles.font20,{marginRight: 44}]}>Terms & Condition</Text>
                        <Text style={[styles.text,styles.textWhite,styles.font20]}>Privacy Policy</Text>
                    </View>
                </View>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appData: state
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
        updateData:async (parameter, callback)=>{
            await dispatch(parameter)
            if(callback){
                callback()
            }
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(BrochureBase)