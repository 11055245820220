import React from 'react';
import { styles } from '../styles/styles';
import { View, TouchableOpacity, Text} from 'react-native';

class FooterInfo extends React.Component  {
    constructor(props){
        super();
        this.state = {
            countriesRow1: [
                {
                    label: 'Australia'
                },
                {
                    label: 'Bangladesh'
                },
                {
                    label: 'Belarus'
                },
                {
                    label: 'Canada'
                },
                {
                    label: 'China'
                },
                {
                    label: 'Denmark'
                },
                {
                    label: 'Dubai'
                },
                {
                    label: 'Egypt'
                },
                {
                    label: 'France'
                },
                {
                    label: 'Finland'
                },
                {
                    label: 'Georgia'
                },
            ],
            countriesRow2: [
                {
                    label: 'Ireland'
                },
                {
                    label: 'Italy'
                },
                {
                    label: 'Japan'
                },
                {
                    label: 'Kazakhstan'
                },
                {
                    label: 'Malaysia'
                },
                {
                    label: 'Mauritus'
                },
                {
                    label: 'New Zealand'
                },
                {
                    label: 'Philippines'
                },
                {
                    label: 'Portugal'
                },
                {
                    label: 'Russia'
                },
                {
                    label: 'Singapore'
                },
            ],
            countriesRow3: [
                {
                    label: 'Switzerland'
                },
                {
                    label: 'Sweden'
                },
                {
                    label: 'The Netherlands'
                },
                {
                    label: 'UK'
                },
                {
                    label: 'Ukraine'
                },
                {
                    label: 'USA'
                },
            ],
            connectIcons: [
                {
                  imgSrc: require('../assets/home_page/facebook.svg'),
                  url: ''   
                },
                {
                    imgSrc: require('../assets/home_page/insta.svg'),
                    url: ''   
                },
                {
                    imgSrc: require('../assets/home_page/linkedin.svg'),
                    url: ''   
                },
                {
                    imgSrc: require('../assets/home_page/quora.svg'),
                    url: ''   
                },
                {
                    imgSrc: require('../assets/home_page/twitter.svg'),
                    url: ''   
                },
                {
                    imgSrc: require('../assets/home_page/youtube.svg'),
                    url: ''   
                },
            ],
        }
    }
    componentDidMount(){
        // this.setState({sections: this.props.appData.pageConfig});
    }
    render(){
        return <View style={[styles.row, styles.bgWhite, {paddingHorizontal: '10%', paddingVertical: 100}]}>
        <View style={[{width:'34%'}]}>
            <View style={[styles.row,styles.justifyStart]}>
                <Text style={[styles.textPrimary, styles.text, {fontSize: 25}]}>Make INDIA knowledgeable, with this slogan we have oathed to provide world’s best and honest knowledge to each individual to get their dream university.</Text>
            </View>  
            <View style={[styles.row,styles.justifyStart, {marginTop: 40}]}>
                <Text style={[styles.textPrimary, styles.fontBold, {fontSize: 25}]}>Connect with us on:</Text>
            </View>    
            <View style={[styles.row,styles.justifyStart]}>
                {this.state.connectIcons.map((c,i)=>{
                    return <TouchableOpacity
                        key={('btn'+(i+1))}
                        onPress = {()=>{
                                                
                        }}
                    >
                        <img src={c.imgSrc} style={{width:50}}/>
                    </TouchableOpacity>
                })
                }
            </View>
            <View style={[styles.row,styles.justifyStart, {marginTop: 40}]}>
                <Text style={[styles.textPrimary, styles.fontBold, {fontSize: 25}]}>Head office:</Text>
            </View>   
            <View style={[styles.row,styles.justifyStart,]}>
                <Text style={[styles.textPrimary, styles.text, {fontSize: 25}]}>Deepshikha Building, #502,</Text>
            </View> 
            <View style={[styles.row,styles.justifyStart,]}>
                <Text style={[styles.textPrimary, styles.text, {fontSize: 25}]}>Rajendra Place, New Delhi - 110008</Text>
            </View>     
            <View style={[styles.row,styles.justifyStart,styles.alignCenter,{marginTop: 20}]}>
                <img src={require('../assets/home_page/subscribe.svg')} style={{width:50, marginRight: 10}}/><Text style={[styles.textPrimary, styles.text,styles.breakAll, {fontSize: 25}]}>info@mycareerpathshala.com</Text>
            </View>          
            <View style={[styles.row,styles.justifyStart,styles.alignCenter,{marginTop: 20}]}>
                <img src={require('../assets/home_page/phone.svg')} style={{width:50, marginRight: 10}}/><Text style={[styles.textPrimary, styles.text,styles.breakAll, {fontSize: 25}]}>+91-9861-905-906</Text>
            </View>                 
        </View>
        <View style={[{width:'66%', paddingLeft: 20}]}>
            <View style={[styles.row,styles.justifyStart, {marginBottom: 30}]}>
                <Text style={[styles.textPrimary, styles.fontBold, {fontSize: 25}]}>Countries</Text>
            </View>
            <View style={[styles.row,styles.justifyStretch]}>
                <View>
                    {
                        this.state.countriesRow1.map((r,i)=>{
                            return <View key={'r1'+(i+1)} style={[styles.row,styles.justifyStart, {marginVertical: 9}]}>
                                <Text style={[styles.textPrimary, styles.text, {fontSize: 25}]}>Study in {r.label}</Text>
                            </View>
                        })
                    }
                </View>
                <View>
                    {
                        this.state.countriesRow2.map((r,i)=>{
                            return <View key={'r2'+(i+1)} style={[styles.row,styles.justifyStart, {marginVertical: 9}]}>
                                <Text style={[styles.textPrimary, styles.text, {fontSize: 25}]}>Study in {r.label}</Text>
                            </View>
                        })
                    }
                </View>
                <View>
                    {
                        this.state.countriesRow3.map((r,i)=>{
                            return <View key={'r3'+(i+1)} style={[styles.row,styles.justifyStart, {marginVertical: 9}]}>
                                <Text style={[styles.textPrimary, styles.text, {fontSize: 25}]}>Study in {r.label}</Text>
                            </View>
                        })
                    }
                </View>
            </View>
        </View>
    </View>
     
    }
}

export default FooterInfo;