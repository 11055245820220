import React from "react";
import { Text, View } from "react-native";
import { styles } from "../../styles/styles";

const ShortListedCountries = ({ shortListedCountries, shortlistType }) => {
	return shortListedCountries
		?.filter(col => col.shortlistType === shortlistType)
		?.map(c => (
			<View
				key={c.countryName}
				style={[
					styles.boxShadow1,
					{
						padding: 10,
						borderRadius: 60,
					},
					styles.bgWhite,
				]}
			>
				<View
					style={[styles.row, styles.justifyStart, styles.alignItemsCenter]}
				>
					<View>
						<img
							src={c.iconUrl}
							style={{ height: 60, width: 60, borderRadius: "50%" }}
						/>
					</View>
					<View style={[{ marginLeft: 10 }]}>
						<Text style={[styles.text, styles.textRed, styles.font22]}>
							{c.countryName}
						</Text>
					</View>
				</View>
			</View>
		));
};

export default ShortListedCountries;
