import { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getShortlistedColleges } from "../actions/collegeActions";
import ApplyNowModal from "../components/ApplyNowModal";
import ShortlistedCollegeMsg from "../components/ShortlistedColleges/ShortlistedCollegeMsg";
import ShortlistedCollegesList from "../components/ShortlistedColleges/ShortlistedCollegesList";
import ShortListedCountries from "../components/ShortlistedCountries/ShortlistedCountries";
import ShortlistedCourses from "../components/ShortlistedCourses/ShortlistedCourses";
import Toggle from "../components/Toggle";
import { styles } from "../styles/styles";

const toggle = [
  {
    label: "MBBS Abroad",
    value: "MBBS Abroad",
  },
  {
    label: "Other Courses",
    value: "Other Courses",
  },
];

const ShortlistedColleges = () => {
  const dispatch = useDispatch();

  const [selectedToggle, setSelectedToggle] = useState("MBBS Abroad");
  const [selectedSubToggle, setSelectedSubToggle] = useState("Universities");
  const [openApplyModal, setOpenApplyModal] = useState(false);

  const subToggle = [
    {
      label: "Universities",
      value: "Universities",
    },
    {
      label: "Countries",
      value: "Countries",
    },
  ];

  if (selectedToggle === "Other Courses") {
    subToggle.push({
      label: "Courses",
      value: "Courses",
    });
  }

  const userId = useSelector((state) => state.userData?.userDetails?.userId);
  const shortListedColleges = useSelector(
    (state) => state.collegeData?.shortListedColleges
  );

  const shortListedCountries = useSelector(
    (state) => state.collegeData?.shortListedCountries
  );

  const shortListedCourses = useSelector(
    (state) => state.collegeData?.shortListedCourses
  );

  const getData = () => {
    dispatch(getShortlistedColleges({ userId }));
  };

  useEffect(() => {
    getData();
  }, []);

  const setToggle = (t) => {
    setSelectedToggle(t.value);
    setSelectedSubToggle("Universities");
  };
  const setSubToggle = (t) => {
    setSelectedSubToggle(t.value);
  };

  const renderShortlists = () => {
    if (
      selectedToggle === "MBBS Abroad" &&
      selectedSubToggle === "Universities"
    ) {
      return shortListedColleges?.filter((col) => col.shortlistType === "mbbs")
        ?.length ? (
        <View style={[styles.row, styles.wrap]}>
          <ShortlistedCollegesList
            shortListedColleges={shortListedCourses}
            shortlistType="mbbs"
            setOpenApplyModal={setOpenApplyModal}
            getData={getData}
          />
        </View>
      ) : (
        <ShortlistedCollegeMsg
          message="No Universities shortlisted yet. Shortlist your Universities here to
				apply with mycareerpathshala."
        />
      );
    } else if (
      selectedToggle === "Other Courses" &&
      selectedSubToggle === "Universities"
    ) {
      return shortListedColleges?.filter(
        (col) => col.shortlistType === "others"
      )?.length ? (
        <View style={[styles.row, styles.wrap]}>
          <ShortlistedCollegesList
            shortListedColleges={shortListedColleges}
            shortlistType="others"
            setOpenApplyModal={setOpenApplyModal}
          />
        </View>
      ) : (
        <ShortlistedCollegeMsg
          message="No Universities shortlisted yet. Shortlist your Universities here to
				apply with mycareerpathshala."
        />
      );
    } else if (selectedSubToggle === "Countries") {
      return shortListedCountries?.filter(
        (col) =>
          col.shortlistType ===
          (selectedToggle === "Other Courses" ? "others" : "mbbs")
      )?.length ? (
        <View style={[styles.row, styles.wrap]}>
          <ShortListedCountries
            shortListedCountries={shortListedCountries}
            shortlistType={
              selectedToggle === "Other Courses" ? "others" : "mbbs"
            }
          />
        </View>
      ) : (
        <ShortlistedCollegeMsg message="No Countries shortlisted yet." />
      );
    } else if (
      selectedToggle === "Other Courses" &&
      selectedSubToggle === "Courses"
    ) {
      return shortListedCourses?.filter((col) => col.shortlistType === "others")
        ?.length ? (
        <View style={[styles.row, styles.wrap]}>
          <ShortlistedCourses
            shortListedCourses={shortListedCourses}
            shortlistType="others"
            setOpenApplyModal={setOpenApplyModal}
          />
        </View>
      ) : (
        <ShortlistedCollegeMsg
          message="No Universities shortlisted yet. Shortlist your Universities here to
				apply with mycareerpathshala."
        />
      );
    }
  };

  return (
    <View
      style={[
        styles.flex,
        {
          padding: 12,
        },
      ]}
    >
      <View style={[styles.row, styles.justifyStretch, { paddingBottom: 36 }]}>
        <Text style={[styles.textPrimary, styles.title]}>Shortlist</Text>
      </View>
      <View style={[styles.row, styles.justifyStretch, { paddingBottom: 23 }]}>
        <Toggle
          data={{ options: toggle }}
          onToggle={(d) => {
            setToggle(d);
          }}
          selectedOption={selectedToggle}
        />
      </View>
      <View
        style={[
          styles.row,
          styles.justifyStretch,
          { paddingBottom: 12, height: "100%" },
          styles.flexGrow,
        ]}
      >
        <View
          style={[
            styles.bgWhite,
            styles.flex,
            styles.flexGrow,
            { paddingVertical: 30, paddingHorizontal: 18, height: "100%" },
            styles.flexGrow,
          ]}
        >
          <View
            style={[styles.row, styles.justifyStretch, { paddingBottom: 68 }]}
          >
            <Toggle
              data={{ options: subToggle }}
              onToggle={(d) => {
                setSubToggle(d);
              }}
              selectedOption={selectedSubToggle}
            />
            {shortListedColleges?.length && (
              <View style={[styles.row, styles.justifyEnd]}>
                <TouchableOpacity
                  style={[
                    {
                      paddingHorizontal: 40,
                      paddingVertical: 22,
                      marginRight: 7,
                    },
                    styles.brPrimary0,
                  ]}
                  onPress={() => {
                    setOpenApplyModal(true);
                  }}
                >
                  <Text style={[styles.textPrimary, styles.font18]}>
                    Apply to All Universities
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    {
                      paddingHorizontal: 40,
                      paddingVertical: 22,
                      marginRight: 60,
                    },
                    styles.brRRed0,
                  ]}
                  onPress={() => {
                    // fld.open = !fld.open;
                    // this.setState({selectionOpn: fld.open});
                  }}
                >
                  <Text style={[styles.textRed, styles.font18]}>
                    Speak to your Counsellor for perfect match
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          {renderShortlists()}
        </View>
      </View>
      {/* Apply all Modal */}
      <ApplyNowModal
        setOpenApplyModal={setOpenApplyModal}
        openApplyModal={openApplyModal}
        type={selectedToggle === "MBBS Abroad" ? "mbbs" : "others"}
      />
      {/* <Modal
				animationType="slide"
				transparent={true}
				visible={openApplyModal}
				onRequestClose={() => {
				}}
			>
				<View
					style={[
						{
							boxShadow: "0px 5px 15px #00000029",
							backgroundColor: "#ffffff",
							position: "absolute",
							top: "25%",
							left: "20%",
							width: "60vw",
							borderRadius: 10,
							padding: 26,
						},
					]}
				>
					<View
						style={[styles.row, styles.alignItemsCenter, styles.justifyStretch]}
					>
						<View>
							<Text
								style={[
									styles.textPrimary,
									styles.font25,
									styles.fontBold,
									{ flex: 1, textAlign: "center" },
								]}
							>
								Thank you. You will be contacted soon by our team.
							</Text>
						</View>
						<View>
							<TouchableOpacity
								onPress={() => {
									setOpenApplyModal(false);
								}}
							>
								<FontAwesome name={"remove"} size={30} color="#B51700" />
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal> */}
    </View>
  );
};
export default ShortlistedColleges;
