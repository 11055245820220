import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import React, { useState } from "react";
import { styles } from "../styles/styles";
import { AntDesign, Entypo } from "@expo/vector-icons";
import { ProgressBar } from "react-native-paper";
import WebView from "react-native-webview";

export default function ApplicationContent({
  colleges,
  educationDetail,
  personalDetail,
  testDetail,
  files,
  Month,
  Redirect,
  onUploadDocument,
  toNextPage,
  scrollViewRef,
  onNavigate,
  uploading,
}) {
  const [openId, setOpenId] = useState(0);

  return (
    <View>
      <View
        style={[
          styles.row,
          styles.justifyStretch,
          styles.alignItemsCenter,
          styles.bgWhite,
          {
            paddingHorizontal: 20,
            paddingVertical: 15,
            marginBottom: 14,
          },
        ]}
      >
        <View style={[styles.row, styles.justifyStart, { width: "40%" }]}>
          <Text style={[styles.font14, styles.textGrey]}>
            Applied Courses & University
          </Text>
        </View>

        <View style={[styles.row, styles.justifyStart, { width: "20%" }]}>
          <Text style={[styles.font14, styles.textGrey]}>
            Application Status
          </Text>
        </View>
        <View style={[styles.row, styles.justifyStart, { width: "20%" }]}>
          <Text style={[styles.font14, styles.textGrey]}>In Stage</Text>
        </View>
        <View style={[styles.row, styles.justifyStart, { width: "20%" }]}>
          <Text style={[styles.font14, styles.textGrey]}>Your Attention</Text>
        </View>
      </View>
      {colleges && colleges.length !== 0
        ? colleges.map((c, ci) => {
            return (
              <TouchableOpacity
                key={"college" + (ci + 1)}
                onPress={() => setOpenId(openId == ci ? -1 : ci)}
              >
                <View
                  style={[
                    styles.row,
                    styles.justifyStretch,
                    styles.alignItemsCenter,
                    styles.bgWhite,
                    { marginVertical: 4 },
                  ]}
                >
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      {
                        width: "40%",
                        borderLeftWidth: 5,
                        borderLeftColor: c.sideBarColor,
                      },
                    ]}
                  >
                    <View style={[{ paddingLeft: 8, paddingVertical: 17 }]}>
                      <img
                        src={c.collegeInfo.logoImg}
                        style={{ height: 89, width: 89 }}
                      />
                    </View>
                    <View
                      style={[
                        styles.justifyCenter,
                        styles.wrap,
                        { paddingLeft: 8, paddingVertical: 17 },
                      ]}
                    >
                      <View style={{ width: "80%" }}>
                        <Text
                          style={[
                            styles.textPrimary,
                            styles.font20,
                            styles.breakword,
                          ]}
                        >
                          {c.collegeInfo.courseName}
                        </Text>
                      </View>
                      <View style={[{ marginTop: 4, width: "80%" }]}>
                        <Text
                          style={[
                            styles.text,
                            styles.font20,
                            styles.fontBold,
                            styles.breakword,
                            { color: "#505050" },
                          ]}
                        >
                          {c.collegeInfo.name}
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={[
                            styles.textGrey,
                            styles.font20,
                            styles.breakword,
                          ]}
                        >
                          Session : {Month(c.collegeInfo.intake.month)}{" "}
                          {c.collegeInfo.intake.year}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[styles.row, styles.justifyStart, { width: "20%" }]}
                  >
                    {(c.applicationStatus == "INCOMPLETE" && (
                      <View
                        style={[
                          {
                            flexDirection: "row",
                            paddingVertical: 9,
                            paddingLeft: 12,
                            paddingRight: 30,
                            minWidth: 200,
                            alignItems: "center",
                            background:
                              "transparent linear-gradient(270deg, #B5170000 0%, #B5170076 100%) 0% 0% no-repeat padding-box",
                          },
                        ]}
                      >
                        <Text style={[{ color: "#B51700" }, styles.font20]}>
                          INCOMPLETE
                        </Text>
                        <Image
                          source={require("../assets/application/university/incomplete.png")}
                          style={{
                            width: 18,
                            height: 17,
                            resizeMode: "center",
                            left: 10,
                          }}
                        />
                      </View>
                    )) ||
                      (c.applicationStatus == "INPROGRESS" && (
                        <View
                          style={[
                            {
                              flexDirection: "row",
                              paddingVertical: 9,
                              paddingLeft: 12,
                              paddingRight: 30,
                              minWidth: 200,
                              alignItems: "center",
                              background:
                                "transparent linear-gradient(270deg, #F0AD5E08 0%, #F0AD5E39 100%) 0% 0% no-repeat padding-box",
                            },
                          ]}
                        >
                          <Text style={[{ color: "#F0AD5E" }, styles.font20]}>
                            INPROGRESS
                          </Text>
                          <Image
                            source={require("../assets/application/university/inprogress.png")}
                            style={{
                              width: 18,
                              height: 17,
                              resizeMode: "center",
                              left: 10,
                            }}
                          />
                        </View>
                      )) ||
                      (c.applicationStatus == "COMPLETE" && (
                        <View
                          style={[
                            {
                              flexDirection: "row",
                              paddingVertical: 9,
                              paddingLeft: 12,
                              paddingRight: 30,
                              minWidth: 200,
                              alignItems: "center",
                              background:
                                "transparent linear-gradient(270deg, #19790700 0%, #19790750 100%) 0% 0% no-repeat padding-box",
                            },
                          ]}
                        >
                          <Text style={[{ color: "#197907" }, styles.font20]}>
                            COMPLETE
                          </Text>
                          <Image
                            source={require("../assets/application/university/complete.png")}
                            style={{
                              width: 18,
                              height: 17,
                              resizeMode: "center",
                              left: 20,
                            }}
                          />
                        </View>
                      ))}
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      styles.wrap,
                      { width: "20%", paddingRight: 20 },
                    ]}
                  >
                    <Text
                      style={[styles.textGrey, styles.font18, styles.breakword]}
                    >
                      {c.inStage}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      { width: "20%" },
                      styles.wrap,
                    ]}
                  >
                    {(c.stageName == "conditionalOfferGenerated" && (
                      <View style={[styles.row, styles.wrap]}>
                        <TouchableOpacity onPress={() => {}}>
                          <Text
                            style={[
                              styles.text,
                              styles.font20,
                              { color: "#0C5397" },
                            ]}
                          >
                            View
                          </Text>
                        </TouchableOpacity>
                        <Text
                          style={[
                            styles.text,
                            styles.font20,
                            { color: "#B51700", marginHorizontal: 6 },
                          ]}
                        >
                          |
                        </Text>
                        <TouchableOpacity onPress={() => {}}>
                          <Text
                            style={[
                              styles.text,
                              styles.font20,
                              { color: "#197907" },
                            ]}
                          >
                            Accept
                          </Text>
                        </TouchableOpacity>
                        <Text
                          style={[
                            styles.text,
                            styles.font20,
                            { color: "#B51700", marginHorizontal: 6 },
                          ]}
                        >
                          |
                        </Text>
                        <TouchableOpacity onPress={() => {}}>
                          <Text
                            style={[
                              styles.text,
                              styles.font20,
                              { color: "#B51700" },
                            ]}
                          >
                            Reject
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )) ||
                      (c.inStage == "Unconditional Offer" && (
                        <View style={[styles.row, styles.wrap]}>
                          <TouchableOpacity onPress={() => {}}>
                            <Text
                              style={[
                                styles.text,
                                styles.font20,
                                { color: "#197907" },
                              ]}
                            >
                              Accept
                            </Text>
                          </TouchableOpacity>
                          <Text
                            style={[
                              styles.text,
                              styles.font20,
                              { color: "#B51700", marginHorizontal: 6 },
                            ]}
                          >
                            |
                          </Text>
                          <TouchableOpacity onPress={() => {}}>
                            <Text
                              style={[
                                styles.text,
                                styles.font20,
                                { color: "#B51700" },
                              ]}
                            >
                              Reject
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )) || (
                        <TouchableOpacity
                          onPress={
                            c.inStage === "Incomplete Profile"
                              ? onNavigate
                              : null
                          }
                          style={{ flexDirection: "row" }}
                        >
                          <Text
                            style={[
                              styles.font18,
                              styles.textGrey,
                              { color: "#B51700", marginHorizontal: 6 },
                            ]}
                          >
                            {Redirect(c.inStage)}
                          </Text>

                          {c.inStage !== "Document Verification Pending" && (
                            <Image
                              source={
                                c.inStage === "Offer Accepted" ||
                                c.inStage === "Incomplete Profile"
                                  ? require("../assets/application/university/view_offer.png")
                                  : require("../assets/application/university/upload_document.png")
                              }
                              style={{
                                width: 18,
                                height: 17,
                                resizeMode: "center",
                                left: 10,
                              }}
                            />
                          )}
                        </TouchableOpacity>
                      )}
                  </View>
                </View>
                {openId == ci && (
                  <View>
                    <View
                      style={[
                        styles.row,
                        styles.justifyStretch,
                        styles.alignStart,
                        styles.bgWhite,
                        { marginTop: -4 },
                      ]}
                    >
                      <View
                        style={[
                          styles.row,
                          styles.justifyStart,
                          styles.wrap,
                          {
                            width: "40%",
                            borderLeftWidth: 5,
                            borderLeftColor: c.sideBarColor,
                            padding: 18,
                          },
                        ]}
                      >
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%" },
                          ]}
                        >
                          <Text style={[styles.textPrimary, styles.font20]}>
                            Personal Details:
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 20 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Name:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.name}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Email:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.email}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Mobile Number:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.mobile?.mobile}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Country:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.country}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              City:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.city}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Nationality:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.nationality}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              State/Province:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.state}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Postal Code/Zip Code:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.zipcode}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Gender:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.gender}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 10 },
                          ]}
                        >
                          <View style={[{ width: "40%" }]}>
                            <Text style={[styles.textGrey, styles.font18]}>
                              Marital Status:
                            </Text>
                          </View>
                          <View style={[{ width: "60%" }]}>
                            <Text
                              style={[
                                styles.textGrey3,
                                styles.font18,
                                styles.fontBold,
                              ]}
                            >
                              {personalDetail.maritalStatus}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.row,
                          styles.justifyStart,
                          styles.wrap,
                          { width: "60%", paddingVertical: 16 },
                          styles.alignStart,
                        ]}
                      >
                        {c.uploadDocument && (
                          <View
                            style={[
                              styles.row,
                              styles.justifyStart,
                              { width: "100%" },
                              styles.alignStart,
                            ]}
                          >
                            <Text
                              style={[
                                styles.textPrimary,
                                styles.font20,
                                styles.fontBold,
                              ]}
                            >
                              Upload Your Required Documents:
                            </Text>
                          </View>
                        )}
                        {c.conditionalOffer &&
                          (Platform.OS === "web" ? (
                            <View
                              style={{
                                flex: 1,
                                borderWidth: 0.5,
                                width: "85%",
                                right: 20,
                                padding: 10,
                                borderColor: "#5479CB",
                                borderRadius: 8,
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: c.conditionalOffer.content,
                                }}
                              />
                            </View>
                          ) : (
                            <WebView
                              content={c.conditionalOffer.content}
                              isReadOnly
                            />
                          ))}
                        {c.uploadDocument && (
                          <ScrollView
                            ref={scrollViewRef}
                            showsHorizontalScrollIndicator={false}
                            horizontal={true}
                            contentContainerStyle={{
                              marginTop: 20,
                              width: "95%",
                            }}
                          >
                            {(c.uploadDocument || []).map((df, dfi) => {
                              return (
                                <View
                                  key={"docf" + (dfi + 1)}
                                  style={[
                                    styles.bgWhite,
                                    styles.brBlue,
                                    // styles.boxShadow1,
                                    styles.flexGrow,
                                    {
                                      marginRight:
                                        dfi === c.uploadDocument.length - 1
                                          ? 0
                                          : 60,
                                      marginLeft:
                                        dfi === c.uploadDocument.length - 1
                                          ? 60
                                          : 0,
                                      flexDirection: "row",
                                      padding: 0,
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                      width: "100%",
                                    },
                                  ]}
                                >
                                  <View
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "25%",
                                      paddingVertical: 18,
                                    }}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.font21,
                                        styles.textPrimary,
                                        styles.fontBold,
                                        styles.textAlignCenter,
                                      ]}
                                    >
                                      {df.label}
                                    </Text>
                                  </View>

                                  <View
                                    style={{
                                      width: "50%",
                                      paddingVertical: 39,
                                      borderRightWidth: 0.5,
                                      borderLeftWidth: 0.5,
                                    }}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.font18,
                                        styles.textPrimary,
                                        styles.textAlignCenter,
                                      ]}
                                    >
                                      {df.description}
                                    </Text>
                                  </View>

                                  <TouchableOpacity
                                    onPress={() => {
                                      onUploadDocument(
                                        dfi,
                                        df.label,
                                        df.sectionName
                                      );
                                    }}
                                    style={[
                                      styles.alignItemsCenter,
                                      styles.textAlignCenter,
                                      styles.justifyCenter,
                                      { width: "25%", paddingVertical: 18 },
                                    ]}
                                  >
                                    <Image
                                      source={{
                                        uri: require("../assets/dashboard/profile/file_upload.svg"),
                                      }}
                                      style={{
                                        width: 60,
                                        height: 38,
                                        resizeMode: "center",
                                      }}
                                    />
                                    <Text
                                      style={[
                                        styles.text,
                                        styles.font18,
                                        styles.textPrimary,
                                      ]}
                                    >
                                      Max size 2MB
                                    </Text>
                                  </TouchableOpacity>
                                  {files && files.length !== 0 && (
                                    <View
                                      style={{
                                        position: "absolute",
                                        bottom: 0,
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        borderBottomLeftRadius: 10,
                                        borderBottomRightRadius: 10,
                                        backgroundColor: "#F8F9FD",
                                        padding: 2,
                                        borderTopWidth: 0.5,
                                      }}
                                    >
                                      <Text
                                        style={
                                          ([styles.font18],
                                          { color: "#0C5397" })
                                        }
                                      >
                                        File Uploaded
                                      </Text>
                                      <View style={{ flexDirection: "row" }}>
                                        <Text
                                          style={[
                                            styles.font18,
                                            { color: "#B51700" },
                                          ]}
                                        >
                                          {files.name}
                                        </Text>
                                        <Entypo
                                          name="attachment"
                                          color="#B51700"
                                          size={15}
                                          style={{ left: 2, top: 5 }}
                                        />
                                      </View>
                                    </View>
                                  )}
                                  {dfi === c.uploadDocument.length - 1 ? (
                                    <TouchableOpacity
                                      onPress={() => toNextPage(-1000)}
                                      style={[
                                        {
                                          position: "absolute",
                                          left: -20,
                                          borderRadius: 30,
                                          padding: 5,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          backgroundColor: "#FFFFFF",
                                          top: 40,
                                        },
                                        styles.boxShadow2,
                                      ]}
                                    >
                                      <AntDesign
                                        name="left"
                                        color="#000000"
                                        size={20}
                                      />
                                    </TouchableOpacity>
                                  ) : (
                                    <TouchableOpacity
                                      onPress={() => toNextPage(1000)}
                                      style={[
                                        {
                                          position: "absolute",
                                          right: -15,
                                          borderRadius: 30,
                                          padding: 5,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          backgroundColor: "#FFFFFF",
                                          top: 40,
                                        },
                                        styles.boxShadow2,
                                      ]}
                                    >
                                      <AntDesign
                                        name="right"
                                        color="#000000"
                                        size={20}
                                      />
                                    </TouchableOpacity>
                                  )}
                                </View>
                              );
                            })}
                          </ScrollView>
                        )}

                        {uploading ? (
                          <View style={{ marginTop: 10 }}>
                            <ProgressBar
                              color="#FCA209"
                              progress={100}
                              style={{
                                width: "95%",
                                padding: 4,
                                borderRadius: 10,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 20,
                                color: "#FCA209",
                                fontWeight: "bold",
                              }}
                            >
                              File Uploading ...
                            </Text>
                          </View>
                        ) : null}
                        <View
                          style={[
                            styles.row,
                            styles.justifyStart,
                            { width: "100%", marginTop: 40 },
                            styles.alignStart,
                          ]}
                        >
                          <View
                            style={[
                              styles.justifyStart,
                              { width: "65%" },
                              styles.alignStart,
                            ]}
                          >
                            <View
                              style={[
                                styles.row,
                                styles.justifyStart,
                                { width: "100%" },
                                styles.alignStart,
                              ]}
                            >
                              <Text style={[styles.textPrimary, styles.font18]}>
                                Education Details:
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.row,
                                styles.justifyStart,
                                { width: "100%", marginTop: 20 },
                              ]}
                            >
                              <View style={[{ width: "50%" }]}>
                                <Text style={[styles.textGrey, styles.font18]}>
                                  Highest Level of Education:
                                </Text>
                              </View>
                              <View style={[{ width: "50%" }]}>
                                <Text
                                  style={[
                                    styles.textGrey3,
                                    styles.font18,
                                    styles.fontBold,
                                  ]}
                                >
                                  {educationDetail.highestQualification}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.row,
                                styles.justifyStart,
                                { width: "100%", marginTop: 10 },
                              ]}
                            >
                              <View style={[{ width: "50%" }]}>
                                <Text style={[styles.textGrey, styles.font18]}>
                                  Country:
                                </Text>
                              </View>
                              <View style={[{ width: "50%" }]}>
                                <Text
                                  style={[
                                    styles.textGrey3,
                                    styles.font18,
                                    styles.fontBold,
                                  ]}
                                >
                                  {educationDetail.country}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.row,
                                styles.justifyStart,
                                { width: "100%", marginTop: 10 },
                              ]}
                            >
                              <View style={[{ width: "50%" }]}>
                                <Text style={[styles.textGrey, styles.font18]}>
                                  Specialization:
                                </Text>
                              </View>
                              <View style={[{ width: "50%" }]}>
                                <Text
                                  style={[
                                    styles.textGrey3,
                                    styles.font18,
                                    styles.fontBold,
                                  ]}
                                >
                                  {educationDetail.specialization.join(", ")}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.justifyStart,
                              { width: "35%" },
                              styles.alignStart,
                            ]}
                          >
                            <View
                              style={[
                                styles.row,
                                styles.justifyStart,
                                { width: "100%" },
                                styles.alignStart,
                              ]}
                            >
                              <Text style={[styles.textPrimary, styles.font18]}>
                                Test Details:
                              </Text>
                            </View>

                            <View
                              style={[
                                styles.row,
                                styles.justifyStart,
                                { width: "100%", marginTop: 10 },
                              ]}
                            >
                              <View style={[{ width: "50%" }]}>
                                <Text style={[styles.textGrey, styles.font18]}>
                                  {testDetail[0].test}:
                                </Text>
                              </View>
                              <View style={[{ width: "50%" }]}>
                                <Text
                                  style={[
                                    styles.textGrey3,
                                    styles.font18,
                                    styles.fontBold,
                                  ]}
                                >
                                  {testDetail[0].score}/720
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.row,
                        styles.justifyStretch,
                        styles.alignItemsCenter,
                        styles.bgWhite,
                        {
                          borderLeftWidth: 5,
                          borderLeftColor: c.sideBarColor,
                          padding: 18,
                        },
                      ]}
                    >
                      <TouchableOpacity
                        key={"college" + (ci + 1)}
                        style={[
                          {
                            paddingHorizontal: 18,
                            paddingVertical: 12,
                            background:
                              "transparent linear-gradient(270deg, #0C5397 0%, #0C539786 100%) 0% 0% no-repeat padding-box",
                            borderRadius: 10,
                          },
                        ]}
                        onPress={() => {}}
                      >
                        <Text
                          style={[
                            styles.textWhite,
                            styles.text,
                            styles.font20,
                            styles.fontBold,
                          ]}
                        >
                          Withdraw Application
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              </TouchableOpacity>
            );
          })
        : null}
    </View>
  );
}
