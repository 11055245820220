import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllPageConfig } from "../actions/appActions";
import Admin from "../pages/Admin/Admin";
import EditConfig from "../pages/Admin/EditConfig";
import Pages from "../pages/Admin/Pages";
import Application from "../pages/Application";
import BrochureBase from "../pages/Brochure/BrochureBase";
import CollegeDetails from "../pages/CollegeDetails";
import CollegeFinder from "../pages/CollegeFinder";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import MBBS from "../pages/Mbbs";
import MBBSFilter from "../pages/MbbsFilter";
import MbbsResults from "../pages/MbbsResults";
import OtherCourses from "../pages/OtherCourses";
import OtherCoursesFilter from "../pages/OtherCoursesFilter";
import OtherCoursesResults from "../pages/OtherCoursesResults";
import Profile from "../pages/Profile";
import ShortlistedColleges from "../pages/ShortlistedColleges";
import SignIn from "../pages/SignIn/SignIn";
import SignInBase from "../pages/SignIn/SigninBase";
import SignUp from "../pages/SignIn/SignUp";

const Stack = createStackNavigator();

const linking = {
  prefixes: ["http://localhost:19006/"],
  config: {
    screens: {
      Home: "",
      SignInBase: "sign-up",
      Settings: ":id/blog",
      Dashboard: "dashboard",
      MBBS: "mbbs-abroad",
      MBBSFilter: "mbbs-filters",
      OtherCoursesFilter: "other-courses-filters",
      OtherCourses: "other-courses",
      MbbsResults: "mbbs-results",
      OtherCoursesResults: "other-courses-results",
      BrochureBase: "download-brochure",
      CollegeDetails: "college-details",
      Admin: "admin",
    },
  },
};

export default function MainNavigationStack() {
  const navigationRef = React.createRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPageConfig());
  }, []);

  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="Dashboard"
          component={Dashboard}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="Admin"
          component={Admin}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="EditConfig"
          component={EditConfig}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="Pages"
          component={Pages}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="MBBS"
          component={MBBS}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="MBBSFilter"
          component={MBBSFilter}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="OtherCoursesFilter"
          component={OtherCoursesFilter}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="OtherCourses"
          component={OtherCourses}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="MbbsResults"
          component={MbbsResults}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="OtherCoursesResults"
          component={OtherCoursesResults}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="CollegeDetails"
          component={CollegeDetails}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="CollegeFinder"
          component={CollegeFinder}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="ShortlistedColleges"
          component={ShortlistedColleges}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="Profile"
          component={Profile}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="Application"
          component={Application}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="SignInBase"
          component={SignInBase}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="BrochureBase"
          component={BrochureBase}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="SignIn"
          component={SignIn}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
        <Stack.Screen
          name="SignUp"
          component={SignUp}
          options={{
            header: () => {
              return null;
            },
            drawerLabel: () => null,
            title: null,
            drawerIcon: () => null,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
