import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getCollegeList } from "../actions/collegeActions";
import CollegeResultNavbar from "../components/CollegeResultNavbar";
import CollegeResultsList from "../components/CollegeResultsList";
import Footer from "../components/Footer";
import { styles } from "../styles/styles";

const OtherCoursesResults = ({ navigation, route }) => {
	const dispatch = useDispatch();

	const shortListedColleges = useSelector(
		state => state.appData.shortListedColleges
	);

	const collegesStore = useSelector(state => state.collegeData.colleges);
	const paginationStore = useSelector(state => state.collegeData.pagination);
	const otherCoursesFilter = useSelector(
		state => state.collegeData.otherCoursesFilters
	);

	const [colleges, setColleges] = useState([]);
	const [pagination, setPagination] = useState({
		pageSize: 12,
		currentPage: 1,
		totalItems: 0,
	});

	useEffect(() => {
		if (collegesStore) setColleges(collegesStore);
	}, [collegesStore]);

	useEffect(() => {
		if (paginationStore) setPagination(paginationStore);
	}, [paginationStore]);

	useEffect(() => {
		const shortListedCollegesList = shortListedColleges?.map(c => c.courseId);
		if (shortListedCollegesList) {
			const collegesObj = collegesStore?.map(college => {
				if (shortListedCollegesList?.includes(college.courseId)) {
					return { ...college, isShortlisted: true };
				}
				return college;
			});
			setColleges(collegesObj);
		}
	}, [shortListedColleges, collegesStore]);

	useEffect(() => {
		getCollege();
	}, []);

	const getCollege = (currentPage = 1) => {
		let req = {
			...otherCoursesFilter,
			pageNumber: currentPage || pagination?.currentPage,
			pageSize: pagination?.pageSize,
		};
		dispatch(getCollegeList("get-other-courses", req));
	};

	const onPageChange = data => {
		setPagination({ ...pagination, currentPage: data.currentPage });
		getCollege(data.currentPage);
	};

	return (
		<View
			style={
				([styles.bgWhite],
				{ display: "flex", justifyContent: "space-between", flex: 1 })
			}
		>
			<CollegeResultNavbar colleges={colleges} navigation={navigation} />
			<CollegeResultsList
				type="others"
				colleges={colleges}
				countries={otherCoursesFilter?.countries || []}
				pagination={pagination}
				onPageChange={onPageChange}
				setColleges={setColleges}
				navigation={navigation}
			/>
			<Footer />
		</View>
	);
};

export default OtherCoursesResults;
