import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import {
  Dimensions,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Footer from "../components/Footer";
import FooterInfo from "../components/FooterInfo";
import Input from "../components/Input";
import { styles } from "../styles/styles";
// import Video from 'react-native-video';
import InputSuggestion from "../components/InputSuggestions";
import KeepMePostedEmail from "../components/KeepMePostedEmail";
import Navbar from "../components/Navbar";
import apisrv from "../service/ApiService";

const win = Dimensions.get("window");

const Home = ({ navigation }) => {
  const [countries, setCountries] = useState([
    { name: "Germany", isSelected: false },
    { name: "Russia", isSelected: false },
    { name: "USA", isSelected: false },
    { name: "Ireland", isSelected: false },
    { name: "UK", isSelected: false },
    { name: "China", isSelected: false },
    { name: "Australia", isSelected: false },
    { name: "Canada", isSelected: false },
    { name: "Others", isSelected: false },
  ]);
  const [courses, setCourses] = useState([
    { name: "MBBS Abroad", isSelected: true },
    { name: "Undergraduate", isSelected: false },
    { name: "Postgraduate", isSelected: false },
  ]);
  const [status, setStatus] = useState("");

  const [fields, setFields] = useState([
    {
      type: "inputGrey",
      placeholder: "Name",
      key: "name",
      value: "",
    },
    {
      type: "inputGrey",
      placeholder: "Email ID",
      keyboardType: "email-address",
      autoComplete: "email",
      key: "email",
      value: "",
      pattern:
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*[\.]*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
    },
    {
      type: "mobileWithCountryCode",
      placeholder: "Mobile Number",
      keyboardType: "phone-pad",
      autoComplete: "tel",
      key: "mobile",
      help: "OTP will be sent to this mobile number.",
      value: "",
      options: [
        {
          label: "+91",
          value: "91",
        },
      ],
    },
  ]);
  const setCountry = (data, i) => {
    let stateobj = countries;
    stateobj[i].isSelected = !stateobj[i].isSelected;
    setCountries([...stateobj]);
  };
  const setCourse = (data, i) => {
    let stateobj = courses;
    stateobj?.map((a) => (a.isSelected = false));

    stateobj[i].isSelected = !stateobj[i].isSelected;
    setCourses([...stateobj]);
  };
  const setData = (key, v) => {
    let stateObj = fields;
    stateObj[key].value = v.value;
    setFields([...stateObj]);
  };
  const bookSession = () => {
    let req = {
      name: fields.find((x) => x.key === "name")?.value,
      emailId: fields.find((x) => x.key === "email")?.value,
      mobile: {
        code: "+91",
        number: fields.find((x) => x.key === "mobile")?.value?.mobile,
      },
      pursueCourse: courses.filter((a) => a.isSelected).map((a) => a.name)[0],
      dreamDestination: countries
        .filter((a) => a.isSelected)
        .map((a) => a.name),
      status: status,
    };
    apisrv.callApi({
      endPoint: "book-session",
      request: req,
      onSuccess: (res) => {},
    });
  };
  // useEffect(() => {}, []);
  // const getData = goto => {
  // 	let req = {
  // 		userId: this.props.appData?.userData?.userId,
  // 	};
  // 	apisrv.callApi({
  // 		endPoint: "get-user-data",
  // 		request: req,
  // 		onSuccess: res => {
  // 			if (res.error) {
  // 				alert(res.error || "Unable to Fetch user data");
  // 			} else {
  // 				res.userId = req.userId;
  // 				this.props.updateData(
  // 					{ key: "userData", value: res, type: "userData" },
  // 					() => {
  // 						this.props.navigation.navigate(goto);
  // 					}
  // 				);
  // 			}
  // 		},
  // 	});
  // };

  // const getAllConfig = () => {
  // 	let req = {
  // 		userId: this.props.appData.userData.userId,
  // 	};

  // 	apisrv.callApi({
  // 		endPoint: "get-all-page-config",
  // 		request: req,
  // 		onSuccess: res => {
  // 			if ((res.config || []).length) {
  // 				this.props.updateData({
  // 					key: "pageConfig",
  // 					value: res.config,
  // 					type: "pageConfig",
  // 				});
  // 			}
  // 		},
  // 	});
  // };
  return (
    <>
      {/* top navigation panel */}
      <Navbar navigation={navigation} />
      {/* End of Navbar */}
      {/* lanidng div with video */}
      <View style={[{ height: win.height * 0.8, backgroundColor: "black" }]}>
        <video
          src={require("../assets/home_page/video.mp4")}
          autoPlay
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            objectFit: "fill",
            opacity: 0.6,
          }}
        />
        <View
          style={[
            styles.row,
            styles.flex,
            styles.justifyStart,
            styles.pPage,
            { marginTop: 100, maxHeight: 55 },
          ]}
        >
          <Text style={[styles.textWhite, styles.text, { fontSize: 55 }]}>
            Your dream destination is not far
          </Text>
        </View>
        <View
          style={[
            styles.row,
            styles.flex,
            styles.justifyStart,
            styles.pPage,
            { maxHeight: 55 },
          ]}
        >
          <Text
            style={[
              styles.textWhite,
              styles.text,
              styles.fontBold,
              { fontSize: 55, marginTop: 10 },
            ]}
          >
            Study anywhere in the world
          </Text>
        </View>
        <View
          style={[
            styles.row,
            styles.flex,
            styles.justifyStart,
            styles.pPage,
            { marginTop: 59, maxHeight: 55 },
          ]}
        >
          <Text style={[styles.textWhite, styles.text, { fontSize: 35 }]}>
            How you want to get started?
          </Text>
        </View>
        <View
          style={[
            styles.row,
            styles.flex,
            styles.justifyStart,
            styles.pPage,
            { marginTop: 0, maxHeight: 55 },
          ]}
        >
          <TouchableOpacity
            style={[
              styles.btnPrimary,
              styles.mr16,
              { height: 68 },
              styles.justifyCenter,
            ]}
            onPress={() => {
              navigation.navigate("MBBS");
            }}
          >
            <Text
              style={[{ color: "#ffffff" }, styles.fontBold, styles.font20]}
            >
              MBBS Abroad
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.btnRed, { height: 68 }, styles.justifyCenter]}
            onPress={() => {
              navigation.navigate("OtherCourses");
            }}
          >
            <Text
              style={[{ color: "#ffffff" }, styles.fontBold, styles.font20]}
            >
              Other Courses
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={[
            styles.row,
            styles.justifyStart,
            styles.pPage,
            { marginTop: 37 },
            styles.alignItemsCenter,
          ]}
        >
          <View style={{ width: "60%" }}>
            <InputSuggestion
              screen="home"
              onSuggestionTapped={(value) =>
                navigation.navigate("CollegeDetails", {
                  courseId: value.courseId,
                  type: value.courseType,
                })
              }
            />
          </View>
        </View>
      </View>
      {/* right platform */}
      <View style={styles.bgWhite}>
        <View
          style={[
            styles.row,
            styles.justifyCenter,
            { position: "relative", top: -60 },
          ]}
        >
          <View
            style={[
              styles.row,
              styles.justifyCenter,
              styles.bgWhite,
              {
                paddingHorizontal: 67,
                borderRadius: 5,
                paddingVertical: 27,
                boxShadow: "0px 5px 35px #00000029",
                maxWidth: win.width,
              },
            ]}
          >
            <Text style={[styles.textRed, { fontSize: 55 }]}>
              The right platform{" "}
            </Text>
            <Text
              style={[styles.textPrimary, styles.fontBold, { fontSize: 55 }]}
            >
              for your Higher Studies
            </Text>
          </View>
        </View>
      </View>
      <View style={[styles.row, styles.justifyCenter, styles.bgWhite]}>
        <View>
          <View style={[styles.row, styles.justifyCenter]}>
            <Text style={[{ fontSize: 30 }, styles.text, styles.textPrimary]}>
              Get your personal dashboard to track your applications
            </Text>
          </View>
          <View style={[styles.row, styles.justifyCenter]}>
            <Text style={[{ fontSize: 30 }, styles.text, styles.textPrimary]}>
              and other contents for information
            </Text>
          </View>
        </View>
      </View>
      <View style={[styles.row, styles.justifyCenter, styles.bgWhite]}>
        <View>
          <View style={[styles.row, styles.justifyCenter]}>
            <img
              src={require("../assets/home_page/g_937.svg")}
              style={{ width: "100%", marginLeft: -37 }}
            />
          </View>
        </View>
      </View>
      <View
        style={[
          styles.row,
          styles.justifyCenter,
          { paddingBottom: 80 },
          styles.bgWhite,
        ]}
      >
        <View>
          <View style={[styles.row, styles.justifyCenter]}>
            <Text style={[{ fontSize: 25 }, styles.text]}>
              You can apply to as many universities you want
            </Text>
          </View>
        </View>
      </View>
      {/* Your Tools */}
      <ImageBackground
        source={require("../assets/home_page/form_background_image.svg")}
        style={{ width: win.width, paddingVertical: 100 }}
      >
        <View
          style={{
            alignSelf: "flex-start",
            borderRadius: 10,
            width: win.width * 0.5,
            marginLeft: win.width * 0.1,
            backgroundColor: "white",
            paddingTop: 20,
          }}
        >
          <Text style={{ textAlign: "center", color: "#B51700", fontSize: 55 }}>
            Book Your{" "}
            <Text style={{ color: "#0C5397", fontWeight: "700" }}>
              FREE Session
            </Text>
          </Text>
          {fields.map((f, fi) => {
            return (
              <View
                style={[
                  { width: "90%", marginVertical: 22, alignSelf: "center" },
                ]}
                key={"filed" + (fi + 1)}
              >
                <Input
                  from="home"
                  data={f}
                  onChange={(v) => {
                    setData(fi, v);
                  }}
                />
              </View>
            );
          })}
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: 22,
              fontWeight: "600",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            Which course you want to pursue?
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              justifyContent: "center",
              width: "60%",
              marginBottom: "5%",
            }}
          >
            {courses.map((course, i) => (
              <TouchableOpacity
                key={i}
                onPress={() => {
                  setCourse(true, i);
                }}
              >
                <View
                  style={{
                    borderWidth: 0.25,
                    borderColor: course.isSelected ? "#0C5397" : "#707070",
                    borderRadius: 50,
                    paddingHorizontal: 15,
                    paddingVertical: 5,
                    marginHorizontal: 8,
                    backgroundColor: course.isSelected ? "#0C5397" : "#fff",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: course.isSelected ? "#fff" : "#000",
                    }}
                  >
                    {course.name}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: 22,
              fontWeight: "600",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            {courses.filter((a) => a.isSelected).map((a) => a.name)[0] ==
            "Mbbs Abroad"
              ? "Did you qualified NEET EXAM ?"
              : courses.filter((a) => a.isSelected).map((a) => a.name)[0] ==
                "Undergraduate"
              ? "Did you completed your class 12th ?"
              : "Did you completed your graduation ?"}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              justifyContent: "center",
              width: "60%",
              marginBottom: "5%",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setStatus("Yes");
              }}
            >
              <View
                style={{
                  borderWidth: 0.25,
                  borderColor: status == "Yes" ? "#0C5397" : "#707070",
                  borderRadius: 50,
                  paddingHorizontal: 15,
                  paddingVertical: 5,
                  marginHorizontal: 8,
                  backgroundColor: status == "Yes" ? "#0C5397" : "#fff",
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: status == "Yes" ? "#fff" : "#000",
                  }}
                >
                  Yes
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setStatus("No");
              }}
            >
              <View
                style={{
                  borderWidth: 0.25,
                  borderColor: status == "No" ? "#0C5397" : "#707070",
                  borderRadius: 50,
                  paddingHorizontal: 15,
                  paddingVertical: 5,
                  marginHorizontal: 8,
                  backgroundColor: status == "No" ? "#0C5397" : "#fff",
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: status == "No" ? "#fff" : "#000",
                  }}
                >
                  No
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: 22,
              fontWeight: "600",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            Your dream destination to study abroad?
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              justifyContent: "center",
              width: "70%",
              flexWrap: "wrap",
              marginBottom: "5%",
            }}
          >
            {countries.map((a, i) => (
              <TouchableOpacity onPress={() => setCountry(true, i)} key={i}>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: a.isSelected ? "#0C5397" : "#707070",
                    borderRadius: 50,
                    paddingHorizontal: 15,
                    paddingVertical: 5,
                    marginHorizontal: 10,
                    marginVertical: 8,
                    backgroundColor: a.isSelected ? "#0C5397" : "#fff",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: a.isSelected ? "#fff" : "#000",
                    }}
                  >
                    {a.name}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: 18,
              fontWeight: "400",
            }}
          >
            By submitting this form, you agree to the Terms of Use and Privacy
            Policy.
          </Text>
          <TouchableOpacity onPress={() => bookSession()}>
            <View
              style={{
                backgroundColor: "#B51700",
                borderRadius: 5,
                paddingVertical: 20,
                width: "30%",
                alignSelf: "center",
                marginVertical: "4%",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: "#fff",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Submit
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ImageBackground>
      <View
        style={[
          styles.row,
          styles.justifyCenter,
          { backgroundColor: "#FAFAFA" },
        ]}
      >
        <View>
          <View style={[styles.row, styles.justifyCenter]}>
            <View style={[{ height: "100%" }]}>
              <ImageBackground
                source={require("../assets/home_page/counselling.svg")}
                style={[
                  {
                    height: win.height * 0.8,
                    paddingBottom: 105,
                    paddingTop: 75,
                    width: win.width,
                  },
                ]}
              >
                <View
                  style={[
                    styles.column,
                    {
                      width: "100%",
                      height: "100%",
                      paddingHorizontal: "10%",
                      marginTop: 70,
                    },
                  ]}
                >
                  <View style={[styles.row]}>
                    <Text
                      style={[styles.textRed, { fontSize: 55 }, styles.text]}
                    >
                      Make Your{" "}
                    </Text>
                    <Text
                      style={[
                        styles.textPrimary,
                        styles.fontBold,
                        { fontSize: 55 },
                      ]}
                    >
                      Future Bright
                    </Text>
                  </View>
                  <View style={[styles.row, styles.justifyStart]}>
                    <Text
                      style={[
                        { fontSize: 30 },
                        styles.text,
                        styles.textPrimary,
                      ]}
                    >
                      Get a dedicated career counsellor
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      styles.alignItemsCenter,
                      { marginBottom: 30, marginTop: 50, marginLeft: "55%" },
                    ]}
                  >
                    <Text
                      style={{ marginTop: 5, fontSize: 10, marginRight: 15 }}
                    >
                      {"\u2B24"}
                    </Text>
                    <Text style={[{ fontSize: 25 }, styles.text]}>
                      Connect directly with mentor
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      styles.noWrap,
                      styles.alignItemsCenter,
                      { marginBottom: 30, marginLeft: "55%" },
                    ]}
                  >
                    <Text
                      style={{ marginTop: 5, fontSize: 10, marginRight: 15 }}
                    >
                      {"\u2B24"}
                    </Text>
                    <Text style={[{ fontSize: 25 }, styles.text]}>
                      Helps you to take right and most suitable career path
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      styles.noWrap,
                      styles.alignItemsCenter,
                      { marginBottom: 30, marginLeft: "55%" },
                    ]}
                  >
                    <Text
                      style={{ marginTop: 5, fontSize: 10, marginRight: 15 }}
                    >
                      {"\u2B24"}
                    </Text>
                    <Text style={[{ fontSize: 25 }, styles.text]}>
                      Career recommendation based on profile evaluation
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      styles.noWrap,
                      styles.alignItemsCenter,
                      { marginBottom: 30, marginLeft: "55%" },
                    ]}
                  >
                    <Text
                      style={{ marginTop: 5, fontSize: 10, marginRight: 15 }}
                    >
                      {"\u2B24"}
                    </Text>
                    <Text style={[{ fontSize: 25 }, styles.text]}>
                      Get relieved from complex process of college admission
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      styles.justifyStart,
                      styles.noWrap,
                      styles.alignItemsCenter,
                      { marginBottom: 30, marginLeft: "55%" },
                    ]}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate("SignInBase");
                      }}
                    >
                      <LinearGradient
                        // Button Linear Gradient
                        colors={["#DB273E", "#C80D53"]}
                        style={[
                          styles.boxShadow,
                          {
                            borderRadius: 5,
                            paddingHorizontal: 28,
                            paddingVertical: 12,
                            height: 68,
                          },
                          styles.justifyCenter,
                          styles.boxShadow,
                        ]}
                      >
                        <Text
                          style={[
                            { color: "#ffffff" },
                            styles.fontBold,
                            styles.font20,
                          ]}
                        >
                          Get Started
                        </Text>
                      </LinearGradient>
                    </TouchableOpacity>
                  </View>
                </View>
              </ImageBackground>
            </View>
          </View>
        </View>
      </View>
      {/* Compare Universities */}
      <Image
        source={{
          uri: require("../assets/home_page/compare_university.svg"),
        }}
        style={{ width: win.width, height: win.height * 0.8 }}
      />
      {/* Discover your destination */}
      <View
        style={[
          styles.row,
          styles.justifyCenter,
          styles.bgWhite,
          { paddingBottom: 98 },
        ]}
      >
        <View
          style={[{ width: "50%", paddingLeft: "8%" }, styles.justifyCenter]}
        >
          <View style={[styles.row, styles.justifyStart]}>
            <Text style={[styles.textRed, { fontSize: 45 }, styles.text]}>
              Discover{" "}
            </Text>
            <Text
              style={[styles.textPrimary, styles.fontBold, { fontSize: 45 }]}
            >
              Your Destination
            </Text>
          </View>
          <View style={[styles.row, styles.justifyStart]}>
            <Text style={[styles.text, { fontSize: 25 }]}>
              Study abroad across globe. It’s within your reach. Tell us your
              priority and we will find your best destination which matches your
              profile.
            </Text>
          </View>
          <View
            style={[
              styles.row,
              styles.justifyStart,
              styles.noWrap,
              { marginTop: 36 },
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("SignInBase");
              }}
            >
              <LinearGradient
                // Button Linear Gradient
                colors={["#DB273E", "#C80D53"]}
                style={[
                  styles.boxShadow,
                  {
                    borderRadius: 5,
                    paddingHorizontal: 28,
                    paddingVertical: 12,
                    height: 68,
                  },
                  styles.justifyCenter,
                  styles.boxShadow,
                ]}
              >
                <Text
                  style={[{ color: "#ffffff" }, styles.fontBold, styles.font20]}
                >
                  Get Started
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ width: "50%" }}>
          <img src={require("../assets/home_page/destination_all.svg")} />
        </View>
      </View>
      {/* MBBS in Italy */}
      <View
        style={[
          styles.row,
          styles.justifyCenter,
          { backgroundColor: "#DDDDDD33" },
        ]}
      >
        <ImageBackground
          source={require("../assets/home_page/italy1.svg")}
          style={[{ height: "100%", paddingBottom: 239, width: "100vw" }]}
        >
          <View style={[styles.justifyCenter, { marginTop: 79 }]}>
            <View style={[styles.row, styles.justifyCenter]}>
              <Text style={[styles.textRed, { fontSize: 55 }, styles.text]}>
                MBBS{" "}
              </Text>
              <Text
                style={[styles.textPrimary, styles.fontBold, { fontSize: 55 }]}
              >
                in ITALY
              </Text>
            </View>
            {/* <View style={[styles.row, styles.justifyCenter]}>
							<Text style={[styles.text, { fontSize: 40 }]}>IMAT PATH</Text>
						</View> */}
            <View
              style={[styles.row, styles.justifyCenter, { marginTop: 100 }]}
            >
              <Text style={[styles.text, { fontSize: 40 }]}>
                Offline/Online IMAT Tuition
              </Text>
            </View>
            <View style={[styles.row, styles.justifyCenter]}>
              <Text style={[styles.text, { fontSize: 40 }]}>
                Pioneer in IMAT coaching in INDIA
              </Text>
            </View>
            {/* <View style={[styles.row, styles.justifyCenter, { marginTop: 30 }]}>
							<LinearGradient
								// Button Linear Gradient
								colors={["#DB273E", "#C80D53"]}
								style={[
									styles.boxShadow,
									{
										borderRadius: 5,
										paddingHorizontal: 28,
										paddingVertical: 12,
										height: 68,
									},
									styles.justifyCenter,
									styles.boxShadow,
								]}
							>
								<TouchableOpacity onPress={() => {}}>
									<Text
										style={[
											{ color: "#ffffff" },
											styles.fontBold,
											styles.font20,
										]}
									>
										IMAT PATH
									</Text>
								</TouchableOpacity>
							</LinearGradient>
						</View> */}
            <View style={[styles.row, styles.justifyCenter, { marginTop: 38 }]}>
              <Text style={[styles.text, { fontSize: 40 }]}>
                Register for 2023 IMAT
              </Text>
            </View>
            <View style={[styles.row, styles.justifyCenter, { marginTop: 30 }]}>
              <LinearGradient
                // Button Linear Gradient
                colors={["#DB273E", "#C80D53"]}
                style={[
                  styles.boxShadow,
                  {
                    borderRadius: 5,
                    paddingHorizontal: 28,
                    paddingVertical: 12,
                    height: 68,
                  },
                  styles.justifyCenter,
                  styles.boxShadow,
                ]}
              >
                <TouchableOpacity onPress={() => {}}>
                  <Text
                    style={[
                      { color: "#ffffff" },
                      styles.fontBold,
                      styles.font20,
                    ]}
                  >
                    REGISTER NOW
                  </Text>
                </TouchableOpacity>
              </LinearGradient>
            </View>
          </View>
        </ImageBackground>
      </View>
      {/* Apply for VISA */}
      <View
        style={[
          styles.row,
          styles.justifyCenter,
          { backgroundColor: "#DDDDDD33" },
        ]}
      >
        <ImageBackground
          source={require("../assets/home_page/visa_all.svg")}
          style={[
            {
              height: "100%",
              paddingBottom: 65,
              width: win.width,
              zIndex: 2,
            },
          ]}
        >
          <View style={[styles.justifyStart, { marginLeft: "20%" }]}>
            <View style={[styles.row, styles.justifyStart, { marginTop: 70 }]}>
              <Text style={[styles.textRed, { fontSize: 55 }, styles.text]}>
                Apply{" "}
              </Text>
              <Text
                style={[styles.textPrimary, styles.fontBold, { fontSize: 55 }]}
              >
                for VISA
              </Text>
            </View>
            <View style={[styles.row, styles.justifyStart, { marginTop: 36 }]}>
              <Text style={[styles.text, { fontSize: 25 }]}>
                Easy and hassle less process for knowing{" "}
              </Text>
            </View>
            <View style={[styles.row, styles.justifyStart]}>
              <Text style={[styles.text, { fontSize: 25 }]}>
                your VISA status from dashboard.
              </Text>
            </View>
            <View style={[styles.row, styles.justifyStart, { marginTop: 30 }]}>
              <TouchableOpacity
                onPress={() => {}}
                style={{
                  paddingHorizontal: 42,
                  paddingVertical: 34,
                  backgroundColor: "#EFCBFF",
                  borderRadius: 10,
                }}
              >
                <Text style={[styles.text, styles.font20]}>
                  Sign Up FREE to know visa status
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ImageBackground>
      </View>
      {/* News and Events */}
      <View
        style={[
          styles.row,
          styles.justifyEnd,
          styles.alignItemsEnd,
          styles.bgWhite,
          { paddingBottom: 76, paddingTop: 119 },
        ]}
      >
        <View style={[{ width: "50%" }]}>
          <img
            src={require("../assets/home_page/news1.svg")}
            style={{ maxWidth: 667 }}
          />
        </View>
        <View style={{ width: "50%" }}>
          <View style={[styles.row, styles.justifyStart]}>
            <Text style={[styles.textRed, { fontSize: 55 }, styles.text]}>
              Be{" "}
            </Text>
            <Text
              style={[styles.textPrimary, styles.fontBold, { fontSize: 55 }]}
            >
              Connected
            </Text>
          </View>
          <View style={[styles.row, styles.justifyStart]}>
            <Text style={[styles.textRed, { fontSize: 55 }, styles.text]}>
              News{" "}
            </Text>
            <Text
              style={[styles.textPrimary, styles.fontBold, { fontSize: 55 }]}
            >
              & Events
            </Text>
          </View>
          <View style={[styles.row, styles.justifyStart, { marginTop: 29 }]}>
            <Text style={[styles.text, { fontSize: 25 }]}>
              Access to all news and events related to study abroad.
            </Text>
          </View>
          <View style={[styles.row, styles.justifyStart]}>
            <Text style={[styles.text, { fontSize: 25 }]}>
              Sign up for FREE events online.
            </Text>
          </View>
          <View style={[styles.row, styles.justifyStart]}>
            <img src={require("../assets/home_page/news2.svg")} />
          </View>
          <View style={[styles.row, styles.justifyStart]}>
            <LinearGradient
              // Button Linear Gradient
              colors={["#DB273E", "#C80D53"]}
              style={[
                styles.boxShadow,
                {
                  borderRadius: 5,
                  paddingHorizontal: 28,
                  paddingVertical: 12,
                  height: 68,
                },
                styles.justifyCenter,
                styles.boxShadow,
              ]}
            >
              <TouchableOpacity onPress={() => {}}>
                <Text
                  style={[{ color: "#ffffff" }, styles.fontBold, styles.font20]}
                >
                  News & Articles
                </Text>
              </TouchableOpacity>
            </LinearGradient>
          </View>
        </View>
      </View>
      {/* email */}
      <KeepMePostedEmail />
      {/* countries */}
      <FooterInfo />
      {/* fOOTER */}
      <Footer />
      {/* </View> */}
    </>
  );
};

export default Home;
