import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { saveUserFilters } from "../../actions/appActions";
import { userSignUp } from "../../actions/userActions";
import Input from "../../components/Input";
import MultiSelect from "../../components/Select";
import apisrv from "../../service/ApiService";
import { styles } from "../../styles/styles";

class SignUp extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleCourse: "mbbs",
      fields: [
        {
          type: "inputGrey",
          placeholder: "Email ID",
          keyboardType: "email-address",
          autoComplete: "email",
          key: "email",
          pattern:
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*[\.]*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
        },
        {
          type: "inputGrey",
          placeholder: "Password",
          isPassword: true,
          key: "password",
        },
        {
          type: "inputGrey",
          placeholder: "Name",
          key: "name",
        },
        {
          type: "mobileWithCountryCode",
          placeholder: "Mobile Number",
          keyboardType: "phone-pad",
          autoComplete: "tel",
          key: "moblie",
          help: "OTP will be sent to this mobile number.",
          options: [
            {
              label: "+91",
              value: "91",
            },
          ],
        },
        {
          type: "selectGrey",
          placeholder: "City",
          key: "city",
          options: [
            {
              label: "Your City",
              value: "",
            },
            {
              label: "C1",
              value: "c1",
            },
          ],
        },
      ],
      step: 1,
      checkbox: {
        type: "checkbox",
        label: "I agree to Terms & Conditions.",
      },
      data: {},
      countries: [
        {
          id: "Bangladesh",
          name: "Bangladesh",
        },
        {
          id: "China",
          name: "China",
        },
        {
          id: "Egypt",
          name: "Egypt",
        },
        {
          id: "Georgia",
          name: "Georgia",
        },
        {
          id: "Kazakhstan",
          name: "Kazakhstan",
        },
        {
          id: "Malaysia",
          name: "Malaysia",
        },
        {
          id: "Mauritus",
          name: "Mauritus",
        },
        {
          id: "Nepal",
          name: "Nepal",
        },
        {
          id: "Philippines",
          name: "Philippines",
        },
        {
          id: "Russia",
          name: "Russia",
        },
        {
          id: "Ukraine",
          name: "Ukraine",
        },
      ],
      yrsofPassing: [
        {
          label: "Select 12th passing year",
          value: "",
        },
        {
          label: "2021",
          value: "2021",
        },
        {
          label: "2022",
          value: "2022",
        },
      ],
      courses: [
        {
          label: "Select course",
          value: "",
        },
        {
          label: "Engineering",
          value: "Engineering",
        },
        {
          label: "MS",
          value: "MS",
        },
      ],
      pageConfig: {
        addressFlds: {
          fields: [],
        },
        basicDetails: [],
        educationDetails: null,
        studyAbroadDetails: {
          toggle: {
            options: [
              {
                label: "MBBS Abroad",
                value: "MBBS Abroad",
              },
              {
                label: "Other Courses",
                value: "Other Courses",
              },
            ],
          },
          mbbs: {
            countries: [
              {
                label: "Bangladesh",
                value: "Bangladesh",
              },
              {
                label: "China",
                value: "China",
              },
              {
                label: "Egypt",
                value: "Egypt",
              },
              {
                label: "Georgia",
                value: "Georgia",
              },
              {
                label: "Kazakhstan",
                value: "Kazakhstan",
              },
              {
                label: "Malaysia",
                value: "Malaysia",
              },
              {
                label: "Mauritus",
                value: "Mauritus",
              },
              {
                label: "Nepal",
                value: "Nepal",
              },
              {
                label: "Philippines",
                value: "Philippines",
              },
              {
                label: "Russia",
                value: "Russia",
              },
              {
                label: "Ukraine",
                value: "Ukraine",
              },
              {
                label: "Others",
                value: "Others",
              },
            ],
            years: [
              {
                label: "2022",
                value: "2022",
              },
              {
                label: "2023",
                value: "2023",
              },
              {
                label: "2024",
                value: "2024",
              },
              {
                label: "2025",
                value: "2025",
              },
              {
                label: "Later",
                value: "Later",
              },
            ],
            intake: [
              {
                label: "Apr - Jul",
                value: "Apr - Jul",
              },
              {
                label: "Aug - Nov",
                value: "Aug - Nov",
              },
              {
                label: "Dec - Mar",
                value: "Dec - Mar",
              },
            ],
            objectives: {
              options: [
                {
                  label: "Get education from top most universities.",
                  value: "Get education from top most universities.",
                  id: 1,
                  isActive: true,
                },
                {
                  label: "Better earning opportunities and job safety.",
                  value: "Better earning opportunities and job safety.",
                  id: 2,
                  isActive: true,
                },
                {
                  label: "International lifestyle.",
                  value: "International lifestyle.",
                  id: 3,
                  isActive: true,
                },
                {
                  label: "Low cost/Budget.",
                  value: "Low cost/Budget.",
                  id: 4,
                  isActive: true,
                },
              ],
            },
            budget: [
              {
                label: "< ₹ 10 Lakh",
                value: "lessthan10Lakh",
              },
              {
                label: "₹ 10 Lakh",
                value: "10lakh",
              },
              {
                label: "₹ 20 Lakh",
                value: "20lakh",
              },
              {
                label: "₹ 40 Lakh",
                value: "40lakh",
              },
              {
                label: "> ₹ 40 Lakh",
                value: "greaterThan40lakh",
              },
            ],
            toggle: {
              type: "toggle",
              offLabel: "No",
              onLabel: "Yes",
            },
            neetScore: {
              type: "inputBlue",
              placeholder: "NEET Score",
              styles: {
                width: 128,
              },
            },
            passportFields: [
              {
                type: "inputBlue",
                placeholder: "Passport Number",
                label: "Passport Number",
                key: "passportNumber",
              },
              {
                type: "select",
                label: "Country of Issue",
                key: "countryOfIssue",
                options: [
                  {
                    label: "Select Country Name",
                    value: "",
                  },
                  {
                    label: "India",
                    value: "India",
                  },
                ],
              },
              {
                type: "monthYear",
                label: "Issued Month-Year",
                key: "issuedMonthYear",
              },
              {
                type: "monthYear",
                label: "Expiry Month-Year",
                key: "expiryMonthYear",
              },
            ],
          },
        },
        test: {
          englishTests: [
            {
              label: "IELTS",
              value: "IELTS",
            },
            {
              label: "TOEFL",
              value: "TOEFL",
            },
            {
              label: "PTE",
              value: "PTE",
            },
            {
              label: "Didn’t have planned yet",
              value: "Didn’t have planned yet",
            },
          ],
          otherTests: [
            {
              label: "ACT",
              value: "ACT",
            },
            {
              label: "GMAT",
              value: "GMAT",
            },
            {
              label: "GRE",
              value: "GRE",
            },
            {
              label: "SAT",
              value: "SAT",
            },
            {
              label: "Didn’t have planned yet",
              value: "Didn’t have planned yet",
            },
          ],
          testTaken: {
            type: "radio",
            options: [
              {
                label: "Taken",
                value: "Taken",
              },
              {
                label: "Planned",
                value: "Planned",
              },
            ],
          },
          visaRefused: [
            {
              type: "select",
              label: "Issued by Country?",
              key: "issueCountry",
              options: [
                {
                  label: "Bangladesh",
                  value: "Bangladesh",
                },
                {
                  label: "China",
                  value: "China",
                },
                {
                  label: "Egypt",
                  value: "Egypt",
                },
                {
                  label: "Georgia",
                  value: "Georgia",
                },
                {
                  label: "Kazakhstan",
                  value: "Kazakhstan",
                },
                {
                  label: "Malaysia",
                  value: "Malaysia",
                },
                {
                  label: "Mauritus",
                  value: "Mauritus",
                },
                {
                  label: "Nepal",
                  value: "Nepal",
                },
                {
                  label: "Philippines",
                  value: "Philippines",
                },
                {
                  label: "Russia",
                  value: "Russia",
                },
                {
                  label: "Ukraine",
                  value: "Ukraine",
                },
                {
                  label: "Others",
                  value: "Others",
                },
              ],
            },
            {
              type: "monthYear",
              key: "rejectionDate",
              label: "Rejection Month-Year",
            },
            {
              type: "inputBlue",
              key: "rejectionYear",
              label: "Rejection Reason",
            },
          ],
        },
        timeToReach: [
          {
            label: "Morning | 9AM - 12NOON",
            value: "morning",
            icon: require("../../assets/dashboard/profile/morning.svg"),
          },
          {
            label: "Noon | 12PM - 4PM",
            value: "noon",
            icon: require("../../assets/dashboard/profile/noon.svg"),
          },
          {
            label: "Evening | 4PM - 8PM",
            value: "evening",
            icon: require("../../assets/dashboard/profile/evening.svg"),
          },
        ],
        modeOfReach: [
          {
            label: "Phone",
            value: "Phone",
            icon: require("../../assets/dashboard/profile/phone.svg"),
          },
          {
            label: "Email",
            value: "Email",
            icon: require("../../assets/dashboard/profile/email.svg"),
          },
          {
            label: "Message",
            value: "Message",
            icon: require("../../assets/dashboard/profile/message.svg"),
          },
          {
            label: "All",
            value: "All",
            icon: require("../../assets/dashboard/profile/all.svg"),
          },
        ],
      },
      selectedCountries: [],
      from: null,
      sideImg: require("../../assets/signin/signup.svg"),
    };
  }
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get("from");
    this.setState({ sections: this.props.appData.pageConfig, from: from });
    if (from == "userHome") {
      this.setState({ step: 2 });
    }
  }

  componentDidUpdate() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get("from");
    if (this.props?.userData?.userDetails?.userId) {
      if (from == "ApplyNow" || from == "userHome") {
        if (
          this.state.step !== 2 &&
          this.state.step !== 3 &&
          this.state.step !== 4
        ) {
          this.setState({ step: 2 });
        }
      } else {
        this.props.navigation.navigate("Dashboard");
      }
    }
  }

  setStudyAbroadData = (key, value) => {
    let stateObj = this.state;
    stateObj.pageConfig.studyAbroadDetails[key] = value;
    this.setState(stateObj);
  };

  onSelectedItemsChange = (selectedCountries) => {
    this.setState({ selectedCountries });
  };

  setData = (key, v) => {
    let stateObj = this.state;
    stateObj.data[key] = v.value;
    this.setState(stateObj);
  };

  save = (from) => {
    let req = this.state.data;
    req.firstName = (req.name && req.name.split(" ")[0]) || "";
    req.lastName = (req.name && req.name.split(" ")[1]) || "";
    req.address = {
      city: req.city,
    };

    this.props.appData?.mbbsUserFilters &&
    Object.keys(this.props.appData.mbbsUserFilters).length
      ? (req.mbbsCollegeFilters = this.props.appData.mbbsUserFilters)
      : null;
    this.props.appData?.shortListedColleges &&
    this.props.appData?.shortListedColleges.length
      ? (req.shortlistedCourses = this?.props?.appData?.shortListedColleges)
      : null;

    this.props.userSignUp(req);
    this.props.saveUserFilters("shortListedColleges", []);
    this.props.saveUserFilters("mbbsUserFilters", {});
  };
  submitData = () => {
    let req = {
      userId: this.props?.userData?.userDetails?.userId,
      mbbsAbroad: {
        year: this.state.data?.monthYear?.year,
        intake: this.state.data?.monthYear?.month,
        country: this.state.selectedCountries,
        passingYear: this.state.data?.selectedyr,
        appearedNeet: this.state.pageConfig.studyAbroadDetails.gaveNeet,
        neet: {
          year: null,
          score: this.state.pageConfig.studyAbroadDetails.mbbs.neetScore,
          category: null,
        },
        passportIssued: this.state.pageConfig.studyAbroadDetails.validPassport,
        preferredTimeToReach:
          this.state.pageConfig?.basicDetails?.preferredTimeToReach,
        preferredModeToReach:
          this.state.pageConfig?.basicDetails?.preferredModeOfReach,
      },
    };

    apisrv.callApi({
      endPoint: "user/preferences",
      request: req,
      onSuccess: (res) => {
        if (res?.success) {
          this.setState({ step: 4 });
        }
      },
    });
  };
  render() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get("from");
    if (this.state.step == 1) {
      return (
        <View
          style={[
            { padding: 56, overflow: "auto", marginBottom: 160, width: "70vw" },
          ]}
        >
          <View style={[styles.row, styles.justifyStretch]}>
            <View style={[{ width: "50%" }]}>
              <View style={[styles.row, styles.justifyStart]}>
                <Text
                  style={[styles.textPrimary, styles.font45, styles.fontBold]}
                >
                  Create your account
                </Text>
              </View>
              <View style={[styles.row, styles.justifyStart]}>
                <Text style={[styles.font25, styles.text]}>
                  Get your personal dashboard to track your application/VISA
                  status.
                </Text>
              </View>
              <View
                style={[styles.row, styles.justifyStart, { marginTop: 52 }]}
              >
                <Text style={[styles.font30, styles.fontBold, styles.text]}>
                  Personal details:{" "}
                </Text>
              </View>
              <View style={[styles.row, styles.justifyStart]}>
                <Text style={[styles.font25, styles.text]}>
                  This will create your account and enable you to receive
                  updates and recommendation.
                </Text>
              </View>
            </View>
            <View style={[{ width: "50%" }]}>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}
              >
                <Image
                  source={{ uri: this.state.sideImg }}
                  style={{ width: 360, height: 320, resizeMode: "center" }}
                />
              </View>
            </View>
          </View>
          <View
            style={[styles.row, styles.justifyStart, { marginVertical: 31 }]}
          >
            <View>
              <TouchableOpacity
                style={[styles.inputTextGrey]}
                onPress={this.save}
              >
                <Text
                  style={[styles.textGrey2, styles.fontBold, styles.font20]}
                >
                  GOOGLE
                </Text>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                style={[styles.inputTextGrey, { marginLeft: 26 }]}
                onPress={this.save}
              >
                <Text
                  style={[styles.textGrey2, styles.fontBold, styles.font20]}
                >
                  FACEBOOK
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={[styles.row, styles.justifyStart, { marginVertical: 35 }]}
          >
            <Text style={[styles.font20, styles.text]}>
              <Text>By continuing, you agree to our </Text>
              <Text style={[styles.textPrimary, styles.fontBold]}>
                Privacy Policy{" "}
              </Text>
              <Text>& </Text>
              <Text style={[styles.textPrimary, styles.fontBold]}>
                Terms & Conditions.
              </Text>
            </Text>
          </View>
          <View
            style={[
              styles.row,
              styles.justifyStretch,
              styles.alignItemsCenter,
              { marginTop: 20 },
            ]}
          >
            <View
              style={[
                styles.flex,
                styles.flexGrow,
                { borderBottomWidth: 0.25, borderBottomColor: "#707070" },
              ]}
            ></View>
            <View style={[{ marginHorizontal: 24 }]}>
              <Text style={[styles.text, styles.font30]}>Or</Text>
            </View>
            <View
              style={[
                styles.flex,
                styles.flexGrow,
                { borderBottomWidth: 0.25, borderBottomColor: "#707070" },
              ]}
            ></View>
          </View>
          <View style={[styles.row, styles.justifyStretch, styles.wrap]}>
            {this.state.fields.map((f, fi) => {
              return (
                <View
                  style={[{ width: "48%", marginVertical: 22 }]}
                  key={"filed" + (fi + 1)}
                >
                  <Input
                    data={f}
                    onChange={(v) => {
                      this.setData(f.key, v);
                    }}
                  />
                </View>
              );
            })}
          </View>
          <View style={[styles.row, styles.justifyStart, { marginTop: 32 }]}>
            <Input
              data={this.state.checkbox}
              onChange={(v) => {
                this.setData("tnc", v);
              }}
            />
          </View>
          <View style={[styles.row, styles.justifyCenter, { marginTop: 32 }]}>
            <TouchableOpacity
              style={[styles.btnPrimary]}
              onPress={() => this.save(from)}
            >
              <Text
                style={[
                  { color: "#ffffff", textTransform: "uppercase" },
                  styles.text,
                  styles.fontBold,
                  styles.font25,
                ]}
              >
                Sign Up
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (this.state.step == 2) {
      return (
        <View
          style={[
            { padding: 56, overflow: "auto", marginBottom: 160, width: "70vw" },
          ]}
        >
          <View style={[styles.row, styles.justifyStretch]}>
            <View style={[{ width: "50%" }]}>
              <View style={[styles.row, styles.justifyStart]}>
                <Text
                  style={[styles.textPrimary, styles.font45, styles.fontBold]}
                >
                  Hello Kumar
                </Text>
              </View>
              <View style={[styles.row, styles.justifyStart]}>
                <Text style={[styles.font25, styles.text]}>
                  Need few details more to understand your requirement
                </Text>
              </View>
              <Text
                style={{
                  fontSize: 25,
                  fontFamily: "Montserrat-SemiBold",
                  color: "#000",
                }}
              >
                Your Preference
              </Text>
              <View
                style={[
                  styles.row,
                  styles.justifyStart,
                  { marginVertical: 20 },
                ]}
              >
                <View>
                  <TouchableOpacity
                    style={[
                      styles.inputTextGrey,
                      { backgroundColor: "#fff", paddingVertical: 15 },
                      this.state.toggleCourse == "mbbs"
                        ? {
                            borderWidth: 0.25,
                            borderColor: "#B51800",
                            backgroundColor: "#efefef",
                            borderLeftWidth: 15,
                          }
                        : null,
                    ]}
                    onPress={() => this.setState({ toggleCourse: "mbbs" })}
                  >
                    <Text
                      style={[
                        { fontFamily: "Montserrat-SemiBold", color: "#000" },

                        styles.font20,
                      ]}
                    >
                      MBBS Abroad
                    </Text>
                  </TouchableOpacity>
                </View>
                <View>
                  <TouchableOpacity
                    style={[
                      styles.inputTextGrey,
                      { backgroundColor: "#fff", paddingVertical: 15 },
                      this.state.toggleCourse == "other"
                        ? {
                            borderWidth: 0.25,
                            borderColor: "#B51800",
                            backgroundColor: "#efefef",
                            borderLeftWidth: 15,
                          }
                        : null,
                    ]}
                    onPress={() => this.setState({ toggleCourse: "other" })}
                  >
                    <Text
                      style={[
                        { fontFamily: "Montserrat-SemiBold", color: "#000" },
                        styles.font20,
                      ]}
                    >
                      Other Courses
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "Montserrat-SemiBold",
                    color: "#000",
                  }}
                >
                  When you plan to start your studies:
                </Text>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Montserrat",
                    color: "#000",
                    marginTop: 10,
                  }}
                >
                  This will help us to recommend you your country & course of
                  interest.
                </Text>
                <View style={{ marginTop: "2%", marginBottom: "5%" }}>
                  <Input
                    data={{
                      type: "monthYear",
                      styles: { width: "50%" },
                      screen: "brochure",
                    }}
                    onChange={(v) => {
                      this.setData("monthYear", v);
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={[{ width: "50%" }]}>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}
              >
                <Image
                  source={{ uri: this.state.sideImg }}
                  style={{ width: 360, height: 320, resizeMode: "center" }}
                />
              </View>
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: 25,
                fontFamily: "Montserrat-SemiBold",
                color: "#000",
                marginBottom: "2%",
              }}
            >
              Your preferred country:
            </Text>
            <MultiSelect
              hideTags
              items={this.state.countries}
              uniqueKey="id"
              ref={(component) => {
                this.multiSelect = component;
              }}
              onSelectedItemsChange={this.onSelectedItemsChange}
              selectedItems={this.state.selectedCountries}
              selectText={"Select country"}
              styleDropdownMenu={{ width: "60%" }}
              displayKey="name"
              single={false}
              hideSubmitButton={true}
            />
            <Text
              style={{
                fontSize: 20,
                fontFamily: "Montserrat",
                color: "#000",
                marginTop: "2%",
                marginBottom: "5%",
              }}
            >
              Add upto 3 countries from list.
            </Text>
          </View>
          {this.state.toggleCourse == "mbbs" ? (
            <View>
              <View style={[styles.row, { marginTop: 5, marginBottom: 10 }]}>
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "Montserrat-SemiBold",
                    color: "#000",
                  }}
                >
                  Your 12th passing year:
                </Text>
              </View>
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "Montserrat",
                  color: "#000",
                  marginBottom: "2%",
                }}
              >
                This will help AI engine to recommend you best universities that
                are looking as per their eligibility criteria
              </Text>
              <View style={[styles.row]}>
                <View style={{ width: "60%" }}>
                  <Input
                    data={{
                      type: "select",
                      options: this.state.yrsofPassing,
                    }}
                    onChange={(data) => {
                      this.setData("selectedyr", data);
                    }}
                  />
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View style={[styles.row, { marginTop: 5, marginBottom: 10 }]}>
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "Montserrat-SemiBold",
                    color: "#000",
                  }}
                >
                  Your preferred courses to study abroad:
                </Text>
              </View>
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "Montserrat",
                  color: "#000",
                  marginBottom: "2%",
                }}
              >
                This will help AI engine to recommend you best universities for
                your choice of interest
              </Text>
              <View style={[styles.row]}>
                <View style={{ width: "60%" }}>
                  <Input
                    data={{
                      type: "select",
                      options: this.state.courses,
                    }}
                    onChange={(data) => {
                      this.setData("prefCourse", data);
                    }}
                  />
                </View>
              </View>
            </View>
          )}

          <View style={[styles.row, styles.justifyCenter, { marginTop: 32 }]}>
            <TouchableOpacity
              style={[styles.btnPrimary]}
              onPress={() => {
                this.state.selectedCountries.length > 0 &&
                  this.state.data?.selectedyr &&
                  this.state.data?.monthYear?.month &&
                  this.state.data?.monthYear?.year &&
                  this.setState({ step: 3 });
              }}
            >
              <Text
                style={[
                  { color: "#ffffff", textTransform: "uppercase" },
                  styles.text,
                  styles.fontBold,
                  styles.font25,
                ]}
              >
                Continue
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (this.state.step == 3) {
      return (
        <View
          style={[
            { padding: 56, overflow: "auto", marginBottom: 160, width: "70vw" },
          ]}
        >
          <View style={[styles.row, styles.justifyStretch]}>
            <View style={[{ width: "50%" }]}>
              <View style={[styles.row, styles.justifyStart]}>
                <Text
                  style={[styles.textPrimary, styles.font45, styles.fontBold]}
                >
                  That's great Kumar
                </Text>
              </View>
              <View style={[styles.row, styles.justifyStart]}>
                <Text style={[styles.font25, styles.text]}>
                  Just last step to get started wth finding your best match
                </Text>
              </View>
            </View>
            <View style={[{ width: "50%" }]}>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}
              >
                <Image
                  source={{ uri: this.state.sideImg }}
                  style={{ width: 360, height: 320, resizeMode: "center" }}
                />
              </View>
            </View>
          </View>

          <View
            style={[
              styles.padding8,
              styles.row,
              styles.justifyStart,
              { marginTop: 55 },
            ]}
          >
            <Text
              style={{
                fontSize: 25,
                fontFamily: "Montserrat-SemiBold",
                color: "#000",
                marginBottom: "2%",
              }}
            >
              Have you given NEET exam to study MBBS in abroad?
            </Text>
          </View>
          <View style={[styles.padding8, styles.row, styles.justifyStart]}>
            <Input
              data={this.state.pageConfig.studyAbroadDetails.mbbs.toggle}
              onChange={(v) => {
                this.setStudyAbroadData("gaveNeet", v.value);
              }}
            />
          </View>
          {this.state.pageConfig.studyAbroadDetails.gaveNeet && (
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.alignItemsCenter,
                styles.justifyStart,
                { marginTop: 50 },
              ]}
            >
              <Input
                data={this.state.pageConfig.studyAbroadDetails.mbbs.neetScore}
                onChange={(v) => {
                  this.setStudyAbroadData("neetScore", v);
                }}
              />
              <Text
                style={[
                  styles.text,
                  styles.textPrimary,
                  styles.font18,
                  { marginLeft: 16 },
                ]}
              >
                Out of 720
              </Text>
            </View>
          )}
          <View
            style={[
              styles.padding8,
              styles.row,
              styles.justifyStart,
              { marginTop: 55 },
            ]}
          >
            <Text
              style={[
                styles.text,
                styles.textPrimary,
                styles.font18,
                styles.fontBold,
              ]}
            >
              Have valid passport?
            </Text>
          </View>
          <View style={[styles.padding8, styles.row, styles.justifyStart]}>
            <Input
              data={this.state.pageConfig.studyAbroadDetails.mbbs.toggle}
              onChange={(v) => {
                this.setStudyAbroadData("validPassport", v.value);
              }}
            />
          </View>
          {this.state.pageConfig.studyAbroadDetails.validPassport && (
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.justifyStart,
                styles.wrap,
                { marginTop: 32 },
              ]}
            >
              {this.state.pageConfig.studyAbroadDetails.mbbs.passportFields.map(
                (mp, mpi) => {
                  return (
                    <View
                      key={"mp" + mpi}
                      style={{ width: "40%", marginRight: 44 }}
                    >
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                        ]}
                      >
                        <Text
                          style={[
                            styles.textPrimary,
                            styles.fontBold,
                            styles.font18,
                          ]}
                        >
                          {mp.label}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.padding8,
                          styles.row,
                          styles.justifyStart,
                        ]}
                      >
                        <Input
                          data={mp}
                          onChange={(v) => {
                            this.setStudyAbroadData(mp.key, v.value);
                          }}
                        />
                      </View>
                    </View>
                  );
                }
              )}
            </View>
          )}
          <View style={[styles.cardBlue]}>
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.justifyStart,
                styles.textRed,
                styles.fontBold,
                { fontSize: 22 },
              ]}
            >
              <Text style={[styles.textRed, styles.fontBold, { fontSize: 22 }]}>
                Prefferred Time To Reach
              </Text>
            </View>
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.justifyStart,
                styles.wrap,
                ,
                styles.textPrimary,
              ]}
            >
              {this.state.pageConfig.timeToReach.map((tm, tmi) => {
                if (
                  (
                    this.state.pageConfig?.basicDetails?.preferredTimeToReach ||
                    []
                  ).indexOf(tm.value) != -1
                ) {
                  return (
                    <TouchableOpacity
                      key={"timeToReach" + (tmi + 1)}
                      style={[
                        {
                          borderRadius: 50,
                          borderWidth: 0.5,
                          borderColor: "#5479CB",
                          padding: 3,
                          marginRight: 32,
                          backgroundColor: "#A9CDF0",
                        },
                      ]}
                      onPress={() => {
                        if (
                          (
                            this.state.pageConfig?.basicDetails
                              ?.preferredTimeToReach || []
                          ).length
                        ) {
                          let stateObj = this.state;
                          stateObj.pageConfig.basicDetails.preferredTimeToReach =
                            stateObj.pageConfig.basicDetails.preferredTimeToReach.filter(
                              (obj) => obj != tm.value
                            );
                          this.setState(stateObj);
                        }
                      }}
                    >
                      <View
                        style={[
                          styles.row,
                          styles.justifyCenter,
                          styles.alignItemsCenter,
                        ]}
                      >
                        <View
                          style={[
                            {
                              padding: 2,
                              borderWidth: 1,
                              borderColor: "#0C5397",
                              borderRadius: "50%",
                            },
                          ]}
                        >
                          <Image
                            source={{ uri: tm.icon }}
                            style={{
                              width: 58,
                              height: 58,
                              resizeMode: "center",
                            }}
                          />
                        </View>
                        <View style={{ margin: 18 }}>
                          <Text
                            style={[
                              styles.text,
                              styles.textWhite,
                              styles.font22,
                              styles.fontBold,
                            ]}
                          >
                            {tm.label}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                } else {
                  return (
                    <TouchableOpacity
                      key={"timeToReach" + (tmi + 1)}
                      style={[
                        {
                          borderRadius: 50,
                          borderWidth: 0.5,
                          borderColor: "#5479CB",
                          padding: 3,
                          marginRight: 32,
                        },
                        styles.bgWhite,
                      ]}
                      onPress={() => {
                        let stateObj = this.state;
                        if (
                          !(
                            stateObj.pageConfig.basicDetails
                              .preferredTimeToReach || []
                          ).length
                        ) {
                          stateObj.pageConfig.basicDetails.preferredTimeToReach =
                            [tm.value];
                        } else {
                          stateObj.pageConfig.basicDetails.preferredTimeToReach.push(
                            tm.value
                          );
                        }
                        this.setState(stateObj);
                      }}
                    >
                      <View
                        style={[
                          styles.row,
                          styles.justifyCenter,
                          styles.alignItemsCenter,
                        ]}
                      >
                        <View
                          style={[
                            {
                              padding: 2,
                              borderWidth: 1,
                              borderColor: "#0C5397",
                              borderRadius: "50%",
                            },
                          ]}
                        >
                          <Image
                            source={{ uri: tm.icon }}
                            style={{
                              width: 58,
                              height: 58,
                              resizeMode: "center",
                            }}
                          />
                        </View>
                        <View style={{ margin: 18 }}>
                          <Text
                            style={[
                              styles.text,
                              styles.textPrimary,
                              styles.font22,
                            ]}
                          >
                            {tm.label}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                }
              })}
            </View>
          </View>
          {/* Prefferred Mode Of Reach */}
          <View style={[styles.cardBlue]}>
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.justifyStart,
                styles.textRed,
                styles.fontBold,
                { fontSize: 22 },
              ]}
            >
              <Text style={[styles.textRed, styles.fontBold, { fontSize: 22 }]}>
                Prefferred Mode Of Reach
              </Text>
            </View>
            <View
              style={[
                styles.padding8,
                styles.row,
                styles.justifyStart,
                styles.wrap,
                ,
                styles.textPrimary,
              ]}
            >
              {this.state.pageConfig.modeOfReach.map((tm, tmi) => {
                if (
                  (
                    this.state.pageConfig?.basicDetails?.preferredModeOfReach ||
                    []
                  ).indexOf(tm.value) != -1
                ) {
                  return (
                    <TouchableOpacity
                      key={"modeOfReach" + (tmi + 1)}
                      style={[
                        {
                          borderRadius: 50,
                          borderWidth: 0.5,
                          borderColor: "#5479CB",
                          padding: 3,
                          marginRight: 32,
                          backgroundColor: "#A9CDF0",
                        },
                      ]}
                      onPress={() => {
                        if (
                          (
                            this.state.pageConfig?.basicDetails
                              ?.preferredModeOfReach || []
                          ).length
                        ) {
                          let stateObj = this.state;
                          stateObj.pageConfig.basicDetails.preferredModeOfReach =
                            stateObj.pageConfig.basicDetails.preferredModeOfReach.filter(
                              (obj) => obj != tm.value
                            );
                          this.setState(stateObj);
                        }
                      }}
                    >
                      <View
                        style={[
                          styles.row,
                          styles.justifyCenter,
                          styles.alignItemsCenter,
                        ]}
                      >
                        <View
                          style={[
                            {
                              padding: 2,
                              borderWidth: 1,
                              borderColor: "#0C5397",
                              borderRadius: "50%",
                            },
                          ]}
                        >
                          <Image
                            source={{ uri: tm.icon }}
                            style={{
                              width: 58,
                              height: 58,
                              resizeMode: "center",
                            }}
                          />
                        </View>
                        <View style={{ margin: 18 }}>
                          <Text
                            style={[
                              styles.text,
                              styles.textWhite,
                              styles.font22,
                              styles.fontBold,
                            ]}
                          >
                            {tm.label}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                } else {
                  return (
                    <TouchableOpacity
                      key={"modeOfReach" + (tmi + 1)}
                      style={[
                        {
                          borderRadius: 50,
                          borderWidth: 0.5,
                          borderColor: "#5479CB",
                          padding: 3,
                          marginRight: 32,
                        },
                        styles.bgWhite,
                      ]}
                      onPress={() => {
                        let stateObj = this.state;
                        if (
                          !(
                            stateObj.pageConfig.basicDetails
                              .preferredModeOfReach || []
                          ).length
                        ) {
                          stateObj.pageConfig.basicDetails.preferredModeOfReach =
                            [tm.value];
                        } else {
                          stateObj.pageConfig.basicDetails.preferredModeOfReach.push(
                            tm.value
                          );
                        }
                        this.setState(stateObj);
                      }}
                    >
                      <View
                        style={[
                          styles.row,
                          styles.justifyCenter,
                          styles.alignItemsCenter,
                        ]}
                      >
                        <View
                          style={[
                            {
                              padding: 2,
                              borderWidth: 1,
                              borderColor: "#0C5397",
                              borderRadius: "50%",
                            },
                          ]}
                        >
                          <Image
                            source={{ uri: tm.icon }}
                            style={{
                              width: 58,
                              height: 58,
                              resizeMode: "center",
                            }}
                          />
                        </View>
                        <View style={{ margin: 18 }}>
                          <Text
                            style={[
                              styles.text,
                              styles.textPrimary,
                              styles.font22,
                            ]}
                          >
                            {tm.label}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                }
              })}
            </View>
          </View>
          <View style={[styles.row, styles.justifyCenter, { marginTop: 32 }]}>
            <TouchableOpacity
              style={[styles.btnPrimary]}
              onPress={() => {
                this.state.pageConfig?.basicDetails?.preferredTimeToReach
                  .length > 0 &&
                  this.state.pageConfig?.basicDetails?.preferredModeOfReach
                    .length > 0 &&
                  this.submitData();
              }}
            >
              <Text
                style={[
                  { color: "#ffffff", textTransform: "uppercase" },
                  styles.text,
                  styles.fontBold,
                  styles.font25,
                ]}
              >
                Submit
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (this.state.step == 4) {
      return (
        <View
          style={[
            { padding: 56, overflow: "auto", marginBottom: 160, width: "70vw" },
          ]}
        >
          <View style={[styles.row, styles.justifyStretch]}>
            <View style={[{ width: "50%" }]}>
              <View style={[styles.row, styles.justifyStart]}>
                <Text
                  style={[styles.textPrimary, styles.font45, styles.fontBold]}
                >
                  Thanks Kumar
                </Text>
              </View>
              <View style={[styles.row, styles.justifyStart]}>
                <Text style={[styles.font25, styles.text]}>
                  Need few details more to understand your requirement
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.row, styles.justifyCenter, { marginTop: 32 }]}>
            <TouchableOpacity
              style={[styles.btnPrimary]}
              onPress={() => {
                this.props.navigation.navigate("Dashboard");
              }}
            >
              <Text
                style={[
                  { color: "#ffffff", textTransform: "uppercase" },
                  styles.text,
                  styles.fontBold,
                  styles.font25,
                ]}
              >
                Yes Go Ahead
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    appData: state?.appData,
    userData: state?.userData,
  };
};

export default connect(mapStateToProps, { userSignUp, saveUserFilters })(
  SignUp
);
