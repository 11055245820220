import { AntDesign, Entypo, FontAwesome, Ionicons } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import React, { createRef } from "react";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Modal,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from "react-redux";
import Input from "../components/Input";
import { styles } from "../styles/styles";
class CollegeFinder extends React.Component {
  constructor(props) {
    super();
    this.scrl = createRef(null);
    this.state = {
      dollartoInr: 78,
      selectedColor: "#0C5397",
      selectedFilter: null,
      isShortListed: false,
      searchedWord: "",
      loading: false,
      paginationData: {
        totalItems: 100,
        currentPage: 1,
        recordsPerPage: 10,
        noOfPages: 1,
      },
      colleges: [],
      data: {
        interest: "MBBS Abroad",
        level: "Post Graduate",
        university: "",
      },
      pageConfig: {
        level: [
          {
            label: "Post Graduate",
            value: "Post Graduate",
          },
          {
            label: "Under Graduate",
            value: "Under Graduate",
          },
        ],
        interest: [
          {
            label: "MBBS Abroad",
            value: "MBBS Abroad",
            color: "#0C5397",
          },
          {
            label: "Other Courses",
            value: "Other Courses",
            color: "#7A78B9",
          },
        ],
        "MBBS Abroad": [
          {
            label: "Basics",
            key: "Basics",
            fields: [
              {
                type: "checkbox",
                label: "Course Duration",
                key: "courseDuration",
                options: [],
                backgroundColor: "Blue",
              },
              {
                type: "checkbox",
                label: "University Type",
                key: "universityType",
                options: [],
              },
            ],
          },
          {
            label: "Country",
            key: "country",
            fields: [
              {
                type: "searchSelect",
                key: "country",
                label: "Country",
                options: [],
              },
            ],
          },
          {
            label: "Campus",
            key: "campus",
            fields: [
              {
                label: "Location",
                key: "location",
                type: "checkbox",
                options: [],
              },
              {
                label: "Campus Type",
                key: "campusType",
                type: "checkbox",
                options: [],
              },
            ],
          },
          {
            label: "Tuition Fees",
            key: "tuitionFees",
            fields: [
              {
                label: "Average Yearly Fees",
                key: "averageYearlyFees",
                type: "range",
                max: "",
              },
            ],
          },
          {
            label: "Intake",
            key: "intake",
            fields: [
              {
                label: "Month",
                key: "month",
                type: "checkbox",
                options: [
                  {
                    label: "Apr - Jul",
                    value: [],
                  },
                  {
                    label: "Aug - Nov",
                    value: [],
                  },
                  {
                    label: "Dec - Mar",
                    value: [],
                  },
                ],
              },
              {
                label: "Year",
                key: "year",
                type: "checkbox",
                options: [],
              },
            ],
          },
        ],
        "Other Courses": [
          {
            label: "Basics",
            key: "Basics",
            fields: [
              {
                type: "searchSelect",
                key: "country",
                label: "country",
                options: [
                  {
                    label: "Bangladesh",
                    value: "Bangladesh",
                  },
                  {
                    label: "China",
                    value: "China",
                  },
                  {
                    label: "Egypt",
                    value: "Egypt",
                  },
                  {
                    label: "Georgia",
                    value: "Georgia",
                  },
                  {
                    label: "Kazakhstan",
                    value: "Kazakhstan",
                  },
                  {
                    label: "Malaysia",
                    value: "Malaysia",
                  },
                  {
                    label: "Mauritus",
                    value: "Mauritus",
                  },
                  {
                    label: "Nepal",
                    value: "Nepal",
                  },
                  {
                    label: "Philippines",
                    value: "Philippines",
                  },
                  {
                    label: "Russia",
                    value: "Russia",
                  },
                  {
                    label: "Ukraine",
                    value: "Ukraine",
                  },
                  {
                    label: "Others",
                    value: "Others",
                  },
                ],
              },
              {
                type: "checkbox",
                label: "Course Duration",
                key: "courseDuration",
                options: [
                  {
                    label: "6-7 Year",
                    value: {
                      from: 6,
                      to: 7,
                    },
                  },
                  {
                    label: "5-6 Year",
                    value: {
                      from: 5,
                      to: 6,
                    },
                  },
                  {
                    label: "4-5 Year",
                    value: {
                      from: 4,
                      to: 5,
                    },
                  },
                  {
                    label: "3-4 Year",
                    value: {
                      from: 3,
                      to: 4,
                    },
                  },
                ],
              },
              {
                type: "checkbox",
                label: "University Type",
                key: "universityType",
                options: [
                  {
                    label: "Public",
                    value: "Public",
                  },
                  {
                    label: "Private",
                    value: "Private",
                  },
                ],
              },
            ],
          },
          {
            label: "Course",
            key: "course",
            fields: [
              {
                label: "Location",
                key: "location",
                type: "checkbox",
                options: [
                  {
                    label: "C1",
                    value: "C1",
                  },
                  {
                    label: "C2",
                    value: "C2",
                  },
                ],
              },
            ],
          },
          {
            label: "Campus",
            key: "Campus",
            fields: [
              {
                label: "Location",
                key: "location",
                type: "checkbox",
                options: [
                  {
                    label: "Semi-Urban",
                    value: "Semi-Urban",
                  },
                  {
                    label: "Urban",
                    value: "Urban",
                  },
                  {
                    label: "Rural",
                    value: "Rural",
                  },
                ],
              },
              {
                label: "Campus Type",
                key: "campusType",
                type: "checkbox",
                options: [
                  {
                    label: "Women’s",
                    value: "Women",
                  },
                  {
                    label: "Boy’s",
                    value: "Boy",
                  },
                  {
                    label: "Co-Ed",
                    value: "Co-Ed",
                  },
                ],
              },
            ],
          },
          {
            label: "Tuition Fees",
            key: "Tuition Fees",
            fields: [
              {
                label: "Average Yearly Fees",
                key: "averageYearlyFees",
                type: "range",
                max: "",
              },
            ],
          },
          {
            label: "Intake",
            key: "intake",
            fields: [
              {
                label: "Month",
                key: "month",
                type: "checkbox",
                options: [
                  {
                    label: "Apr - Jul",
                    value: "Apr - Jul",
                  },
                  {
                    label: "Aug - Nov",
                    value: "Aug - Nov",
                  },
                  {
                    label: "Dec - Mar",
                    value: "Dec - Mar",
                  },
                ],
              },
              {
                label: "Year",
                key: "year",
                type: "checkbox",
                options: [
                  {
                    label: "2022",
                    value: "2022",
                  },
                  {
                    label: "2023",
                    value: "2023",
                  },
                  {
                    label: "2024",
                    value: "2024",
                  },
                  {
                    label: "2025",
                    value: "2025",
                  },
                  {
                    label: "Later",
                    value: "Later",
                  },
                ],
              },
            ],
          },
        ],
      },
      scrollX: 0,
      scrollEnd: false,
    };
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }

  componentDidMount() {
    this.getBasicFields();
    this.getCountries();
    this.getCampus();
    this.getIntake();
    this.getMbbsColleges();
  }

  setCurrentPage(pageNumber) {
    this.setState({ paginationData: { currentPage: pageNumber } });
  }

  slide(shift) {
    this.scrl.current.scrollLeft += shift;
    const newScrollX = this.state.scrollX + shift;

    if (
      Math.floor(
        this.scrl.current.scrollWidth - this.scrl.current.scrollLeft
      ) <= this.scrl.current.offsetWidth
    ) {
      this.setState({ scrollEnd: true });
    } else {
      this.setState({ scrollEnd: false });
    }
    this.setState({ scrollX: newScrollX });
  }

  scrollCheck() {
    let newScrollX = this.scrl.current.scrollLeft;

    if (
      Math.floor(
        this.scrl.current.scrollWidth - this.scrl.current.scrollLeft
      ) <= this.scrl.current.offsetWidth
    ) {
      this.setState({ scrollEnd: true });
    } else {
      this.setState({ scrollEnd: false });
    }
    this.setState({ scrollX: newScrollX });
  }

  getBasicFields() {
    let body = {
      fieldOptions: [
        {
          page: "collegeFilters",
          label: "universityType",
        },
        {
          page: "mbbsFilters",
          label: "courseDurationYears",
        },
      ],
    };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    fetch(
      "https://hammerhead-app-ikpjl.ondigitalocean.app/field-options/multiple",
      requestOptions
    )
      .then(async (response) => await response.json())
      .then(async (result) => {
        result?.mbbsFilters?.courseDurationYears?.options?.map((item) => {
          this.state.pageConfig["MBBS Abroad"][0].fields[0].options.push(item);
        });
        result?.collegeFilters?.universityType?.options?.map((item) => {
          this.state.pageConfig["MBBS Abroad"][0].fields[1].options.push(item);
        });

        return this.state.basic;
      })
      .catch((error) => console.log("error", error));
  }

  getCountries() {
    let body = {
      page: "all",
      label: "countries",
    };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    fetch(
      "https://hammerhead-app-ikpjl.ondigitalocean.app/get-field-options",
      requestOptions
    )
      .then(async (response) => await response.json())
      .then(async (result) => {
        result?.options?.map((item) => {
          this.state.pageConfig["MBBS Abroad"][1].fields[0].options.push(item);
        });

        return this.state.pageConfig["MBBS Abroad"][1];
      })
      .catch((error) => console.log("error", error));
  }

  getCampus() {
    let body = {
      fieldOptions: [
        {
          page: "collegeFilters",
          label: "campusLocation",
        },
        {
          page: "collegeFilters",
          label: "campusType",
        },
      ],
    };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    fetch(
      "https://hammerhead-app-ikpjl.ondigitalocean.app/field-options/multiple",
      requestOptions
    )
      .then(async (response) => await response.json())
      .then(async (result) => {
        result?.collegeFilters?.campusLocation?.options?.map((item) => {
          this.state.pageConfig["MBBS Abroad"][2].fields[0].options.push(item);
        });
        result?.collegeFilters?.campusType?.options?.map((item) => {
          this.state.pageConfig["MBBS Abroad"][2].fields[1].options.push(item);
        });

        return this.state.pageConfig["MBBS Abroad"][2];
      })
      .catch((error) => console.log("error", error));
  }

  getIntake() {
    let body = {
      page: "collegeFilters",
      label: "intakeMonthYear",
    };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    fetch(
      "https://hammerhead-app-ikpjl.ondigitalocean.app/get-field-options",
      requestOptions
    )
      .then(async (response) => await response.json())
      .then(async (result) => {
        result?.options?.map((item, i) => {
          if ((i + 1) % 12 == 0) {
            this.state.pageConfig["MBBS Abroad"][4].fields[1].options.push({
              label: item?.value.slice(4, 8),
              value: item?.value.slice(4, 8),
            });
          }
          if (i == 3 || i == 4 || i == 5 || i == 6) {
            this.state.pageConfig[
              "MBBS Abroad"
            ][4].fields[0].options[0].value.push(item?.value.slice(0, 3));
          }
          if (i == 7 || i == 8 || i == 9 || i == 10) {
            this.state.pageConfig[
              "MBBS Abroad"
            ][4].fields[0].options[1].value.push(item?.value.slice(0, 3));
          }
          if (i == 11 || i == 0 || i == 1 || i == 2) {
            this.state.pageConfig[
              "MBBS Abroad"
            ][4].fields[0].options[2].value.push(item?.value.slice(0, 3));
          }
        });
      })
      .catch((error) => console.log("error", error));
  }

  getMbbsColleges(payload) {
    let dates = [];
    this.setState({ loading: true });
    payload?.month?.map((data) => {
      data.map((mnt) => {
        dates.push({
          month: mnt,
          year: payload?.year?.length > 0 ? parseInt(payload?.year[0]) : 2023,
        });
      });
    });
    let body = {
      userId: this.props.appData?.userData?.userDetails?.userId,
      countries:
        payload?.country?.length > 0
          ? payload?.country
          : ["India", "Bangladesh", "Russia"],
      campusType:
        payload?.campusType?.length > 0
          ? payload?.campusType
          : ["Boys", "Women", "Co-Ed"],
      campusLocation:
        payload?.location?.length > 0
          ? payload?.location
          : ["Semi-Urban", "Urban", "Rural"],
      universityType:
        payload?.universityType?.length > 0
          ? payload?.universityType
          : ["Public", "Private"],
      courseDuration:
        payload?.courseDuration?.length > 0
          ? payload?.courseDuration
          : ["3-4", "5-7"],
      intake:
        dates.length > 0
          ? [...new Set(dates)]
          : [
              {
                month: "Jan",
                year: 2023,
              },
              {
                month: "Feb",
                year: 2023,
              },
              {
                month: "Mar",
                year: 2023,
              },
            ],
      yearOfPassing: "2018",
      budget: {
        min: 5000,
        max:
          payload?.averageYearlyFees <= 1000
            ? 50000
            : payload?.averageYearlyFees,
      },
      neet: {
        year: 2020,
        score: 230,
        category: "OBC",
      },

      pageNumber: this.state.paginationData.currentPage || 1,
      pageSize: this.state.paginationData.recordsPerPage || 12,
    };

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };

    fetch(
      "https://hammerhead-app-ikpjl.ondigitalocean.app/get-mbbs-college",
      requestOptions
    )
      .then(async (response) => await response.json())
      .then(async (result) => {
        this.setState({ colleges: result.colleges });
        this.setState({
          paginationData: {
            totalItems: result?.totalItems,
            currentPage: result?.pageNumber,
            recordsPerPage: result?.pageSize,
            noOfPages: Math.ceil(result?.totalItems / result?.pageSize),
          },
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loading: false });
      });
  }

  searchedColleges(word) {
    this.setState({ loading: true });
    let body = {
      userId: this.props.appData?.userData?.userDetails?.userId,
      searchTerm: word,
      pageNumber: this.state.paginationData.currentPage || 1,
      pageSize: this.state.paginationData.recordsPerPage || 12,
    };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    fetch(
      "https://hammerhead-app-ikpjl.ondigitalocean.app/search-college-name",
      requestOptions
    )
      .then(async (response) => await response.json())
      .then(async (result) => {
        this.setState({ colleges: result.colleges });
        this.setState({
          paginationData: {
            totalItems: result?.totalItems,
            currentPage: result?.pageNumber,
            recordsPerPage: result?.pageSize,
            noOfPages: Math.ceil(result?.totalItems / result?.pageSize),
          },
        });

        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loading: false });
      });
  }

  setValue = (value, key) => {
    let stateObj = this.state;
    stateObj.data[key] = value;
    this.setState(stateObj);
  };

  markAsShortlsted = (c) => {
    let collegesObj = this.state.colleges.map((college) => {
      if (college.courseId === c.courseId) {
        return { ...college, isShortlisted: !college.isShortlisted };
      }
      return college;
    });

    let body = {
      userId: this.props.appData?.userData?.userDetails?.userId,
      courseId: c.courseId,
      shortlistType: "mbbs",
      dbId: c.shortlistDbId,
    };

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let req = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };

    fetch(
      "https://hammerhead-app-ikpjl.ondigitalocean.app/toggle-user-shortlist",
      req
    )
      .then(async (response) => await response.json())
      .then(async (result) => {})
      .catch((error) => {
        console.log("error", error);
      });
    // apisrv.callApi({
    //   endPoint: "update-user-shortlist",
    //   request: req,
    //   onSuccess: (res) => {
    //     let stateObj = this.state;
    //     stateObj.colleges = res.colleges;
    //     stateObj.paginationData.totalItems = res.totalItems;
    //     this.setState(stateObj);
    //   },
    // });

    this.setState({
      colleges: collegesObj,
    });
  };

  handleText = async (text) => {
    this.setState({ searchedWord: text });
    setTimeout(() => {
      this.searchedColleges(this.state.searchedWord);
    }, 1400);
  };

  onPageChange = (data) => {};

  renderFilter = (fld) => {
    if (fld.type == "checkbox") {
      return (
        <View>
          {(fld.options || []).map((opt, opti) => {
            return (
              <View
                key={fld.label + (opti + 1)}
                style={[
                  styles.row,
                  styles.justifyStart,
                  styles.noWrap,
                  { marginTop: 10 },
                  styles.alignItemsCenter,
                ]}
              >
                <Input
                  data={{
                    type: "checkbox",
                    label: opt.label,
                    value:
                      this.state.data[fld.key] &&
                      this.state.data[fld.key].indexOf(opt.value) >= 0,
                    width: 17,
                    height: 17,
                    color: "#0C5397",
                    breakWord: "none",
                  }}
                  onChange={(v) => {
                    let value = v.value;
                    let stateObj = this.state;
                    if (!stateObj.data[fld.key]) {
                      stateObj.data[fld.key] = [];
                    }

                    let ind = stateObj.data[fld.key].indexOf(opt.value);
                    if (value && ind == -1) {
                      stateObj.data[fld.key].push(opt.value);
                    } else if (!value && ind >= 0) {
                      stateObj.data[fld.key].splice(ind, 1);
                    }
                    this.setState(stateObj);
                    this.getMbbsColleges(this.state.data);
                  }}
                />
              </View>
            );
          })}
        </View>
      );
    } else if (fld.key == "averageYearlyFees") {
      return (
        <View>
          <View style={[styles.row, styles.justifyCenter, styles.noWrap]}>
            <Input
              data={{ type: "slider" }}
              onChange={(v) => {
                this.setValue(v.value, fld.key);
                this.getMbbsColleges(this.state.data);
              }}
            />
          </View>
          <View
            style={[
              styles.row,
              styles.justifyCenter,
              styles.noWrap,
              styles.alignItemsCenter,
            ]}
          >
            <Text
              style={[
                styles.text,
                styles.font22,
                { color: this.state.selectedColor, zIndex: 99 },
                styles.noWrap,
                styles.breakNone,
              ]}
            >
              Upto
            </Text>
            <View
              style={{
                marginLeft: 16,
                boxShadow: "inset 0px 3px 6px #00000029",
                borderRadius: 5,
                borderColor: "#F3F2F7",
                borderWidth: 1,
                paddingHorizontal: 22,
                paddingVertical: 10,
              }}
            >
              <Text
                style={[
                  styles.text,
                  styles.font22,
                  { color: this.state.selectedColor, zIndex: 99 },
                  styles.noWrap,
                  styles.breakNone,
                ]}
              >
                $ {this.state.data[fld.key]}
              </Text>
            </View>
          </View>
        </View>
      );
    } else if (fld.type == "searchSelect") {
      return (
        <View style={{ marginTop: 14 }}>
          <TouchableOpacity
            onPress={() => {
              fld.open = !fld.open;
              this.setState({ selectionOpn: fld.open });
            }}
          >
            <View
              style={[
                styles.row,
                styles.justifyStart,
                styles.noWrap,
                {
                  borderBottomWidth: 1,
                  borderColor: "#5479CB",
                  width: 250,
                  paddingBottom: 8,
                },
              ]}
            >
              <Image
                source={{ uri: require("../assets/home_page/search_icon.svg") }}
                style={{ width: 17, height: 21, resizeMode: "contain" }}
              />
            </View>
          </TouchableOpacity>
          {fld.open ? (
            <View
              style={{ boxShadow: "0px 1px 3px #00000029", maxHeight: 400 }}
            >
              <ScrollView>
                {fld.options.map((opt, opti) => {
                  return (
                    <TouchableOpacity
                      key={fld.key + (opti + 1)}
                      style={[
                        styles.row,
                        styles.justifyStart,
                        styles.noWrap,
                        { paddingHorizontal: 18, paddingVertical: 5 },
                      ]}
                      onPress={() => {
                        let stateObj = this.state;
                        if (!stateObj.data[fld.key]) {
                          stateObj.data[fld.key] = [opt.value];
                        } else if (
                          stateObj.data[fld.key].indexOf(opt.value) == -1
                        ) {
                          stateObj.data[fld.key].push(opt.value);
                        } else {
                          let ind = stateObj.data[fld.key].indexOf(opt.value);
                          stateObj.data[fld.key].splice(ind, 1);
                        }
                        fld.open = false;
                        this.setState(stateObj);
                        this.getMbbsColleges(this.state.data);
                      }}
                    >
                      <Text
                        style={[
                          styles.text,
                          styles.breakNone,
                          { color: this.state.selectedColor },
                        ]}
                      >
                        {opt.label}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            </View>
          ) : (
            <View
              style={[
                styles.row,
                styles.justifyStart,
                styles.wrap,
                { maxWidth: 280 },
              ]}
            >
              {(this.state.data[fld.key] || []).map((v, vi) => {
                return (
                  <TouchableOpacity
                    key={fld.key + "value" + (vi + 1)}
                    style={[
                      styles.row,
                      styles.justifyStart,
                      {
                        paddingHorizontal: 12,
                        paddingVertical: 8,
                        backgroundColor: this.state.selectedColor,
                        borderRadius: 50,
                        margin: 4,
                      },
                      styles.alignItemsCenter,
                    ]}
                    onPress={() => {
                      let stateObj = this.state;
                      stateObj.data[fld.key].splice(vi, 1);
                      this.setState(stateObj);
                      this.getMbbsColleges(this.state.data);
                    }}
                  >
                    <Text
                      style={[
                        styles.text,
                        styles.textWhite,
                        styles.font14,
                        { marginRight: 10 },
                      ]}
                    >
                      {v}
                    </Text>
                    <FontAwesome name="remove" size={12} color={"#FFFFFF"} />
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
        </View>
      );
    }
  };

  render() {
    const indexOfLastRecord =
      this.state.paginationData.currentPage *
      this.state.paginationData.recordsPerPage;
    const indexOfFirstRecord =
      indexOfLastRecord - this.state.paginationData.recordsPerPage;
    const currentRecords = this.state.colleges.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            position: "sticky",
            top: 0,
            flex: 1,
            zIndex: 100,
            backgroundColor: "white",
          }}
        >
          <Text
            style={[
              styles.textPrimary,
              styles.title,
              {
                paddingTop: 20,
                marginBottom: 10,
                paddingVertical: 5,
                paddingHorizontal: 10,
                position: "sticky",
                top: 0,
                flex: 1,
                backgroundColor: "white",
                zIndex: 100,
              },
            ]}
          >
            Explore University
          </Text>
          {/* Filters--------------------------------------------------------------------------- */}
          <View
            style={[
              styles.row,
              styles.justifyStretch,
              styles.alignItemsCenter,
              {
                paddingVertical: 5,
                paddingHorizontal: 15,
                flexWrap: "wrap",
              },
            ]}
          >
            <View style={{ alignItems: "left", gap: 5, marginVertical: 10 }}>
              <Text style={[{ color: "#19BABA" }, styles.text, styles.font14]}>
                Choose your interest
              </Text>
              <Picker
                style={[
                  {
                    color: "#19BABA",
                    fontSize: 25,
                    fontWeight: "bold",
                    paddingVertical: 10,
                    paddingHorizontal: 20,
                    borderRadius: 5,
                    borderColor: "#F1F2F8",
                    backgroundColor: "rgb(241, 242, 248)",
                  },
                ]}
                selectedValue={this.state.data.interest}
                dropdownIconColor={"#19BABA"}
                // itemStyle={}
                onValueChange={(itemValue, itemIndex) => {
                  let stateObj = this.state;
                  stateObj.data.interest = itemValue;
                  stateObj.selectedColor =
                    this.state.pageConfig.interest[itemIndex].color;
                  this.setState(stateObj);
                }}
              >
                {(this.state.pageConfig.interest || []).map((o) => {
                  return (
                    <Picker.Item
                      key={o.value}
                      label={o.label}
                      value={o.value}
                    />
                  );
                })}
              </Picker>
            </View>
            {this.state.data.interest == "Other Courses" && (
              <View style={{ alignItems: "left", gap: 5 }}>
                <Text
                  style={[
                    { color: "rgb(84, 121, 203)" },
                    styles.text,
                    styles.font14,
                  ]}
                >
                  Select your study level
                </Text>
                <Picker
                  style={[
                    {
                      color: "rgb(84, 121, 203)",
                      fontSize: 25,
                      fontWeight: "bold",
                      paddingVertical: 10,
                      paddingHorizontal: 20,
                      borderRadius: 5,
                      borderColor: "#F1F2F8",
                      backgroundColor: "rgb(241, 242, 248)",
                    },
                  ]}
                  selectedValue={this.state.data.level}
                  dropdownIconColor={"#5479CB"}
                  // itemStyle={}
                  onValueChange={(itemValue, itemIndex) => {
                    let stateObj = this.state;
                    stateObj.data.level = itemValue;
                    this.setState(stateObj);
                  }}
                >
                  {(this.state.pageConfig.level || []).map((o) => {
                    return (
                      <Picker.Item
                        key={o.value}
                        label={o.label}
                        value={o.value}
                      />
                    );
                  })}
                </Picker>
              </View>
            )}

            <View
              style={{
                flexShrink: "unset",
                alignItems: "center",
                gap: 5,
                marginVertical: 10,
              }}
            >
              <Text
                style={[
                  {
                    color: this.state.selectedColor,
                    alignSelf: "flex-start",
                    marginHorizontal: 10,
                  },
                  styles.text,
                  styles.font14,
                ]}
              >
                Refine your search
              </Text>
              <View
                style={[
                  styles.row,
                  {
                    justifyContent: "space-between",
                    gap: 20,
                    flexWrap: "wrap",
                    flexShrink: 2,
                  },
                ]}
              >
                {(this.state.pageConfig[this.state.data.interest] || []).map(
                  (f, fi) => {
                    return (
                      <View
                        key={"filter" + (fi + 1)}
                        onLayout={(event) => {
                          const layout = event.nativeEvent.layout;
                          let stateObj = this.state;
                          f.top = layout.top + layout.height + 6;
                          f.left = layout.left;
                          if (
                            !stateObj.pageConfig[this.state.data.interest].top
                          ) {
                            stateObj.pageConfig[this.state.data.interest].top =
                              layout.top + layout.height + 6;
                            stateObj.pageConfig[this.state.data.interest].left =
                              layout.left;
                            this.setState(stateObj);
                          }
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            paddingVertical: 10,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                            marginHorizontal: 10,
                            backgroundColor: "rgb(241, 242, 248)",
                          }}
                          onPress={() => {
                            if (this.state.selectedFilter == f.key) {
                              this.setState({ selectedFilter: null });
                            } else {
                              this.setState({ selectedFilter: f.key });
                            }
                          }}
                        >
                          <Text
                            style={[
                              styles.text,
                              styles.textMedium,
                              { color: this.state.selectedColor, fontSize: 20 },
                            ]}
                          >
                            {f.label}
                          </Text>
                        </TouchableOpacity>
                        {/* Selected Filter */}
                        <Modal
                          animationType="slide"
                          transparent={true}
                          visible={this.state.selectedFilter == f.key}
                          onRequestClose={() => {}}
                        >
                          <TouchableOpacity
                            activeOpacity={1}
                            onPress={() => {
                              this.setState({
                                selectedFilter: null,
                              });
                            }}
                            style={{
                              flex: 1,
                              backgroundColor: "trnasparent",
                            }}
                          >
                            <View
                              style={{
                                marginLeft: Math.ceil(f.left),
                                marginTop: Math.ceil(f.top),
                                width: "fit-content",
                                backgroundColor: "#fff",
                                borderRadius: 10,
                                borderColor: "#F5F5F5",
                                paddingVertical: 14,
                                paddingHorizontal: 24,
                                elevation: 10,
                              }}
                            >
                              {(f.fields || []).map((fld, fldi) => {
                                return (
                                  <View
                                    key={f.key + "filter" + (fldi + 1)}
                                    style={{ zIndex: 99, marginBottom: 20 }}
                                  >
                                    <View
                                      style={[
                                        styles.row,
                                        styles.noWrap,
                                        {
                                          gap: 10,
                                          justifyContent: "space-between",
                                        },
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          styles.font22,
                                          styles.textMedium,
                                          {
                                            color: this.state.selectedColor,
                                            zIndex: 99,
                                          },
                                          styles.noWrap,
                                          styles.breakNone,
                                        ]}
                                      >
                                        {fld.label}
                                      </Text>
                                      {fldi === 0 && (
                                        <TouchableOpacity
                                          onPress={() => {
                                            this.setState({
                                              selectedFilter: null,
                                            });
                                          }}
                                          style={[
                                            styles.row,
                                            styles.justifyEnd,
                                          ]}
                                        >
                                          <Ionicons
                                            name="close-outline"
                                            size={24}
                                            color="black"
                                          />
                                        </TouchableOpacity>
                                      )}
                                    </View>
                                    {this.renderFilter(fld)}
                                  </View>
                                );
                              })}
                            </View>
                          </TouchableOpacity>
                        </Modal>
                      </View>
                    );
                  }
                )}
              </View>
            </View>
            <TouchableOpacity
              style={{ marginTop: 20, marginHorizontal: 10 }}
              onPress={() => {
                this.setState({
                  data: {
                    interest: "MBBS Abroad",
                    level: "Post Graduate",
                    university: "",
                  },
                });
                this.setState({ searchedColleges: [] });
                this.setState({ searchedWord: "" });
                this.getMbbsColleges();
              }}
            >
              <Text
                style={[
                  styles.text,
                  styles.font18,
                  styles.textMedium,
                  { color: "#" },
                ]}
              >
                Clear All
              </Text>
            </TouchableOpacity>
          </View>
          {/* Filter CHIPS --------------------------------------------------------------------- */}
          <View
            style={{
              marginVertical: 10,
              paddingVertical: 10,
              paddingHorizontal: 15,
              borderTopWidth: 1,
              borderBottomWidth: 1,
              borderColor: "#c2c2c2",
              flexDirection: "row",
              gap: 15,
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text
              style={[
                {
                  color: this.state.selectedColor,
                  paddingVertical: 10,
                },
                styles.text,
                styles.font18,
              ]}
            >
              Showing Universities as per your interes/choice
            </Text>

            <View style={{ flexDirection: "row", flex: 1 }}>
              {this.state.scrollX !== 0 && (
                <Pressable
                  android_ripple={false}
                  className="prev"
                  style={{
                    //   paddingHorizontal: 13,
                    //   borderRadius: 100,
                    //   backgroundColor: "white",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                    //   shadowColor: "#171717",
                    //   shadowOffset: { width: -2, height: 4 },
                    //   shadowOpacity: 0.2,
                    //   shadowRadius: 3,
                    padding: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => this.slide(-50)}
                >
                  <AntDesign name="left" size={10} color="black" />
                </Pressable>
              )}
              <ScrollView
                showsHorizontalScrollIndicator={false}
                horizontal={true}
                ref={this.scrl}
                onScroll={this.scrollCheck}
                contentContainerStyle={{
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  flex: 1,
                  padding: 5,
                  gap: 5,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  {this.state.data?.courseDuration?.map((item) => {
                    return (
                      <View
                        style={{
                          backgroundColor: "#0090ff",
                          borderRadius: 10,
                          color: "white",
                          margin: 5,
                          paddingHorizontal: 15,
                          paddingVertical: 5,
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <Text style={{ color: "white" }}>{item}</Text>

                        <Entypo
                          style={{
                            paddingVertical: 2,
                            paddingHorizontal: 3,
                            borderRadius: 100,
                            backgroundColor: "rgba(166,225,252,255)",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onPress={() => {
                            const newData =
                              this.state.data?.courseDuration?.filter(
                                (items) => items !== item
                              );

                            this.setState({
                              data: {
                                ...this.state.data,
                                courseDuration: newData,
                              },
                            });
                            setTimeout(() => {
                              this.getMbbsColleges(this.state.data);
                            }, 1000);
                          }}
                          name="cross"
                          size={10}
                          color="white"
                        />
                      </View>
                    );
                  })}
                  {this.state.data?.universityType &&
                    this.state.data?.universityType?.map((item) => {
                      return (
                        <View
                          style={{
                            backgroundColor: "#0090ff",
                            borderRadius: 10,
                            color: "white",
                            margin: 5,
                            paddingHorizontal: 15,
                            paddingVertical: 5,
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <Text style={{ color: "white" }}>{item}</Text>

                          <Entypo
                            style={{
                              paddingVertical: 2,
                              paddingHorizontal: 3,
                              borderRadius: 100,
                              backgroundColor: "rgba(166,225,252,255)",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onPress={() => {
                              const newData =
                                this.state.data?.universityType?.filter(
                                  (items) => items !== item
                                );

                              this.setState({
                                data: {
                                  ...this.state.data,
                                  universityType: newData,
                                },
                              });
                              setTimeout(() => {
                                this.getMbbsColleges(this.state.data);
                              }, 1000);
                            }}
                            name="cross"
                            size={10}
                            color="white"
                          />
                        </View>
                      );
                    })}
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  {this.state.data?.country?.map((item) => {
                    return (
                      <View
                        style={{
                          backgroundColor: "#0090ff",
                          borderRadius: 10,
                          color: "white",
                          margin: 5,
                          paddingHorizontal: 15,
                          paddingVertical: 5,
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <Text style={{ color: "white" }}>{item}</Text>

                        <Entypo
                          style={{
                            paddingVertical: 2,
                            paddingHorizontal: 3,
                            borderRadius: 100,
                            backgroundColor: "rgba(166,225,252,255)",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onPress={() => {
                            const newData = this.state.data?.country?.filter(
                              (items) => items !== item
                            );

                            this.setState({
                              data: {
                                ...this.state.data,
                                country: newData,
                              },
                            });
                            setTimeout(() => {
                              this.getMbbsColleges(this.state.data);
                            }, 1000);
                          }}
                          name="cross"
                          size={10}
                          color="white"
                        />
                      </View>
                    );
                  })}
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  {this.state.data?.location?.map((item) => {
                    return (
                      <View
                        style={{
                          backgroundColor: "#0090ff",
                          borderRadius: 10,
                          color: "white",
                          margin: 5,
                          paddingHorizontal: 15,
                          paddingVertical: 5,
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <Text style={{ color: "white" }}>{item}</Text>

                        <Entypo
                          style={{
                            paddingVertical: 2,
                            paddingHorizontal: 3,
                            borderRadius: 100,
                            backgroundColor: "rgba(166,225,252,255)",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onPress={() => {
                            const newData = this.state.data?.location?.filter(
                              (items) => items !== item
                            );

                            this.setState({
                              data: {
                                ...this.state.data,
                                location: newData,
                              },
                            });
                            setTimeout(() => {
                              this.getMbbsColleges(this.state.data);
                            }, 1000);
                          }}
                          name="cross"
                          size={10}
                          color="white"
                        />
                      </View>
                    );
                  })}
                  {this.state.data?.campusType?.map((item) => {
                    return (
                      <View
                        style={{
                          backgroundColor: "#0090ff",
                          borderRadius: 10,
                          color: "white",
                          margin: 5,
                          paddingHorizontal: 15,
                          paddingVertical: 5,
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <Text style={{ color: "white" }}>{item}</Text>

                        <Entypo
                          style={{
                            paddingVertical: 2,
                            paddingHorizontal: 3,
                            borderRadius: 100,
                            backgroundColor: "rgba(166,225,252,255)",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onPress={() => {
                            const newData = this.state.data?.campusType?.filter(
                              (items) => items !== item
                            );

                            this.setState({
                              data: {
                                ...this.state.data,
                                campusType: newData,
                              },
                            });
                            setTimeout(() => {
                              this.getMbbsColleges(this.state.data);
                            }, 1000);
                          }}
                          name="cross"
                          size={10}
                          color="white"
                        />
                      </View>
                    );
                  })}
                </View>
                {this.state.data?.averageYearlyFees && (
                  <View
                    style={{
                      backgroundColor: "#0090ff",
                      borderRadius: 10,
                      color: "white",
                      margin: 5,
                      paddingHorizontal: 15,
                      paddingVertical: 5,
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text style={{ color: "white" }}>
                      {this.state.data?.averageYearlyFees}
                    </Text>

                    <Entypo
                      style={{
                        paddingVertical: 2,
                        paddingHorizontal: 3,
                        borderRadius: 100,
                        backgroundColor: "rgba(166,225,252,255)",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onPress={() => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            averageYearlyFees: null,
                          },
                        });
                        setTimeout(() => {
                          this.getMbbsColleges(this.state.data);
                        }, 1000);
                      }}
                      name="cross"
                      size={10}
                      color="white"
                    />
                  </View>
                )}
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  {this.state.data?.month?.map((item) => (
                    <View
                      style={{
                        backgroundColor: "#0090ff",
                        borderRadius: 10,
                        color: "white",
                        margin: 5,
                        paddingHorizontal: 15,
                        paddingVertical: 5,
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          paddingHorizontal: 15,
                          backgroundColor: "#0090ff",
                          borderRadius: 10,
                          color: "white",
                        }}
                      >
                        {item?.map((it) => {
                          return <span style={{ margin: "0 10px" }}>{it}</span>;
                        })}
                      </Text>

                      <Entypo
                        style={{
                          paddingVertical: 2,
                          paddingHorizontal: 3,
                          borderRadius: 100,
                          backgroundColor: "rgba(166,225,252,255)",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onPress={() => {
                          const newData = this.state.data?.month?.filter(
                            (items) => items !== item
                          );

                          this.setState({
                            data: {
                              ...this.state.data,
                              month: newData,
                            },
                          });
                          setTimeout(() => {
                            this.getMbbsColleges(this.state.data);
                          }, 1000);
                        }}
                        name="cross"
                        size={10}
                        color="white"
                      />
                    </View>
                  ))}
                  {this.state.data?.year?.map((item) => {
                    return (
                      <View
                        style={{
                          backgroundColor: "#0090ff",
                          borderRadius: 10,
                          color: "white",
                          margin: 5,
                          paddingHorizontal: 15,
                          paddingVertical: 5,
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <Text style={{ color: "white" }}>{item}</Text>

                        <Entypo
                          style={{
                            paddingVertical: 2,
                            paddingHorizontal: 3,
                            borderRadius: 100,
                            backgroundColor: "rgba(166,225,252,255)",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onPress={() => {
                            const newData = this.state.data?.year?.filter(
                              (items) => items !== item
                            );

                            this.setState({
                              data: {
                                ...this.state.data,
                                year: newData,
                              },
                            });
                            setTimeout(() => {
                              this.getMbbsColleges(this.state.data);
                            }, 1000);
                          }}
                          name="cross"
                          size={10}
                          color="white"
                        />
                      </View>
                    );
                  })}
                </View>
              </ScrollView>
              {!this.state.scrollEnd && (
                <Pressable
                  style={{
                    padding: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    //   shadowColor: "#171717",
                    //   shadowOffset: { width: -2, height: 4 },
                    //   shadowOpacity: 0.2,
                    //   shadowRadius: 3,
                  }}
                  className="next"
                  onPress={() => this.slide(+50)}
                >
                  <AntDesign name="right" size={13} color="black" />
                </Pressable>
              )}
            </View>
          </View>
          {/* Results Bar */}
          <View
            style={{
              paddingHorizontal: 15,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              margin: 10,
              padding: 10,
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <Text
                style={[
                  {
                    color:
                      (this.state.data.interest == "Other Courses" &&
                        "#7A78B9") ||
                      (this.state.data.interest == "MBBS Abroad" && "#0C5397"),
                  },
                  styles.font22,
                  styles.fontBold,
                ]}
              >
                {this.state.data.interest}
              </Text>
              <View
                style={{ height: 30, borderWidth: 1, borderColor: "#7A78B9" }}
              ></View>
              <Text
                style={[
                  {
                    color:
                      (this.state.data.interest == "Other Courses" &&
                        "#7A78B9") ||
                      (this.state.data.interest == "MBBS Abroad" && "#0C5397"),
                  },
                  styles.font20,
                ]}
              >
                Showing all {this.state.colleges.length} results
              </Text>
            </View>

            <View
              style={[
                styles.justifyStart,
                styles.alignItemsCenter,
                {
                  flexDirection: "row",
                  paddingHorizontal: 18,
                  backgroundColor: "rgba(245,245,245,255)",
                  borderRadius: 30,
                  alignItems: "center",
                  gap: 10,
                  boxShadow: "rgba(0, 0, 0, 0.2) 5px 5px 20px",
                },
              ]}
            >
              <AntDesign name="search1" size={24} color="#0C5397" />
              <TextInput
                value={this.state.searchedWord}
                onChangeText={(text) => this.handleText(text)}
                placeholder="Search University"
                style={[
                  styles.bgWhite,
                  {
                    flex: 1,
                    paddingVertical: 15,
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    color: "#343A40",
                    backgroundColor: "transparent",
                    outlineStyle: "none",
                    width: 300,
                  },
                ]}
              />
            </View>
          </View>
        </View>

        {/* Result College */}
        <View
          style={[
            styles.row,
            styles.wrap,
            {
              display: "flex",
              justifyContent: "space-between",
              rowGap: 20,
              alignContent: "flex-start",
              alignItems: "flex-start",
              marginVertical: 0,
              backgroundColor: "white",
            },
          ]}
        >
          {this.state.loading ? (
            <View style={{ flex: 1, justifyContent: "center", padding: 100 }}>
              <ActivityIndicator size="large" />
            </View>
          ) : currentRecords.length > 0 ? (
            currentRecords.map((c, i) => {
              return (
                <View
                  key={c.courseId}
                  style={[
                    {
                      marginHorizontal: 20,
                      marginVertical: 25,
                      width: 350,
                      borderRadius: 10,
                      shadowColor: "#171717",
                      minHeight: 350,
                      boxShadow: "rgba(0, 0, 0, 0.2) 10px 15px 30px",
                      gap: 20,
                      overflow: "hidden",
                    },
                    styles.bgWhite,
                  ]}
                >
                  <ImageBackground
                    style={[
                      styles.row,
                      {
                        height: 200,
                        padding: 10,
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                      },
                    ]}
                    source={{ uri: c.bgImg }}
                  >
                    <TouchableOpacity
                      activeOpacity={1}
                      style={{
                        padding: 10,
                        backgroundColor: "white",
                        borderRadius: 100,
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 20px 30px",
                      }}
                      onPress={() => {
                        this.markAsShortlsted(c);
                      }}
                    >
                      {c?.isShortlisted ? (
                        <Ionicons
                          name="heart-sharp"
                          size={20}
                          style={{
                            color: "#B51700",
                          }}
                        />
                      ) : (
                        <AntDesign name="hearto" size={20} color="black" />
                      )}
                    </TouchableOpacity>
                  </ImageBackground>
                  <View
                    style={{
                      padding: 10,
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={[
                        styles.row,
                        styles.textAlignCenter,
                        styles.justifyCenter,
                        { flexDirection: "column" },
                      ]}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.props.navigation.navigate("CollegeDetails", {
                            courseId: c.courseId,
                            type: "mbbs",
                          });
                        }}
                      >
                        <Text
                          style={[
                            styles.text,
                            styles.textPrimary,
                            styles.fontBold,
                            styles.font18,
                          ]}
                        >
                          {c.name}
                        </Text>
                      </TouchableOpacity>
                      <Text
                        style={[styles.text, styles.textGrey1, styles.font18]}
                      >
                        {(c.address.city, c.address.country)}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.row,
                        styles.textAlignCenter,
                        styles.justifyCenter,
                        { gap: 10 },
                      ]}
                    >
                      <Text
                        style={[
                          styles.text,
                          styles.font14,
                          {
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            backgroundColor: "#f3f3f3",
                            borderRadius: 10,
                          },
                        ]}
                      >
                        {c.duration} years
                      </Text>

                      <Text
                        style={[
                          styles.text,
                          styles.font14,
                          {
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            backgroundColor: "#f3f3f3",
                            borderRadius: 10,
                          },
                        ]}
                      >
                        $ {c.fees} | ₹
                        {c.fees ? c.fees * this.state.dollartoInr : ""}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.row,
                        styles.textAlignCenter,
                        styles.justifyCenter,
                      ]}
                    >
                      {(c.testScores || []).map((s, si) => {
                        return (
                          <View
                            key={"testScore" + (i + 1) + (si + 1)}
                            style={[{ margin: 20 }]}
                          >
                            <View
                              style={[
                                styles.row,
                                styles.textAlignCenter,
                                styles.justifyCenter,
                              ]}
                            >
                              <Text
                                style={[
                                  styles.text,
                                  styles.textRed,
                                  styles.font18,
                                ]}
                              >
                                Avg. {s.name}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.row,
                                styles.textAlignCenter,
                                styles.justifyCenter,
                              ]}
                            >
                              <Text
                                style={[
                                  styles.text,
                                  styles.textPrimary,
                                  styles.fontBold,
                                  styles.font18,
                                ]}
                              >
                                {s.score}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                  <img
                    src={c.logoImg}
                    style={{
                      height: 60,
                      padding: 15,
                      borderRadius: 100,
                      backgroundColor: "white",
                      alignSelf: "flex-end",
                      position: "absolute",
                      left: 10,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 20px 30px",
                      top: "35%",
                    }}
                  />
                </View>
              );
            })
          ) : (
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Text style={{ fontWeight: "600", fontSize: 20 }}>
                Sorry No Results Match your Filter Types Please Try other
                filters
              </Text>
            </View>
          )}
        </View>

        {/* {this.state.colleges.length > 0 && (
          <Pagination_new
            nPages={this.state.paginationData.noOfPages}
            currentPage={this.state.paginationData.currentPage}
            setCurrentPage={this.setCurrentPage}
            // onPageChange={this.getMbbsColleges()}
          />
        )} */}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appData: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateData: async (parameter, callback) => {
      await dispatch(parameter);
      if (callback) {
        callback();
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollegeFinder);
