import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Modal, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getCollegeFees } from "../actions/collegeActions";
import Input from "../components/Input";
import { styles } from "../styles/styles";

const ApplyNowModal = ({ openApplyModal, setOpenApplyModal, type }) => {
	const dispatch = useDispatch();
	const shortListedColleges = useSelector(
		state => state.collegeData?.shortListedColleges
	);
	const [colleges, setColleges] = useState([]);
	const userDetails = useSelector(state => state.userData?.userDetails);

	const fees =
		type === "mbbs"
			? colleges?.filter(c => c.isSelected === true)?.length &&
			  colleges?.[0]?.mcpFees?.fees
			: colleges
					?.filter(c => c.isSelected === true)
					?.reduce((acc, val) => {
						return acc + val?.mcpFees?.fees;
					}, 0);

	const gst =
		type === "mbbs"
			? colleges?.filter(c => c.isSelected === true)?.length &&
			  colleges?.[0]?.mcpFees?.fees * (colleges?.[0]?.mcpFees?.gstRate / 100)
			: colleges
					?.filter(c => c.isSelected === true)
					?.reduce((acc, val) => {
						return acc + val?.mcpFees?.fees * (val?.mcpFees?.gstRate / 100);
					}, 0);

	useEffect(() => {
		setColleges(
			shortListedColleges?.map(college => {
				return {
					...college,
					isSelected: false,
					inTakeInput: college?.intake?.[0]?.value,
				};
			})
		);
	}, [shortListedColleges]);

	useEffect(() => {
		if (shortListedColleges && type !== "mbbs")
			dispatch(
				getCollegeFees({
					userId: userDetails?.userId,
					courseId:
						shortListedColleges
							.filter(col => col.shortlistType === "others")
							?.map(col => col.courseId) || [],
				})
			);
	}, [shortListedColleges]);

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={openApplyModal}
			onRequestClose={() => {
				console.log("closed");
			}}
		>
			<View
				style={[
					{
						boxShadow: "0px 5px 15px #00000029",
						backgroundColor: "#ffffff",
						position: "absolute",
						top: "10%",
						left: "20%",
						width: "60vw",
						borderRadius: 10,
						padding: 26,
					},
				]}
			>
				<View
					style={[styles.row, styles.alignItemsCenter, styles.justifyStretch]}
				>
					<View>
						<Text style={[styles.textPrimary, styles.font25, styles.fontBold]}>
							Apply to All Shortlisted Universities
						</Text>
					</View>
					<View>
						<TouchableOpacity
							onPress={() => {
								setOpenApplyModal(false);
							}}
						>
							<FontAwesome name={"remove"} size={30} color="#B51700" />
						</TouchableOpacity>
					</View>
				</View>
				<View
					style={[
						styles.row,
						styles.alignItemsCenter,
						styles.justifyStretch,
						{ marginTop: 88, padding: 12 },
					]}
				>
					<View style={[styles.justifyCenter, { width: "15%" }]}>
						<Input
							data={{ type: "checkbox", label: "Select All" }}
							onChange={({ value }) => {
								setColleges(
									colleges?.map(c => {
										return { ...c, isSelected: value };
									})
								);
							}}
							value={
								colleges?.filter(c => c?.isSelected === false)?.length > 0
									? false
									: true
							}
						/>
					</View>
					<View style={[styles.justifyCenter, { width: "40%" }]}>
						<Text style={[styles.text, styles.font20]}>University/Courses</Text>
					</View>
					{type !== "mbbs" && (
						<View style={[styles.justifyCenter, { width: "25%" }]}>
							<Text style={[styles.text, styles.font20]}>
								mycareerpathshala
							</Text>
							<Text style={[styles.text, styles.font20]}>FEES</Text>
						</View>
					)}
					<View style={[styles.justifyCenter, { width: "20%" }]}>
						<Text style={[styles.text, styles.font20]}>Intake</Text>
					</View>
				</View>
				{colleges?.map((c, i) => {
					return (
						<View
							key={c?.dbId}
							style={[
								styles.row,
								styles.alignItemsCenter,
								styles.justifyStretch,
								{
									marginTop: 12,
									backgroundColor: c.isSelected ? "#EFE2FE" : "#FFFFFF",
									borderColor: "#EFE2FE",
									borderRadius: 5,
									borderWidth: 1,
									padding: 12,
								},
							]}
						>
							<View style={[styles.justifyCenter, { width: "15%" }]}>
								<Input
									data={{
										type: "checkbox",
										label: "",
										value: c.isSelected,
									}}
									onChange={({ value }) => {
										setColleges(
											colleges?.map(col => {
												if (col?.courseId === c?.courseId)
													return { ...col, isSelected: value };
												return col;
											})
										);
									}}
								/>
							</View>
							<View style={[styles.justifyCenter, { width: "40%" }]}>
								<Text
									style={[styles.textPrimary, styles.font22, styles.fontBold]}
								>
									{c.name}
								</Text>
								<Text style={[styles.textPrimary, styles.font18]}>
									{c.course}
								</Text>
								<Text style={[styles.text, styles.font14]}>
									{c.address.city}, {c.address.country}
								</Text>
							</View>
							{type !== "mbbs" && (
								<View style={[styles.justifyCenter, { width: "25%" }]}>
									<Text style={[styles.textRed, styles.font25]}>
										{c?.mcpFees?.fees}
									</Text>
								</View>
							)}
							<View
								style={[
									styles.justifyCenter,
									{ width: "20%", marginRight: 10 },
								]}
							>
								<Input
									data={{
										type: "select",
										options: c?.intake,
										value: c?.inTakeInput || c?.inTake?.[0]?.value,
										color: "#0C5397",
									}}
									onChange={({ value }) => {
										setColleges(
											colleges?.map(col => {
												if (col?.courseId === c?.courseId)
													return { ...col, inTakeInput: value };
												return col;
											})
										);
									}}
								/>
							</View>
						</View>
					);
				})}
				<View
					style={[
						styles.row,
						styles.alignItemsCenter,
						styles.justifyStart,
						{ marginTop: 11, padding: 12 },
					]}
				>
					<View style={[{ width: "20%" }]}>
						<Text style={[styles.textPrimary, styles.font25]}>FEEs :</Text>
					</View>
					<View style={[{ width: "10%" }]}>
						<Text style={[styles.textRed, styles.fontBold, styles.font25]}>
							{fees}
						</Text>
					</View>
				</View>
				<View
					style={[
						styles.row,
						styles.alignItemsCenter,
						styles.justifyStart,
						{ marginTop: 11, padding: 12 },
					]}
				>
					<View
						style={[{ width: "20%", paddingBottom: 12 }, styles.brBotttomGrey]}
					>
						<Text style={[styles.textPrimary, styles.font25]}>GST(18%):</Text>
					</View>
					<View
						style={[{ width: "10%", paddingBottom: 12 }, styles.brBotttomGrey]}
					>
						<Text style={[styles.textRed, styles.fontBold, styles.font25]}>
							{gst}
						</Text>
					</View>
				</View>
				<View
					style={[
						styles.row,
						styles.alignItemsCenter,
						styles.justifyStart,
						{ padding: 12, paddingBottom: 0 },
					]}
				>
					<View>
						<Text style={[styles.textPrimary, styles.fontBold, styles.font25]}>
							Total Amount to be Paid
						</Text>
					</View>
				</View>
				<View
					style={[
						styles.row,
						styles.alignItemsCenter,
						styles.brBotttomGrey,
						styles.justifyEnd,
						{ width: "30%", padding: 12 },
					]}
				>
					<View style={[styles.justifyEnd]}>
						<Text style={[styles.textRed, styles.fontBold, styles.font25]}>
							{fees + gst}
						</Text>
					</View>
				</View>
				<View
					style={[
						styles.row,
						styles.alignItemsCenter,
						styles.justifyEnd,
						{ marginTop: 30 },
					]}
				>
					<TouchableOpacity
						style={[styles.btnRed, styles.justifyCenter]}
						onPress={() => {
							// 	setState({openApplyModal: true});
						}}
					>
						<Text
							style={[{ color: "#ffffff" }, styles.fontBold, styles.font18]}
						>
							Apply to selected universities
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		</Modal>
	);
};

export default ApplyNowModal;
