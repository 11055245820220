import {
	FETCH_APP_CONFIG,
	FETCH_FILTERS_DATA,
	SAVE_USER_FILTERS,
} from "../constants";

const initialState = {
	isFetchingAppConfig: false,
	isFetchingAppFilters: false,
	shortListedColleges: [],
	otherCoursesFilters: [],
};

export const appReducers = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_APP_CONFIG.REQUEST:
			return {
				...state,
				isFetchingAppConfig: true,
			};
		case FETCH_APP_CONFIG.SUCCESS:
			return {
				...state,
				isFetchingAppConfig: false,
				config: payload?.data,
			};
		case FETCH_APP_CONFIG.FAILURE:
			return {
				...state,
				isFetchingAppConfig: false,
			};
		case FETCH_FILTERS_DATA.REQUEST:
			return {
				...state,
				isFetchingAppFilters: true,
			};
		case FETCH_FILTERS_DATA.SUCCESS:
			return {
				...state,
				isFetchingAppFilters: false,
				[payload?.label]: payload?.options,
			};
		case FETCH_FILTERS_DATA.FAILURE:
			return {
				...state,
				isFetchingAppFilters: false,
			};
		case SAVE_USER_FILTERS.SUCCESS:
			return {
				...state,
				[payload?.data?.label]: payload?.data.data,
			};

		default:
			return state;
	}
};
