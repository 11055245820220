import { FETCH_APPLICATION_DATA } from "../constants";

const initialState = {
  colleges: [],
  data: null,
};

export const applicationReducers = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_APPLICATION_DATA.REQUEST:
      return {
        ...state,
        isFetchingApplicationData: true,
      };
    case FETCH_APPLICATION_DATA.SUCCESS:
      return {
        ...state,
        isFetchingApplicationData: false,
        colleges: { ...state.colleges, ...payload.data },
      };
    case FETCH_APPLICATION_DATA.FAILURE:
      return {
        ...state,
        isFetchingApplicationData: false,
      };
    default:
      return state;
  }
};
