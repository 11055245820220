import { FontAwesome, Ionicons } from "@expo/vector-icons";
import React, { useState } from "react";
import { Modal, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { styles } from "../../styles/styles";

const ShortListedCollegesList = ({
	shortListedColleges,
	shortlistType,
	setOpenApplyModal,
	getData,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [removeCollege, setRemoveCollege] = useState(null);

	const userDetails = useSelector(state => state.userData?.userDetails);

	const removeCollegeFromShortlist = () => {
		let body = {
			userId: userDetails?.userId,
			courseId: removeCollege?.courseId,
			shortlistType: "mbbs",
			dbId: removeCollege.shortlistDbId,
		};

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		let req = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: "follow",
		};

		fetch(
			"https://hammerhead-app-ikpjl.ondigitalocean.app/toggle-user-shortlist",
			req
		)
			.then(async response => await response.json())
			.then(async result => {
				if (result.success) {
					setIsModalOpen(false);
					setRemoveCollege(null);
					getData();
				}
			})
			.catch(error => {
				console.log("error", error);
			});
	};

	const removeSelectedCollege = () => {
		return (
			<Modal animationType="slide" transparent={true} visible={isModalOpen}>
				<View
					style={[
						{
							boxShadow: "0px 5px 15px #00000029",
							backgroundColor: "#ffffff",
							position: "absolute",
							top: "30%",
							left: "30%",
							width: "40vw",
							borderRadius: 10,
							padding: 35,
						},
					]}
				>
					<View
						style={[styles.row, styles.alignItemsCenter, styles.justifyStretch]}
					>
						<View></View>
						<View>
							<TouchableOpacity
								onPress={() => {
									setIsModalOpen(false);
									setRemoveCollege(null);
								}}
							>
								<FontAwesome name={"remove"} size={30} color="#B51700" />
							</TouchableOpacity>
						</View>
					</View>
					<View style={{ width: "100%", marginTop: 10 }}>
						<Text
							style={[
								styles.textRed,
								styles.font38,
								styles.textAlignCenter,
								styles.fontBold,
							]}
						>
							{`Remove ${removeCollege?.name} from your list`}
						</Text>
					</View>
					<TouchableOpacity
						style={[styles.btnRed, { marginTop: 30, marginHorizontal: "auto" }]}
						onPress={() => removeCollegeFromShortlist()}
					>
						<Text
							style={[
								{
									color: "#ffffff",
									fontWeight: "bold",
									fontSize: 20,
								},
							]}
						>
							Remove
						</Text>
					</TouchableOpacity>
				</View>
			</Modal>
		);
	};

	return (
		<>
			{removeSelectedCollege()}
			{shortListedColleges
				?.filter(col => col.shortlistType === shortlistType)
				?.map(c => (
					<View
						key={c.collegeId}
						style={[
							styles.boxShadow1,
							{
								margin: 15,
								padding: 15,
								maxWidth: "23%",
								borderRadius: 5,
							},
							styles.bgWhite,
						]}
					>
						<View
							style={[styles.row, styles.justifyStart, styles.alignItemsCenter]}
						>
							<View
								style={[
									{
										boxShadow: "0px 5px 35px #00000029",
										borderRadius: "50%",
										paddingVertical: 7,
										paddingHorizontal: 9,
									},
									styles.bgWhite,
									styles.justifyCenter,
								]}
							>
								<img src={c.logoImg} style={{ height: 50, width: 50 }} />
							</View>
							<View style={[{ marginLeft: 25 }]}>
								<Text style={[styles.text, styles.textRed, styles.font22]}>
									{c.country}
								</Text>
							</View>
							{shortlistType === "mbbs" && (
								<View style={{ marginLeft: 10 }}>
									<TouchableOpacity
										onPress={() => {
											setIsModalOpen(true);
											setRemoveCollege(c);
										}}
									>
										<Ionicons
											name="heart-sharp"
											size={40}
											style={{
												color: "#B51700",
											}}
										/>
									</TouchableOpacity>
								</View>
							)}
						</View>
						<View
							style={[
								styles.row,
								styles.textAlignCenter,
								styles.justifyCenter,
								{ marginTop: 27, marginHorizontal: 16 },
							]}
						>
							<Text
								style={[
									styles.text,
									styles.textPrimary,
									styles.fontBold,
									styles.font22,
								]}
							>
								{c.name}
							</Text>
						</View>
						<View
							style={[styles.row, styles.textAlignCenter, styles.justifyCenter]}
						>
							<Text style={[styles.text, styles.textGrey1, styles.font18]}>
								{c.address.city}, {c.address.country}
							</Text>
						</View>
						{shortlistType === "mbbs" && (
							<>
								<View
									style={[
										styles.row,
										styles.textAlignCenter,
										styles.justifyCenter,
										{ marginTop: 35 },
									]}
								>
									<TouchableOpacity
										style={[styles.btnRed, styles.justifyCenter]}
										onPress={() => {
											setOpenApplyModal(true);
										}}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font14,
											]}
										>
											Apply
										</Text>
									</TouchableOpacity>
								</View>
								<View
									style={[
										styles.row,
										styles.textAlignCenter,
										styles.justifyCenter,
										{ marginTop: 6 },
									]}
								>
									<TouchableOpacity
										style={[styles.btnPrimary, styles.justifyCenter]}
										onPress={() => {}}
									>
										<Text
											style={[
												{ color: "#ffffff" },
												styles.fontBold,
												styles.font14,
											]}
										>
											Contact Your Career Counsellor
										</Text>
									</TouchableOpacity>
								</View>
							</>
						)}
					</View>
				))}
		</>
	);
};

export default ShortListedCollegesList;
