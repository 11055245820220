import axios from "axios";
import {
  FETCH_USER_DATA,
  USER_LOGIN,
  USER_SIGN_UP,
  FETCH_APPLICATION_DATA,
} from "../constants";

const base_url = "https://hammerhead-app-ikpjl.ondigitalocean.app";

export const userSignUp = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_SIGN_UP.REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`${base_url}/sign-up`, body, config);

    dispatch({
      type: USER_SIGN_UP.SUCCESS,
      payload: {
        success: "success",
        data: data,
      },
    });
  } catch (error) {
    dispatch({ type: USER_SIGN_UP.FAILURE, payload: error.message });
  }
};

export const userLogin = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOGIN.REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${base_url}/sign-in`,
      { email, password },
      config
    );

    dispatch({
      type: USER_LOGIN.SUCCESS,
      payload: {
        success: "success",
        data: data,
      },
    });
  } catch (error) {
    dispatch({ type: USER_LOGIN.FAILURE, payload: error.message });
  }
};

export const getUserData = (userId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_DATA.REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${base_url}/get-user-data`,
      { userId },
      config
    );

    dispatch({
      type: FETCH_USER_DATA.SUCCESS,
      payload: {
        success: "success",
        data: data,
      },
    });
  } catch (error) {
    dispatch({ type: FETCH_USER_DATA.FAILURE, payload: error.message });
  }
};
