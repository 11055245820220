import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../actions/userActions";
import Application from "./Application";
import CollegeFinder from "./CollegeFinder";
import Profile from "./Profile";
import ShortlistedColleges from "./ShortlistedColleges";
import Sidebar from "./Sidebar";
import UserHome from "./UserHome";

const Dashboard = ({ navigation }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const userId = useSelector((state) => state?.userData?.userDetails?.userId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) dispatch(getUserData(userId));
  }, []);

  return (
    <View style={{ maxHeight: "100%", flexDirection: "row" }}>
      <View style={{ height: "100%", width: 100 }}>
        <Sidebar
          onSelect={(i) => {
            setSelectedIndex(i?.index);
          }}
        />
      </View>
      <View style={{ flex: 1 }}>
        {(selectedIndex == 0 && <UserHome navigation={navigation} />) ||
          (selectedIndex == 1 && <Profile />) ||
          (selectedIndex == 2 && <CollegeFinder navigation={navigation} />) ||
          (selectedIndex == 3 && <ShortlistedColleges />) ||
          (selectedIndex == 4 && <Application navigation={navigation} />)}
      </View>
    </View>
  );
};

export default Dashboard;
