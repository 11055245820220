import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "../../styles/styles";

const ShortlistedCourses = ({
	shortListedCourses,
	shortlistType,
	setOpenApplyModal,
}) => {
	return shortListedCourses
		?.filter(col => col.shortlistType === shortlistType)
		?.map(c => (
			<View
				key={c.courseId}
				style={[
					styles.boxShadow1,
					{
						margin: 15,
						padding: 15,
						maxWidth: "23%",
						borderRadius: 5,
					},
					styles.bgWhite,
				]}
			>
				<View
					style={[styles.row, styles.justifyStart, styles.alignItemsCenter]}
				>
					<View
						style={[
							{
								boxShadow: "0px 5px 35px #00000029",
								borderRadius: "50%",
								paddingVertical: 7,
								paddingHorizontal: 9,
							},
							styles.bgWhite,
							styles.justifyCenter,
						]}
					>
						<img src={c.logoImg} style={{ height: 50, width: 50 }} />
					</View>
					<View style={[{ marginLeft: 25 }]}>
						<Text style={[styles.text, styles.textRed, styles.font22]}>
							{c.country}
						</Text>
					</View>
				</View>
				<View
					style={[
						styles.row,
						styles.textAlignCenter,
						styles.justifyCenter,
						{ marginTop: 27, marginHorizontal: 16 },
					]}
				>
					<Text
						style={[
							styles.text,
							styles.textPrimary,
							styles.fontBold,
							styles.font22,
						]}
					>
						{c.courseName}
					</Text>
				</View>
				<View
					style={[styles.row, styles.textAlignCenter, styles.justifyCenter]}
				>
					<Text style={[styles.text, styles.textGrey1, styles.font18]}>
						{c.address.city}, {c.address.country}
					</Text>
				</View>
				<View
					style={[
						styles.row,
						styles.textAlignCenter,
						styles.justifyCenter,
						{ marginTop: 35 },
					]}
				>
					<TouchableOpacity
						style={[styles.btnRed, styles.justifyCenter]}
						onPress={() => {
							setOpenApplyModal(true);
						}}
					>
						<Text
							style={[{ color: "#ffffff" }, styles.fontBold, styles.font14]}
						>
							Apply
						</Text>
					</TouchableOpacity>
				</View>
				<View
					style={[
						styles.row,
						styles.textAlignCenter,
						styles.justifyCenter,
						{ marginTop: 6 },
					]}
				>
					<TouchableOpacity
						style={[styles.btnPrimary, styles.justifyCenter]}
						onPress={() => {}}
					>
						<Text
							style={[{ color: "#ffffff" }, styles.fontBold, styles.font14]}
						>
							Contact Your Career Counsellor
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		));
};

export default ShortlistedCourses;
