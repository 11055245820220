import axios from "axios";
import {
	GET_COLLEGE_DETAILS,
	GET_COLLEGE_FEES,
	GET_COLLEGE_LIST,
	GET_SHORTLISTED_COLLEGES,
} from "../constants";

const base_url = "https://hammerhead-app-ikpjl.ondigitalocean.app";

export const getCollegeDetails = (endPoint, body) => async dispatch => {
	try {
		dispatch({ type: GET_COLLEGE_DETAILS.REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const { data } = await axios.post(`${base_url}/${endPoint}`, body, config);

		dispatch({
			type: GET_COLLEGE_DETAILS.SUCCESS,
			payload: {
				success: "success",
				data: data,
			},
		});
	} catch (error) {
		dispatch({ type: GET_COLLEGE_DETAILS.FAILURE, payload: error.message });
	}
};

export const getCollegeList = (endPoint, body) => async dispatch => {
	try {
		dispatch({ type: GET_COLLEGE_LIST.REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const { data } = await axios.post(`${base_url}/${endPoint}`, body, config);

		dispatch({
			type: GET_COLLEGE_LIST.SUCCESS,
			payload: {
				success: "success",
				data,
			},
		});
	} catch (error) {
		dispatch({ type: GET_COLLEGE_LIST.FAILURE, payload: error.message });
	}
};

export const getShortlistedColleges = body => async dispatch => {
	try {
		dispatch({ type: GET_SHORTLISTED_COLLEGES.REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const { data } = await axios.post(
			`${base_url}/get-shortlisted-colleges`,
			body,
			config
		);

		dispatch({
			type: GET_SHORTLISTED_COLLEGES.SUCCESS,
			payload: {
				success: "success",
				data,
			},
		});
	} catch (error) {
		dispatch({
			type: GET_SHORTLISTED_COLLEGES.FAILURE,
			payload: error.message,
		});
	}
};

export const getCollegeFees = body => async dispatch => {
	try {
		dispatch({ type: GET_COLLEGE_FEES.REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const { data } = await axios.post(
			`${base_url}/get-course-fees`,
			body,
			config
		);

		dispatch({
			type: GET_COLLEGE_FEES.SUCCESS,
			payload: {
				success: "success",
				data,
			},
		});
	} catch (error) {
		dispatch({
			type: GET_COLLEGE_FEES.FAILURE,
			payload: error.message,
		});
	}
};
