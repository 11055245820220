import styled from 'styled-components/';

export const FeesTable = styled.div`
  table {
  }
  th {
    background-color: #0c5397;
    color: #fff;
    padding: 6px;
  }
  td{
      padding:8px  !important
  }
  th:first-child {
    width: 40%;
  }
 
  tr:nth-child(even){
    background-color: #E1EAEB;
  }
`;
